import React, {  useRef, useState } from "react";
import { BottomSheet } from 'react-spring-bottom-sheet';

const VoiceRecording = (props) => {
    const audioChunk = useRef([])
    const mediaRecorderRef = useRef(null)
    // eslint-disable-next-line
    const [recording, setRecording] = useState([])
    const { open, onhide, handleAddRecording } = props;
    const startRec = async () => {
        try {
            if (!(navigator.mediaDevices && navigator.mediaDevices.getUserMedia)) {
                // 'mediaDevices API or getUserMedia method is not supported in this browser.'
            } else {
                const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
                const mediaRecorder = new MediaRecorder(stream);
                mediaRecorder.ondataavailable = (e) => {
                    if (e.data.size > 0) audioChunk.current.push(e.data);
                }
                mediaRecorder.onstop = () => {
                    const audioBlob = new Blob(audioChunk.current, { type: "audio/mp3" });
                    if (audioBlob) handleAddRecording(audioBlob)
                    // const audioUrl = URL.createObjectURL(audioBlob);
                    // setRecording(prev => [...prev, audioUrl]);
                }
                mediaRecorderRef.current = mediaRecorder;
                mediaRecorder.start();
            }
        } catch (error) {
            console.log(error)
        }
    }

    const stopRec = () => {
        if (mediaRecorderRef.current && mediaRecorderRef.current?.state === 'recording') mediaRecorderRef.current.stop()
    }

    // const deleteRec = (audioURL) => {
    //     const recording = recording.filter(a => a !== audioURL);
    //     setRecording(recording);
    // }

    return (
        <BottomSheet
            open={open}
            defaultSnap={({ snapPoints, lastSnap }) => lastSnap ?? snapPoints[1]}
            snapPoints={({ minHeight, maxHeight }) => [maxHeight * 80 / 100]}
            expandOnContentDrag={true}
            skipInitialTransition
            blocking={false}
            header={
                <div className="dialogBox__title">
                    <h2>Voice Recording</h2>
                </div>
            }
            footer={
                <>
                    <div className="bottomActionBtn">
                        <button className="btn btn-primary size30rem" onClick={() => {
                            onhide()
                        }}>Confirm</button>
                    </div>
                    <span className="createTagDiloag__handleBottom"></span>
                </>
            }
            onDismiss={() => {
                onhide()
            }}
        >
            <div>
                <button onClick={startRec}> start recording</button>
                <button onClick={stopRec}> stop recording</button> <br />
                {
                    recording.map((rec, i) =>
                        <div className="common-slider__items event_img" key={i}>
                            <button
                                className="cross-red-btn"
                                onClick={() => {

                                }}
                                style={{ top: '5px' }}
                            ></button>
                            <audio controls={true} controlsList="nodownload">
                                <source src={rec} type="audio/mpeg" />
                            </audio>
                        </div>
                    )
                }
            </div>
        </BottomSheet>
    )

};

export default VoiceRecording;
