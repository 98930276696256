import $ from 'jquery/dist/jquery';

export function inputPlaceHolders() {
  $(".input__field").each(function () {
    var currentInput = this;

    if ($(currentInput).val() !== "") {
      $(currentInput).parents('fieldset').addClass("input--filled");
    }

    $(currentInput).off();
    $(currentInput).on('focus', onFocus);
    $(currentInput).on('blur', onBlur);
  });

  function onFocus() {
    $(this).parents('fieldset').addClass('input--filled');
  }

  function onBlur(c) {
    if ($(this).val() === "") {
      $(this).parents('fieldset').removeClass("input--filled");
    }
  }
}

export function isEmail(email) {
  var reg = /^(([^<>()\\.,;:\s@"]+(\.[^<>()\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return reg.test(String(email).toLowerCase());
}

export function validateEmail(email) {
  // eslint-disable-next-line
  const pattern = /[a-zA-Z0-9]+[\.]?([a-zA-Z0-9]+)?[\@][a-z]{3,20}[\.][a-z]{2,5}/g;
  const result = pattern.test(email);
  return result
}

export function isEmpty(str) {
  // return str === null || str.match(/^ *$/) !== null;
  if (str.trim() === '') return true;
}

export function isOnlyNumber(mobile) {
  var pattern = new RegExp(/^[0-9\b]+$/);
  if (!pattern.test(mobile)) {
    return false
  }
  return true
}

export function isMobileTenDigits(mobile) {
  if (mobile.length !== 10) {
    return false
  }
  return true
}

export function isNumeric(val) {
  var reg = /^\s*[+-]?(\d+|\.\d+|\d+\.\d+|\d+\.)(e[+-]?\d+)?\s*$/;
  return reg.test(val);
}


export function maxLength(val) {
  if (val.length >= 250) {
    return true
  }
  return false
}

export function formatDate(date) {
  var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2)
    month = '0' + month;
  if (day.length < 2)
    day = '0' + day;

  return [year, month, day].join('-');
}

export function sidebarMenuNew() {
  $('#sideMenuTigger').click(function (event) {
    $('.sidebar').toggleClass('opened');
    $('.overlay').toggleClass('showOvr');
    $(this).toggleClass('closeMenu');
    event.stopPropagation();
    $("body").click(function (event) {
      $('.sidebar').removeClass('opened');
      $('#sideMenuTigger').removeClass('closeMenu');
    });
  });
}

export function minDateValue(customDate) {
  let date = customDate;
  return new Date(date.getFullYear(), date.getMonth(), (date.getDate() + 1));
}


export function webMenuTigger() {
  $('#webMenuTigger').click(function () {
    $('.mainNav').toggleClass('mobileMenuOpen');
    $(this).toggleClass('closeMenu');
  });
}

export function secondsToDhms(seconds) {
  seconds = Number(seconds);
  var d = Math.floor(seconds / (3600 * 24));
  var h = Math.floor(seconds % (3600 * 24) / 3600);
  var m = Math.floor(seconds % 3600 / 60);

  var dDisplay = d > 0 ? d + (d === 1 ? " Day, " : " Days, ") : "";
  var hDisplay = h > 0 ? h + (h === 1 ? " Hour, " : " Hours, ") : "";
  var mDisplay = m > 0 ? m + (m === 1 ? " Minute, " : " Minutes, ") : "";
  return dDisplay + hDisplay + mDisplay;
}

let eventGuid = 0
let todayStr = new Date().toISOString().replace(/T.*$/, '') // YYYY-MM-DD of today

export const INITIAL_EVENTS = [
  {
    id: createEventId(),
    title: 'All-day event',
    start: todayStr
  },
  {
    id: createEventId(),
    title: 'Timed event',
    start: todayStr + 'T12:00:00'
  }
]

export function createEventId() {
  return String(eventGuid++)
}

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const tagTypeDetail = {
  "Memory": {
    "short_desc": "Memory Tag Short Descr",
    "long_desc": "As one of Wexxle’s essential tag types this tag will unlock the power of remembrance with the Memory Tag type from Wexxle. This innovative tool allows you to capture, organize, and relive your most cherished moments and experiences. Integrate images, notes, and descriptions to create a rich tapestry of memories that you can revisit anytime, anywhere. Whether it's preserving special moments with loved ones or cataloging important information, the Memory Tag App is your digital vault for all things unforgettable. Experience the joy of nostalgia and the convenience of modern technology in one beautifully designed app"
  }
  ,
  "Task": {
    "short_desc": "Task tags are used to describe tasks that need to be completed. They can track progress, assign tasks to team members, and set deadlines.",
    "long_desc": "Elevate your productivity with the Task App by Wexxle. This versatile tool is your personal task manager, designed to streamline your daily life. Effortlessly create, organize, and prioritize tasks to stay on your to-do list. With intuitive features, customizable categories, and friendly reminders, Task empowers you to achieve your goals, whether completing work assignments, managing household chores, or tackling personal projects. Take control of your tasks, reduce stress, and maximize your time with the Task App. Your efficient, organized life starts here."
  }
  ,
  "Event": {
    "short_desc": "Event tags are used to describe events that are happening or will happen. They can plan events, invite people, and track attendance.",
    "long_desc": "Experience seamless event planning with the Wexxle Event App. Whether you're organizing a corporate conference, a birthday celebration, or a community gathering, this app is your go-to companion. Create, manage, and promote events effortlessly with intuitive tools and customizable templates. Stay connected with attendees through real-time updates, ticketing, and interactive features. Plus, our AI-driven recommendations help you discover exciting events in your area. Elevate your event planning game and make every occasion memorable with the Wexxle Event App. Your perfect event is one tag away."
  }
  ,
  "Asset": {
    "short_desc": "Asset tags are used to describe assets that are owned or operated by the owner or a company. They can track the location of assets, manage inventory, and ensure that assets are adequately maintained.",
    "long_desc": "Unlock the full potential of your digital and physical assets with the Wexxle Assets App. Organize, manage, and track your possessions effortlessly, from valuable collectibles to important documents. This powerful app provides a user-friendly interface, allowing you to create Virtual Asset Tags for each item you own, complete with detailed descriptions, images, and location data. Whether safeguarding your assets for insurance purposes or keeping track of your inventory, the Assets App streamlines the process. Plus, you can easily share and collaborate on assets with others or make them public and add Assets to the marketplace, making it a valuable tool for individuals and businesses. Elevate your asset management with Wexxle, where every item becomes a digital gem."
  },
  "Ecommerce": {
    "short_desc": "eCommerce tags describe products and services that the owner or company sells. They can be used to track sales, manage inventory, and ensure that products are properly marketed.",
    "long_desc": "Welcome to the future of online shopping with the Wexxle eCommerce App template. Shop smarter, discover unique products, and connect with your favorite brands in one place. Our app offers a seamless and secure shopping experience with various items, from fashion and electronics to handmade crafts. Browse personalized recommendations, enjoy exclusive deals, and create your virtual storefront by distance. With integrated wexxle payment options and real-time tracking, you can shop with confidence. Elevate your eCommerce journey with Wexxle - where shopping meets innovation."
  },
  "Post": {
    "short_desc": "Media tags are used to describe media files that are owned or operated by the owner or company. They can track the location of media files, manage permissions, and ensure they are appropriately stored.",
    "long_desc": "Experience a new media sharing and consumption dimension with the Wexxle Media App. This innovative app transforms how you interact with images, videos, and audio content. Seamlessly merge your real-world experiences with augmented reality (AR) by attaching Media Virtual Asset Tags to your media files. Share your memories and stories in exciting new ways by embedding them into specific locations or objects. Explore a treasure trove of user-generated AR-enhanced content, from interactive photos to immersive videos, accessible through the Wexxle platform. The Media App empowers you to curate, share, and discover media like never before, turning every moment into an interactive adventure. Start your journey in the world of augmented reality media with Wexxle."
  },
  "Spotify": {
    "short_desc": "Enhance your music experience with the Wexxle Spotify App. Seamlessly integrate your Spotify playlists into the augmented reality world. Share music in unique ways using Media Virtual Asset Tags. Explore and discover musical surprises in your surroundings. Elevate your music listening with the Wexxle Spotify App.",
    "long_desc": "Unlock the full potential of your music library with the Wexxle Spotify App. This dynamic app integrates your favorite Spotify playlists and tracks with augmented reality (AR) technology. Say goodbye to traditional listening and immerse yourself in a truly interactive musical experience. Use Media Virtual Asset Tags to link specific songs to real-world locations, objects, or moments. Share your music like never before by leaving musical surprises for friends and fellow users to discover. As you explore, let the Wexxle Spotify App guide you to hidden musical gems scattered throughout your surroundings. It's time to turn your world into a personal concert hall with the Wexxle Spotify App and experience music in a new dimension."
  },
  "Quick": {
    "short_desc": "Swiftly create, customize, and share virtual asset tag scenes with the Quick Tag App from Wexxle. Effortlessly mark locations, objects, and memories with personalized Tags. Enhance your augmented reality experience with this intuitive tagging tool.",
    "long_desc": "The Quick Tag App by Wexxle revolutionizes how you interact with the world through augmented reality. With this powerful yet user-friendly application, you can instantly create virtual asset tags known as Tags. Whether exploring a new city, capturing memorable moments, or organizing your environment, the Quick Tag App simplifies the process to Create tags easily, attaching them to physical locations, objects, or experiences. To enrich your memories, personalize each tag with text, images, and audio notes by adding Tag Templates. Share your tags with friends and family, making reliving and revisiting special moments easy.This app seamlessly integrates with Wexxle's AR Browser, enhancing your surroundings with a layer of digital information and memories. Explore your world in a new way by tagging and sharing your discoveries.The Quick Tag App empowers users to interact with augmented reality effortlessly, transforming everyday experiences into lasting memories. Start tagging your world today with the Quick Tag App from Wexxle."
  }

}


export const metaTagContent = (title, description) => {
  title = title?.substring(0, 70);
  description = description?.substring(0, 160);
  return {
    "dashboard": {
      "title": "Dashboard",
      "description": "Track your assets by location using Wexxle tags All users have access to add-ons extending capabilities",
      "keywords": "Virtual Asset Tags, GIS Integration, Augmented Reality, AR Search Browser, Quick-Tags, In-App Purchases, Parental Controls, Map Browser, AI Vision Module, Multiplayer Gaming Experience, Active Tags,ADMAT, AI Image Reader, AI Integration, Tag Templates, Tag Ecosystem, Tag Gamification Tag, Tag ID Verification, Tag News Feed, Tag Proximity Detection, Tag User Profiles, Chores Game App, Content Sharing, Data Processing, Deaf Accessibility, Developer Tools, Digital Content, Emergency Situations, Location-Based Services, Marketplace Advertising, Media Tag Type, Memory Tag Type, Parental Controls, Personalized Ads, Physical Active Tags, Receipt Management, Tag Creation, Tag Management, Task Tag Type, User Adaptations, Virtual Tags, Voice Accessibility, Wearable Devices."
    },
    "calendar": {
      "title": "Calendar",
      "description": "Wexxle Calendar Service",
      "keywords": "calendar, tags"
    },
    "in_app_email": {
      "title": "Email",
      "description": "Wexxle Email Service",
      "keywords": "email, tags, setting "
    },
    "profile": {
      "title": "Profile",
      "description": "Wexxle Profile Service",
      "keywords": "profile, users"
    },
    "setting": {
      "title": "Setting",
      "description": "Wexxle Setting Service",
      "keywords": "setting, marketplace, switch , facelock"
    },
    "chat": {
      "title": "Chat",
      "description": "Wexxle Chat Service",
      "keywords": "chat, users, friend, family, individual"
    },
    "map": {
      "title": "Map",
      "description": "Wexxle Map Service",
      "keywords": "map, google map, wexxle map, tags, colors, size"
    },
    "recycle_bin": {
      "title": "Recycle Bin",
      "description": "Wexxle Recycle Bin Service",
      "keywords": "react, meta tags, seo"
    },
    "people_profile": {
      "title": "Search People Profile",
      "description": "Wexxle Search People Profile Service",
      "keywords": "people, connection, search, profile"
    },
    "connection": {
      "title": "Connection",
      "description": "Wexxle Connection Service",
      "keywords": "friend, family, individual, watcher, blocked, request sent, receive request, watching"
    },
    "app_store": {
      "title": "App Store",
      "description": "Wexxle App Store Service",
      "keywords": "tags, apps"
    },
    "market_place": {
      "title": "Marketplace",
      "description": "Wexxle Marketplace Service",
      "keywords": "marketplace, buy, sell, recent"
    },
    "place": {
      "title": "Places",
      "description": "Wexxle Places Service",
      "keywords": "location, place"
    },
    "navigation": {
      "title": "Navigation",
      "description": "Wexxle Navigation Service",
      "keywords": "react, meta tags, seo"
    },
    "personal_advertisement": {
      "title": "Personal Advertisement",
      "description": "Wexxle Personal Advertisement Service",
      "keywords": "public, public tags, tags charges"
    },
    "help_&_support": {
      "title": "Help & Support ",
      "description": "Wexxle Help & Support Service",
      "keywords": "help & support, ticket, chat"
    },
    "assets_detail": {
      "title": title || 'Asset',
      ...(description && { "description": description, }),
      "keywords": "asset, asset tag, asset tag detail"
    },
    "ecommerce_detail": {
      "title": title || 'Ecommerce',
      ...(description && { "description": description, }),
      "keywords": "ecommerce, ecommerce tag, ecommerce tag detail"
    },
    "event_detail": {
      "title": title || 'Event',
      ...(description && { "description": description, }),
      "keywords": "event, event tag, event tag detail"
    },
    "media_detail": {
      "title": title || 'Media',
      ...(description && { "description": description, }),
      "keywords": "media, post, media tag, post tag, media tag detail, post tag detail"
    },
    "quick_detail": {
      "title": title || 'Quick',
      ...(description && { "description": description, }),
      "keywords": "quick, quick tag, quick tag detail"
    },
    "memory_detail": {
      "title": title || 'Memory',
      ...(description && { "description": description, }),
      "keywords": "memory, memory tag, memory tag detail"
    },
    "news_detail": {
      "title": title || 'News',
      ...(description && { "description": description, }),
      "keywords": "news, news tag, news tag detail"
    },
    "scene_detail": {
      "title": title || 'Scene',
      ...(description && { "description": description, }),
      "keywords": "scene, scene tag, scene tag detail"
    },
    "spotify_detail": {
      "title": title || 'Spotify',
      ...(description && { "description": description, }),
      "keywords": "spotify, spotify tag, spotify tag detail"
    },
    "task_detail": {
      "title": title || 'Task',
      ...(description && { "description": description, }),
      "keywords": "task, task tag, task detail"
    },
    "t&c": {
      "title": "Term & Condition",
      "description": "Wexxle Term & Condition Service",
      "keywords": "term & condition"
    }
  }
}