import React from 'react';
import { toast } from 'react-toastify/dist/react-toastify.esm';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import GoogleLogin from 'react-google-login';
import AppleLogin from 'react-apple-login/dist/index';
import { socialLogin } from '../../api/LoginSignupApi';
import * as myConstants from "../../Helper/constants";
import jwt_decode from "jwt-decode";
import connect from 'react-redux/es/connect/connect';
import { childPermission } from '../../redux/actions/childPermission';
import { register } from '../../Helper/webPushRegister'
import { enablesetting } from '../../redux/actions/onBoardingActions';
import { gapi } from "gapi-script"
import $ from 'jquery/dist/jquery'
class LoginWithSocialMedia extends React.PureComponent {
	constructor(props) {
		super(props)
		this.token = localStorage.getItem("Default")
		this.state = {
			fbloading: false,
			gloading: false,
			apploading: false
		}
	}

	responseFromSocialMedia = (response, domain) => {
		if (response && domain === "google") {
			if (response.profileObj && response.profileObj.email) {
				const { imageUrl } = response?.profileObj || {};
				this.handleSocialLogin(response.profileObj.email, response.profileObj.name, 'google', response.googleId, imageUrl);
			} else if (response.error) {
				// toast.error(response.error);
			}
		} else if (response && domain === "facebook") {
			const { data } = response?.picture || {};
			const { url } = data || {};
			if (response.email) {
				this.handleSocialLogin(response.email, response.name, 'facebook', response.userID, url);
			} else if (response.status) {
				// if (response.status === "unknown") toast.error("Something Went Wrong, Try again!!");
			}
		} else if (response && domain === "apple") {
			let username = response.user ? (response.user.name ? response.user.name.firstName + " " + response.user.name.lastName : "") : ""
			if (response.authorization && response.authorization.id_token) {
				let decoded = jwt_decode(response.authorization.id_token)
				this.handleSocialLogin(decoded.email, username, 'apple', "NULL");
			} else if (response.error) {
				// toast.error(response.error.error);
			}
		}
	}

	handleSocialLogin = async (email, name, domain, token, image) => {
		try {
			var params = {};
			params.email = email;
			params.name = name;
			params.social_media_type = domain;
			params.access_token = token;
			// params.image = image || "";
			this.setState({
				...this.state,
				fbloading: domain === "facebook" && true,
				gloading: domain === "google" && true,
				apploading: domain === "apple" && true
			});
			let res = await socialLogin(params);
			if (res && res.success === true) {
				if (!["localhost", "127.0.0.1", ""].includes(window.location.hostname)) await register('news');
				localStorage.setItem('token', res.data.token)
				localStorage.setItem('id', res.data.ID ? res.data.ID : "")
				localStorage.setItem('email', res.data.email ? res.data.email : "")
				localStorage.setItem('firstName', res.data.first_name ? res.data.first_name : "")
				localStorage.setItem('lastName', res.data.last_name ? res.data.last_name : "")
				localStorage.setItem('displayName', res.data.display_name ? res.data.display_name : "")
				localStorage.setItem('isKid', res.data.is_kid === "1" ? "true" : "false")
				if (res && res.data.profile_img) localStorage.setItem('profile_image', myConstants.media_base_url + "/" + res.data.profile_img)
				else localStorage.setItem('profile_image', process.env.PUBLIC_URL + "/images/user-icon.svg")
				this.props.enablesetting(true)
				if (localStorage.getItem("referalcode")) localStorage.removeItem("referalcode");
				if (localStorage.getItem("invitecode")) localStorage.removeItem("invitecode");
				if (!this.token) this.props.checkLogin("")
				if (this.token && res.data.ID && res.data.token) {
					window.location.reload()
					localStorage.removeItem("Default")
				}
			} else if (res && res.success === false) {
				toast.error(res.message)
				console.log(res.message)
			}
			this.setState({
				...this.state,
				fbloading: domain === "facebook" && false,
				gloading: domain === "google" && false,
				apploading: domain === "apple" && false
			});
		} catch (error) {
			console.log(error.message)
			toast.error(error.message)
			this.setState({
				...this.state,
				fbloading: domain === "facebook" && false,
				gloading: domain === "google" && false,
				apploading: domain === "apple" && false
			});
		}
	}

	componentWillUnmount() {
		this.setState = (state, callback) => {
			return;
		};
	}


	componentDidUpdate(prevProps, prevState) {
		if ((prevState.fbloading !== this.state.fbloading) || (prevState.apploading !== this.state.apploading) || (prevState.gloading !== this.state.gloading)) {
			this.props.socialLoader(this.state.fbloading, this.state.gloading, this.state.apploading)
		}
	}

	start = () => {
		gapi.client.init({
			clientId: process.env.REACT_APP_GOOGLE_CLIENTID,
			scope: 'email',
		});
	}

	componentDidMount() {
		gapi.load('client:auth2', this.start)
		// add id for andriod to catch social login//
		if (!this.token) $("span[class='btn btn-social btn-fb']").attr("id", "facebookbutton")
		if (this.token) $("span[class='btn btn-social btn-fb guest_page_soci_spac']").attr("id", "facebookbutton")
		if (!this.token) $("span[class='btn btn-social btn-apple']").attr("id", "applebutton")
		if (this.token) $("span[class='btn btn-social btn-apple guest_page_soci_spac']").attr("id", "applebutton")
		if (!this.token) $("span[class='btn btn-social btn-google']").attr("id", "googlebutton")
		if (this.token) $("span[class='btn btn-social btn-google guest_page_soci_spac']").attr("id", "googlebutton")
	}

	render() {
		let { emailLoginLoader } = this.props
		return (
			<div className="socialBtnBlock">
				<FacebookLogin
					appId={process.env.REACT_APP_FB_APPID}
					fields="name,email,picture"
					autoLoad={false}
					callback={(response) => { this.responseFromSocialMedia(response, "facebook") }}
					onFailure={(error) => { this.responseFromSocialMedia(error, "facebook") }}
					render={renderProps => (
						<span className={
							this.state.fbloading ? "btn btn-social btn-fb cred_social" : (
								this.token ? "btn btn-social btn-fb guest_page_soci_spac" : "btn btn-social btn-fb"
							)} style={{ pointerEvents: this.state.fbloading || this.state.apploading || this.state.gloading || emailLoginLoader ? "none" : "" }} onClick={() => { renderProps.onClick() }}>
							<i className="socialIcon"><img src={process.env.PUBLIC_URL + "/images/fb-icon.svg"} alt="facebook" /></i>
							<span className="d-none d-md-inline  left_space">{this.state.fbloading ? "Loading" : "Connect with Facebook"}</span>
						</span>
					)}
				/>

				<AppleLogin
					clientId={process.env.REACT_APP_APPLE_CLIENTID}
					autoLoad={false}
					scope={"email name"}
					responseType={"id_token"}
					responseMode={"form_post"}
					redirectURI={process.env.REACT_APP_APPLE_REDIRECTURI}
					usePopup={true}
					callback={(response) => { this.responseFromSocialMedia(response, "apple") }}
					render={renderProps => (
						<span className={this.state.apploading ? "btn btn-social btn-apple cred_social" : (
							this.token ? "btn btn-social btn-apple guest_page_soci_spac" : "btn btn-social btn-apple"
						)} style={{ pointerEvents: this.state.fbloading || this.state.apploading || this.state.gloading || emailLoginLoader ? "none" : "" }} onClick={() => { renderProps.onClick() }}>
							<i className="socialIcon"><img src={process.env.PUBLIC_URL + "/images/apple-icon.svg"} alt="Apple" /></i>
							<span className="d-none d-md-inline left_space">{this.state.apploading ? "Loading" : "Connect with Apple"}</span>
						</span>
					)}
				/>

				{/* ["localhost", "127.0.0.1", ""].includes(window.location.hostname) ?
				process.env.REACT_APP_GOOGLE_CLIENTID_LOCAL
				: process.env.REACT_APP_GOOGLE_CLIENTID_LIVE */}
				<GoogleLogin
					clientId={process.env.REACT_APP_GOOGLE_CLIENTID}
					autoLoad={false}
					render={renderProps => (
						<span className={this.state.gloading ? "btn btn-social btn-google cred_social" : (
							this.token ? "btn btn-social btn-google guest_page_soci_spac" : "btn btn-social btn-google"
						)} style={{ pointerEvents: this.state.fbloading || this.state.apploading || this.state.gloading || emailLoginLoader ? "none" : "" }} onClick={() => { renderProps.onClick() }}>
							<i className="socialIcon"><img src={process.env.PUBLIC_URL + "/images/google-icon.svg"} alt="Google" /></i>
							<span className="d-none d-md-inline left_space">{this.state.gloading ? "Loading" : "Connect with Google"}</span>
						</span>
					)}
					onSuccess={(response) => { this.responseFromSocialMedia(response, "google") }}
					onFailure={(error) => { this.responseFromSocialMedia(error, "google") }}
					cookiePolicy={'single_host_origin'}
				/>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	permission: state,
})
const mapDispatchToProps = {
	childPermission,
	enablesetting
}


export default connect(mapStateToProps, mapDispatchToProps)(LoginWithSocialMedia);
