import { App_Listing, SetError, LoadUI, AppDetail, AppDetailPage, Reset, UPDATEAPP, GETAPPREVIEW, ENABLEAPPLIST } from '../types'

const initialstate = {
    AppList: [],
    AppList_: [],
    loader: false,
    AppDetail: {},
    Review: {},
    obj: {},
    checked: false,
    enableAppList: []
}

export const Listreducer = (state = initialstate, action) => {
    switch (action.type) {
        case App_Listing:
            return {
                ...state,
                loader: false,
                AppList_: action.payload,
                AppList: action.payload
            };
        case SetError:
            return {
                ...state,
                loader: false
            }
        case LoadUI:
            return {
                ...state,
                loader: true
            }
        case AppDetail:
            return {
                ...state,
                loader: false,
                AppDetail: action.payload
            }
        case AppDetailPage:
            // let index = state.AppList_.findIndex(val => val.id === action.payload.id)
            // if (index !== -1) {
            // action.payload.user_enabled ? state.AppList_[index].user_enabled = true : state.AppList_[index].user_enabled = false
            // }
            return {
                ...state,
                loader: false,
                AppDetail: action.payload
            }
        case Reset:
            return {
                ...state,
                AppList: [],
                loader: false,
                checked: false
            }
        case UPDATEAPP:
            return {
                ...state,
                loader: false,
                AppDetail: action.payload
            }
        case GETAPPREVIEW:
            return {
                ...state,
                loader: false,
                Review: action.payload
            }
        case ENABLEAPPLIST:
            return {
                ...state,
                loader: false,
                enableAppList: action.payload
            }
        default:
            return state;
    }
}

