import { LOADING_QRUI, CLEAR_ERRORS, CREATEQR, ENABLETOFETCHSETTING } from '../types'
import {toast} from 'react-toastify/dist/react-toastify.esm';
import $ from 'jquery/dist/jquery';
import { getIpv4 } from '../../Helper/commonFunction';
import { createQR } from '../../api/GraphqlApi';
export const createQRUrl = payload => async dispatch => {
    try {
        let { key, auth, open, user_id } = payload
        dispatch({ type: LOADING_QRUI })
        let ipv4 = await getIpv4();
        let response = await createQR(ipv4, key, auth, user_id)
        if (response.data && response.data.createLoginQR) {
            if (open) $("#qrCodeModal").modal("show");
            dispatch({
                type: CREATEQR,
                payload: Object.keys(response.data.createLoginQR).length ? response.data.createLoginQR : {}
            })
        } else {
            dispatch({ type: CLEAR_ERRORS })
        }
    } catch (error) {
        if (error.message) toast.error(error.message)
        else if (error && error.errors.length) toast.error(error.errors[0].message);
        else toast.error("Something Went Wrong!")
        dispatch({ type: CLEAR_ERRORS })
    }
}

export const enablesetting = payload => async dispatch => {
    dispatch({ type: ENABLETOFETCHSETTING, payload: payload })
}