import AWS from 'aws-sdk';
export const AwsConfig = () => {
    AWS.config.update({
        region: process.env.REACT_APP_AWS_REGION,
        accessKeyId: process.env.REACT_APP_AWS_ACCESSKEY,
        secretAccessKey: process.env.REACT_APP_AWS_SECRETKEY,
        signatureVersion: 'v4'
    });
    const s3 = new AWS.S3();
    return s3;
}