import { SET_UNAUTHENTICATED, SET_PEOPLEORTAG_SEARCH, PEOPLEORTAG_DETAIL, CLEAR_ERRORS, LOADING_UI } from '../types'
const initialState = {
    loading: false,
    searchList: [],
    searchDetail: {},

}

export default function searchPeopleOrTag(state = initialState, action) {
    switch (action.type) {
        case SET_UNAUTHENTICATED:
            return initialState;
        case LOADING_UI:
            return {
                ...state,
                searchList: action.payload,
                loading: true
            }
        case SET_PEOPLEORTAG_SEARCH:
            return {
                ...state,
                searchList: action.payload,
                loading: false
            }
        case PEOPLEORTAG_DETAIL:
            return {
                ...state,
                searchDetail: action.payload,
                loading: false
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                searchDetail: action.payload,
                loading: false
            }
        default:
            return state;
    }
}