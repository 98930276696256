import { CHAT_DETAIL, RESET_CHAT_USER } from '../types';

const initialstate = {
	chatObj: {},
};

const onChatreducer = (state = initialstate, action) => {
	switch (action.type) {
		case CHAT_DETAIL:
			return {
				chatObj: action.payload,
			};
		case RESET_CHAT_USER:
			return {
				chatObj: {},
			};
		default:
			return state;
	}
};

export default onChatreducer;
