import { HIDE_INSTALL_PROMPT } from '../types';

const initialState = {
	showInstallPrompt: true,
};

export const PwaRelatedData = (state = initialState, action) => {
	switch (action.type) {
		case HIDE_INSTALL_PROMPT:
			return {
				...state,
				showInstallPrompt: action.payload,
			};
		default:
			return state;
	}
};
