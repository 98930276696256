import { USERPROFILEDETAIL, PROFILE_UI, WALLETBALANCE, ADDRESSVERIFIED } from '../types'
import { getUserDetail } from '../../api/GraphqlApi';
import { getWalletBalance } from '../../api/WalletApi'
export const FetchUserDetail = () => async (dispatch) => {
    dispatch({ type: PROFILE_UI })
    let response = await getUserDetail("user_detail", localStorage.getItem("id"));
    dispatch({
        type: USERPROFILEDETAIL,
        payload: response && response.statusCode === 200 ? (
            response && response.body && response.body.length ? response.body[0] : {}
        ) : {}
    })

    // try {
    //     dispatch({ type: PROFILE_UI })
    //     let res = await AppsyncFetchuser(["first_name", "user_name", "last_name", "gender", "dob", "phone", "address", "email", "about_me"])
    //     if (res && res.data && res.data.get_user) {
    //         dispatch({
    //             type: USERPROFILEDETAIL,
    //             payload: Array.isArray(res.data.get_user) && res.data.get_user.length ?
    //                 res.data.get_user[0] : {}
    //         })
    //     } else if (res.errors !== undefined && res.errors.length > 0) {
    //         toast.error(res.errors[0].message)
    //         dispatch({ type: CLEAR_PROFILE_ERROR });
    //     } else if (res.statusCode) {
    //         Customerror(res.statusCode)
    //         dispatch({ type: CLEAR_PROFILE_ERROR });
    //     } else {
    //         toast.error("Something Went Wrong")
    //         dispatch({ type: CLEAR_PROFILE_ERROR });
    //     }
    // } catch (error) {
    //     console.log(error.message)
    //     toast.error(error.message)
    //     if (error.response) {
    //         Customerror(error.response.status)
    //     }
    //     dispatch({ type: CLEAR_PROFILE_ERROR });
    // }
}

export const fetchAddressVerified = (payload) => async (dispatch) => {
    dispatch({ type: ADDRESSVERIFIED, payload: payload })
}

export const getWalletBalancere = () => async (dispatch) => {
    let response = await getWalletBalance("get_user_balance");
    dispatch({
        type: WALLETBALANCE,
        payload: response && response.statusCode === 200 ? (
            response && response.body && response.body.data.length ? response.body.data[0] : {}
        ) : {}
    })
}