import { FETCHSPOTIFYTOKEN } from "../types";

const initialState = {
    access_token: null,
    refresh_token: null,
    expires_in: ""
}

export default function spotifyReducer(state = initialState, action) {
    switch (action.type) {
        case FETCHSPOTIFYTOKEN:
            const { payload } = action
            const { access_token, refresh_token } = payload;
            sessionStorage.setItem("access_token", access_token)
            sessionStorage.setItem('refresh_token', refresh_token)
            sessionStorage.setItem('expires_in', Date.now())
            return {
                ...state,
                access_token,
                refresh_token,
                expires_in: Date.now()
            };
        default:
            return state;
    }
}