import { PAYMENT_UI, CREATE_PAYMENT, PAYMENT_ERROR, FETCHCUSTOMER } from "../types";

const initialstate = {
    loader: false,
    paymentObject: {},
    error: {},
    customer: ""
}

export const paymentReducer = (state = initialstate, { type, payload }) => {
    switch (type) {
        case PAYMENT_UI:
            return {
                ...state,
                loader: true
            }
        case CREATE_PAYMENT:
            return {
                ...state,
                loader: false,
                paymentObject: payload ? payload : {}
            }
        case PAYMENT_ERROR:
            return {
                ...state,
                loader: false
            }
        case FETCHCUSTOMER:
            return {
                ...state,
                customer: payload ? payload : ""
            }
        default:
            return state;
    }
}