import { toast } from 'react-toastify/dist/react-toastify.esm';
import { AwsConfig } from '../Helper/Awsconfiguration'
import { fetchandDeleteById } from './commonFunction'
import { getMediaplayLog } from '../api/GraphqlApi';
const s3 = AwsConfig()

export const s3Imageupload = async (file) => {
    const params = {
        Bucket: 'wexxle-stage-s3',
        Body: file,
        Key: file.name.split('.').join('-' + Date.now() + '.'),
        ContentLength: file.size,
        ContentType: file.type
    }
    const stored = await s3.upload(params).promise()
    return stored;
}

export const uploadTagImage = async (params) => {
    const stored = await s3.upload(params).promise()
    return stored;
}

export const s3Imagefetch = async (key) => {
    // let Location = await s3.getSignedUrl("getObject", { Bucket: "wexxle-stage-s3", Key: key, Expires: 60 * 60 })
    // return Location;
    let Location = `${process.env.REACT_APP_MEDIA_BASE_URL}/${key}`
    return Location;
}

export const s3Imagedelete = async (key) => {
    try {
        const params = {
            Bucket: "wexxle-stage-s3",
            Key: key,
        };
        await s3.headObject(params).promise()  //check for object exist or not
        await s3.deleteObject(params).promise() //if exist  delete object
        console.log("Image Deleted Successfully")
        fetchandDeleteById(key);  // delete from wexxle storage
    } catch (err) {
        if (err.code === 'NotFound') {
            console.log("Image Not Found")
        } else {
            toast.error(err.message)
        }
    }
}

export const FetchImageInLoop = async (Detail, id) => {
    if (Array.isArray(Detail) && Detail.length) {
        return await Promise.all(Detail.map(async (val) => {
            var { media, comment_list } = val
            if (id) var playlist = await getMediaplayLog(id)
            if (Array.isArray(media) && media.length) {
                var mediaImage = []
                media.map(async (key) => {
                    var mediaObject = {}
                    let s3Image = await s3Imagefetch(key)
                    let ext = key.substring(key.lastIndexOf('.') + 1)
                    mediaObject["ext"] = ext
                    mediaObject["url"] = s3Image
                    if (['mp4', 'mp3'].includes(ext) && playlist && playlist.length) {
                        playlist.forEach((value) => {
                            const { extra, id } = value
                            let info = JSON.parse(extra)
                            if (info.key === key) {
                                mediaObject["id"] = id;
                                mediaObject["duration"] = info.duration;
                                mediaObject["currentTime"] = info.currentTime;
                            }
                        })
                    }
                    mediaImage.push(mediaObject);
                })
                val.image = mediaImage
            } else {
                val.image = [{ "ext": "", "url": "" }]
            }

            // For User Image of Comment
            if (Array.isArray(comment_list) && comment_list.length) {
                comment_list.map(async (value, index) => {
                    if (Array.isArray(value.user_detail) && value.user_detail.length) {
                        if (value.user_detail[0].profile_img) {
                            let commentPic = await s3Imagefetch(value.user_detail[0].profile_img)
                            val.comment_list[index].user_detail[0]["commentPic"] = commentPic
                        } else {
                            val.comment_list[index].user_detail[0]["commentPic"] = ""
                        }
                    }
                })
            }

            return val;
        }))
    }
}

export const ParticularImageFetch = async (Detail) => {
    if (Array.isArray(Detail) && Detail.length) {
        return await Promise.all(Detail.map(async (val, index) => {
            var { profile_img } = val
            if (profile_img) {
                let s3Image = await s3Imagefetch(profile_img)
                val.profile_image = s3Image
            } else {
                val.profile_image = ""
            }
            return val;
        }))
    }
}

export const ParticularnestedImageFetch = async (Detail) => {
    if (Array.isArray(Detail) && Detail.length) {
        return await Promise.all(Detail.map(async (val, index) => {
            if (val && val.recipient_detail[0]) {
                var UserDetail = val.recipient_detail[0].user_id !== localStorage.getItem("id") ?
                    val.recipient_detail[0] : val.sender[0]
                if (UserDetail) var { profile_img } = UserDetail;
                else profile_img = ""
                if (profile_img) {
                    let s3Image = await s3Imagefetch(profile_img)
                    val.profile_image = s3Image
                } else {
                    val.profile_image = ""
                }
            }
            return val;
        }))
    } else return [];
}


export const UploadthumbnailImage = async (file, key, module) => {
    const params = {
        Bucket: 'wexxle-stage-s3',
        Body: file,
        Key: `thumbnail/${module}/${key}`,
        ContentLength: file.size,
        ContentType: file.type
    }
    try {
        return s3.upload(params).promise();
    } catch (err) {
        toast.error(err.message)
    }
}