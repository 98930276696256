import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min';
import App from './App';
import * as serviceWorker from './serviceWorker';
import Amplify from "aws-amplify";
import awsExports from "./aws-exports";
import 'react-phone-number-input/style.css'
import "react-datepicker/dist/react-datepicker.css";
import 'react-calendar/dist/Calendar.css';
import "react-toastify/dist/ReactToastify.css";
import "react-image-crop/dist/ReactCrop.css";
import TimeAgo from 'javascript-time-ago'
import { store } from './redux/stores';
import { Provider } from 'react-redux';
import en from 'javascript-time-ago/locale/en.json'
import ru from 'javascript-time-ago/locale/ru.json'
import { BrowserRouter } from 'react-router-dom';

TimeAgo.addDefaultLocale(en)
TimeAgo.addLocale(ru)
Amplify.configure(awsExports);

// get unique key from url as params (In QR login use)
let params = new URLSearchParams(window.location.search);
let qrId = params.get("qrId");
if (qrId) localStorage.setItem("qrId", qrId);

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter  basename={document.querySelector('base')?.getAttribute('href') ?? '/' }>
      <App />
    </BrowserRouter>
  </Provider>,
  document.getElementById('root')
);
serviceWorker.register();
let deferredPrompt;
const addBtn = document.querySelector('.add-button');
if (addBtn) {
  addBtn.style.display = 'none';
}
window.addEventListener('beforeinstallprompt', function (e) {
  e.preventDefault();
  deferredPrompt = e;
  addBtn.style.display = 'block';
});

addBtn.addEventListener('click', (e) => {
  addBtn.style.display = 'none';
  deferredPrompt.prompt();
  deferredPrompt.userChoice
    .then((choiceResult) => {
      if (choiceResult.outcome === 'accepted') {
        console.log('User accepted the A2HS prompt');
      } else {
        console.log('User dismissed the A2HS prompt');
      }
      deferredPrompt = null;
    });
});