export const media_base_url = process.env.REACT_APP_MEDIA_BASE_URL;
export const api_url = process.env.REACT_APP_CUSTOM_REST_URL;
export const api_dev_url = process.env.REACT_APP_WEXXLE_REST_URL;
export const auth_wexxle_url_appsync = process.env.REACT_APP_AUTH_APPSYNC_URL;
export const unauth_wexxle_url_appsync = process.env.REACT_APP_UNAUTH_APPSYNC_URL;
export const wexxle_cli_url = process.env.REACT_APP_AWS_CLI_URL
export const profile_default_setting = {
    "general_setting": {
        "lang": "English",
        "unit": "Mile",
        "prevent_auto_lock": 1
    },
    "map_display_setting": {
        "display": "Default",
        "speedometer": "Always slow",
        "night_mode": 1,
        "lock_north_up_mode": 1,
        "auto_zoom": 0
    },
    "vehicle_details": {
        "vehicle_type": ["private_car"],
        "avoid_toll_roads": 1,
        "toll_passes_and_exemption": 1
    }
}
export const account_closer = ["I have a duplicate account", "I'm getting too many emails", "I'm not getting any value from my membership", "I have a privacy concern", "Other"]
export const account_closer_val = ["I_have_a_duplicate_account", "I'm_getting_too_many_emails", "I'm_not_getting_any_value_from_my_membership", "I_have_a privacy_concern", "Other"]

let eventAction = {}
eventAction["0"] = "Home Page"
eventAction["1"] = "Tag Create"
export { eventAction };

export const template_module_id = {
    "chat": 25,
    "create_tag": 4,
    "update_tag": 18,
    "tag_comment": 13,
    "like_dislike_tag": 5,
    "like_dislike_comment": 11,
}
