var AWS = require('aws-sdk');

AWS.config.update({
    region: 'us-east-1',
    accessKeyId: process.env.REACT_APP_AWS_ACCESSKEY,
    secretAccessKey: process.env.REACT_APP_AWS_SECRETKEY,
    signatureVersion: 'v4'
});

var sns = new AWS.SNS();


export const send_push_notification = (userId, title, message, url = "") => {
    if (url == null || url === "") {
        url = process.env.REACT_APP_DEFAULT_WEB_PUSH_URL
    }

    // generate actual data from notificationId and replace below data array
    var data = {
        "title": title,
        "body": message,
        "url": url
    }
    // publish to particular topic ARN or to endpoint ARN
    var params = {
        Message: '{\n  "topic": "news",\n  "title": "' + data.title + '",\n  "body": "' + data.text + '",\n   "url": "' + data.url + '",\n}',
        TopicArn: 'arn:aws:sns:us-east-1:119356022291:web-push-notification-snsTopic-13O3S9FZNVS5X',
    };

    if (userId != null) {
        params = {
            Message: '{\n  "topic": "news",\n  "title": "' + data.title + '",\n  "body": "' + data.text + '",\n  "userId":  "' + userId + '"\n  "url": "' + data.url + '",\n}',
            TopicArn: 'arn:aws:sns:us-east-1:119356022291:web-push-notification-snsTopic-13O3S9FZNVS5X',
        };
    }

    sns.publish(params, function (err, data) {
        if (err) {
            console.log('publish error')
            console.log(err, err.stack);
        }
        else {
        } console.log(data);
    });

    return true
}