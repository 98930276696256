import { CREATEAD, FETCHADS, ISPERSONALADS, ISPAYMENTSUCESS, ADSFROMADVERTISMENTTAB } from '../types';

const initialState = {
	adsList: [],
	createADS: false,
	ispersonaladv: false,
	isPaymentSuccessfull: null,
	adsFromAdvertismentPage: false
};

export const CreateAD = (state = initialState, action) => {
	switch (action.type) {
		case CREATEAD:
			return {
				...state,
				createADS: action.payload,
			};
		case FETCHADS:
			return {
				...state,
				adsList: action.payload,
			}
		case ISPERSONALADS:
			return {
				...state,
				ispersonaladv: action.payload,
			}
		case ISPAYMENTSUCESS:
			return {
				...state,
				isPaymentSuccessfull: action.payload,
			}
		case ADSFROMADVERTISMENTTAB:
			return {
				...state,
				adsFromAdvertismentPage: action.payload,
			}
		default:
			return state;
	}
};