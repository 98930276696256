import { CREATETAGOPENPOPUP, CreateOverlayOverMaps, OPENCREATETAGPOPUP, OPENNESTEDCREATEDTAG } from '../types'

export const createTagOpenpopup = (payload) => (dispatch) => {
    dispatch(openCreateTagPopUp({ 'isOpen': false, 'extra': "" }))
    dispatch(nestedCreateTagPopup({ 'isOpen': false, 'extraProps': {} }))
    dispatch({ type: CREATETAGOPENPOPUP, payload: payload })
}

export const nestedCreateTagPopup = (payload) => (dispatch) => {
    dispatch(openCreateTagPopUp({ 'isOpen': false, 'extra': "" }))
    dispatch({ type: OPENNESTEDCREATEDTAG, payload: payload })
}

export const createViewOverMaps = payload => dispatch => {
    dispatch(openCreateTagPopUp({ 'isOpen': false, 'extra': "" }))
    dispatch({ type: CreateOverlayOverMaps, payload: payload })
}

export const openCreateTagPopUp = payload => dispatch => dispatch({ type: OPENCREATETAGPOPUP, payload: payload })
