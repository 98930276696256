import Amplify from '@aws-amplify/api';

export const initializeAuthCLI = () => {
    return Amplify.configure({
        API: {
            aws_appsync_graphqlEndpoint: process.env.REACT_APP_AWS_CLI_URL,
            aws_appsync_region: process.env.REACT_APP_AWS_REGION,
            aws_appsync_authenticationType: process.env.REACT_APP_AWS_AUTHENTICATIONTYPE,
            aws_appsync_apiKey: process.env.REACT_APP_AWS_CLI_KEY
        }
    })
}

// export const initializeAuthGraphql = () => {
//     return Amplify.configure({
//         API: {
//             aws_appsync_graphqlEndpoint: process.env.REACT_APP_AWS_URL,
//             aws_appsync_region: process.env.REACT_APP_AWS_REGION,
//             aws_appsync_authenticationType: process.env.REACT_APP_AWS_AUTHENTICATIONTYPE,
//             aws_appsync_apiKey: process.env.REACT_APP_AWS_KEY
//         }
//     })
// }


// export const initializeUnauthGraphql = () => {
//     return Amplify.configure({
//         API: {
//             aws_appsync_graphqlEndpoint: process.env.REACT_APP_AWS_URL_DEFAULT,
//             aws_appsync_region: process.env.REACT_APP_AWS_REGION,
//             aws_appsync_authenticationType: process.env.REACT_APP_AWS_AUTHENTICATIONTYPE,
//             aws_appsync_apiKey: process.env.REACT_APP_AWS_KEY_DEFAULT
//         }
//     })
// }


export const initializeWebPushCLI = () => {
    return Amplify.configure({
        API: {
            aws_appsync_graphqlEndpoint: process.env.REACT_APP_APPSYNC_URL_WEB_PUSH,
            aws_appsync_region: process.env.REACT_APP_WEBPUSH_REGION,
            aws_appsync_authenticationType: process.env.REACT_APP_AWS_AUTHENTICATIONTYPE,
            aws_appsync_apiKey: process.env.REACT_APP_APPSYNC_API_KEY_WEB_PUSH
        }
    })
}
