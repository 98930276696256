import React from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify/dist/react-toastify.esm';

// import { isEmpty } from "../../Helper/util";

//Layout
import LoginWithSocialMedia from '../layout/LoginWithSocialMedia';
//api
import { register, checkRegisterEmail, OtpVerification } from '../../api/LoginSignupApi';
// import PhoneInput from 'react-phone-number-input'
import OtpInput from 'react-otp-input';
import { activate_User } from '../../api/GraphqlApi';
import $ from 'jquery/dist/jquery'
// import 'react-phone-number-input/style.css'
import { isValidPhoneNumber } from 'react-phone-number-input'

import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { removeClass, deviceType, countryCode } from '../../Helper/commonFunction';
import { registrationBasedOnReferral } from '../../api/WalletApi';
import { registrationOnInvitation } from '../../api/GraphqlApi'
class Register extends React.PureComponent {
    constructor(props) {
        super(props)
        this.token = localStorage.getItem("Default")
        this.state = {
            email: '',
            name: "",
            phone: "",
            otp: '',
            seconds: 0,
            minutes: 1,
            signUpPassword: '',
            confirmPassword: '',
            signUpLoading: false,
            hasErrored: false,
            isDisabled: false,
            resend: false,
            otpVerified: false,
            ismobile: false,
            isoCode: "",
            errors: {}
        }
    }


    checkEmail = async () => {
        var params = {};
        params.email = this.state.email;
        try {
            let res = await checkRegisterEmail(params);
            if (res && res.success === true) {
                let errors = this.state.errors;
                errors.email = ""
                this.setState({ errors });
            } else if (res && res.error) {
                let errors = this.state.errors;
                errors.email = res.error.email;
                this.setState({ errors });
            } else if (res && res.message) {
                let errors = this.state.errors;
                errors.email = res.message
                this.setState({ errors });
            }
            return this.state.errors.email;
        } catch (error) {
            console.log(error.message)
            toast.error(error.message)
        }
    }

    checkPhone = async () => {
        let phone_number = this.state.phone
        if (!phone_number) {
            this.setState({
                ...this.state,
                errors: {
                    ...this.state.errors,
                    "phone": "Please Enter Phone Number."
                }
            })
            return;
        }
        if (!isValidPhoneNumber(phone_number)) {
            this.setState({
                ...this.state,
                errors: {
                    ...this.state.errors,
                    "phone": "Invalid Phone Number."
                }
            })
            return;
        }
        const response = await activate_User(phone_number, "check_phone_number");
        if (response) { var { statusCode } = response }
        this.setState(prev => ({
            ...prev,
            ...(statusCode === 200 ?
                { errors: { ...prev.errors, "phone": "" } }
                : (statusCode === 400) ?
                    { errors: { ...prev.errors, "phone": "Phone Number Already exists." } } :
                    { errors: { ...prev.errors, "phone": "Something Went Wrong." } })
        }))
    }

    registeredBasedOnReferral = async (email) => {
        let response = await registrationBasedOnReferral("set_referral_bonus", email, localStorage.getItem("referalcode"));
        let { statusCode, body } = response;
        if (statusCode === 200 && body && body.data) {
            localStorage.removeItem("referalcode")
        }
    }
    registerBasedOnInvitation = async (user_id) => {
        let response = await registrationOnInvitation("setup_parent_adult_user", user_id, localStorage.getItem("invitecode"));
        let { statusCode, body } = response;
        if (statusCode === 200 && body && body.data) {
            localStorage.removeItem("invitecode")
        }
        if (statusCode === 204 && body && body.data) {
            toast.error('User Exit or Invalid code')
            localStorage.removeItem("invitecode")
        }

    }

    handleRegister = async (key) => {
        this.setState({
            ...this.state, signUpLoading: true, seconds: 0,
            minutes: 1,
            otp: "",
            hasErrored: false,
            otpVerified: false
        }, () => removeClass())
        var params = {};
        params['name'] = this.state.name
        params["email"] = this.state.email;
        params["phone"] = this.state.phone
        params["signUpPassword"] = this.state.signUpPassword;
        params["confirmPassword"] = this.state.confirmPassword;
        params["user_type"] = "1"
        this.props.showLoader(true);
        try {
            let res = await register(params);
            if (res && res.success === true) {
                if (localStorage.getItem("referalcode")) this.registeredBasedOnReferral(this.state.email);
                if (localStorage.getItem("invitecode")) { await this.registerBasedOnInvitation(res.user_id); };
                this.OtpTimer();
                toast.success("Please check device for otp!");
                $("#exampleModal").modal("show");
                this.setState({ ...this.state, resend: true, errors: {} });
            } else if (res && res.error) {
                this.setState({
                    ...this.state, errors: {
                        ...this.state.errors, ...res.error
                    }
                })
            } else if (res && res.message) {
                var errors = this.state.errors;
                errors.phone = res.message
                console.log(res.message)
                this.setState({
                    ...this.state, errors: {
                        ...this.state.errors, ...errors
                    }
                });
            }
            this.setState({ signUpLoading: false });
            this.props.showLoader(false);
        } catch (error) {
            console.log(error.message)
            toast.error(error.message)
            this.setState({ signUpLoading: false });
            this.props.showLoader(false);
        }
    }

    componentWillUnmount() {
        this.setState = (state, callback) => {
            return;
        };
    }


    handleChange = (otp) => this.setState({ otp });

    componentDidUpdate(_, prevState) {
        if (prevState.otp !== this.state.otp) {
            if (this.state.otp.length === 4) {
                this.OtpVerification(this.state.otp, this.state.phone)
            }
        }
    }

    isoCountryCode = async () => {
        let response = await countryCode();
        this.setState({
            ...this.state,
            isoCode: response ? response : 'us'
        })
    }

    componentDidMount() {
        this.isoCountryCode()
        let response = deviceType()
        this.setState({
            ...this.state,
            ismobile: ["android phone"].includes(response) ? true : false
        })
    }

    OtpVerification = async (otp, phone_number) => {
        try {
            this.setState({ ...this.state, hasErrored: false, isDisabled: true })
            let res = await OtpVerification({ otp, phone_number });
            if (res && res.success === true) {
                this.Useractivate(phone_number)
            } else if (res && res.message) {
                console.log(res.message)
                toast.error(res.message)
                this.setState({
                    ...this.state,
                    isDisabled: false,
                    hasErrored: true
                })
            }
        } catch (error) {
            console.log(error.message)
            toast.error(error.message)
            this.setState({
                ...this.state,
                isDisabled: false,
                hasErrored: false
            })
        }
    }

    Useractivate = async (phone_number) => {
        let activatedResponse = await activate_User(phone_number)
        if (activatedResponse) { var { statusCode } = activatedResponse; }
        if (statusCode === 200) {
            $("#exampleModal").modal("hide")
            toast.success("User Registered Successfully!")
            this.setState({
                ...this.state,
                email: '',
                name: "",
                phone: "",
                signUpPassword: '',
                confirmPassword: '',
                signUpLoading: false,
                otpVerified: true,
                otp: "",
                errors: {}
            })
            this.props.LoginSignupToggle("login")
        } else {
            this.setState({ ...this.state, isDisabled: false, hasErrored: false, })
        }
    }

    // Otp Timer For Resend Otp
    OtpTimer = () => {
        let OtpInterval = setInterval(() => {
            if (this.state.otpVerified) {
                clearInterval(OtpInterval)
                this.setState({ resend: false })
            }
            if (this.state.seconds > 0) {
                this.setState({
                    seconds: this.state.seconds - 1
                })
            } else if (this.state.seconds === 0) {
                if (this.state.minutes === 0) {
                    clearInterval(OtpInterval)
                    this.setState({ resend: false })
                } else {
                    this.setState({
                        minutes: this.state.minutes - 1,
                        seconds: 59
                    })
                }
            }
        }, 1000);
    }


    socialLoader = (facebook, google, apple) => {
        this.setState({
            ...this.state,
            signUpLoading: facebook ? facebook : (google ? google : (apple ? apple : false))
        })
    }

    render() {
        // eslint-disable-next-line
        const { errors, ismobile, isoCode } = this.state;
        const { showConfirmPassword, showPassword } = this.props
        return (
            <>
                <div className="onboarding-card-container signupBox">

                    <div className="onboarding-card-header">
                        <figure>
                            <img src={process.env.PUBLIC_URL + "/images/logo.svg"} alt="logo" height="45" />
                        </figure>
                        <h4 className="onboarding-card-header__title">wexxle</h4>
                    </div>

                    <div className="onboarding-card registerCardMain common_label_mb">
                        <span className="onboarding-card__handle" onClick={() => this.token ? this.props.isClosedToggle() : this.props.isClosedSplashToggle()}></span>
                        <div className={this.token ? 'scrollSection guest_login_padding' : 'scrollSection'}>
                            <div className="onboarding-card__logo">
                                <Link to="" className="anchor" style={{ pointerEvents: "none" }}>
                                    <img src={process.env.PUBLIC_URL + "/images/logo.svg"} alt="logo" height="30" />
                                    <span className="logoText">wexxle</span>
                                </Link>
                            </div>

                            <div className="welcome-text-desktop">
                                <h2>Create an account</h2>
                                <p>Sign up to continue</p>
                            </div>

                            <div className="welcome-text">
                                <h1>Sign Up</h1>
                                <p>Already have an account? <Link to="" className="linkPrime" onClick={this.props.LoginSignupToggle.bind(this, 'login')}>Sign In</Link></p>
                            </div>
                            <form action="" className="custom-form">
                                <div className="onlyFilleds">
                                    <div className="form-group mb-20 regis_large_scren">
                                        <label className="d-none d-md-block">FULL NAME</label>
                                        <div className="filledWithIcon lgn_scn_frm">
                                            <span className="inputIcons userIcon for_mble">&nbsp;</span>
                                            {Object.keys(errors).length > 0 &&
                                                <>
                                                    {errors.name ?
                                                        <span className="inputIconsRight validateCross">
                                                            <span className="validTooltip requiredMsg">{errors.name}</span>
                                                        </span>
                                                        :
                                                        (this.state.name ? <span className="inputIconsRight validateCheck">&nbsp;</span> : ""
                                                        )
                                                    }
                                                </>
                                            }

                                            <input id="name" placeholder="Enter Name" type="text" className="form-control login_padd only_mble_shw" value={this.state.name} onChange={(e) => {
                                                const re = /^[a-zA-Z ]*$/;
                                                if (re.test(e.target.value))
                                                    this.setState({
                                                        ...this.state,
                                                        name: e.target.value,
                                                        errors: {
                                                            ...this.state.errors,
                                                            name: ""
                                                        }
                                                    })
                                            }} />
                                        </div>
                                    </div>

                                    <div className="form-group mb-20 regis_large_scren common_label_mb">
                                        <label className="d-none d-md-block">EMAIL</label>
                                        <div className="filledWithIcon lgn_scn_frm">
                                            <span className="inputIcons userIcon for_mble">&nbsp;</span>
                                            {Object.keys(errors).length > 0 &&
                                                <>
                                                    {errors.email ?
                                                        <span className="inputIconsRight validateCross">
                                                            <span className="validTooltip requiredMsg">{errors.email}</span>
                                                        </span>
                                                        : (this.state.email ? <span className="inputIconsRight validateCheck">&nbsp;</span> : ""
                                                        )
                                                    }
                                                </>
                                            }
                                            <input id="email" placeholder="Enter Email" value={this.state.email} type="email" className="form-control login_padd only_mble_shw" onChange={(e) => this.setState({
                                                ...this.state,
                                                email: e.target.value,
                                                errors: {
                                                    ...this.state.errors,
                                                    email: ""
                                                }
                                            })} onBlur={this.checkEmail} />
                                        </div>
                                    </div>


                                    <div className="form-group mb-20 regis_large_scren common_label_mb">
                                        <label className="d-none d-md-block">PHONE</label>

                                        <div className="filledWithIcon lgn_scn_frm">
                                            <span className="inputIcons userIcon for_mble">&nbsp;</span>
                                            {Object.keys(errors).length > 0 &&
                                                <>
                                                    {errors.phone ?
                                                        <span className="inputIconsRight validateCross">
                                                            <span className="validTooltip requiredMsg">{errors.phone}</span>
                                                        </span>
                                                        :
                                                        (this.state.phone ? <span className="inputIconsRight validateCheck">&nbsp;</span> : ""
                                                        )
                                                    }
                                                </>
                                            }

                                            {/* <input id="phoneNumber" placeholder="Enter Phone Number" type="text" className="form-control" onKeyDown={(evt) => {
                                                ["e", "E", ".", "+", "-"].includes(evt.key) && evt.preventDefault()
                                            }} value={this.state.phone} maxLength={14} onChange={(e) => this.setState({
                                                ...this.state,
                                                phone: e.target.value,
                                                errors: {
                                                    ...this.state.errors,
                                                    phone: ""
                                                }
                                            })} onInput={(evt) => {
                                                var x = evt.target.value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
                                                evt.target.value = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
                                            }} /> */}

                                            {/* <PhoneInput
                                                onBlur={() => this.checkPhone()}
                                                defaultCountry={"IN"}
                                                className="form-control"
                                                international={true}
                                                placeholder="Enter Phone Number"
                                                limitMaxLength={true}
                                                value={this.state.phone}
                                                onChange={(e) => this.setState({
                                                    ...this.state,
                                                    phone: e ? e : "",
                                                    errors: {
                                                        ...this.state.errors,
                                                        phone: ""
                                                    }
                                                })}
                                            /> */}


                                            <PhoneInput
                                                onBlur={() => this.checkPhone()}
                                                country={isoCode}
                                                masks={{ in: '(...) ...-....' }}
                                                enableAreaCodes={true}
                                                placeholder="Enter Phone Number"
                                                value={this.state.phone}
                                                inputClass={'com_phone_filed'}
                                                onChange={(e) => this.setState({
                                                    ...this.state,
                                                    phone: e ? "+" + e : "",
                                                    errors: {
                                                        ...this.state.errors,
                                                        phone: ""
                                                    }
                                                })}
                                            />
                                        </div>
                                    </div>

                                    <div className="form-group mb-20 regis_large_scren common_label_mb">
                                        <label className="d-none d-md-block">PASSWORD</label>
                                        <div className="filledWithIcon lgn_scn_frm">
                                            <span className="inputIcons passwordIcon for_mble">&nbsp;</span>
                                            {errors.signUpPassword ?
                                                <span className="inputIconsRight validateCross">
                                                    <span className="validTooltip requiredMsg" dangerouslySetInnerHTML={{ __html: errors.signUpPassword }}></span>
                                                </span>

                                                : <span className={!showPassword ? "inputIconsRight hidepassword" : "inputIconsRight showPassword"} onClick={this.props.showHidePassword.bind(this, 'password')}></span>
                                            }
                                            <input id="password" maxLength={12} value={this.state.signUpPassword} type={this.props.showPassword ? "text" : "password"} placeholder="* * * * * *" className="form-control login_padd only_mble_shw" onChange={(e) => this.setState({
                                                ...this.state,
                                                signUpPassword: e.target.value,
                                                errors: {
                                                    ...this.state.errors,
                                                    signUpPassword: ""
                                                }
                                            })} autoComplete="new-password" />
                                            <p className="login-error_msg">
                                                {errors.signUpPassword ?
                                                    <span className="inputIconsRight validateCross">
                                                        <span className="validTooltip requiredMsg" dangerouslySetInnerHTML={{ __html: errors.signUpPassword }}></span>
                                                    </span>
                                                    : <span className={!showPassword ? "inputIconsRight hidepassword" : "inputIconsRight showPassword"} onClick={this.props.showHidePassword.bind(this, 'password')}></span>
                                                }
                                            </p>
                                        </div>
                                    </div>

                                    <div className="form-group mb-20 regis_large_scren common_label_mb">
                                        <label className="d-none d-md-block">CONFIRM PASSWORD</label>
                                        <div className="filledWithIcon lgn_scn_frm">
                                            <span className="inputIcons passwordConfIcon for_mble">&nbsp;</span>
                                            {errors.confirmPassword ?
                                                <span className="inputIconsRight validateCross">
                                                    <span className="validTooltip requiredMsg">{errors.confirmPassword}</span>
                                                </span>
                                                : <span className={!showConfirmPassword ? "inputIconsRight hidepassword" : "inputIconsRight showPassword"} onClick={this.props.showHidePassword.bind(this, 'confirm_password')}></span>
                                            }
                                            <input id="confirm_password" maxLength={12} value={this.state.confirmPassword} type={this.props.showConfirmPassword ? "text" : "password"} placeholder="* * * * * *" className="form-control login_padd only_mble_shw" onChange={(e) => this.setState({
                                                ...this.state,
                                                confirmPassword: e.target.value,
                                                errors: {
                                                    ...this.state.errors,
                                                    confirmPassword: ""
                                                }
                                            })} />
                                            <p className="login-error_msg">{errors.confirmPassword ?
                                                <span className="inputIconsRight validateCross">
                                                    <span className="validTooltip requiredMsg">{errors.confirmPassword}</span>
                                                </span>
                                                : <span className={!showConfirmPassword ? "inputIconsRight hidepassword" : "inputIconsRight showPassword"} onClick={this.props.showHidePassword.bind(this, 'confirm_password')}></span>
                                            }</p>
                                        </div>
                                    </div>

                                    <div className="form-group mb-20 create_btn">
                                        <input type="button" disabled={this.state.signUpLoading ? true : false} value={this.state.signUpLoading ? "Loading" : "Create an account"} className="btn btn-secondary btn-block " onClick={() => { this.handleRegister("") }} />
                                    </div>

                                    <div className="form-group text-center mt-3  d-md-none hr_lne">
                                        <span className="hr_line_bth">OR</span>
                                    </div>

                                    {/* !ismobile &&  */}
                                    {
                                        <>
                                            <div className="form-group text-center d-none d-md-block lg_scren_para_space margin_btom cnnt_spn_div">
                                                <span className="cnnt_scl">Or connect with socials</span>
                                            </div>
                                            <LoginWithSocialMedia checkLogin={this.props.checkLogin} socialLoader={this.socialLoader} emailLoginLoader={this.state.signUpLoading} />
                                        </>
                                    }
                                    <div className="form-group text-center d-md-none login_last_txt">
                                        <span>Or connect with your email</span>
                                    </div>
                                </div>
                            </form>

                            {
                                this.token && <div className="text-center" style={{
                                    pointerEvents: this.state.signUpLoading ? "none" : ""
                                }}>
                                    Already Registered! <span className='text-secondary cursor' onClick={() => { this.props.LoginSignupToggle('login') }}>Login</span>
                                </div>
                            }
                        </div>
                    </div>
                </div>

                <div className="modal fade otp_verify" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content otp_box_fill">
                            <div className="modal-header d-block text-center">
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => this.setState({
                                    ...this.state,
                                    otpVerified: true
                                })}>
                                </button>
                                <h5 className="modal-title" id="exampleModalLabel">Verify Phone Number </h5>
                                <p>Enter OTP to Verify Phone Number</p>
                                {
                                    this.state.resend ? <><p><span>{this.state.minutes < 10 ? "0" + this.state.minutes : this.state.minutes}</span>&nbsp;:&nbsp;<span>{this.state.seconds < 10 ? "0" + this.state.seconds : this.state.seconds}</span></p></> : <a href="void:(0)" style={this.state.signUpLoading ? { pointerEvents: "none" } : {}} onClick={() => {
                                        this.setState({ ...this.state, otp: "", hasErrored: false }, () => this.handleRegister("Resend"))
                                    }}><img src={process.env.PUBLIC_URL + "/images/resend-icon.png"} alt="resend-icon"></img><span>Resend</span></a>
                                }

                            </div>
                            <div className="modal-body">
                                <OtpInput
                                    isInputNum={true}
                                    value={this.state.otp}
                                    onChange={this.handleChange}
                                    numInputs={4}
                                    containerStyle={"otpBox"}
                                    inputStyle={"otpInput"}
                                    separator={<span>&nbsp;</span>}
                                    isDisabled={this.state.isDisabled}
                                    hasErrored={this.state.hasErrored}
                                    errorStyle={"otpInputError"}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
export default Register;