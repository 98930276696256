export const createCloneVariable = (tag, sharedTag) => {
    let variable = {};
    variable["title"] = tag[0].title
    variable["content"] = tag[0].content
    variable["type"] = tag[0].type
    variable["addresses"] = tag[0].addresses
    variable["visibility"] = tag[0].visibility
    variable["comments"] = tag[0].comments
    variable["tagged_user_id"] = tag[0].tagged_user_id
    variable["memory_date"] = tag[0].memory_date
    variable["occasion"] = tag[0].occasion
    variable["category"] = tag[0].category
    variable["sub_category"] = tag[0].sub_category
    variable["price"] = tag[0].price
    variable["quantity"] = tag[0].quantity
    variable["auto_attach"] = tag[0].auto_attach
    variable["custom_field"] = tag[0].custom_field
    variable["start_date"] = tag[0].start_date
    variable["end_date"] = tag[0].end_date
    variable["task_date"] = tag[0].task_date
    variable["repeat"] = tag[0].repeat
    variable["custom_start"] = tag[0].custom_start
    variable["custom_repeat"] = tag[0].custom_repeat
    variable["custom_repeat_value"] = tag[0].custom_repeat_value
    variable["custom_days"] = tag[0].custom_days
    variable["custom_end"] = tag[0].custom_end
    variable["public_distance"] = tag[0].public_distance
    variable["public_start"] = tag[0].public_start
    variable["public_end"] = tag[0].public_end
    variable["user_id"] = localStorage.getItem("id")
    variable["purchasing_date"] = tag[0].purchasing_date
    variable["latitude"] = tag[0].latitude
    variable["available_for_sale"] = tag[0].available_for_sale
    variable["longitude"] = tag[0].longitude
    variable["media"] = tag[0].media
    variable['status'] = tag[0].status
    if (tag[0].spotify) variable['spotify'] = JSON.stringify(tag[0].spotify)
    else variable['spotify'] = null
    variable["top_parent_id"] = tag[0].top_parent_id || 0
    variable["parent_id"] = tag[0].parent_id || 0
    variable["is_public"] = tag[0].is_public
    variable["is_tc_accepted"] = tag[0].is_tc_accepted || false
    if (sharedTag === "sharedTag") variable["tagShared_tagsId"] = tag[0].id
    return variable;
}
