import React from 'react';
import ReactSlider from '../Common/Slider';
import { Line } from 'rc-progress';
//Layout
import Hello from "./Hello";
//api

class Splash extends React.PureComponent {
    constructor(props) {
        super(props);
        this.splashscreen = null
        this.state = {
            percent: 33,
            // showHello: ["localhost", "127.0.0.1", ""].includes(window.location.hostname) ? true : false,
            showHello: false
        }
        // showHello: ["localhost", "127.0.0.1", ""].includes(window.location.hostname) ? true : false,
    }

    setLoader = () => {
        var percent = this.state.percent;
        percent = percent + 33;
        this.setState({ percent });
        if (percent === 99) {
            this.splashscreen = setTimeout(() => {
                this.setState({ showHello: true })
            }, 350);
        }
    }

    render() {
        var settings = {
            dots: false,
            infinite: false,
            pauseOnHover: false,
            fade: true,
            arrows: false,
            autoplay: true,
            autoplaySpeed: 5000,
            speed: 1000,
            slidesToShow: 1,
            slidesToScroll: 1,
            afterChange: this.setLoader.bind(this)
        };


        return (
            <>
                {(this.state.showHello) ?
                    <Hello checkLogin={this.props.checkLogin} />
                    :
                    <div className="splash-screen">
                        <div className="splash-screen__logo">
                            <h1>
                                <figure>
                                    <img src={process.env.PUBLIC_URL + "/images/logo.svg"} alt="logo" height="100" />
                                </figure>
                                <span>wexxle</span>
                            </h1>
                        </div>
                        <ReactSlider {...settings}>
                            <div>
                                <div className="splash-screen__slide slide-1">
                                    <img alt='' src={process.env.PUBLIC_URL + "/images/slide-1.svg"} />
                                    <div className="splash-screen__slide__title">
                                        <h2>Social Reinvented</h2>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className="splash-screen__slide slide-2">
                                <img alt='' src={process.env.PUBLIC_URL + "/images/slide-2.svg"} />
                                    <div className="splash-screen__slide__title">
                                        <h2>Reimagine and Create <br></br>
                                            things and ideas </h2>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className="splash-screen__slide slide-3">
                                <img alt='' src={process.env.PUBLIC_URL + "/images/slide-3.svg"} />
                                    <div className="splash-screen__slide__title ">
                                        <h2 className="thrid_screen">A tag for
                                            Every Thing </h2>
                                    </div>
                                </div>
                            </div>
                        </ReactSlider>
                        <div className="about-block" >
                            <figure onClick={() => window.open("https://lapidus.io/", "__blank")}>
                                <img src={process.env.PUBLIC_URL + "/images/logo-lapidus-together.svg"} alt="logo" height="44" />
                            </figure>

                            <h5 className="title">
                                <img src={process.env.PUBLIC_URL + "/images/logo-lapidus.svg"} alt="logo" height="16" />
                                A LAPIDUS COMPANY
                            </h5>
                            <div className="progressLoader">
                                <Line percent={this.state.percent} strokeWidth="6" trailWidth="6" strokeColor="#5983F0" />
                            </div>
                        </div>

                    </div>
                }
            </>
        );
    }
}

export default Splash;