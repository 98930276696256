import React from 'react';
import { Link } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

// import QRCode from 'qrcode.react';
// import { Line } from 'rc-progress';
// import QrReader from 'react-qr-scanner';
//LoginSignup and ForgotPassword Modal
import Login from '../layout/Login';
import Register from '../layout/Register';
import PasswordRecovery from '../layout/PasswordRecovery';
import $ from 'jquery/dist/jquery'
import connect from 'react-redux/es/connect/connect';
import { API, graphqlOperation } from '@aws-amplify/api';
import * as graphqlSubscription from '../../graphql/subscriptions'
import { getCurrentLocation } from '../../functions';
import { initializeAuthCLI } from '../../Helper/InitializeApi';
import { getUserDetail, deleteQR } from '../../api/GraphqlApi';
import * as myConstants from "../../Helper/constants";
import { enablesetting } from '../../redux/actions/onBoardingActions';

var QRsubscription = undefined
class LoginRegister extends React.PureComponent {
    constructor(props) {
        super(props);
        this.token = localStorage.getItem("Default")
        this.state = {
            loginSignupToggle: 'login',
            showPassword: false,
            showConfirmPassword: false,
            percent: 0,
            qrValue: 0,
            OwnerObject: [],
            isClosed: this.token ? true : false
        }
        // this.handleScan = this.handleScan.bind(this)
    }

    UNSAFE_componentWillMount() {
        if (localStorage.getItem("referalcode")) this.isClosedSplashToggle();
        this.setState(prev => ({ ...prev, ...(localStorage.getItem("referalcode") && { loginSignupToggle: "register" }) }))
    }


    LoginSignupToggle = (action, e) => {
        this.setState({ loginSignupToggle: action });
    }


    showHidePassword = (action, e) => {
        if (action === 'password') {
            var showPassword = this.state.showPassword;
            this.setState({ showPassword: !showPassword })
        }
        else if (action === 'confirm_password') {
            var showConfirmPassword = this.state.showConfirmPassword;
            this.setState({ showConfirmPassword: !showConfirmPassword })
        }
    }

    showLoader = (value) => {
        if (value) {
            var percent = this.state.percent;
            percent = percent + 30;
            setTimeout(() => this.setState({ percent }), 100);
        }
        else {
            this.setState({ percent: 100 })
        }
    }

    // handleScan(data) {
    //     // console.log(data)
    // }

    // handleError(err) {
    //     console.error(err)
    // }

    componentWillUnmount() {
        if (QRsubscription) { QRsubscription.unsubscribe() }
        this.setState = (state, callback) => {
            return;
        };
    }

    isClosedToggle = () => {
        this.setState({ isClosed: !this.state.isClosed });
        $('.mainSearchBlock').toggleClass('closed')
        $('.mobileDesktopBlock').toggleClass('closed')
        $('.map-container').toggleClass('closed')
        $('.profileBanner').toggleClass('closed')
    };



    componentDidUpdate(prevProps) {
        // Note:- this if block is only to add or remove the closed class. so ux work properly for without login when url change.
        if (prevProps.pathname !== this.props.pathname && this.token && !this.state.isClosed) {
            $('.mobileDesktopBlock').toggleClass('closed')
            $('.map-container').toggleClass('closed')
            $('.profileBanner').toggleClass('closed')
        }
        if (prevProps.qrUrl.qrobj.id !== this.props.qrUrl.qrobj.id) {
            if (QRsubscription) { QRsubscription.unsubscribe() }
            this.subscriptionToQRLogin(this.props.qrUrl.qrobj.id)
        }

        if (prevProps.qrUrl.qrobj.id !== this.props.qrUrl.qrobj.id) {
            $("#qrcode").html("");
            var qrcode = new window.QRCode("qrcode");
            qrcode.makeCode(this.props.qrUrl.qrobj.id);
            this.setState({ ...this.state, qrValue: this.props.qrUrl.qrobj.id })
        }
    }

    isClosedSplashToggle = () => {
        $(".screenContent").toggleClass("fullwidth")
    }

    fetchUserDetail = async (userId) => {
        let response = await getUserDetail("user_detail", userId)
        this.setState({
            ...this.state,
            OwnerObject: response && response.statusCode === 200 ? (
                response && response.body && response.body.length ? response.body : []
            ) : []
        })
    }


    subscriptionToQRLogin = (qrId) => {
        initializeAuthCLI();
        QRsubscription = API.graphql(
            graphqlOperation(graphqlSubscription.onUpdateLoginQRById,
                { id: qrId })
        ).subscribe({
            next: async ({ provider, value }) => {
                getCurrentLocation();
                let { onUpdateLoginQRById } = value.data
                if (onUpdateLoginQRById && onUpdateLoginQRById.jwt_auth && onUpdateLoginQRById.user_id) {
                    await deleteQR(qrId);
                    localStorage.removeItem("device_type")
                    let { jwt_auth, user_id } = onUpdateLoginQRById
                    if (user_id) await this.fetchUserDetail(user_id);
                    if (this.state.OwnerObject.length) {
                        var { user_name, first_name, last_name, gender, dob, phone, address, email, about_me, profile_img } = this.state.OwnerObject[0]
                    }
                    if (jwt_auth) localStorage.setItem('token', jwt_auth)
                    if (user_id) localStorage.setItem('id', user_id)
                    localStorage.setItem('email', email ? email : "")
                    localStorage.setItem('firstName', first_name ? first_name : "")
                    localStorage.setItem('lastName', last_name ? last_name : "")
                    localStorage.setItem('displayName', user_name ? user_name : "");
                    if (profile_img) localStorage.setItem('profile_image', myConstants.media_base_url + "/" + profile_img)
                    else localStorage.setItem('profile_image', process.env.PUBLIC_URL + "/images/user-icon.svg")
                    localStorage.setItem('address', address ? address : "")
                    localStorage.setItem('dob', dob ? dob : "")
                    localStorage.setItem('gender', gender ? gender : "")
                    localStorage.setItem('phone_number', phone ? phone : "")
                    localStorage.setItem('about_me', about_me ? about_me : "")
                    this.props.enablesetting(true)
                    if (localStorage.getItem("referalcode")) localStorage.removeItem("referalcode");
                    if (localStorage.getItem("invitecode")) localStorage.removeItem("invitecode");
                    $("#qrCodeModal").modal("hide")
                    if (!this.token) this.props.checkLogin("")
                    if (this.token && user_id && jwt_auth) {
                        window.location.reload()
                        localStorage.removeItem("Default")
                    }
                }
                // Login Successfully....
            },
            error: error => {
                if (error?.error?.errors[0].message?.toLowerCase().includes("timeout disconnect")) {
                    if (QRsubscription) QRsubscription.unsubscribe();
                    window.location.reload();
                }
            }
        });
    }



    render() {
        //fb==347311489845559
        //google==264117812845-ls89pdbms13313st5c6ai5l2k0civjao.apps.googleusercontent.com
        const isClosed = this.state.isClosed;
        const qrUrl = new URL(process.env.REACT_APP_LiVE_URL);
        if (this.props.qrUrl.qrobj.id) qrUrl.searchParams.append("qrId", this.props.qrUrl.qrobj.id)


        return (
            <>
                <ToastContainer />
                {
                    this.token ? <div className={isClosed ? "withoutlogin" : "withoutlogin closed"}>
                        {(this.state.loginSignupToggle === 'login') ?
                            <Login showLoader={this.showLoader} checkLogin={this.props.checkLogin} LoginSignupToggle={this.LoginSignupToggle} showHidePassword={this.showHidePassword} showPassword={this.state.showPassword} isClosedToggle={this.isClosedToggle} />
                            :
                            (this.state.loginSignupToggle === 'register') ?
                                <Register showLoader={this.showLoader} checkLogin={this.props.checkLogin} LoginSignupToggle={this.LoginSignupToggle} showHidePassword={this.showHidePassword} showPassword={this.state.showPassword} showConfirmPassword={this.state.showConfirmPassword} isClosedToggle={this.isClosedToggle} />
                                :
                                <PasswordRecovery showLoader={this.showLoader} LoginSignupToggle={this.LoginSignupToggle} isClosedToggle={this.isClosedToggle} showHidePassword={this.showHidePassword} showPassword={this.state.showPassword} showConfirmPassword={this.state.showConfirmPassword} />
                        }

                        <div className="modal fade" id="qrCodeModal" tabIndex="-1" role="dialog" aria-hidden="true">
                            <div className="modal-dialog modal-dialog-centered" role="document">
                                <div className="modal-content">
                                    <div className="modal-body pt-5 pb-5">
                                        <div className="text-center">
                                            {/* <QRCode
                                                id="qrDesktop"
                                                value={this.state.qrValue}
                                                size={290}
                                            /> */}
                                            <div id="qrcode"></div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* mobile modal camera */}
                        <div className="modal fade" id="qrCodeScannerModal" tabIndex="-1" role="dialog" aria-hidden="true">
                            <div className="modal-dialog modal-dialog-centered" role="document">
                                <div className="modal-content">
                                    <div className="modal-body pt-5 pb-5">
                                        <div className="text-center">
                                            {/* <QrReader
                                                onError={this.handleError}
                                                onScan={this.handleScan}
                                            /> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> : <>
                        <div className="onboarding-bg">
                            <div className="welcome-block">
                                {(this.state.loginSignupToggle === 'login') ?
                                    <figure className="welcome-block__infographic signin-figure"></figure>
                                    :
                                    (this.state.loginSignupToggle === 'register') ?
                                        <figure className="welcome-block__infographic signup-figure"></figure>
                                        :
                                        <figure className="welcome-block__infographic recovery-figure"></figure>
                                }

                                <div className="welcome-block__content pass_recover">
                                    {["login", "register"].includes(this.state.loginSignupToggle) ? <h1>{this.state.loginSignupToggle === "register" ? "Reimagine and Create things and ideas" : "Social Reinvented"}</h1> : <h1><span className='onbor_tag_text'>A tag For Every Thing</span></h1>}
                                    {(this.state.loginSignupToggle === 'login') ?
                                        <>
                                            <p className="large_scren_p">Don’t have an account yet?</p>
                                            <Link to="" className="btn btn-secondary  create_btn" onClick={this.LoginSignupToggle.bind(this, 'register')}>Create an account</Link>
                                        </>
                                        :
                                        <>
                                            {(this.state.loginSignupToggle === 'register') ? <p className="large_scren_p">Already have an account?</p> : <p className="large_scren_p">Remember your password?</p>}
                                            <Link to="" className="btn btn-primary btn-block create_btn" onClick={this.LoginSignupToggle.bind(this, 'login')}>Login</Link>
                                        </>
                                    }
                                </div>
                            </div>

                            <div className="about-block">
                                <figure onClick={() => window.open("https://lapidus.io/", "__blank")}>
                                    <img src={process.env.PUBLIC_URL + "/images/logo-lapidus-together.svg"} alt="logo" height="44" />
                                </figure>

                                <h5 className="title">
                                    <img src={process.env.PUBLIC_URL + "/images/logo-lapidus.svg"} alt="logo" height="16" />
                                    A LAPIDUS COMPANY
                                </h5>
                                {/* <div className="progressLoader">
                            <Line percent={this.state.percent} strokeWidth="6" trailWidth="6" strokeColor="#5983F0" />
                        </div> */}
                            </div>
                        </div>

                        {(this.state.loginSignupToggle === 'login') ?
                            <Login showLoader={this.showLoader} checkLogin={this.props.checkLogin} LoginSignupToggle={this.LoginSignupToggle} showHidePassword={this.showHidePassword} showPassword={this.state.showPassword} isClosedSplashToggle={this.isClosedSplashToggle} />
                            :
                            (this.state.loginSignupToggle === 'register') ?
                                <Register showLoader={this.showLoader} checkLogin={this.props.checkLogin} LoginSignupToggle={this.LoginSignupToggle} showHidePassword={this.showHidePassword} showPassword={this.state.showPassword} showConfirmPassword={this.state.showConfirmPassword} isClosedSplashToggle={this.isClosedSplashToggle} />
                                :
                                <PasswordRecovery LoginSignupToggle={this.LoginSignupToggle} showLoader={this.showLoader} isClosedSplashToggle={this.isClosedSplashToggle} showHidePassword={this.showHidePassword} showPassword={this.state.showPassword} showConfirmPassword={this.state.showConfirmPassword} />
                        }

                        <div className="modal fade" id="qrCodeModal" tabIndex="-1" role="dialog" aria-hidden="true">
                            <div className="modal-dialog modal-dialog-centered" role="document">
                                <div className="modal-content">
                                    <div className="modal-body pt-5 pb-5">
                                        <div className="text-center">
                                            {/* <QRCode
                                                id="qrDesktop"
                                                value={this.state.qrValue}
                                                size={290}
                                            /> */}
                                            <div id="qrcode"></div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* mobile modal camera */}
                        <div className="modal fade" id="qrCodeScannerModal" tabIndex="-1" role="dialog" aria-hidden="true">
                            <div className="modal-dialog modal-dialog-centered" role="document">
                                <div className="modal-content">
                                    <div className="modal-body pt-5 pb-5">
                                        <div className="text-center">
                                            {/* <QrReader
                                                onError={this.handleError}
                                                onScan={this.handleScan}
                                            /> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                }

            </>
        );
    }
}

const mapStateToprops = state => ({
    qrUrl: state.onboarding
})

const mapDispatchToProps = {
    enablesetting
}

export default connect(mapStateToprops, mapDispatchToProps)(LoginRegister);
