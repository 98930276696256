import { UNREADNOTIFICATIONCOUNT, ISDEVICEDEVICELOGIN, CLEARISDEVICEDEVICELOGIN } from "../types";

const initialstate = {
    unreadNotiCount: 0,
    loggedinobject: ""
};

const unreadnotireducer = (state = initialstate, { type, payload }) => {
    switch (type) {
        case UNREADNOTIFICATIONCOUNT:
            return {
                ...state,
                unreadNotiCount: payload,
            };
        case ISDEVICEDEVICELOGIN:
            return {
                ...state,
                loggedinobject: payload,
            };
        case CLEARISDEVICEDEVICELOGIN:
            return {
                ...state,
                loggedinobject: "",
            };
        default:
            return state;
    }
};

export default unreadnotireducer;