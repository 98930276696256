import { FETCHUSERACTIONLOG } from '../types'

const initialState = {
    id: null
}

export const useractionlogreducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case FETCHUSERACTIONLOG:
            return { id: payload }
        default:
            return state;
    }
}