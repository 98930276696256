import { USERPROFILEDETAIL, PROFILE_UI, CLEAR_PROFILE_ERROR, WALLETBALANCE, ADDRESSVERIFIED } from '../types'

const initialState = {
    Profile_loader: false,
    Userdetail: {},
    Isloading: true,
    balance: {},
    verified: "1"
}

export const Userdetailreducer = (state = initialState, action) => {
    switch (action.type) {
        case USERPROFILEDETAIL:
            return {
                ...state,
                Userdetail: action.payload,
                Profile_loader: false,
                Isloading: false
            }
        case PROFILE_UI:
            return {
                ...state,
                Profile_loader: true,
                Isloading: false
            }
        case CLEAR_PROFILE_ERROR:
            return {
                ...state,
                Profile_loader: false,
                Isloading: false
            }
        case WALLETBALANCE:
            return {
                ...state,
                balance: action.payload,
            }
        case ADDRESSVERIFIED:
            return {
                ...state,
                verified: action.payload || "1",
            }
        default:
            return state
    }
}
