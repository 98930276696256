import { createCustomer, getCustomers } from "../../api/StripeApi";
import { CREATE_PAYMENT, FETCHCUSTOMER } from "../types";
import { toast } from 'react-toastify/dist/react-toastify.esm';
export const createPayment = (payload, history) => dispatch => {
    if (payload && !Object.keys(payload).length) {
        toast.error("Something Get Missing While Create Payment!")
        return;
    }
    let paymentPlan = {};
    paymentPlan["anually"] = payload ? (payload.anually ? payload.anually : 0) : ""
    paymentPlan["feature_key"] = payload ? (payload.feature_key ? payload.feature_key : "") : ""
    paymentPlan["feature_value"] = payload ? (payload.feature_value ? payload.feature_value : "") : ""
    paymentPlan["monthly"] = payload ? (payload.monthly ? payload.monthly : 0) : ""
    paymentPlan["plan_id"] = payload ? (payload.plan_id ? payload.plan_id : "") : ""
    paymentPlan["title"] = payload ? (payload.title ? payload.title : "") : ""
    paymentPlan["discount"] = payload ? (payload.discount ? payload.discount : 0) : ""
    paymentPlan["shipping"] = payload ? (payload.shipping ? payload.shipping : 0) : ""
    paymentPlan["duration"] = payload ? (payload.duration ? payload.duration : "") : ""
    paymentPlan["freeTrial"] = payload ? (payload.freeTrial ? payload.freeTrial : 0) : 0
    paymentPlan["monthly_Charge"] = payload ? (payload.stripe_plan_monthly ? payload.stripe_plan_monthly : "") : ""
    paymentPlan["annually_Charge"] = payload ? (payload.stripe_plan_anually ? payload.stripe_plan_anually : "") : ""
    paymentPlan["address1"] = payload ? (payload.address1 ? payload.address1 : "") : ""
    paymentPlan["address2"] = payload ? (payload.address2 ? payload.address2 : "") : ""
    paymentPlan["city"] = payload ? (payload.city ? payload.city : "") : ""
    paymentPlan["state"] = payload ? (payload.state ? payload.state : "") : ""
    paymentPlan["zip"] = payload ? (payload.zip ? payload.zip : "") : ""
    paymentPlan["business_name"] = payload ? (payload.business_name ? payload.business_name : "") : ""
    paymentPlan["moduleTypeId"] = payload ? (payload.moduleTypeId ? payload.moduleTypeId : 0) : ""
    paymentPlan["to_email"] = payload ? (payload.to_email ? payload.to_email : "") : ""
    paymentPlan["notes"] = payload ? (payload.notes ? payload.notes : "") : ""
    paymentPlan["to_user_id"] = payload ? (payload.to_user_id ? payload.to_user_id : "") : ""
    paymentPlan["tag_id"] = payload ? (payload.tag_id ? payload.tag_id : "") : ""
    paymentPlan["discount_applied "] = payload ? (payload.discount_applied ? payload.discount_applied : 0) : ""
    paymentPlan["discount_percent "] = payload ? (payload.discount_percent ? payload.discount_percent : 0) : ""


    if (payload && payload.duration === "monthly") {
        paymentPlan["total"] = (parseFloat(payload && payload.monthly ? payload.monthly : 0) + parseFloat(payload && payload.shipping ? payload.shipping : 0) - parseFloat(payload && payload.discount ? payload.discount : 0)).toFixed(2);
    } else if (payload && payload.duration === "anually") {
        paymentPlan["total"] = (parseFloat(payload && payload.anually ? payload.anually : 0) + parseFloat(payload && payload.shipping ? payload.shipping : 0) - parseFloat(payload && payload.discount ? payload.discount : 0)).toFixed(2);
    } else if (payload && payload.duration === "oneTimeVerification") {
        paymentPlan["total"] = 1;
    } else if (payload && payload.duration === "oneTimeFee") {
        paymentPlan["total"] = 823;
    } else if (payload && payload.duration === "walletMoney") {
        paymentPlan["total"] = payload.total;
    } else if (payload && payload.duration === "AD_Payment") {
        paymentPlan["total"] = payload.total;
    }

    dispatch({ type: CREATE_PAYMENT, payload: paymentPlan });
    history.push('/payment-checkout');
}

export const fetchAndCreateCustomer = (payload, history) => async dispatch => {
    const { email, name } = payload || {};
    const response = await getCustomers(email);
    const { data } = response || {};
    if (data.data && !data.data.length) {
        const customer = await createCustomer(name, email);
        if (customer && customer.status === 200) {
            if (customer?.data && customer?.data?.id) dispatch({ type: FETCHCUSTOMER, payload: customer.data.id });
        }
    } else if (data && data.data.length) {
        if (data.data[0]?.id) dispatch({ type: FETCHCUSTOMER, payload: data.data[0].id });
    }
}