import { CONDITIONALEDITTAG } from "../types";

let initailstate = {
    tagValue: {},
}

export const conditionalEditTag = (state = initailstate, { type, payload }) => {
    switch (type) {
        case CONDITIONALEDITTAG:
            return {
                ...state,
                tagValue: payload
            }
        default:
            return state;
    }
}
