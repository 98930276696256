import  API  from '@aws-amplify/api';
import { initializeWebPushCLI } from './InitializeApi';

export const webPushConfig = {
    pushKey: process.env.REACT_APP_VAPID_PUBLIC_KEY,
    appSyncUrl: process.env.REACT_APP_APPSYNC_URL_WEB_PUSH,
    appSyncApiKey: process.env.REACT_APP_APPSYNC_API_KEY_WEB_PUSH
};


const saveSubscription = async (subscription, topic) => {
    const userId = localStorage.getItem('id');
    const userAgent = fnBrowserDetect();
    const endpoint = subscription.endpoint
    const webPushSubscription = /* GraphQL */ `
        query getSubscription(
        $endpoint: String,
        ) {
        getSubscription(endpoint: $endpoint)
    }`;



    // check if endpoint is already exist
    initializeWebPushCLI();

    let subsciptionList = await API.graphql({
        query: webPushSubscription,
        variables: {
            endpoint: endpoint
        }
    });

    let listData = JSON.parse(subsciptionList.data.getSubscription);

    console.log('dynamodb endpoint data on server')
    console.log(listData)

    if (listData == null || listData.length <= 0) {
        console.log('savign subscription now')
        const res = await fetch(webPushConfig.appSyncUrl, {
            method: "POST",
            headers: { "x-api-key": webPushConfig.appSyncApiKey },
            body: JSON.stringify({
                query: `mutation($topic: String, $subscription: String, $userId: String, $userAgent: String, $endpoint: String) {subscribe(topic: $topic, subscription: $subscription, userId: $userId, userAgent: $userAgent, endpoint: $endpoint)}`,
                variables: { topic, subscription: JSON.stringify(subscription), userId, userAgent, endpoint }
            })
        });

        return res.status === 200 ? res.json() : false;
    } else {
        console.log('user already subscribed for this endpoint')
    }
};

const generateSubscription = async (swRegistration, topic) => {
    // check browser support
    if (swRegistration.pushManager) {
        await window.Notification.requestPermission();
        const pushSubscription = await swRegistration.pushManager.getSubscription();
        console.log(pushSubscription)
        if (!pushSubscription) {
            try {
                const subscription = await swRegistration.pushManager.subscribe({
                    userVisibleOnly: true,
                    applicationServerKey: urlBase64ToUint8Array(webPushConfig.pushKey)
                });
                console.log(subscription)
                const saved = await saveSubscription(subscription, topic);
                if (saved) return saved;
                throw Error('Subscription not saved!');
            } catch (error) {
                console.log(error);
            }
        } else return pushSubscription;

    } else {
        console.log('swRegistration.pushManager does not supported by browser')
        // var domain = window.location.hostname
        // var pResult = window.safari.pushNotification.permission(domain);
        // console.log(pResult)
        // if(pResult.permission === 'default') {
        //     //request permission
        //     console.log(pResult)
        // } else if (pResult.permission === 'granted') {
        //     console.log("Permission for " + domain + " is " + pResult.permission);
        //     var token = pResult.deviceToken;
        //     // Show subscription for debug
        //     window.prompt('Subscription details:',token);
        // } else if(pResult.permission === 'denied') {
        //     alert("Permission for " + domain + " is " + pResult.permission);
        // }

    }
};


const registerServiceWorker = async () => {
    return await navigator.serviceWorker.register(`${process.env.PUBLIC_URL}/sw-webpush.js`);
};

export const register = async (topic) => {
    console.log('registering')
    if ('serviceWorker' in navigator) {
        const swRegistration = await registerServiceWorker();
        await generateSubscription(swRegistration, topic);
    } else throw new Error('ServiceWorkers are not supported by your browser!');
};

export const unsubscribeWebPush = async (topic) => {
    console.log('unsubscribing')
    if ('serviceWorker' in navigator) {
        const swRegistration = await registerServiceWorker();
        await swRegistration.pushManager.getSubscription().then(function (subscription) {
            if (subscription == null) {
                console.log('no subscription found')
            } else {
                subscription.unsubscribe().then(function (successful) {
                    // You've successfully unsubscribed
                    //   delete record from db
                    deleteServerSubscription(subscription.endpoint, topic)
                    console.log(successful)
                }).catch(function (e) {
                    // Unsubscribing failed
                    console.log('failed to unsubscribe')
                })
            }
        })
    } else throw new Error('ServiceWorkers are not supported by your browser!');
}

const deleteServerSubscription = async (endpoint, topic) => {
    console.log(endpoint, topic)
    const res = await fetch(webPushConfig.appSyncUrl, {
        method: "POST",
        headers: { "x-api-key": webPushConfig.appSyncApiKey },
        body: JSON.stringify({
            query: `mutation($topic: String, $endpoint: String) {deleteSubscription(topic: $topic,, endpoint: $endpoint)}`,
            variables: { topic, endpoint }
        })
    });

    console.log('delete subs log')
    console.log(res)
    return res.status === 200 ? res.json() : false;
}


const urlBase64ToUint8Array = base64String => {
    if (base64String) {
        const padding = '='.repeat((4 - (base64String?.length % 4)) % 4);
        const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/');
        const rawData = atob(base64);
        const outputArray = new Uint8Array(rawData.length);
        for (let i = 0; i < rawData.length; i += 1) {
            outputArray[i] = rawData.charCodeAt(i);
        }
        return outputArray;
    }
};


function fnBrowserDetect() {
    let userAgent = navigator.userAgent;
    let browserName;

    if (userAgent.match(/chrome|chromium|crios/i)) {
        browserName = "chrome";
    } else if (userAgent.match(/firefox|fxios/i)) {
        browserName = "firefox";
    } else if (userAgent.match(/safari/i)) {
        browserName = "safari";
    } else if (userAgent.match(/opr\//i)) {
        browserName = "opera";
    } else if (userAgent.match(/edg/i)) {
        browserName = "edge";
    } else {
        browserName = "";
    }

    return browserName
}
