import React from "react";
import { Helmet } from 'react-helmet';

const MetaTags = (props) => {
    const { title, description, keywords } = props
    return (
        <Helmet>
            {
                title && <title>{title}</title>
            }
            {
                keywords && <meta name="keywords" content={keywords} />
            }
            {
                description && <meta name="description" content={description} />
            }
        </Helmet>
    )
};

export default MetaTags;
