import React from 'react';
import { Link } from 'react-router-dom';

// import { Line } from 'rc-progress';
import $ from 'jquery/dist/jquery'
import { deviceOs } from '../../Helper/commonFunction';
//Layout
//api
class Hello extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            percent: 0
        }
    }


    openDialogue = () => {
        //When fblogin for andriod 
        // will take some time
        // till wait create an account
        let fburl = new URLSearchParams(new URL(window.location.href.split('#').join('')).search).get('access_token');
        if (!fburl) $(".screenContent").toggleClass("fullwidth")
    }

    isClosedSplashToggle = () => {
        //When fblogin for andriod 
        // will take some time
        // till wait handlebar 
        let fburl = new URLSearchParams(new URL(window.location.href.split('#').join('')).search).get('access_token');
        if (!fburl) $(".screenContent").toggleClass("fullwidth")
    }

    render() {
        return (
            <>
                <div className="onboarding-bg">
                    <div className="welcome-block">
                        <figure className="welcome-block__infographic signin-figure"></figure>
                        <div className="welcome-block__content">
                            <h1 className="large_screen_size social_reven_font large_font">Social Reinvented</h1>
                            <p className="large_scren_p">Don’t have an account yet?</p>
                            <button className="btn btn-secondary create_btn" onClick={() => { this.openDialogue() }}>Create an account</button>
                        </div>
                    </div>

                    <div className="about-block">
                        <figure onClick={() => window.open("https://lapidus.io/", "__blank")}>
                            <img src={process.env.PUBLIC_URL + "/images/logo-lapidus-together.svg"} alt="logo" height="44" />
                        </figure>

                        <h5 className="title">
                            <img src={process.env.PUBLIC_URL + "/images/logo-lapidus.svg"} alt="logo" height="16" />
                            A LAPIDUS COMPANY
                        </h5>
                        {/* <div className="progressLoader">
                            <Line percent={this.state.percent} strokeWidth="6" trailWidth="6" strokeColor="#5983F0" />
                        </div> */}
                    </div>
                </div>

                <div className="onboarding-card-container loginBox  ">
                    <div className="onboarding-card-header">
                        <figure>
                            <img src={process.env.PUBLIC_URL + "/images/logo.svg"} alt="logo" height="45" />
                        </figure>
                        <h4 className="onboarding-card-header__title">wexxle</h4>
                    </div>
                    <div className="onboarding-card gapSet helloScreenCard padd_fr_mbl">
                        <span className="onboarding-card__handle" onClick={() => { this.isClosedSplashToggle() }}></span>
                        <div className="onboarding-card__logo">
                            <Link to="" className="anchor">
                                <img src={process.env.PUBLIC_URL + "/images/logo.svg"} alt="logo" height="30" />
                                <span className="logoText">wexxle</span>
                            </Link>
                        </div>

                        <div className="welcome-text-desktop hellowScreen guest_screen">
                            <h2>Reimagine and <br></br>
                                Create, things <br></br>
                                And ideas.</h2>

                        </div>

                        <div className="button_width">
                            <button type="button" className="btn btn-primary btn-block mb-4" onClick={this.props.checkLogin.bind(this, false)}>Login</button>
                            <button type="button" id='continue_without_login' className="btn btn-outline-secondary btn-block"
                                onClick={() => {
                                    deviceOs() === "iOS" ?
                                        window.location.href = "iosApp://continue_without_signin" : (
                                            deviceOs() === "android" ? document.location = "/continue_without_signin" : this.props.checkLogin(true)
                                        )
                                }}>Continue without Sign in</button>
                        </div>

                    </div>
                </div >
            </>
        );
    }
}
export default Hello;