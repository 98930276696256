import React from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify/dist/react-toastify.esm';
import $ from 'jquery/dist/jquery'
//Layout
import LoginWithSocialMedia from '../layout/LoginWithSocialMedia';
import * as myConstants from "../../Helper/constants";
//api
import { AES, enc } from 'crypto-js'
import { login } from '../../api/LoginSignupApi';
import Cookies from 'universal-cookie';
import { createQRUrl } from '../../redux/actions/onBoardingActions';
import connect from 'react-redux/es/connect/connect';
import { OtpVerification } from '../../api/LoginSignupApi';
import OtpInput from 'react-otp-input';
import { passwordRecovery } from '../../api/LoginSignupApi';
import { removeClass, getIpv4, deviceType } from '../../Helper/commonFunction'
import { activate_User } from '../../api/GraphqlApi';
import { mapSetting, createUserLoginAction, fetchUserLoginDetail, updateUserLoginAction } from '../../api/GraphqlApi';
import { register } from '../../Helper/webPushRegister'
import { initializeAuthCLI } from '../../Helper/InitializeApi';
import { fetchCookiesConditionally, createCookies } from '../../Helper/cookies';
import { fetchUserSetting } from '../../api/GraphqlApi';
import { childPermission } from '../../redux/actions/childPermission';
import { enablesetting } from '../../redux/actions/onBoardingActions';
import history from '../../history'
const cookies = new Cookies();
const secretkey = "my-secret-key@123"

class Login extends React.PureComponent {
    constructor(props) {
        super(props)
        this.token = localStorage.getItem("Default")
        this.state = {
            email: '',
            password: '',
            otp: '',
            phone: "",
            seconds: 0,
            minutes: 1,
            loading: false,
            rememberme: false,
            hasErrored: false,
            isDisabled: false,
            resend: false,
            otpVerified: false,
            errors: {},
            ismobile: false,
            socialloading: false
        }
        this.handleLogin = this.handleLogin.bind(this);
    }


    displaySize = () => {
        // let innerHeight = Math.floor($("#app_height").innerHeight())
        // let upperheight = 200;
        // let finalHeight = innerHeight - upperheight;
        // console.log(finalHeight);
        // $('#loginScrollSection').css({ "maxHeight": `${finalHeight}px` });
        const doc = document.documentElement;
        doc.style.setProperty('--app-height', `${window.innerHeight}px`);
    }

    async handleLogin() {
        var params = {};
        params.email = this.state.email;
        params.password = this.state.password;
        params.user_type = "1"
        this.setState({ loading: true, seconds: 0, minutes: 1, otp: "", otpVerified: false, hasErrored: false });
        this.props.showLoader(true);
        try {
            let res = await login(params);
            if (res && res.success === true) {
                if (!["localhost", "127.0.0.1", ""].includes(window.location.hostname)) await register('news');
                localStorage.setItem('token', res.data.token)
                localStorage.setItem('id', res.data.ID ? res.data.ID : "")
                localStorage.setItem('email', res.data.email ? res.data.email : "")
                localStorage.setItem('firstName', res.data.first_name ? res.data.first_name : "")
                localStorage.setItem('lastName', res.data.last_name ? res.data.last_name : "")
                localStorage.setItem('displayName', res.data.display_name ? res.data.display_name : "");
                localStorage.setItem('isKid', res.data.is_kid === "1" ? "true" : "false")
                if (res && res.data.profile_img) localStorage.setItem('profile_image', myConstants.media_base_url + "/" + res.data.profile_img)
                else localStorage.setItem('profile_image', process.env.PUBLIC_URL + "/images/user-icon.svg")
                if (res && res.data.marketplace_id) localStorage.setItem('marketplace_id', res.data.marketplace_id && res.data.marketplace_id)
                localStorage.setItem('address', res.data.address ? res.data.address : "")
                localStorage.setItem('dob', res.data.dob ? res.data.dob : "")
                localStorage.setItem('gender', res.data.gender ? res.data.gender : "")
                localStorage.setItem('phone_number', res.data.phone_number ? res.data.phone_number : "")
                localStorage.setItem('about_me', res.data.about_me ? res.data.about_me : "")
                this.props.enablesetting(true)
                if (localStorage.getItem("referalcode")) localStorage.removeItem("referalcode");
                if (localStorage.getItem("invitecode")) localStorage.removeItem("invitecode");
                if (!this.token) this.props.checkLogin("")
                if (this.token && res.data.ID && res.data.token) {
                    window.location.reload()
                    localStorage.removeItem("Default")
                }
                if (this.props) this.props.childPermission()

                // Set Cookies For Rememberme
                let encryptpassword = AES.encrypt(JSON.stringify(this.state.password), secretkey)?.toString();
                let encryptemail = AES.encrypt(JSON.stringify(res.data.email), secretkey)?.toString();
                cookies.set("_rme_", this.state.rememberme, { path: `'${process.env.REACT_APP_PATH}'` })
                cookies.set('_EMS_', encryptemail, { path: `'${process.env.REACT_APP_PATH}'` })
                cookies.set('_Pws_', encryptpassword, { path: `'${process.env.REACT_APP_PATH}'` })
                if (localStorage.getItem('url')) {
                    history.push(localStorage.getItem('url'))
                    window.location.reload()
                }
            } else if (res && res.activation === "User not activated.") {
                let { phone_number } = res.data;
                // eslint-disable-next-line
                var params = {};
                params.phone = phone_number;
                params.isregistered = true;
                await passwordRecovery(params);   //To create Otp
                this.OtpTimer()
                toast.success("User not activated. Please check device for otp!")
                removeClass();
                $("#exampleModal").modal("show")
                this.setState({ ...this.state, phone: phone_number, resend: true, errors: {} })
            } else if (res && res.success === false) {
                toast.error(res.message)
            } else if (res && res.error) {
                this.setState({
                    ...this.state, errors: {
                        ...this.state.errors,
                        ...res.error
                    }
                });
            }
            this.setState({ loading: false });
            this.props.showLoader(false);
        } catch (error) {
            toast.error(error?.message || "Something went wrong.")
            console.log(error?.message || "Something went wrong")
            this.props.showLoader(false);
            this.setState({ loading: false });
        }
    }

    checkCookiesForCreateCaptcha = async (id) => {
        let response = fetchCookiesConditionally("logincookies")
        if (response) {
            createCookies("logincookies", response, 129600);
            if (response && Object.keys(response).length) {
                let { ip } = JSON.parse(response);
                this.fetchWithUpdateUserLog(ip);
            }
        } else {
            let ipv4 = await getIpv4()
            createCookies("logincookies", JSON.stringify({ "user_id": id, "ip": ipv4 }), 129600);
            this.fetchWithUpdateUserLog(ipv4);
        }

    }

    fetchWithUpdateUserLog = async (ip) => {
        let response = await fetchUserLoginDetail(ip);
        let { items } = response.data.searchUserLoginActionlogs;
        if (Array.isArray(items) && items.length) {
            let { id } = items[0];
            await updateUserLoginAction(id, true);
        } else if ((Array.isArray(items) && !items.length)) {
            await createUserLoginAction();
        }
    }

    componentDidMount() {
        this.displaySize();
        let response = deviceType()
        window.addEventListener('resize', () => this.displaySize());
        let _remember_ = cookies.get("_rme_")
        let email = cookies.get("_EMS_")
        let password = cookies.get("_Pws_")
        if (password && email) {
            var bytes = AES.decrypt(password, secretkey);
            var ebytes = AES.decrypt(email, secretkey);
            var decryptpassword = JSON.parse(bytes.toString(enc.Utf8));
            var decryptemail = JSON.parse(ebytes.toString(enc.Utf8))
        }
        if (_remember_ && JSON.parse(_remember_)) {
            this.setState({
                email: decryptemail ? decryptemail : "",
                rememberme: _remember_ ? JSON.parse(_remember_) : false,
                password: decryptpassword ? decryptpassword : ""
            })
        }
        this.setState({
            ...this.state,
            ismobile: ["android phone"].includes(response) ? true : false
        })
    }

    componentWillUnmount() {
        this.setState = (state, callback) => {
            return;
        };
    }

    handleChange = (otp) => this.setState({ otp });

    componentDidUpdate(_, prevState) {
        if (prevState.otp !== this.state.otp) {
            if (this.state.otp.length === 4) {
                this.OtpVerification(this.state.otp, this.state.phone)
            }
        }
    }

    OtpVerification = async (otp, phone_number) => {
        try {
            this.setState({ ...this.state, hasErrored: false, isDisabled: true })
            let res = await OtpVerification({ otp, phone_number });
            if (res && res.success === true) {
                this.Useractivate(phone_number)
            } else if (res && res.message) {
                toast.error(res.message)
                this.setState({
                    ...this.state,
                    isDisabled: false,
                    hasErrored: true
                })
            }
        } catch (error) {
            console.log(error.message)
            toast.error(error.message)
            this.setState({
                ...this.state,
                isDisabled: false,
                hasErrored: false
            })
        }
    }

    Useractivate = async (phone_number) => {
        let activatedResponse = await activate_User(phone_number)
        if (activatedResponse) { var { statusCode } = activatedResponse; }
        if (statusCode === 200) {
            $("#exampleModal").modal("hide")
            toast.success("User Activated Successfully!")
            this.setState({
                ...this.state,
                email: '',
                phone: "",
                password: "",
                otpVerified: true,
                otp: "",
                loading: false,
                errors: {}
            })
        } else {
            this.setState({ ...this.state, isDisabled: false, hasErrored: false, })
        }
    }

    // Otp Timer For Resend Otp
    OtpTimer = () => {
        let OtpInterval = setInterval(() => {
            if (this.state.otpVerified) {
                clearInterval(OtpInterval)
                this.setState({ resend: false })
            }
            if (this.state.seconds > 0) {
                this.setState({
                    seconds: this.state.seconds - 1
                })
            } else if (this.state.seconds === 0) {
                if (this.state.minutes === 0) {
                    clearInterval(OtpInterval)
                    this.setState({ resend: false })
                } else {
                    this.setState({
                        minutes: this.state.minutes - 1,
                        seconds: 59
                    })
                }
            }
        }, 1000);
    }

    fetchMapSetting = async () => {
        initializeAuthCLI();
        let response = await mapSetting("get_setting");
        if (response && response.statusCode === 200) {
            if (response && response.body && response.body.length) {
                for (let i = 0; i < response.body.length; i++) {
                    if (response.body[i].user_id === localStorage.getItem("id")) {
                        localStorage.setItem(
                            response.body[i].setting_type,
                            response.body[i].setting_value
                        )
                    }
                }
            }
        }
    }

    FetchWexxleStorage = async () => {
        let response = await fetchUserSetting("user_general_setting", "wexxle_cloud_default_storage_gb");
        if (response && response.body && response.body.data && response.body.data.length) {
            let { default_value, current_value } = response.body.data[0]
            let wexxle_storage = current_value ? current_value : default_value;
            localStorage.setItem("wexxlestorage", wexxle_storage)
        } else {
            toast.error("Something Went Wrong!");
        }
    }

    socialLoader = (facebook, google, apple) => {
        this.setState({
            ...this.state,
            socialloading: facebook ? facebook : (google ? google : (apple ? apple : false))
        })
    }

    render() {
        const { showPassword } = this.props
        // eslint-disable-next-line
        const { errors, ismobile, socialloading } = this.state;
        return (
            <>
                <div className="onboarding-card-container loginBox">
                    <div className="onboarding-card-header">
                        <figure>
                            <img src={process.env.PUBLIC_URL + "/images/logo.svg"} alt="logo" height="45" />
                        </figure>
                        <h4 className="onboarding-card-header__title" >wexxle</h4>
                    </div>
                    <div className={this.token ? "onboarding-card loginCardMain login_lar_scr_spac" : "onboarding-card loginCardMain "}>
                        <span className="onboarding-card__handle" onClick={() => this.token ? this.props.isClosedToggle() : this.props.isClosedSplashToggle()}></span>
                        <div id="loginScrollSection" className={this.token ? 'scrollSection guest_login_padding' : 'scrollSection'}>
                            <div className="onboarding-card__logo">
                                <Link to="" className="anchor" style={{ pointerEvents: "none" }}>
                                    <img src={process.env.PUBLIC_URL + "/images/logo.svg"} alt="logo" height="30" />
                                    <span className="logoText">wexxle</span>
                                </Link>
                            </div>
                            <div className="welcome-text-desktop">
                                <h2>Welcome Back!</h2>
                                <p className="lar_space">Sign in to continue </p>
                            </div>
                            <div className="welcome-text">
                                <h1>Sign In</h1>
                                <p>Don’t have an account? <Link to="" className="linkPrime" onClick={() => { this.props.LoginSignupToggle('register') }}>Sign Up</Link></p>
                            </div>

                            <form action="" className="custom-form" autoComplete="off">
                                <div className="onlyFilleds">
                                    <div className="form-group mb-20 email_lar_scr_space">
                                        <label htmlFor="email" className="d-none d-md-block">Email</label>
                                        <div className="filledWithIcon lgn_scn_frm ">
                                            <span className="inputIcons userIcon for_mble">&nbsp;</span>
                                            {(Object.keys(errors).length) > 0 &&
                                                <>
                                                    {errors.email ?
                                                        <span className="inputIconsRight validateCross">
                                                            <span className="validTooltip requiredMsg">{errors.email}</span>
                                                        </span>
                                                        : (this.state.email ? <span className="inputIconsRight validateCheck">&nbsp;</span> : ""
                                                        )
                                                    }
                                                </>
                                            }
                                            <input id="email" placeholder="Enter Email" type="email" value={this.state.email} className="form-control login_padd only_mble_shw" onChange={(e) => this.setState({
                                                ...this.state,
                                                email: e.target.value,
                                                errors: {
                                                    ...this.state.errors,
                                                    email: ""
                                                }
                                            })} />
                                            <p className="login-error_msg"> {(Object.keys(errors).length) > 0 &&
                                                <>
                                                    {errors.email ?
                                                        <span className="inputIconsRight validateCross">
                                                            <span className="validTooltip requiredMsg">{errors.email}</span>
                                                        </span>
                                                        : (this.state.email ? <span className="inputIconsRight validateCheck">&nbsp;</span> : ""
                                                        )
                                                    }
                                                </>
                                            }</p>
                                        </div>
                                    </div>

                                    <div className="form-group mb-20 email_lar_scr_space">
                                        <label htmlFor="password" className="d-none d-md-block">Password</label>
                                        <div className="filledWithIcon lgn_scn_frm">
                                            <span className="inputIcons passwordIcon for_mble">&nbsp;</span>
                                            {errors.password ?
                                                <span className="inputIconsRight validateCross">
                                                    <span className="validTooltip requiredMsg error_max_len" dangerouslySetInnerHTML={{ __html: errors.password }}></span>
                                                </span>
                                                :
                                                // <span className="inputIconsRight showPassword" onClick={() => { this.props.showHidePassword('password') }}>&nbsp;</span>
                                                <span className={!showPassword ? "inputIconsRight hidepassword" : "inputIconsRight showPassword"} onClick={() => this.props.showHidePassword('password')}></span>
                                            }
                                            <input id="password" maxLength={12} type={this.props.showPassword ? "text" : "password"} placeholder="* * * * * *" className="form-control login_padd only_mble_shw" value={this.state.password} onChange={(e) => this.setState({
                                                ...this.state,
                                                password: e.target.value,
                                                errors: {
                                                    ...this.state.errors,
                                                    password: ""
                                                }
                                            })} />
                                        </div>
                                    </div>

                                    <div className="d-flex justify-content-between mt-2 mb-2">
                                        <label htmlFor="rememberMe" className="m-0 label_remember for_mobile">
                                            {/* <input id="rememberMe" type="checkbox" checked={this.state.rememberme} onChange={(e) => {
                                                this.setState({
                                                    ...this.state,
                                                    rememberme: !this.state.rememberme
                                                })
                                            }} /> */}
                                            <label className="rememberMe">
                                                <input type="checkbox" />
                                                <span className="checkmark"></span>
                                            </label>
                                            <span className="size14 rememberText ">Remember me</span>
                                        </label>
                                        <span className="forgot-password-link mb-20" style={{
                                            pointerEvents: (this.state.loading || socialloading) ? "none" : ""
                                        }} onClick={() => { this.props.LoginSignupToggle('forgot') }}>Forgot Password?</span>
                                    </div>
                                </div>

                                <div className="form-group mb-20 $font-black">
                                    <input type="button" disabled={
                                        (socialloading || this.state.loading) ? true : false
                                    } value={this.state.loading ? "Loading" : "Login"} className="btn btn-primary btn-block login_button" onClick={this.handleLogin} />
                                </div>
                                <div className="form-group  text-center  d-md-none hr_lne">
                                    <span className="hr_line_bth">OR</span>
                                </div>
                                {/* !ismobile && */}
                                {
                                    <>
                                        <div className="form-group  text-center d-none d-md-block margin_btom cnnt_spn_div">
                                            <span className="cnnt_scl">Or connect with socials</span>
                                        </div>
                                        <LoginWithSocialMedia socialLoader={this.socialLoader} checkLogin={this.props.checkLogin} emailLoginLoader={this.state.loading} />
                                    </>
                                }
                                <div className="form-group text-center d-md-none login_last_txt">
                                    <span>Or connect with your email</span>
                                </div>
                            </form>
                            {
                                this.token && <div className="text-center" style={{
                                    pointerEvents: (this.state.loading || socialloading) ? "none" : ""
                                }}>
                                    New User! <span className='text-secondary cursor' onClick={() => { this.props.LoginSignupToggle('register') }}>Register</span>
                                </div>
                            }
                            <div className="onboardingQrcode">
                                {/* data-toggle="modal" data-target="#qrCodeModal" */}
                                <div className="onboardingQrcode__inside" style={{
                                    pointerEvents: (this.state.loading || socialloading) ? "none" : ""
                                }} onClick={() => {
                                    this.props.createQRUrl({ key: Date.now(), auth: "", open: true, user_id: "0" })
                                }}>
                                    <span className="">Or Scan QR Code</span>
                                    <div className="qrcodeImg">
                                        <img src={process.env.PUBLIC_URL + "/images/qrcode.svg"} alt="QR" />
                                    </div>
                                </div>
                                {/* <div className="onboardingQrcode__mobileOnly" data-toggle="modal" data-target="#qrCodeScannerModal">
                                    <span>Scan QR Code</span>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade otp_verify" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content otp_box_fill">
                            <div className="modal-header d-block text-center">
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => this.setState({
                                    ...this.state,
                                    otpVerified: true
                                })}>
                                </button>
                                <h5 className="modal-title" id="exampleModalLabel">Activate Your Account </h5>
                                <p>Enter Otp To Activate Your Account</p>
                                {
                                    this.state.resend ? <><p><span>{this.state.minutes < 10 ? "0" + this.state.minutes : this.state.minutes}</span>&nbsp;:&nbsp;<span>{this.state.seconds < 10 ? "0" + this.state.seconds : this.state.seconds}</span></p></> : <a href="void:(0)" style={this.state.loading ? { pointerEvents: "none" } : {}} onClick={() => {
                                        this.setState({ ...this.state, otp: "", hasErrored: false }, () => this.handleLogin())
                                    }}><img src={process.env.PUBLIC_URL + "/images/resend-icon.png"} alt="resend-icon"></img><span>Resend</span></a>
                                }

                            </div>
                            <div className="modal-body">
                                <OtpInput
                                    isInputNum={true}
                                    value={this.state.otp}
                                    onChange={this.handleChange}
                                    numInputs={4}
                                    containerStyle={"otpBox"}
                                    inputStyle={"otpInput"}
                                    separator={<span>&nbsp;</span>}
                                    isDisabled={this.state.isDisabled}
                                    hasErrored={this.state.hasErrored}
                                    errorStyle={"otpInputError"}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    permission: state,
})
const mapDispatchToProps = {
    createQRUrl,
    childPermission,
    enablesetting,
}


export default connect(mapStateToProps, mapDispatchToProps)(Login);

