import React from "react";
import Slider from "react-slick/lib/slider";

const ReactSlider = (props) => {
    const { slideref, children, ...rest } = props;
    return <Slider {...rest} {...(slideref && { ref: slideref })} >
        {children}
    </Slider>
};

export default ReactSlider;
