import React, { Fragment, useState } from 'react'
import { toast } from 'react-toastify';
import isEmail from "validator/lib/isEmail";
import { capitalizeFirstLetter, isEmpty } from '../../Helper/util';
import { sendAppNotification } from '../../api/GraphqlApi';

const ShareTagViaEmail = (props) => {
    const { url } = props;
    const [state, setState] = useState({
        email: "",
        loading: false,
        shareOpened: false
    })
    const { email, loading, shareOpened } = state
    const style = {
        "backgroundColor": "transparent",
        "border": "none",
        "padding": "0px",
        "font": "inherit",
        "color": "inherit",
        "cursor": "pointer",
        "background": "#243b6b",
        "width": "100px",
        "lineHeight": "48px",
        "borderRadius": "2.8rem"

    }
    return (
        <Fragment>
            <button className="react-share__ShareButton" style={{ ...style }} onClick={() => setState(prev => ({ ...prev, shareOpened: true }))}>
                <img src='/images/mail-send.svg' alt='' style={{ width: "28px" }} />
            </button>
            <div className={shareOpened ? "overlay opened" : "overlay"} ></div>
            <div className={shareOpened ? "bottom-slide-card positionFixed opened" : "bottom-slide-card"}>
                <div className="bottom-slide-card__header">
                    <h4 className="mb-0">Share Via Mail</h4>
                    <div className="iconxs closeIcon" onClick={(e) => {
                        setState({ email: "", loading: false, shareOpened: false })
                    }}>&nbsp;</div>
                </div>
                <div className="bottom-slide-card__body">
                    <input
                        type='text'
                        value={email}
                        className='form-control'
                        placeholder='Please Enter Email'
                        onChange={(e) => {
                            e.persist()
                            setState(prev => ({ ...prev, email: e.target.value }))
                        }} />
                </div>
                <button className="btn btn-outline-primary" disabled={loading} onClick={async () => {
                    if (isEmpty(email)) {
                        toast.error("Please enter email")
                        return;
                    }
                    else if (!isEmail(email)) {
                        toast.error("Please enter valid email")
                        return;
                    }
                    setState(prev => ({ ...prev, loading: true }))
                    const response = await sendAppNotification(localStorage.getItem("id"), "send_general_notifications", 35, { ...{ ...(url && { tag_link: url }), ...(localStorage.getItem("displayName") && { creator_name: capitalizeFirstLetter(localStorage.getItem("displayName")) }) } }, "", email)
                    const { status } = response || {}
                    if (status === 200) {
                        toast.success("Tag shared successfully via mail")
                        setTimeout(() => {
                            setState({ email: "", loading: false, shareOpened: false })
                        }, 500);
                    }
                    else setState(prev => ({ ...prev, loading: false }))
                }}>Send</button>
            </div>
        </Fragment>
    )
}

export default ShareTagViaEmail