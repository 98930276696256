import React from 'react'
import connect from 'react-redux/es/connect/connect';
import Layout from '../layout/Layout';
import HomeView from '../views/HomeView';
import {toast} from 'react-toastify/dist/react-toastify.esm';

// import { showAppInstallPrompt } from '../../serviceWorker';
// import { hideInstallPrompt } from '../../redux/actions/pwaRelatedDataAction';
toast.configure();
const Home = (props) => {
    // const {hideInstallPrompt,PwaRelatedData}=props;
    // const {showInstallPrompt}=PwaRelatedData;
    return (
        <div>
        {/* <div onClick={()=>showInstallPrompt&&showAppInstallPrompt(hideInstallPrompt)}> */}
            <Layout>
                <HomeView />
            </Layout>
        </div>
    )
}

const mapStateToProps = state => ({
    PwaRelatedData:state.PwaRelatedData,
});
export default connect(mapStateToProps,{
    // hideInstallPrompt
})(Home)
