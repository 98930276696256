import { UNREADEMAILCOUNT } from "../types";

const initialstate = {
    unreadMsgCount: 0
};

const unreademailreducer = (state = initialstate, { type, payload }) => {
    switch (type) {
        case UNREADEMAILCOUNT:
            return {
                unreadMsgCount: payload,
            };
        default:
            return state;
    }
};

export default unreademailreducer;