import React from 'react';
//import Header from './Header';
//import Menu from './Menu';

const Layout = ({ children }) => {
	return (
		<div className="wrapper">
			{/* <Header goBack={goBack} screenName={screenName} /> */}

			<section className="screenContent fullwidth">{children}</section>

			{/* <Menu /> */}
		</div>
	);
};

export default Layout;
