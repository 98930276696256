/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateMessageByCoversationId = /* GraphQL */ `
  subscription OnCreateMessageByCoversationId(
    $conversationMessagesId: String!
  ) {
    onCreateMessageByCoversationId(
      conversationMessagesId: $conversationMessagesId
    ) {
      id
      conversation_id {
        id
        sender
        recipient
        messages {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        message_from
        tag_id
        status
        __typename
      }
      sender
      content
      recipient
      media
      is_readed
      message_timestamp
      createdAt
      message_from
      tag_id
      status
      updatedAt
      conversationMessagesId
      __typename
    }
  }
`;
export const onUpdateMessageByCoversationId = /* GraphQL */ `
  subscription OnUpdateMessageByCoversationId(
    $conversationMessagesId: String!
  ) {
    onUpdateMessageByCoversationId(
      conversationMessagesId: $conversationMessagesId
    ) {
      id
      conversation_id {
        id
        sender
        recipient
        messages {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        message_from
        tag_id
        status
        __typename
      }
      sender
      content
      recipient
      media
      is_readed
      message_timestamp
      createdAt
      message_from
      tag_id
      status
      updatedAt
      conversationMessagesId
      __typename
    }
  }
`;
export const onCreateMessageByRecipient = /* GraphQL */ `
  subscription OnCreateMessageByRecipient($recipient: Int!) {
    onCreateMessageByRecipient(recipient: $recipient) {
      id
      conversation_id {
        id
        sender
        recipient
        messages {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        message_from
        tag_id
        status
        __typename
      }
      sender
      content
      recipient
      media
      is_readed
      message_timestamp
      createdAt
      message_from
      tag_id
      status
      updatedAt
      conversationMessagesId
      __typename
    }
  }
`;
export const onUpdateMapsByType = /* GraphQL */ `
  subscription OnUpdateMapsByType($item_type: String!) {
    onUpdateMapsByType(item_type: $item_type) {
      id
      item_id
      item_type
      longitude
      latitude
      status
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateLoginQRById = /* GraphQL */ `
  subscription OnUpdateLoginQRById($id: String!) {
    onUpdateLoginQRById(id: $id) {
      id
      user_id
      jwt_auth
      ip_address
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateUserNotificationsByUser = /* GraphQL */ `
  subscription OnCreateUserNotificationsByUser($user_id: Int!) {
    onCreateUserNotificationsByUser(user_id: $user_id) {
      id
      user_id
      user_name
      alert_msg
      redirect_url
      status
      read_status
      added_by_id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateLoginStatusByUser = /* GraphQL */ `
  subscription OnUpdateLoginStatusByUser(
    $user_id: Int!
    $login_status: Boolean!
  ) {
    onUpdateLoginStatusByUser(user_id: $user_id, login_status: $login_status) {
      id
      user_id
      device_os
      device_id
      device_type
      device_name
      user_agent
      remote_ip
      login_status
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateNewInAppEmail = /* GraphQL */ `
  subscription OnCreateNewInAppEmail($to_user_id: Int, $to_email: String) {
    onCreateNewInAppEmail(to_user_id: $to_user_id, to_email: $to_email) {
      id
      from_email
      to_email
      from_user_id
      to_user_id
      is_from_admin
      subject_line
      message_body
      read_status
      status
      category
      sub_category
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUserLogin = /* GraphQL */ `
  subscription OnUserLogin($user_id: Int!, $login_status: Boolean) {
    onUserLogin(user_id: $user_id, login_status: $login_status) {
      id
      user_id
      device_os
      device_id
      device_type
      device_name
      user_agent
      remote_ip
      login_status
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateTag = /* GraphQL */ `
  subscription OnCreateTag($filter: ModelSubscriptionTagFilterInput) {
    onCreateTag(filter: $filter) {
      id
      user_id
      type
      title
      content
      addresses
      visibility
      comments
      tagged_user_id
      memory_date
      occasion
      category
      sub_category
      price
      asset_price
      sold_price
      quantity
      available_for_sale
      auto_attach
      custom_field
      start_date
      end_date
      task_date
      task_end_date
      media
      repeat
      custom_start
      custom_repeat
      custom_repeat_value
      custom_days
      custom_end
      public_distance
      public_start
      public_end
      latitude
      longitude
      altitude
      purchasing_date
      createdAt
      updatedAt
      tag_attach_id
      shared_tag_id {
        id
        user_id
        type
        title
        content
        addresses
        visibility
        comments
        tagged_user_id
        memory_date
        occasion
        category
        sub_category
        price
        asset_price
        sold_price
        quantity
        available_for_sale
        auto_attach
        custom_field
        start_date
        end_date
        task_date
        task_end_date
        media
        repeat
        custom_start
        custom_repeat
        custom_repeat_value
        custom_days
        custom_end
        public_distance
        public_start
        public_end
        latitude
        longitude
        altitude
        purchasing_date
        createdAt
        updatedAt
        tag_attach_id
        shared_tag_id {
          id
          user_id
          type
          title
          content
          addresses
          visibility
          comments
          tagged_user_id
          memory_date
          occasion
          category
          sub_category
          price
          asset_price
          sold_price
          quantity
          available_for_sale
          auto_attach
          custom_field
          start_date
          end_date
          task_date
          task_end_date
          media
          repeat
          custom_start
          custom_repeat
          custom_repeat_value
          custom_days
          custom_end
          public_distance
          public_start
          public_end
          latitude
          longitude
          altitude
          purchasing_date
          createdAt
          updatedAt
          tag_attach_id
          tag_level
          status
          floor
          room
          is_sold
          sold_date
          scene_tags
          is_abvertising_paid
          is_admin_approved
          spotify
          parent_id
          top_parent_id
          is_public
          is_tc_accepted
          is_master_attached
          full_day
          attached_with_master
          tagShared_tagsId
          tagChild_tagsId
          tagAttached_tag_idId
          __typename
        }
        shared_tags {
          nextToken
          __typename
        }
        parent_tag_id {
          id
          user_id
          type
          title
          content
          addresses
          visibility
          comments
          tagged_user_id
          memory_date
          occasion
          category
          sub_category
          price
          asset_price
          sold_price
          quantity
          available_for_sale
          auto_attach
          custom_field
          start_date
          end_date
          task_date
          task_end_date
          media
          repeat
          custom_start
          custom_repeat
          custom_repeat_value
          custom_days
          custom_end
          public_distance
          public_start
          public_end
          latitude
          longitude
          altitude
          purchasing_date
          createdAt
          updatedAt
          tag_attach_id
          tag_level
          status
          floor
          room
          is_sold
          sold_date
          scene_tags
          is_abvertising_paid
          is_admin_approved
          spotify
          parent_id
          top_parent_id
          is_public
          is_tc_accepted
          is_master_attached
          full_day
          attached_with_master
          tagShared_tagsId
          tagChild_tagsId
          tagAttached_tag_idId
          __typename
        }
        child_tags {
          nextToken
          __typename
        }
        tag_level
        comment_list {
          nextToken
          __typename
        }
        like_dislike_list {
          nextToken
          __typename
        }
        tag_messages {
          nextToken
          __typename
        }
        status
        floor
        room
        is_sold
        sold_date
        scene_tags
        is_abvertising_paid
        is_admin_approved
        analytics_user_data {
          nextToken
          __typename
        }
        spotify
        parent_id
        top_parent_id
        is_public
        is_tc_accepted
        is_master_attached
        attached_tag_id {
          nextToken
          __typename
        }
        full_day
        attached_with_master
        tagShared_tagsId
        tagChild_tagsId
        tagAttached_tag_idId
        __typename
      }
      shared_tags {
        items {
          id
          user_id
          type
          title
          content
          addresses
          visibility
          comments
          tagged_user_id
          memory_date
          occasion
          category
          sub_category
          price
          asset_price
          sold_price
          quantity
          available_for_sale
          auto_attach
          custom_field
          start_date
          end_date
          task_date
          task_end_date
          media
          repeat
          custom_start
          custom_repeat
          custom_repeat_value
          custom_days
          custom_end
          public_distance
          public_start
          public_end
          latitude
          longitude
          altitude
          purchasing_date
          createdAt
          updatedAt
          tag_attach_id
          tag_level
          status
          floor
          room
          is_sold
          sold_date
          scene_tags
          is_abvertising_paid
          is_admin_approved
          spotify
          parent_id
          top_parent_id
          is_public
          is_tc_accepted
          is_master_attached
          full_day
          attached_with_master
          tagShared_tagsId
          tagChild_tagsId
          tagAttached_tag_idId
          __typename
        }
        nextToken
        __typename
      }
      parent_tag_id {
        id
        user_id
        type
        title
        content
        addresses
        visibility
        comments
        tagged_user_id
        memory_date
        occasion
        category
        sub_category
        price
        asset_price
        sold_price
        quantity
        available_for_sale
        auto_attach
        custom_field
        start_date
        end_date
        task_date
        task_end_date
        media
        repeat
        custom_start
        custom_repeat
        custom_repeat_value
        custom_days
        custom_end
        public_distance
        public_start
        public_end
        latitude
        longitude
        altitude
        purchasing_date
        createdAt
        updatedAt
        tag_attach_id
        shared_tag_id {
          id
          user_id
          type
          title
          content
          addresses
          visibility
          comments
          tagged_user_id
          memory_date
          occasion
          category
          sub_category
          price
          asset_price
          sold_price
          quantity
          available_for_sale
          auto_attach
          custom_field
          start_date
          end_date
          task_date
          task_end_date
          media
          repeat
          custom_start
          custom_repeat
          custom_repeat_value
          custom_days
          custom_end
          public_distance
          public_start
          public_end
          latitude
          longitude
          altitude
          purchasing_date
          createdAt
          updatedAt
          tag_attach_id
          tag_level
          status
          floor
          room
          is_sold
          sold_date
          scene_tags
          is_abvertising_paid
          is_admin_approved
          spotify
          parent_id
          top_parent_id
          is_public
          is_tc_accepted
          is_master_attached
          full_day
          attached_with_master
          tagShared_tagsId
          tagChild_tagsId
          tagAttached_tag_idId
          __typename
        }
        shared_tags {
          nextToken
          __typename
        }
        parent_tag_id {
          id
          user_id
          type
          title
          content
          addresses
          visibility
          comments
          tagged_user_id
          memory_date
          occasion
          category
          sub_category
          price
          asset_price
          sold_price
          quantity
          available_for_sale
          auto_attach
          custom_field
          start_date
          end_date
          task_date
          task_end_date
          media
          repeat
          custom_start
          custom_repeat
          custom_repeat_value
          custom_days
          custom_end
          public_distance
          public_start
          public_end
          latitude
          longitude
          altitude
          purchasing_date
          createdAt
          updatedAt
          tag_attach_id
          tag_level
          status
          floor
          room
          is_sold
          sold_date
          scene_tags
          is_abvertising_paid
          is_admin_approved
          spotify
          parent_id
          top_parent_id
          is_public
          is_tc_accepted
          is_master_attached
          full_day
          attached_with_master
          tagShared_tagsId
          tagChild_tagsId
          tagAttached_tag_idId
          __typename
        }
        child_tags {
          nextToken
          __typename
        }
        tag_level
        comment_list {
          nextToken
          __typename
        }
        like_dislike_list {
          nextToken
          __typename
        }
        tag_messages {
          nextToken
          __typename
        }
        status
        floor
        room
        is_sold
        sold_date
        scene_tags
        is_abvertising_paid
        is_admin_approved
        analytics_user_data {
          nextToken
          __typename
        }
        spotify
        parent_id
        top_parent_id
        is_public
        is_tc_accepted
        is_master_attached
        attached_tag_id {
          nextToken
          __typename
        }
        full_day
        attached_with_master
        tagShared_tagsId
        tagChild_tagsId
        tagAttached_tag_idId
        __typename
      }
      child_tags {
        items {
          id
          user_id
          type
          title
          content
          addresses
          visibility
          comments
          tagged_user_id
          memory_date
          occasion
          category
          sub_category
          price
          asset_price
          sold_price
          quantity
          available_for_sale
          auto_attach
          custom_field
          start_date
          end_date
          task_date
          task_end_date
          media
          repeat
          custom_start
          custom_repeat
          custom_repeat_value
          custom_days
          custom_end
          public_distance
          public_start
          public_end
          latitude
          longitude
          altitude
          purchasing_date
          createdAt
          updatedAt
          tag_attach_id
          tag_level
          status
          floor
          room
          is_sold
          sold_date
          scene_tags
          is_abvertising_paid
          is_admin_approved
          spotify
          parent_id
          top_parent_id
          is_public
          is_tc_accepted
          is_master_attached
          full_day
          attached_with_master
          tagShared_tagsId
          tagChild_tagsId
          tagAttached_tag_idId
          __typename
        }
        nextToken
        __typename
      }
      tag_level
      comment_list {
        items {
          id
          user_id
          content
          comment_type
          status
          createdAt
          updatedAt
          tagComment_listId
          __typename
        }
        nextToken
        __typename
      }
      like_dislike_list {
        items {
          unique_id
          type
          user_id
          diamond
          duck
          createdAt
          updatedAt
          tagLike_dislike_listId
          commentLike_dislike_listId
          __typename
        }
        nextToken
        __typename
      }
      tag_messages {
        items {
          id
          sender
          recipient
          content
          createdAt
          updatedAt
          tagTag_messagesId
          tagMessageRepliesId
          __typename
        }
        nextToken
        __typename
      }
      status
      floor
      room
      is_sold
      sold_date
      scene_tags
      is_abvertising_paid
      is_admin_approved
      analytics_user_data {
        items {
          id
          user_id
          ipAddress
          browser
          deviceType
          is_view_only
          createdAt
          updatedAt
          tagAnalytics_user_dataId
          __typename
        }
        nextToken
        __typename
      }
      spotify
      parent_id
      top_parent_id
      is_public
      is_tc_accepted
      is_master_attached
      attached_tag_id {
        items {
          id
          user_id
          type
          title
          content
          addresses
          visibility
          comments
          tagged_user_id
          memory_date
          occasion
          category
          sub_category
          price
          asset_price
          sold_price
          quantity
          available_for_sale
          auto_attach
          custom_field
          start_date
          end_date
          task_date
          task_end_date
          media
          repeat
          custom_start
          custom_repeat
          custom_repeat_value
          custom_days
          custom_end
          public_distance
          public_start
          public_end
          latitude
          longitude
          altitude
          purchasing_date
          createdAt
          updatedAt
          tag_attach_id
          tag_level
          status
          floor
          room
          is_sold
          sold_date
          scene_tags
          is_abvertising_paid
          is_admin_approved
          spotify
          parent_id
          top_parent_id
          is_public
          is_tc_accepted
          is_master_attached
          full_day
          attached_with_master
          tagShared_tagsId
          tagChild_tagsId
          tagAttached_tag_idId
          __typename
        }
        nextToken
        __typename
      }
      full_day
      attached_with_master
      tagShared_tagsId
      tagChild_tagsId
      tagAttached_tag_idId
      __typename
    }
  }
`;
export const onUpdateTag = /* GraphQL */ `
  subscription OnUpdateTag($filter: ModelSubscriptionTagFilterInput) {
    onUpdateTag(filter: $filter) {
      id
      user_id
      type
      title
      content
      addresses
      visibility
      comments
      tagged_user_id
      memory_date
      occasion
      category
      sub_category
      price
      asset_price
      sold_price
      quantity
      available_for_sale
      auto_attach
      custom_field
      start_date
      end_date
      task_date
      task_end_date
      media
      repeat
      custom_start
      custom_repeat
      custom_repeat_value
      custom_days
      custom_end
      public_distance
      public_start
      public_end
      latitude
      longitude
      altitude
      purchasing_date
      createdAt
      updatedAt
      tag_attach_id
      shared_tag_id {
        id
        user_id
        type
        title
        content
        addresses
        visibility
        comments
        tagged_user_id
        memory_date
        occasion
        category
        sub_category
        price
        asset_price
        sold_price
        quantity
        available_for_sale
        auto_attach
        custom_field
        start_date
        end_date
        task_date
        task_end_date
        media
        repeat
        custom_start
        custom_repeat
        custom_repeat_value
        custom_days
        custom_end
        public_distance
        public_start
        public_end
        latitude
        longitude
        altitude
        purchasing_date
        createdAt
        updatedAt
        tag_attach_id
        shared_tag_id {
          id
          user_id
          type
          title
          content
          addresses
          visibility
          comments
          tagged_user_id
          memory_date
          occasion
          category
          sub_category
          price
          asset_price
          sold_price
          quantity
          available_for_sale
          auto_attach
          custom_field
          start_date
          end_date
          task_date
          task_end_date
          media
          repeat
          custom_start
          custom_repeat
          custom_repeat_value
          custom_days
          custom_end
          public_distance
          public_start
          public_end
          latitude
          longitude
          altitude
          purchasing_date
          createdAt
          updatedAt
          tag_attach_id
          tag_level
          status
          floor
          room
          is_sold
          sold_date
          scene_tags
          is_abvertising_paid
          is_admin_approved
          spotify
          parent_id
          top_parent_id
          is_public
          is_tc_accepted
          is_master_attached
          full_day
          attached_with_master
          tagShared_tagsId
          tagChild_tagsId
          tagAttached_tag_idId
          __typename
        }
        shared_tags {
          nextToken
          __typename
        }
        parent_tag_id {
          id
          user_id
          type
          title
          content
          addresses
          visibility
          comments
          tagged_user_id
          memory_date
          occasion
          category
          sub_category
          price
          asset_price
          sold_price
          quantity
          available_for_sale
          auto_attach
          custom_field
          start_date
          end_date
          task_date
          task_end_date
          media
          repeat
          custom_start
          custom_repeat
          custom_repeat_value
          custom_days
          custom_end
          public_distance
          public_start
          public_end
          latitude
          longitude
          altitude
          purchasing_date
          createdAt
          updatedAt
          tag_attach_id
          tag_level
          status
          floor
          room
          is_sold
          sold_date
          scene_tags
          is_abvertising_paid
          is_admin_approved
          spotify
          parent_id
          top_parent_id
          is_public
          is_tc_accepted
          is_master_attached
          full_day
          attached_with_master
          tagShared_tagsId
          tagChild_tagsId
          tagAttached_tag_idId
          __typename
        }
        child_tags {
          nextToken
          __typename
        }
        tag_level
        comment_list {
          nextToken
          __typename
        }
        like_dislike_list {
          nextToken
          __typename
        }
        tag_messages {
          nextToken
          __typename
        }
        status
        floor
        room
        is_sold
        sold_date
        scene_tags
        is_abvertising_paid
        is_admin_approved
        analytics_user_data {
          nextToken
          __typename
        }
        spotify
        parent_id
        top_parent_id
        is_public
        is_tc_accepted
        is_master_attached
        attached_tag_id {
          nextToken
          __typename
        }
        full_day
        attached_with_master
        tagShared_tagsId
        tagChild_tagsId
        tagAttached_tag_idId
        __typename
      }
      shared_tags {
        items {
          id
          user_id
          type
          title
          content
          addresses
          visibility
          comments
          tagged_user_id
          memory_date
          occasion
          category
          sub_category
          price
          asset_price
          sold_price
          quantity
          available_for_sale
          auto_attach
          custom_field
          start_date
          end_date
          task_date
          task_end_date
          media
          repeat
          custom_start
          custom_repeat
          custom_repeat_value
          custom_days
          custom_end
          public_distance
          public_start
          public_end
          latitude
          longitude
          altitude
          purchasing_date
          createdAt
          updatedAt
          tag_attach_id
          tag_level
          status
          floor
          room
          is_sold
          sold_date
          scene_tags
          is_abvertising_paid
          is_admin_approved
          spotify
          parent_id
          top_parent_id
          is_public
          is_tc_accepted
          is_master_attached
          full_day
          attached_with_master
          tagShared_tagsId
          tagChild_tagsId
          tagAttached_tag_idId
          __typename
        }
        nextToken
        __typename
      }
      parent_tag_id {
        id
        user_id
        type
        title
        content
        addresses
        visibility
        comments
        tagged_user_id
        memory_date
        occasion
        category
        sub_category
        price
        asset_price
        sold_price
        quantity
        available_for_sale
        auto_attach
        custom_field
        start_date
        end_date
        task_date
        task_end_date
        media
        repeat
        custom_start
        custom_repeat
        custom_repeat_value
        custom_days
        custom_end
        public_distance
        public_start
        public_end
        latitude
        longitude
        altitude
        purchasing_date
        createdAt
        updatedAt
        tag_attach_id
        shared_tag_id {
          id
          user_id
          type
          title
          content
          addresses
          visibility
          comments
          tagged_user_id
          memory_date
          occasion
          category
          sub_category
          price
          asset_price
          sold_price
          quantity
          available_for_sale
          auto_attach
          custom_field
          start_date
          end_date
          task_date
          task_end_date
          media
          repeat
          custom_start
          custom_repeat
          custom_repeat_value
          custom_days
          custom_end
          public_distance
          public_start
          public_end
          latitude
          longitude
          altitude
          purchasing_date
          createdAt
          updatedAt
          tag_attach_id
          tag_level
          status
          floor
          room
          is_sold
          sold_date
          scene_tags
          is_abvertising_paid
          is_admin_approved
          spotify
          parent_id
          top_parent_id
          is_public
          is_tc_accepted
          is_master_attached
          full_day
          attached_with_master
          tagShared_tagsId
          tagChild_tagsId
          tagAttached_tag_idId
          __typename
        }
        shared_tags {
          nextToken
          __typename
        }
        parent_tag_id {
          id
          user_id
          type
          title
          content
          addresses
          visibility
          comments
          tagged_user_id
          memory_date
          occasion
          category
          sub_category
          price
          asset_price
          sold_price
          quantity
          available_for_sale
          auto_attach
          custom_field
          start_date
          end_date
          task_date
          task_end_date
          media
          repeat
          custom_start
          custom_repeat
          custom_repeat_value
          custom_days
          custom_end
          public_distance
          public_start
          public_end
          latitude
          longitude
          altitude
          purchasing_date
          createdAt
          updatedAt
          tag_attach_id
          tag_level
          status
          floor
          room
          is_sold
          sold_date
          scene_tags
          is_abvertising_paid
          is_admin_approved
          spotify
          parent_id
          top_parent_id
          is_public
          is_tc_accepted
          is_master_attached
          full_day
          attached_with_master
          tagShared_tagsId
          tagChild_tagsId
          tagAttached_tag_idId
          __typename
        }
        child_tags {
          nextToken
          __typename
        }
        tag_level
        comment_list {
          nextToken
          __typename
        }
        like_dislike_list {
          nextToken
          __typename
        }
        tag_messages {
          nextToken
          __typename
        }
        status
        floor
        room
        is_sold
        sold_date
        scene_tags
        is_abvertising_paid
        is_admin_approved
        analytics_user_data {
          nextToken
          __typename
        }
        spotify
        parent_id
        top_parent_id
        is_public
        is_tc_accepted
        is_master_attached
        attached_tag_id {
          nextToken
          __typename
        }
        full_day
        attached_with_master
        tagShared_tagsId
        tagChild_tagsId
        tagAttached_tag_idId
        __typename
      }
      child_tags {
        items {
          id
          user_id
          type
          title
          content
          addresses
          visibility
          comments
          tagged_user_id
          memory_date
          occasion
          category
          sub_category
          price
          asset_price
          sold_price
          quantity
          available_for_sale
          auto_attach
          custom_field
          start_date
          end_date
          task_date
          task_end_date
          media
          repeat
          custom_start
          custom_repeat
          custom_repeat_value
          custom_days
          custom_end
          public_distance
          public_start
          public_end
          latitude
          longitude
          altitude
          purchasing_date
          createdAt
          updatedAt
          tag_attach_id
          tag_level
          status
          floor
          room
          is_sold
          sold_date
          scene_tags
          is_abvertising_paid
          is_admin_approved
          spotify
          parent_id
          top_parent_id
          is_public
          is_tc_accepted
          is_master_attached
          full_day
          attached_with_master
          tagShared_tagsId
          tagChild_tagsId
          tagAttached_tag_idId
          __typename
        }
        nextToken
        __typename
      }
      tag_level
      comment_list {
        items {
          id
          user_id
          content
          comment_type
          status
          createdAt
          updatedAt
          tagComment_listId
          __typename
        }
        nextToken
        __typename
      }
      like_dislike_list {
        items {
          unique_id
          type
          user_id
          diamond
          duck
          createdAt
          updatedAt
          tagLike_dislike_listId
          commentLike_dislike_listId
          __typename
        }
        nextToken
        __typename
      }
      tag_messages {
        items {
          id
          sender
          recipient
          content
          createdAt
          updatedAt
          tagTag_messagesId
          tagMessageRepliesId
          __typename
        }
        nextToken
        __typename
      }
      status
      floor
      room
      is_sold
      sold_date
      scene_tags
      is_abvertising_paid
      is_admin_approved
      analytics_user_data {
        items {
          id
          user_id
          ipAddress
          browser
          deviceType
          is_view_only
          createdAt
          updatedAt
          tagAnalytics_user_dataId
          __typename
        }
        nextToken
        __typename
      }
      spotify
      parent_id
      top_parent_id
      is_public
      is_tc_accepted
      is_master_attached
      attached_tag_id {
        items {
          id
          user_id
          type
          title
          content
          addresses
          visibility
          comments
          tagged_user_id
          memory_date
          occasion
          category
          sub_category
          price
          asset_price
          sold_price
          quantity
          available_for_sale
          auto_attach
          custom_field
          start_date
          end_date
          task_date
          task_end_date
          media
          repeat
          custom_start
          custom_repeat
          custom_repeat_value
          custom_days
          custom_end
          public_distance
          public_start
          public_end
          latitude
          longitude
          altitude
          purchasing_date
          createdAt
          updatedAt
          tag_attach_id
          tag_level
          status
          floor
          room
          is_sold
          sold_date
          scene_tags
          is_abvertising_paid
          is_admin_approved
          spotify
          parent_id
          top_parent_id
          is_public
          is_tc_accepted
          is_master_attached
          full_day
          attached_with_master
          tagShared_tagsId
          tagChild_tagsId
          tagAttached_tag_idId
          __typename
        }
        nextToken
        __typename
      }
      full_day
      attached_with_master
      tagShared_tagsId
      tagChild_tagsId
      tagAttached_tag_idId
      __typename
    }
  }
`;
export const onDeleteTag = /* GraphQL */ `
  subscription OnDeleteTag($filter: ModelSubscriptionTagFilterInput) {
    onDeleteTag(filter: $filter) {
      id
      user_id
      type
      title
      content
      addresses
      visibility
      comments
      tagged_user_id
      memory_date
      occasion
      category
      sub_category
      price
      asset_price
      sold_price
      quantity
      available_for_sale
      auto_attach
      custom_field
      start_date
      end_date
      task_date
      task_end_date
      media
      repeat
      custom_start
      custom_repeat
      custom_repeat_value
      custom_days
      custom_end
      public_distance
      public_start
      public_end
      latitude
      longitude
      altitude
      purchasing_date
      createdAt
      updatedAt
      tag_attach_id
      shared_tag_id {
        id
        user_id
        type
        title
        content
        addresses
        visibility
        comments
        tagged_user_id
        memory_date
        occasion
        category
        sub_category
        price
        asset_price
        sold_price
        quantity
        available_for_sale
        auto_attach
        custom_field
        start_date
        end_date
        task_date
        task_end_date
        media
        repeat
        custom_start
        custom_repeat
        custom_repeat_value
        custom_days
        custom_end
        public_distance
        public_start
        public_end
        latitude
        longitude
        altitude
        purchasing_date
        createdAt
        updatedAt
        tag_attach_id
        shared_tag_id {
          id
          user_id
          type
          title
          content
          addresses
          visibility
          comments
          tagged_user_id
          memory_date
          occasion
          category
          sub_category
          price
          asset_price
          sold_price
          quantity
          available_for_sale
          auto_attach
          custom_field
          start_date
          end_date
          task_date
          task_end_date
          media
          repeat
          custom_start
          custom_repeat
          custom_repeat_value
          custom_days
          custom_end
          public_distance
          public_start
          public_end
          latitude
          longitude
          altitude
          purchasing_date
          createdAt
          updatedAt
          tag_attach_id
          tag_level
          status
          floor
          room
          is_sold
          sold_date
          scene_tags
          is_abvertising_paid
          is_admin_approved
          spotify
          parent_id
          top_parent_id
          is_public
          is_tc_accepted
          is_master_attached
          full_day
          attached_with_master
          tagShared_tagsId
          tagChild_tagsId
          tagAttached_tag_idId
          __typename
        }
        shared_tags {
          nextToken
          __typename
        }
        parent_tag_id {
          id
          user_id
          type
          title
          content
          addresses
          visibility
          comments
          tagged_user_id
          memory_date
          occasion
          category
          sub_category
          price
          asset_price
          sold_price
          quantity
          available_for_sale
          auto_attach
          custom_field
          start_date
          end_date
          task_date
          task_end_date
          media
          repeat
          custom_start
          custom_repeat
          custom_repeat_value
          custom_days
          custom_end
          public_distance
          public_start
          public_end
          latitude
          longitude
          altitude
          purchasing_date
          createdAt
          updatedAt
          tag_attach_id
          tag_level
          status
          floor
          room
          is_sold
          sold_date
          scene_tags
          is_abvertising_paid
          is_admin_approved
          spotify
          parent_id
          top_parent_id
          is_public
          is_tc_accepted
          is_master_attached
          full_day
          attached_with_master
          tagShared_tagsId
          tagChild_tagsId
          tagAttached_tag_idId
          __typename
        }
        child_tags {
          nextToken
          __typename
        }
        tag_level
        comment_list {
          nextToken
          __typename
        }
        like_dislike_list {
          nextToken
          __typename
        }
        tag_messages {
          nextToken
          __typename
        }
        status
        floor
        room
        is_sold
        sold_date
        scene_tags
        is_abvertising_paid
        is_admin_approved
        analytics_user_data {
          nextToken
          __typename
        }
        spotify
        parent_id
        top_parent_id
        is_public
        is_tc_accepted
        is_master_attached
        attached_tag_id {
          nextToken
          __typename
        }
        full_day
        attached_with_master
        tagShared_tagsId
        tagChild_tagsId
        tagAttached_tag_idId
        __typename
      }
      shared_tags {
        items {
          id
          user_id
          type
          title
          content
          addresses
          visibility
          comments
          tagged_user_id
          memory_date
          occasion
          category
          sub_category
          price
          asset_price
          sold_price
          quantity
          available_for_sale
          auto_attach
          custom_field
          start_date
          end_date
          task_date
          task_end_date
          media
          repeat
          custom_start
          custom_repeat
          custom_repeat_value
          custom_days
          custom_end
          public_distance
          public_start
          public_end
          latitude
          longitude
          altitude
          purchasing_date
          createdAt
          updatedAt
          tag_attach_id
          tag_level
          status
          floor
          room
          is_sold
          sold_date
          scene_tags
          is_abvertising_paid
          is_admin_approved
          spotify
          parent_id
          top_parent_id
          is_public
          is_tc_accepted
          is_master_attached
          full_day
          attached_with_master
          tagShared_tagsId
          tagChild_tagsId
          tagAttached_tag_idId
          __typename
        }
        nextToken
        __typename
      }
      parent_tag_id {
        id
        user_id
        type
        title
        content
        addresses
        visibility
        comments
        tagged_user_id
        memory_date
        occasion
        category
        sub_category
        price
        asset_price
        sold_price
        quantity
        available_for_sale
        auto_attach
        custom_field
        start_date
        end_date
        task_date
        task_end_date
        media
        repeat
        custom_start
        custom_repeat
        custom_repeat_value
        custom_days
        custom_end
        public_distance
        public_start
        public_end
        latitude
        longitude
        altitude
        purchasing_date
        createdAt
        updatedAt
        tag_attach_id
        shared_tag_id {
          id
          user_id
          type
          title
          content
          addresses
          visibility
          comments
          tagged_user_id
          memory_date
          occasion
          category
          sub_category
          price
          asset_price
          sold_price
          quantity
          available_for_sale
          auto_attach
          custom_field
          start_date
          end_date
          task_date
          task_end_date
          media
          repeat
          custom_start
          custom_repeat
          custom_repeat_value
          custom_days
          custom_end
          public_distance
          public_start
          public_end
          latitude
          longitude
          altitude
          purchasing_date
          createdAt
          updatedAt
          tag_attach_id
          tag_level
          status
          floor
          room
          is_sold
          sold_date
          scene_tags
          is_abvertising_paid
          is_admin_approved
          spotify
          parent_id
          top_parent_id
          is_public
          is_tc_accepted
          is_master_attached
          full_day
          attached_with_master
          tagShared_tagsId
          tagChild_tagsId
          tagAttached_tag_idId
          __typename
        }
        shared_tags {
          nextToken
          __typename
        }
        parent_tag_id {
          id
          user_id
          type
          title
          content
          addresses
          visibility
          comments
          tagged_user_id
          memory_date
          occasion
          category
          sub_category
          price
          asset_price
          sold_price
          quantity
          available_for_sale
          auto_attach
          custom_field
          start_date
          end_date
          task_date
          task_end_date
          media
          repeat
          custom_start
          custom_repeat
          custom_repeat_value
          custom_days
          custom_end
          public_distance
          public_start
          public_end
          latitude
          longitude
          altitude
          purchasing_date
          createdAt
          updatedAt
          tag_attach_id
          tag_level
          status
          floor
          room
          is_sold
          sold_date
          scene_tags
          is_abvertising_paid
          is_admin_approved
          spotify
          parent_id
          top_parent_id
          is_public
          is_tc_accepted
          is_master_attached
          full_day
          attached_with_master
          tagShared_tagsId
          tagChild_tagsId
          tagAttached_tag_idId
          __typename
        }
        child_tags {
          nextToken
          __typename
        }
        tag_level
        comment_list {
          nextToken
          __typename
        }
        like_dislike_list {
          nextToken
          __typename
        }
        tag_messages {
          nextToken
          __typename
        }
        status
        floor
        room
        is_sold
        sold_date
        scene_tags
        is_abvertising_paid
        is_admin_approved
        analytics_user_data {
          nextToken
          __typename
        }
        spotify
        parent_id
        top_parent_id
        is_public
        is_tc_accepted
        is_master_attached
        attached_tag_id {
          nextToken
          __typename
        }
        full_day
        attached_with_master
        tagShared_tagsId
        tagChild_tagsId
        tagAttached_tag_idId
        __typename
      }
      child_tags {
        items {
          id
          user_id
          type
          title
          content
          addresses
          visibility
          comments
          tagged_user_id
          memory_date
          occasion
          category
          sub_category
          price
          asset_price
          sold_price
          quantity
          available_for_sale
          auto_attach
          custom_field
          start_date
          end_date
          task_date
          task_end_date
          media
          repeat
          custom_start
          custom_repeat
          custom_repeat_value
          custom_days
          custom_end
          public_distance
          public_start
          public_end
          latitude
          longitude
          altitude
          purchasing_date
          createdAt
          updatedAt
          tag_attach_id
          tag_level
          status
          floor
          room
          is_sold
          sold_date
          scene_tags
          is_abvertising_paid
          is_admin_approved
          spotify
          parent_id
          top_parent_id
          is_public
          is_tc_accepted
          is_master_attached
          full_day
          attached_with_master
          tagShared_tagsId
          tagChild_tagsId
          tagAttached_tag_idId
          __typename
        }
        nextToken
        __typename
      }
      tag_level
      comment_list {
        items {
          id
          user_id
          content
          comment_type
          status
          createdAt
          updatedAt
          tagComment_listId
          __typename
        }
        nextToken
        __typename
      }
      like_dislike_list {
        items {
          unique_id
          type
          user_id
          diamond
          duck
          createdAt
          updatedAt
          tagLike_dislike_listId
          commentLike_dislike_listId
          __typename
        }
        nextToken
        __typename
      }
      tag_messages {
        items {
          id
          sender
          recipient
          content
          createdAt
          updatedAt
          tagTag_messagesId
          tagMessageRepliesId
          __typename
        }
        nextToken
        __typename
      }
      status
      floor
      room
      is_sold
      sold_date
      scene_tags
      is_abvertising_paid
      is_admin_approved
      analytics_user_data {
        items {
          id
          user_id
          ipAddress
          browser
          deviceType
          is_view_only
          createdAt
          updatedAt
          tagAnalytics_user_dataId
          __typename
        }
        nextToken
        __typename
      }
      spotify
      parent_id
      top_parent_id
      is_public
      is_tc_accepted
      is_master_attached
      attached_tag_id {
        items {
          id
          user_id
          type
          title
          content
          addresses
          visibility
          comments
          tagged_user_id
          memory_date
          occasion
          category
          sub_category
          price
          asset_price
          sold_price
          quantity
          available_for_sale
          auto_attach
          custom_field
          start_date
          end_date
          task_date
          task_end_date
          media
          repeat
          custom_start
          custom_repeat
          custom_repeat_value
          custom_days
          custom_end
          public_distance
          public_start
          public_end
          latitude
          longitude
          altitude
          purchasing_date
          createdAt
          updatedAt
          tag_attach_id
          tag_level
          status
          floor
          room
          is_sold
          sold_date
          scene_tags
          is_abvertising_paid
          is_admin_approved
          spotify
          parent_id
          top_parent_id
          is_public
          is_tc_accepted
          is_master_attached
          full_day
          attached_with_master
          tagShared_tagsId
          tagChild_tagsId
          tagAttached_tag_idId
          __typename
        }
        nextToken
        __typename
      }
      full_day
      attached_with_master
      tagShared_tagsId
      tagChild_tagsId
      tagAttached_tag_idId
      __typename
    }
  }
`;
export const onCreateTagAnalyticsUserData = /* GraphQL */ `
  subscription OnCreateTagAnalyticsUserData(
    $filter: ModelSubscriptionTagAnalyticsUserDataFilterInput
  ) {
    onCreateTagAnalyticsUserData(filter: $filter) {
      id
      tag_id {
        id
        user_id
        type
        title
        content
        addresses
        visibility
        comments
        tagged_user_id
        memory_date
        occasion
        category
        sub_category
        price
        asset_price
        sold_price
        quantity
        available_for_sale
        auto_attach
        custom_field
        start_date
        end_date
        task_date
        task_end_date
        media
        repeat
        custom_start
        custom_repeat
        custom_repeat_value
        custom_days
        custom_end
        public_distance
        public_start
        public_end
        latitude
        longitude
        altitude
        purchasing_date
        createdAt
        updatedAt
        tag_attach_id
        shared_tag_id {
          id
          user_id
          type
          title
          content
          addresses
          visibility
          comments
          tagged_user_id
          memory_date
          occasion
          category
          sub_category
          price
          asset_price
          sold_price
          quantity
          available_for_sale
          auto_attach
          custom_field
          start_date
          end_date
          task_date
          task_end_date
          media
          repeat
          custom_start
          custom_repeat
          custom_repeat_value
          custom_days
          custom_end
          public_distance
          public_start
          public_end
          latitude
          longitude
          altitude
          purchasing_date
          createdAt
          updatedAt
          tag_attach_id
          tag_level
          status
          floor
          room
          is_sold
          sold_date
          scene_tags
          is_abvertising_paid
          is_admin_approved
          spotify
          parent_id
          top_parent_id
          is_public
          is_tc_accepted
          is_master_attached
          full_day
          attached_with_master
          tagShared_tagsId
          tagChild_tagsId
          tagAttached_tag_idId
          __typename
        }
        shared_tags {
          nextToken
          __typename
        }
        parent_tag_id {
          id
          user_id
          type
          title
          content
          addresses
          visibility
          comments
          tagged_user_id
          memory_date
          occasion
          category
          sub_category
          price
          asset_price
          sold_price
          quantity
          available_for_sale
          auto_attach
          custom_field
          start_date
          end_date
          task_date
          task_end_date
          media
          repeat
          custom_start
          custom_repeat
          custom_repeat_value
          custom_days
          custom_end
          public_distance
          public_start
          public_end
          latitude
          longitude
          altitude
          purchasing_date
          createdAt
          updatedAt
          tag_attach_id
          tag_level
          status
          floor
          room
          is_sold
          sold_date
          scene_tags
          is_abvertising_paid
          is_admin_approved
          spotify
          parent_id
          top_parent_id
          is_public
          is_tc_accepted
          is_master_attached
          full_day
          attached_with_master
          tagShared_tagsId
          tagChild_tagsId
          tagAttached_tag_idId
          __typename
        }
        child_tags {
          nextToken
          __typename
        }
        tag_level
        comment_list {
          nextToken
          __typename
        }
        like_dislike_list {
          nextToken
          __typename
        }
        tag_messages {
          nextToken
          __typename
        }
        status
        floor
        room
        is_sold
        sold_date
        scene_tags
        is_abvertising_paid
        is_admin_approved
        analytics_user_data {
          nextToken
          __typename
        }
        spotify
        parent_id
        top_parent_id
        is_public
        is_tc_accepted
        is_master_attached
        attached_tag_id {
          nextToken
          __typename
        }
        full_day
        attached_with_master
        tagShared_tagsId
        tagChild_tagsId
        tagAttached_tag_idId
        __typename
      }
      user_id
      ipAddress
      browser
      deviceType
      is_view_only
      createdAt
      updatedAt
      tagAnalytics_user_dataId
      __typename
    }
  }
`;
export const onUpdateTagAnalyticsUserData = /* GraphQL */ `
  subscription OnUpdateTagAnalyticsUserData(
    $filter: ModelSubscriptionTagAnalyticsUserDataFilterInput
  ) {
    onUpdateTagAnalyticsUserData(filter: $filter) {
      id
      tag_id {
        id
        user_id
        type
        title
        content
        addresses
        visibility
        comments
        tagged_user_id
        memory_date
        occasion
        category
        sub_category
        price
        asset_price
        sold_price
        quantity
        available_for_sale
        auto_attach
        custom_field
        start_date
        end_date
        task_date
        task_end_date
        media
        repeat
        custom_start
        custom_repeat
        custom_repeat_value
        custom_days
        custom_end
        public_distance
        public_start
        public_end
        latitude
        longitude
        altitude
        purchasing_date
        createdAt
        updatedAt
        tag_attach_id
        shared_tag_id {
          id
          user_id
          type
          title
          content
          addresses
          visibility
          comments
          tagged_user_id
          memory_date
          occasion
          category
          sub_category
          price
          asset_price
          sold_price
          quantity
          available_for_sale
          auto_attach
          custom_field
          start_date
          end_date
          task_date
          task_end_date
          media
          repeat
          custom_start
          custom_repeat
          custom_repeat_value
          custom_days
          custom_end
          public_distance
          public_start
          public_end
          latitude
          longitude
          altitude
          purchasing_date
          createdAt
          updatedAt
          tag_attach_id
          tag_level
          status
          floor
          room
          is_sold
          sold_date
          scene_tags
          is_abvertising_paid
          is_admin_approved
          spotify
          parent_id
          top_parent_id
          is_public
          is_tc_accepted
          is_master_attached
          full_day
          attached_with_master
          tagShared_tagsId
          tagChild_tagsId
          tagAttached_tag_idId
          __typename
        }
        shared_tags {
          nextToken
          __typename
        }
        parent_tag_id {
          id
          user_id
          type
          title
          content
          addresses
          visibility
          comments
          tagged_user_id
          memory_date
          occasion
          category
          sub_category
          price
          asset_price
          sold_price
          quantity
          available_for_sale
          auto_attach
          custom_field
          start_date
          end_date
          task_date
          task_end_date
          media
          repeat
          custom_start
          custom_repeat
          custom_repeat_value
          custom_days
          custom_end
          public_distance
          public_start
          public_end
          latitude
          longitude
          altitude
          purchasing_date
          createdAt
          updatedAt
          tag_attach_id
          tag_level
          status
          floor
          room
          is_sold
          sold_date
          scene_tags
          is_abvertising_paid
          is_admin_approved
          spotify
          parent_id
          top_parent_id
          is_public
          is_tc_accepted
          is_master_attached
          full_day
          attached_with_master
          tagShared_tagsId
          tagChild_tagsId
          tagAttached_tag_idId
          __typename
        }
        child_tags {
          nextToken
          __typename
        }
        tag_level
        comment_list {
          nextToken
          __typename
        }
        like_dislike_list {
          nextToken
          __typename
        }
        tag_messages {
          nextToken
          __typename
        }
        status
        floor
        room
        is_sold
        sold_date
        scene_tags
        is_abvertising_paid
        is_admin_approved
        analytics_user_data {
          nextToken
          __typename
        }
        spotify
        parent_id
        top_parent_id
        is_public
        is_tc_accepted
        is_master_attached
        attached_tag_id {
          nextToken
          __typename
        }
        full_day
        attached_with_master
        tagShared_tagsId
        tagChild_tagsId
        tagAttached_tag_idId
        __typename
      }
      user_id
      ipAddress
      browser
      deviceType
      is_view_only
      createdAt
      updatedAt
      tagAnalytics_user_dataId
      __typename
    }
  }
`;
export const onDeleteTagAnalyticsUserData = /* GraphQL */ `
  subscription OnDeleteTagAnalyticsUserData(
    $filter: ModelSubscriptionTagAnalyticsUserDataFilterInput
  ) {
    onDeleteTagAnalyticsUserData(filter: $filter) {
      id
      tag_id {
        id
        user_id
        type
        title
        content
        addresses
        visibility
        comments
        tagged_user_id
        memory_date
        occasion
        category
        sub_category
        price
        asset_price
        sold_price
        quantity
        available_for_sale
        auto_attach
        custom_field
        start_date
        end_date
        task_date
        task_end_date
        media
        repeat
        custom_start
        custom_repeat
        custom_repeat_value
        custom_days
        custom_end
        public_distance
        public_start
        public_end
        latitude
        longitude
        altitude
        purchasing_date
        createdAt
        updatedAt
        tag_attach_id
        shared_tag_id {
          id
          user_id
          type
          title
          content
          addresses
          visibility
          comments
          tagged_user_id
          memory_date
          occasion
          category
          sub_category
          price
          asset_price
          sold_price
          quantity
          available_for_sale
          auto_attach
          custom_field
          start_date
          end_date
          task_date
          task_end_date
          media
          repeat
          custom_start
          custom_repeat
          custom_repeat_value
          custom_days
          custom_end
          public_distance
          public_start
          public_end
          latitude
          longitude
          altitude
          purchasing_date
          createdAt
          updatedAt
          tag_attach_id
          tag_level
          status
          floor
          room
          is_sold
          sold_date
          scene_tags
          is_abvertising_paid
          is_admin_approved
          spotify
          parent_id
          top_parent_id
          is_public
          is_tc_accepted
          is_master_attached
          full_day
          attached_with_master
          tagShared_tagsId
          tagChild_tagsId
          tagAttached_tag_idId
          __typename
        }
        shared_tags {
          nextToken
          __typename
        }
        parent_tag_id {
          id
          user_id
          type
          title
          content
          addresses
          visibility
          comments
          tagged_user_id
          memory_date
          occasion
          category
          sub_category
          price
          asset_price
          sold_price
          quantity
          available_for_sale
          auto_attach
          custom_field
          start_date
          end_date
          task_date
          task_end_date
          media
          repeat
          custom_start
          custom_repeat
          custom_repeat_value
          custom_days
          custom_end
          public_distance
          public_start
          public_end
          latitude
          longitude
          altitude
          purchasing_date
          createdAt
          updatedAt
          tag_attach_id
          tag_level
          status
          floor
          room
          is_sold
          sold_date
          scene_tags
          is_abvertising_paid
          is_admin_approved
          spotify
          parent_id
          top_parent_id
          is_public
          is_tc_accepted
          is_master_attached
          full_day
          attached_with_master
          tagShared_tagsId
          tagChild_tagsId
          tagAttached_tag_idId
          __typename
        }
        child_tags {
          nextToken
          __typename
        }
        tag_level
        comment_list {
          nextToken
          __typename
        }
        like_dislike_list {
          nextToken
          __typename
        }
        tag_messages {
          nextToken
          __typename
        }
        status
        floor
        room
        is_sold
        sold_date
        scene_tags
        is_abvertising_paid
        is_admin_approved
        analytics_user_data {
          nextToken
          __typename
        }
        spotify
        parent_id
        top_parent_id
        is_public
        is_tc_accepted
        is_master_attached
        attached_tag_id {
          nextToken
          __typename
        }
        full_day
        attached_with_master
        tagShared_tagsId
        tagChild_tagsId
        tagAttached_tag_idId
        __typename
      }
      user_id
      ipAddress
      browser
      deviceType
      is_view_only
      createdAt
      updatedAt
      tagAnalytics_user_dataId
      __typename
    }
  }
`;
export const onCreateComment = /* GraphQL */ `
  subscription OnCreateComment($filter: ModelSubscriptionCommentFilterInput) {
    onCreateComment(filter: $filter) {
      id
      tag_id {
        id
        user_id
        type
        title
        content
        addresses
        visibility
        comments
        tagged_user_id
        memory_date
        occasion
        category
        sub_category
        price
        asset_price
        sold_price
        quantity
        available_for_sale
        auto_attach
        custom_field
        start_date
        end_date
        task_date
        task_end_date
        media
        repeat
        custom_start
        custom_repeat
        custom_repeat_value
        custom_days
        custom_end
        public_distance
        public_start
        public_end
        latitude
        longitude
        altitude
        purchasing_date
        createdAt
        updatedAt
        tag_attach_id
        shared_tag_id {
          id
          user_id
          type
          title
          content
          addresses
          visibility
          comments
          tagged_user_id
          memory_date
          occasion
          category
          sub_category
          price
          asset_price
          sold_price
          quantity
          available_for_sale
          auto_attach
          custom_field
          start_date
          end_date
          task_date
          task_end_date
          media
          repeat
          custom_start
          custom_repeat
          custom_repeat_value
          custom_days
          custom_end
          public_distance
          public_start
          public_end
          latitude
          longitude
          altitude
          purchasing_date
          createdAt
          updatedAt
          tag_attach_id
          tag_level
          status
          floor
          room
          is_sold
          sold_date
          scene_tags
          is_abvertising_paid
          is_admin_approved
          spotify
          parent_id
          top_parent_id
          is_public
          is_tc_accepted
          is_master_attached
          full_day
          attached_with_master
          tagShared_tagsId
          tagChild_tagsId
          tagAttached_tag_idId
          __typename
        }
        shared_tags {
          nextToken
          __typename
        }
        parent_tag_id {
          id
          user_id
          type
          title
          content
          addresses
          visibility
          comments
          tagged_user_id
          memory_date
          occasion
          category
          sub_category
          price
          asset_price
          sold_price
          quantity
          available_for_sale
          auto_attach
          custom_field
          start_date
          end_date
          task_date
          task_end_date
          media
          repeat
          custom_start
          custom_repeat
          custom_repeat_value
          custom_days
          custom_end
          public_distance
          public_start
          public_end
          latitude
          longitude
          altitude
          purchasing_date
          createdAt
          updatedAt
          tag_attach_id
          tag_level
          status
          floor
          room
          is_sold
          sold_date
          scene_tags
          is_abvertising_paid
          is_admin_approved
          spotify
          parent_id
          top_parent_id
          is_public
          is_tc_accepted
          is_master_attached
          full_day
          attached_with_master
          tagShared_tagsId
          tagChild_tagsId
          tagAttached_tag_idId
          __typename
        }
        child_tags {
          nextToken
          __typename
        }
        tag_level
        comment_list {
          nextToken
          __typename
        }
        like_dislike_list {
          nextToken
          __typename
        }
        tag_messages {
          nextToken
          __typename
        }
        status
        floor
        room
        is_sold
        sold_date
        scene_tags
        is_abvertising_paid
        is_admin_approved
        analytics_user_data {
          nextToken
          __typename
        }
        spotify
        parent_id
        top_parent_id
        is_public
        is_tc_accepted
        is_master_attached
        attached_tag_id {
          nextToken
          __typename
        }
        full_day
        attached_with_master
        tagShared_tagsId
        tagChild_tagsId
        tagAttached_tag_idId
        __typename
      }
      user_id
      content
      comment_type
      like_dislike_list {
        items {
          unique_id
          type
          user_id
          diamond
          duck
          createdAt
          updatedAt
          tagLike_dislike_listId
          commentLike_dislike_listId
          __typename
        }
        nextToken
        __typename
      }
      status
      createdAt
      updatedAt
      tagComment_listId
      __typename
    }
  }
`;
export const onUpdateComment = /* GraphQL */ `
  subscription OnUpdateComment($filter: ModelSubscriptionCommentFilterInput) {
    onUpdateComment(filter: $filter) {
      id
      tag_id {
        id
        user_id
        type
        title
        content
        addresses
        visibility
        comments
        tagged_user_id
        memory_date
        occasion
        category
        sub_category
        price
        asset_price
        sold_price
        quantity
        available_for_sale
        auto_attach
        custom_field
        start_date
        end_date
        task_date
        task_end_date
        media
        repeat
        custom_start
        custom_repeat
        custom_repeat_value
        custom_days
        custom_end
        public_distance
        public_start
        public_end
        latitude
        longitude
        altitude
        purchasing_date
        createdAt
        updatedAt
        tag_attach_id
        shared_tag_id {
          id
          user_id
          type
          title
          content
          addresses
          visibility
          comments
          tagged_user_id
          memory_date
          occasion
          category
          sub_category
          price
          asset_price
          sold_price
          quantity
          available_for_sale
          auto_attach
          custom_field
          start_date
          end_date
          task_date
          task_end_date
          media
          repeat
          custom_start
          custom_repeat
          custom_repeat_value
          custom_days
          custom_end
          public_distance
          public_start
          public_end
          latitude
          longitude
          altitude
          purchasing_date
          createdAt
          updatedAt
          tag_attach_id
          tag_level
          status
          floor
          room
          is_sold
          sold_date
          scene_tags
          is_abvertising_paid
          is_admin_approved
          spotify
          parent_id
          top_parent_id
          is_public
          is_tc_accepted
          is_master_attached
          full_day
          attached_with_master
          tagShared_tagsId
          tagChild_tagsId
          tagAttached_tag_idId
          __typename
        }
        shared_tags {
          nextToken
          __typename
        }
        parent_tag_id {
          id
          user_id
          type
          title
          content
          addresses
          visibility
          comments
          tagged_user_id
          memory_date
          occasion
          category
          sub_category
          price
          asset_price
          sold_price
          quantity
          available_for_sale
          auto_attach
          custom_field
          start_date
          end_date
          task_date
          task_end_date
          media
          repeat
          custom_start
          custom_repeat
          custom_repeat_value
          custom_days
          custom_end
          public_distance
          public_start
          public_end
          latitude
          longitude
          altitude
          purchasing_date
          createdAt
          updatedAt
          tag_attach_id
          tag_level
          status
          floor
          room
          is_sold
          sold_date
          scene_tags
          is_abvertising_paid
          is_admin_approved
          spotify
          parent_id
          top_parent_id
          is_public
          is_tc_accepted
          is_master_attached
          full_day
          attached_with_master
          tagShared_tagsId
          tagChild_tagsId
          tagAttached_tag_idId
          __typename
        }
        child_tags {
          nextToken
          __typename
        }
        tag_level
        comment_list {
          nextToken
          __typename
        }
        like_dislike_list {
          nextToken
          __typename
        }
        tag_messages {
          nextToken
          __typename
        }
        status
        floor
        room
        is_sold
        sold_date
        scene_tags
        is_abvertising_paid
        is_admin_approved
        analytics_user_data {
          nextToken
          __typename
        }
        spotify
        parent_id
        top_parent_id
        is_public
        is_tc_accepted
        is_master_attached
        attached_tag_id {
          nextToken
          __typename
        }
        full_day
        attached_with_master
        tagShared_tagsId
        tagChild_tagsId
        tagAttached_tag_idId
        __typename
      }
      user_id
      content
      comment_type
      like_dislike_list {
        items {
          unique_id
          type
          user_id
          diamond
          duck
          createdAt
          updatedAt
          tagLike_dislike_listId
          commentLike_dislike_listId
          __typename
        }
        nextToken
        __typename
      }
      status
      createdAt
      updatedAt
      tagComment_listId
      __typename
    }
  }
`;
export const onDeleteComment = /* GraphQL */ `
  subscription OnDeleteComment($filter: ModelSubscriptionCommentFilterInput) {
    onDeleteComment(filter: $filter) {
      id
      tag_id {
        id
        user_id
        type
        title
        content
        addresses
        visibility
        comments
        tagged_user_id
        memory_date
        occasion
        category
        sub_category
        price
        asset_price
        sold_price
        quantity
        available_for_sale
        auto_attach
        custom_field
        start_date
        end_date
        task_date
        task_end_date
        media
        repeat
        custom_start
        custom_repeat
        custom_repeat_value
        custom_days
        custom_end
        public_distance
        public_start
        public_end
        latitude
        longitude
        altitude
        purchasing_date
        createdAt
        updatedAt
        tag_attach_id
        shared_tag_id {
          id
          user_id
          type
          title
          content
          addresses
          visibility
          comments
          tagged_user_id
          memory_date
          occasion
          category
          sub_category
          price
          asset_price
          sold_price
          quantity
          available_for_sale
          auto_attach
          custom_field
          start_date
          end_date
          task_date
          task_end_date
          media
          repeat
          custom_start
          custom_repeat
          custom_repeat_value
          custom_days
          custom_end
          public_distance
          public_start
          public_end
          latitude
          longitude
          altitude
          purchasing_date
          createdAt
          updatedAt
          tag_attach_id
          tag_level
          status
          floor
          room
          is_sold
          sold_date
          scene_tags
          is_abvertising_paid
          is_admin_approved
          spotify
          parent_id
          top_parent_id
          is_public
          is_tc_accepted
          is_master_attached
          full_day
          attached_with_master
          tagShared_tagsId
          tagChild_tagsId
          tagAttached_tag_idId
          __typename
        }
        shared_tags {
          nextToken
          __typename
        }
        parent_tag_id {
          id
          user_id
          type
          title
          content
          addresses
          visibility
          comments
          tagged_user_id
          memory_date
          occasion
          category
          sub_category
          price
          asset_price
          sold_price
          quantity
          available_for_sale
          auto_attach
          custom_field
          start_date
          end_date
          task_date
          task_end_date
          media
          repeat
          custom_start
          custom_repeat
          custom_repeat_value
          custom_days
          custom_end
          public_distance
          public_start
          public_end
          latitude
          longitude
          altitude
          purchasing_date
          createdAt
          updatedAt
          tag_attach_id
          tag_level
          status
          floor
          room
          is_sold
          sold_date
          scene_tags
          is_abvertising_paid
          is_admin_approved
          spotify
          parent_id
          top_parent_id
          is_public
          is_tc_accepted
          is_master_attached
          full_day
          attached_with_master
          tagShared_tagsId
          tagChild_tagsId
          tagAttached_tag_idId
          __typename
        }
        child_tags {
          nextToken
          __typename
        }
        tag_level
        comment_list {
          nextToken
          __typename
        }
        like_dislike_list {
          nextToken
          __typename
        }
        tag_messages {
          nextToken
          __typename
        }
        status
        floor
        room
        is_sold
        sold_date
        scene_tags
        is_abvertising_paid
        is_admin_approved
        analytics_user_data {
          nextToken
          __typename
        }
        spotify
        parent_id
        top_parent_id
        is_public
        is_tc_accepted
        is_master_attached
        attached_tag_id {
          nextToken
          __typename
        }
        full_day
        attached_with_master
        tagShared_tagsId
        tagChild_tagsId
        tagAttached_tag_idId
        __typename
      }
      user_id
      content
      comment_type
      like_dislike_list {
        items {
          unique_id
          type
          user_id
          diamond
          duck
          createdAt
          updatedAt
          tagLike_dislike_listId
          commentLike_dislike_listId
          __typename
        }
        nextToken
        __typename
      }
      status
      createdAt
      updatedAt
      tagComment_listId
      __typename
    }
  }
`;
export const onCreateLikeDislike = /* GraphQL */ `
  subscription OnCreateLikeDislike(
    $filter: ModelSubscriptionLikeDislikeFilterInput
  ) {
    onCreateLikeDislike(filter: $filter) {
      unique_id
      tag_id {
        id
        user_id
        type
        title
        content
        addresses
        visibility
        comments
        tagged_user_id
        memory_date
        occasion
        category
        sub_category
        price
        asset_price
        sold_price
        quantity
        available_for_sale
        auto_attach
        custom_field
        start_date
        end_date
        task_date
        task_end_date
        media
        repeat
        custom_start
        custom_repeat
        custom_repeat_value
        custom_days
        custom_end
        public_distance
        public_start
        public_end
        latitude
        longitude
        altitude
        purchasing_date
        createdAt
        updatedAt
        tag_attach_id
        shared_tag_id {
          id
          user_id
          type
          title
          content
          addresses
          visibility
          comments
          tagged_user_id
          memory_date
          occasion
          category
          sub_category
          price
          asset_price
          sold_price
          quantity
          available_for_sale
          auto_attach
          custom_field
          start_date
          end_date
          task_date
          task_end_date
          media
          repeat
          custom_start
          custom_repeat
          custom_repeat_value
          custom_days
          custom_end
          public_distance
          public_start
          public_end
          latitude
          longitude
          altitude
          purchasing_date
          createdAt
          updatedAt
          tag_attach_id
          tag_level
          status
          floor
          room
          is_sold
          sold_date
          scene_tags
          is_abvertising_paid
          is_admin_approved
          spotify
          parent_id
          top_parent_id
          is_public
          is_tc_accepted
          is_master_attached
          full_day
          attached_with_master
          tagShared_tagsId
          tagChild_tagsId
          tagAttached_tag_idId
          __typename
        }
        shared_tags {
          nextToken
          __typename
        }
        parent_tag_id {
          id
          user_id
          type
          title
          content
          addresses
          visibility
          comments
          tagged_user_id
          memory_date
          occasion
          category
          sub_category
          price
          asset_price
          sold_price
          quantity
          available_for_sale
          auto_attach
          custom_field
          start_date
          end_date
          task_date
          task_end_date
          media
          repeat
          custom_start
          custom_repeat
          custom_repeat_value
          custom_days
          custom_end
          public_distance
          public_start
          public_end
          latitude
          longitude
          altitude
          purchasing_date
          createdAt
          updatedAt
          tag_attach_id
          tag_level
          status
          floor
          room
          is_sold
          sold_date
          scene_tags
          is_abvertising_paid
          is_admin_approved
          spotify
          parent_id
          top_parent_id
          is_public
          is_tc_accepted
          is_master_attached
          full_day
          attached_with_master
          tagShared_tagsId
          tagChild_tagsId
          tagAttached_tag_idId
          __typename
        }
        child_tags {
          nextToken
          __typename
        }
        tag_level
        comment_list {
          nextToken
          __typename
        }
        like_dislike_list {
          nextToken
          __typename
        }
        tag_messages {
          nextToken
          __typename
        }
        status
        floor
        room
        is_sold
        sold_date
        scene_tags
        is_abvertising_paid
        is_admin_approved
        analytics_user_data {
          nextToken
          __typename
        }
        spotify
        parent_id
        top_parent_id
        is_public
        is_tc_accepted
        is_master_attached
        attached_tag_id {
          nextToken
          __typename
        }
        full_day
        attached_with_master
        tagShared_tagsId
        tagChild_tagsId
        tagAttached_tag_idId
        __typename
      }
      like_dislike_id {
        id
        tag_id {
          id
          user_id
          type
          title
          content
          addresses
          visibility
          comments
          tagged_user_id
          memory_date
          occasion
          category
          sub_category
          price
          asset_price
          sold_price
          quantity
          available_for_sale
          auto_attach
          custom_field
          start_date
          end_date
          task_date
          task_end_date
          media
          repeat
          custom_start
          custom_repeat
          custom_repeat_value
          custom_days
          custom_end
          public_distance
          public_start
          public_end
          latitude
          longitude
          altitude
          purchasing_date
          createdAt
          updatedAt
          tag_attach_id
          tag_level
          status
          floor
          room
          is_sold
          sold_date
          scene_tags
          is_abvertising_paid
          is_admin_approved
          spotify
          parent_id
          top_parent_id
          is_public
          is_tc_accepted
          is_master_attached
          full_day
          attached_with_master
          tagShared_tagsId
          tagChild_tagsId
          tagAttached_tag_idId
          __typename
        }
        user_id
        content
        comment_type
        like_dislike_list {
          nextToken
          __typename
        }
        status
        createdAt
        updatedAt
        tagComment_listId
        __typename
      }
      type
      user_id
      diamond
      duck
      createdAt
      updatedAt
      tagLike_dislike_listId
      commentLike_dislike_listId
      __typename
    }
  }
`;
export const onUpdateLikeDislike = /* GraphQL */ `
  subscription OnUpdateLikeDislike(
    $filter: ModelSubscriptionLikeDislikeFilterInput
  ) {
    onUpdateLikeDislike(filter: $filter) {
      unique_id
      tag_id {
        id
        user_id
        type
        title
        content
        addresses
        visibility
        comments
        tagged_user_id
        memory_date
        occasion
        category
        sub_category
        price
        asset_price
        sold_price
        quantity
        available_for_sale
        auto_attach
        custom_field
        start_date
        end_date
        task_date
        task_end_date
        media
        repeat
        custom_start
        custom_repeat
        custom_repeat_value
        custom_days
        custom_end
        public_distance
        public_start
        public_end
        latitude
        longitude
        altitude
        purchasing_date
        createdAt
        updatedAt
        tag_attach_id
        shared_tag_id {
          id
          user_id
          type
          title
          content
          addresses
          visibility
          comments
          tagged_user_id
          memory_date
          occasion
          category
          sub_category
          price
          asset_price
          sold_price
          quantity
          available_for_sale
          auto_attach
          custom_field
          start_date
          end_date
          task_date
          task_end_date
          media
          repeat
          custom_start
          custom_repeat
          custom_repeat_value
          custom_days
          custom_end
          public_distance
          public_start
          public_end
          latitude
          longitude
          altitude
          purchasing_date
          createdAt
          updatedAt
          tag_attach_id
          tag_level
          status
          floor
          room
          is_sold
          sold_date
          scene_tags
          is_abvertising_paid
          is_admin_approved
          spotify
          parent_id
          top_parent_id
          is_public
          is_tc_accepted
          is_master_attached
          full_day
          attached_with_master
          tagShared_tagsId
          tagChild_tagsId
          tagAttached_tag_idId
          __typename
        }
        shared_tags {
          nextToken
          __typename
        }
        parent_tag_id {
          id
          user_id
          type
          title
          content
          addresses
          visibility
          comments
          tagged_user_id
          memory_date
          occasion
          category
          sub_category
          price
          asset_price
          sold_price
          quantity
          available_for_sale
          auto_attach
          custom_field
          start_date
          end_date
          task_date
          task_end_date
          media
          repeat
          custom_start
          custom_repeat
          custom_repeat_value
          custom_days
          custom_end
          public_distance
          public_start
          public_end
          latitude
          longitude
          altitude
          purchasing_date
          createdAt
          updatedAt
          tag_attach_id
          tag_level
          status
          floor
          room
          is_sold
          sold_date
          scene_tags
          is_abvertising_paid
          is_admin_approved
          spotify
          parent_id
          top_parent_id
          is_public
          is_tc_accepted
          is_master_attached
          full_day
          attached_with_master
          tagShared_tagsId
          tagChild_tagsId
          tagAttached_tag_idId
          __typename
        }
        child_tags {
          nextToken
          __typename
        }
        tag_level
        comment_list {
          nextToken
          __typename
        }
        like_dislike_list {
          nextToken
          __typename
        }
        tag_messages {
          nextToken
          __typename
        }
        status
        floor
        room
        is_sold
        sold_date
        scene_tags
        is_abvertising_paid
        is_admin_approved
        analytics_user_data {
          nextToken
          __typename
        }
        spotify
        parent_id
        top_parent_id
        is_public
        is_tc_accepted
        is_master_attached
        attached_tag_id {
          nextToken
          __typename
        }
        full_day
        attached_with_master
        tagShared_tagsId
        tagChild_tagsId
        tagAttached_tag_idId
        __typename
      }
      like_dislike_id {
        id
        tag_id {
          id
          user_id
          type
          title
          content
          addresses
          visibility
          comments
          tagged_user_id
          memory_date
          occasion
          category
          sub_category
          price
          asset_price
          sold_price
          quantity
          available_for_sale
          auto_attach
          custom_field
          start_date
          end_date
          task_date
          task_end_date
          media
          repeat
          custom_start
          custom_repeat
          custom_repeat_value
          custom_days
          custom_end
          public_distance
          public_start
          public_end
          latitude
          longitude
          altitude
          purchasing_date
          createdAt
          updatedAt
          tag_attach_id
          tag_level
          status
          floor
          room
          is_sold
          sold_date
          scene_tags
          is_abvertising_paid
          is_admin_approved
          spotify
          parent_id
          top_parent_id
          is_public
          is_tc_accepted
          is_master_attached
          full_day
          attached_with_master
          tagShared_tagsId
          tagChild_tagsId
          tagAttached_tag_idId
          __typename
        }
        user_id
        content
        comment_type
        like_dislike_list {
          nextToken
          __typename
        }
        status
        createdAt
        updatedAt
        tagComment_listId
        __typename
      }
      type
      user_id
      diamond
      duck
      createdAt
      updatedAt
      tagLike_dislike_listId
      commentLike_dislike_listId
      __typename
    }
  }
`;
export const onDeleteLikeDislike = /* GraphQL */ `
  subscription OnDeleteLikeDislike(
    $filter: ModelSubscriptionLikeDislikeFilterInput
  ) {
    onDeleteLikeDislike(filter: $filter) {
      unique_id
      tag_id {
        id
        user_id
        type
        title
        content
        addresses
        visibility
        comments
        tagged_user_id
        memory_date
        occasion
        category
        sub_category
        price
        asset_price
        sold_price
        quantity
        available_for_sale
        auto_attach
        custom_field
        start_date
        end_date
        task_date
        task_end_date
        media
        repeat
        custom_start
        custom_repeat
        custom_repeat_value
        custom_days
        custom_end
        public_distance
        public_start
        public_end
        latitude
        longitude
        altitude
        purchasing_date
        createdAt
        updatedAt
        tag_attach_id
        shared_tag_id {
          id
          user_id
          type
          title
          content
          addresses
          visibility
          comments
          tagged_user_id
          memory_date
          occasion
          category
          sub_category
          price
          asset_price
          sold_price
          quantity
          available_for_sale
          auto_attach
          custom_field
          start_date
          end_date
          task_date
          task_end_date
          media
          repeat
          custom_start
          custom_repeat
          custom_repeat_value
          custom_days
          custom_end
          public_distance
          public_start
          public_end
          latitude
          longitude
          altitude
          purchasing_date
          createdAt
          updatedAt
          tag_attach_id
          tag_level
          status
          floor
          room
          is_sold
          sold_date
          scene_tags
          is_abvertising_paid
          is_admin_approved
          spotify
          parent_id
          top_parent_id
          is_public
          is_tc_accepted
          is_master_attached
          full_day
          attached_with_master
          tagShared_tagsId
          tagChild_tagsId
          tagAttached_tag_idId
          __typename
        }
        shared_tags {
          nextToken
          __typename
        }
        parent_tag_id {
          id
          user_id
          type
          title
          content
          addresses
          visibility
          comments
          tagged_user_id
          memory_date
          occasion
          category
          sub_category
          price
          asset_price
          sold_price
          quantity
          available_for_sale
          auto_attach
          custom_field
          start_date
          end_date
          task_date
          task_end_date
          media
          repeat
          custom_start
          custom_repeat
          custom_repeat_value
          custom_days
          custom_end
          public_distance
          public_start
          public_end
          latitude
          longitude
          altitude
          purchasing_date
          createdAt
          updatedAt
          tag_attach_id
          tag_level
          status
          floor
          room
          is_sold
          sold_date
          scene_tags
          is_abvertising_paid
          is_admin_approved
          spotify
          parent_id
          top_parent_id
          is_public
          is_tc_accepted
          is_master_attached
          full_day
          attached_with_master
          tagShared_tagsId
          tagChild_tagsId
          tagAttached_tag_idId
          __typename
        }
        child_tags {
          nextToken
          __typename
        }
        tag_level
        comment_list {
          nextToken
          __typename
        }
        like_dislike_list {
          nextToken
          __typename
        }
        tag_messages {
          nextToken
          __typename
        }
        status
        floor
        room
        is_sold
        sold_date
        scene_tags
        is_abvertising_paid
        is_admin_approved
        analytics_user_data {
          nextToken
          __typename
        }
        spotify
        parent_id
        top_parent_id
        is_public
        is_tc_accepted
        is_master_attached
        attached_tag_id {
          nextToken
          __typename
        }
        full_day
        attached_with_master
        tagShared_tagsId
        tagChild_tagsId
        tagAttached_tag_idId
        __typename
      }
      like_dislike_id {
        id
        tag_id {
          id
          user_id
          type
          title
          content
          addresses
          visibility
          comments
          tagged_user_id
          memory_date
          occasion
          category
          sub_category
          price
          asset_price
          sold_price
          quantity
          available_for_sale
          auto_attach
          custom_field
          start_date
          end_date
          task_date
          task_end_date
          media
          repeat
          custom_start
          custom_repeat
          custom_repeat_value
          custom_days
          custom_end
          public_distance
          public_start
          public_end
          latitude
          longitude
          altitude
          purchasing_date
          createdAt
          updatedAt
          tag_attach_id
          tag_level
          status
          floor
          room
          is_sold
          sold_date
          scene_tags
          is_abvertising_paid
          is_admin_approved
          spotify
          parent_id
          top_parent_id
          is_public
          is_tc_accepted
          is_master_attached
          full_day
          attached_with_master
          tagShared_tagsId
          tagChild_tagsId
          tagAttached_tag_idId
          __typename
        }
        user_id
        content
        comment_type
        like_dislike_list {
          nextToken
          __typename
        }
        status
        createdAt
        updatedAt
        tagComment_listId
        __typename
      }
      type
      user_id
      diamond
      duck
      createdAt
      updatedAt
      tagLike_dislike_listId
      commentLike_dislike_listId
      __typename
    }
  }
`;
export const onCreateConversation = /* GraphQL */ `
  subscription OnCreateConversation(
    $filter: ModelSubscriptionConversationFilterInput
  ) {
    onCreateConversation(filter: $filter) {
      id
      sender
      recipient
      messages {
        items {
          id
          sender
          content
          recipient
          media
          is_readed
          message_timestamp
          createdAt
          message_from
          tag_id
          status
          updatedAt
          conversationMessagesId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      message_from
      tag_id
      status
      __typename
    }
  }
`;
export const onUpdateConversation = /* GraphQL */ `
  subscription OnUpdateConversation(
    $filter: ModelSubscriptionConversationFilterInput
  ) {
    onUpdateConversation(filter: $filter) {
      id
      sender
      recipient
      messages {
        items {
          id
          sender
          content
          recipient
          media
          is_readed
          message_timestamp
          createdAt
          message_from
          tag_id
          status
          updatedAt
          conversationMessagesId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      message_from
      tag_id
      status
      __typename
    }
  }
`;
export const onDeleteConversation = /* GraphQL */ `
  subscription OnDeleteConversation(
    $filter: ModelSubscriptionConversationFilterInput
  ) {
    onDeleteConversation(filter: $filter) {
      id
      sender
      recipient
      messages {
        items {
          id
          sender
          content
          recipient
          media
          is_readed
          message_timestamp
          createdAt
          message_from
          tag_id
          status
          updatedAt
          conversationMessagesId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      message_from
      tag_id
      status
      __typename
    }
  }
`;
export const onCreateMessages = /* GraphQL */ `
  subscription OnCreateMessages($filter: ModelSubscriptionMessagesFilterInput) {
    onCreateMessages(filter: $filter) {
      id
      conversation_id {
        id
        sender
        recipient
        messages {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        message_from
        tag_id
        status
        __typename
      }
      sender
      content
      recipient
      media
      is_readed
      message_timestamp
      createdAt
      message_from
      tag_id
      status
      updatedAt
      conversationMessagesId
      __typename
    }
  }
`;
export const onUpdateMessages = /* GraphQL */ `
  subscription OnUpdateMessages($filter: ModelSubscriptionMessagesFilterInput) {
    onUpdateMessages(filter: $filter) {
      id
      conversation_id {
        id
        sender
        recipient
        messages {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        message_from
        tag_id
        status
        __typename
      }
      sender
      content
      recipient
      media
      is_readed
      message_timestamp
      createdAt
      message_from
      tag_id
      status
      updatedAt
      conversationMessagesId
      __typename
    }
  }
`;
export const onDeleteMessages = /* GraphQL */ `
  subscription OnDeleteMessages($filter: ModelSubscriptionMessagesFilterInput) {
    onDeleteMessages(filter: $filter) {
      id
      conversation_id {
        id
        sender
        recipient
        messages {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        message_from
        tag_id
        status
        __typename
      }
      sender
      content
      recipient
      media
      is_readed
      message_timestamp
      createdAt
      message_from
      tag_id
      status
      updatedAt
      conversationMessagesId
      __typename
    }
  }
`;
export const onCreateTagMessage = /* GraphQL */ `
  subscription OnCreateTagMessage(
    $filter: ModelSubscriptionTagMessageFilterInput
  ) {
    onCreateTagMessage(filter: $filter) {
      id
      sender
      recipient
      content
      parent_id {
        id
        sender
        recipient
        content
        parent_id {
          id
          sender
          recipient
          content
          createdAt
          updatedAt
          tagTag_messagesId
          tagMessageRepliesId
          __typename
        }
        replies {
          nextToken
          __typename
        }
        tag_id {
          id
          user_id
          type
          title
          content
          addresses
          visibility
          comments
          tagged_user_id
          memory_date
          occasion
          category
          sub_category
          price
          asset_price
          sold_price
          quantity
          available_for_sale
          auto_attach
          custom_field
          start_date
          end_date
          task_date
          task_end_date
          media
          repeat
          custom_start
          custom_repeat
          custom_repeat_value
          custom_days
          custom_end
          public_distance
          public_start
          public_end
          latitude
          longitude
          altitude
          purchasing_date
          createdAt
          updatedAt
          tag_attach_id
          tag_level
          status
          floor
          room
          is_sold
          sold_date
          scene_tags
          is_abvertising_paid
          is_admin_approved
          spotify
          parent_id
          top_parent_id
          is_public
          is_tc_accepted
          is_master_attached
          full_day
          attached_with_master
          tagShared_tagsId
          tagChild_tagsId
          tagAttached_tag_idId
          __typename
        }
        createdAt
        updatedAt
        tagTag_messagesId
        tagMessageRepliesId
        __typename
      }
      replies {
        items {
          id
          sender
          recipient
          content
          createdAt
          updatedAt
          tagTag_messagesId
          tagMessageRepliesId
          __typename
        }
        nextToken
        __typename
      }
      tag_id {
        id
        user_id
        type
        title
        content
        addresses
        visibility
        comments
        tagged_user_id
        memory_date
        occasion
        category
        sub_category
        price
        asset_price
        sold_price
        quantity
        available_for_sale
        auto_attach
        custom_field
        start_date
        end_date
        task_date
        task_end_date
        media
        repeat
        custom_start
        custom_repeat
        custom_repeat_value
        custom_days
        custom_end
        public_distance
        public_start
        public_end
        latitude
        longitude
        altitude
        purchasing_date
        createdAt
        updatedAt
        tag_attach_id
        shared_tag_id {
          id
          user_id
          type
          title
          content
          addresses
          visibility
          comments
          tagged_user_id
          memory_date
          occasion
          category
          sub_category
          price
          asset_price
          sold_price
          quantity
          available_for_sale
          auto_attach
          custom_field
          start_date
          end_date
          task_date
          task_end_date
          media
          repeat
          custom_start
          custom_repeat
          custom_repeat_value
          custom_days
          custom_end
          public_distance
          public_start
          public_end
          latitude
          longitude
          altitude
          purchasing_date
          createdAt
          updatedAt
          tag_attach_id
          tag_level
          status
          floor
          room
          is_sold
          sold_date
          scene_tags
          is_abvertising_paid
          is_admin_approved
          spotify
          parent_id
          top_parent_id
          is_public
          is_tc_accepted
          is_master_attached
          full_day
          attached_with_master
          tagShared_tagsId
          tagChild_tagsId
          tagAttached_tag_idId
          __typename
        }
        shared_tags {
          nextToken
          __typename
        }
        parent_tag_id {
          id
          user_id
          type
          title
          content
          addresses
          visibility
          comments
          tagged_user_id
          memory_date
          occasion
          category
          sub_category
          price
          asset_price
          sold_price
          quantity
          available_for_sale
          auto_attach
          custom_field
          start_date
          end_date
          task_date
          task_end_date
          media
          repeat
          custom_start
          custom_repeat
          custom_repeat_value
          custom_days
          custom_end
          public_distance
          public_start
          public_end
          latitude
          longitude
          altitude
          purchasing_date
          createdAt
          updatedAt
          tag_attach_id
          tag_level
          status
          floor
          room
          is_sold
          sold_date
          scene_tags
          is_abvertising_paid
          is_admin_approved
          spotify
          parent_id
          top_parent_id
          is_public
          is_tc_accepted
          is_master_attached
          full_day
          attached_with_master
          tagShared_tagsId
          tagChild_tagsId
          tagAttached_tag_idId
          __typename
        }
        child_tags {
          nextToken
          __typename
        }
        tag_level
        comment_list {
          nextToken
          __typename
        }
        like_dislike_list {
          nextToken
          __typename
        }
        tag_messages {
          nextToken
          __typename
        }
        status
        floor
        room
        is_sold
        sold_date
        scene_tags
        is_abvertising_paid
        is_admin_approved
        analytics_user_data {
          nextToken
          __typename
        }
        spotify
        parent_id
        top_parent_id
        is_public
        is_tc_accepted
        is_master_attached
        attached_tag_id {
          nextToken
          __typename
        }
        full_day
        attached_with_master
        tagShared_tagsId
        tagChild_tagsId
        tagAttached_tag_idId
        __typename
      }
      createdAt
      updatedAt
      tagTag_messagesId
      tagMessageRepliesId
      __typename
    }
  }
`;
export const onUpdateTagMessage = /* GraphQL */ `
  subscription OnUpdateTagMessage(
    $filter: ModelSubscriptionTagMessageFilterInput
  ) {
    onUpdateTagMessage(filter: $filter) {
      id
      sender
      recipient
      content
      parent_id {
        id
        sender
        recipient
        content
        parent_id {
          id
          sender
          recipient
          content
          createdAt
          updatedAt
          tagTag_messagesId
          tagMessageRepliesId
          __typename
        }
        replies {
          nextToken
          __typename
        }
        tag_id {
          id
          user_id
          type
          title
          content
          addresses
          visibility
          comments
          tagged_user_id
          memory_date
          occasion
          category
          sub_category
          price
          asset_price
          sold_price
          quantity
          available_for_sale
          auto_attach
          custom_field
          start_date
          end_date
          task_date
          task_end_date
          media
          repeat
          custom_start
          custom_repeat
          custom_repeat_value
          custom_days
          custom_end
          public_distance
          public_start
          public_end
          latitude
          longitude
          altitude
          purchasing_date
          createdAt
          updatedAt
          tag_attach_id
          tag_level
          status
          floor
          room
          is_sold
          sold_date
          scene_tags
          is_abvertising_paid
          is_admin_approved
          spotify
          parent_id
          top_parent_id
          is_public
          is_tc_accepted
          is_master_attached
          full_day
          attached_with_master
          tagShared_tagsId
          tagChild_tagsId
          tagAttached_tag_idId
          __typename
        }
        createdAt
        updatedAt
        tagTag_messagesId
        tagMessageRepliesId
        __typename
      }
      replies {
        items {
          id
          sender
          recipient
          content
          createdAt
          updatedAt
          tagTag_messagesId
          tagMessageRepliesId
          __typename
        }
        nextToken
        __typename
      }
      tag_id {
        id
        user_id
        type
        title
        content
        addresses
        visibility
        comments
        tagged_user_id
        memory_date
        occasion
        category
        sub_category
        price
        asset_price
        sold_price
        quantity
        available_for_sale
        auto_attach
        custom_field
        start_date
        end_date
        task_date
        task_end_date
        media
        repeat
        custom_start
        custom_repeat
        custom_repeat_value
        custom_days
        custom_end
        public_distance
        public_start
        public_end
        latitude
        longitude
        altitude
        purchasing_date
        createdAt
        updatedAt
        tag_attach_id
        shared_tag_id {
          id
          user_id
          type
          title
          content
          addresses
          visibility
          comments
          tagged_user_id
          memory_date
          occasion
          category
          sub_category
          price
          asset_price
          sold_price
          quantity
          available_for_sale
          auto_attach
          custom_field
          start_date
          end_date
          task_date
          task_end_date
          media
          repeat
          custom_start
          custom_repeat
          custom_repeat_value
          custom_days
          custom_end
          public_distance
          public_start
          public_end
          latitude
          longitude
          altitude
          purchasing_date
          createdAt
          updatedAt
          tag_attach_id
          tag_level
          status
          floor
          room
          is_sold
          sold_date
          scene_tags
          is_abvertising_paid
          is_admin_approved
          spotify
          parent_id
          top_parent_id
          is_public
          is_tc_accepted
          is_master_attached
          full_day
          attached_with_master
          tagShared_tagsId
          tagChild_tagsId
          tagAttached_tag_idId
          __typename
        }
        shared_tags {
          nextToken
          __typename
        }
        parent_tag_id {
          id
          user_id
          type
          title
          content
          addresses
          visibility
          comments
          tagged_user_id
          memory_date
          occasion
          category
          sub_category
          price
          asset_price
          sold_price
          quantity
          available_for_sale
          auto_attach
          custom_field
          start_date
          end_date
          task_date
          task_end_date
          media
          repeat
          custom_start
          custom_repeat
          custom_repeat_value
          custom_days
          custom_end
          public_distance
          public_start
          public_end
          latitude
          longitude
          altitude
          purchasing_date
          createdAt
          updatedAt
          tag_attach_id
          tag_level
          status
          floor
          room
          is_sold
          sold_date
          scene_tags
          is_abvertising_paid
          is_admin_approved
          spotify
          parent_id
          top_parent_id
          is_public
          is_tc_accepted
          is_master_attached
          full_day
          attached_with_master
          tagShared_tagsId
          tagChild_tagsId
          tagAttached_tag_idId
          __typename
        }
        child_tags {
          nextToken
          __typename
        }
        tag_level
        comment_list {
          nextToken
          __typename
        }
        like_dislike_list {
          nextToken
          __typename
        }
        tag_messages {
          nextToken
          __typename
        }
        status
        floor
        room
        is_sold
        sold_date
        scene_tags
        is_abvertising_paid
        is_admin_approved
        analytics_user_data {
          nextToken
          __typename
        }
        spotify
        parent_id
        top_parent_id
        is_public
        is_tc_accepted
        is_master_attached
        attached_tag_id {
          nextToken
          __typename
        }
        full_day
        attached_with_master
        tagShared_tagsId
        tagChild_tagsId
        tagAttached_tag_idId
        __typename
      }
      createdAt
      updatedAt
      tagTag_messagesId
      tagMessageRepliesId
      __typename
    }
  }
`;
export const onDeleteTagMessage = /* GraphQL */ `
  subscription OnDeleteTagMessage(
    $filter: ModelSubscriptionTagMessageFilterInput
  ) {
    onDeleteTagMessage(filter: $filter) {
      id
      sender
      recipient
      content
      parent_id {
        id
        sender
        recipient
        content
        parent_id {
          id
          sender
          recipient
          content
          createdAt
          updatedAt
          tagTag_messagesId
          tagMessageRepliesId
          __typename
        }
        replies {
          nextToken
          __typename
        }
        tag_id {
          id
          user_id
          type
          title
          content
          addresses
          visibility
          comments
          tagged_user_id
          memory_date
          occasion
          category
          sub_category
          price
          asset_price
          sold_price
          quantity
          available_for_sale
          auto_attach
          custom_field
          start_date
          end_date
          task_date
          task_end_date
          media
          repeat
          custom_start
          custom_repeat
          custom_repeat_value
          custom_days
          custom_end
          public_distance
          public_start
          public_end
          latitude
          longitude
          altitude
          purchasing_date
          createdAt
          updatedAt
          tag_attach_id
          tag_level
          status
          floor
          room
          is_sold
          sold_date
          scene_tags
          is_abvertising_paid
          is_admin_approved
          spotify
          parent_id
          top_parent_id
          is_public
          is_tc_accepted
          is_master_attached
          full_day
          attached_with_master
          tagShared_tagsId
          tagChild_tagsId
          tagAttached_tag_idId
          __typename
        }
        createdAt
        updatedAt
        tagTag_messagesId
        tagMessageRepliesId
        __typename
      }
      replies {
        items {
          id
          sender
          recipient
          content
          createdAt
          updatedAt
          tagTag_messagesId
          tagMessageRepliesId
          __typename
        }
        nextToken
        __typename
      }
      tag_id {
        id
        user_id
        type
        title
        content
        addresses
        visibility
        comments
        tagged_user_id
        memory_date
        occasion
        category
        sub_category
        price
        asset_price
        sold_price
        quantity
        available_for_sale
        auto_attach
        custom_field
        start_date
        end_date
        task_date
        task_end_date
        media
        repeat
        custom_start
        custom_repeat
        custom_repeat_value
        custom_days
        custom_end
        public_distance
        public_start
        public_end
        latitude
        longitude
        altitude
        purchasing_date
        createdAt
        updatedAt
        tag_attach_id
        shared_tag_id {
          id
          user_id
          type
          title
          content
          addresses
          visibility
          comments
          tagged_user_id
          memory_date
          occasion
          category
          sub_category
          price
          asset_price
          sold_price
          quantity
          available_for_sale
          auto_attach
          custom_field
          start_date
          end_date
          task_date
          task_end_date
          media
          repeat
          custom_start
          custom_repeat
          custom_repeat_value
          custom_days
          custom_end
          public_distance
          public_start
          public_end
          latitude
          longitude
          altitude
          purchasing_date
          createdAt
          updatedAt
          tag_attach_id
          tag_level
          status
          floor
          room
          is_sold
          sold_date
          scene_tags
          is_abvertising_paid
          is_admin_approved
          spotify
          parent_id
          top_parent_id
          is_public
          is_tc_accepted
          is_master_attached
          full_day
          attached_with_master
          tagShared_tagsId
          tagChild_tagsId
          tagAttached_tag_idId
          __typename
        }
        shared_tags {
          nextToken
          __typename
        }
        parent_tag_id {
          id
          user_id
          type
          title
          content
          addresses
          visibility
          comments
          tagged_user_id
          memory_date
          occasion
          category
          sub_category
          price
          asset_price
          sold_price
          quantity
          available_for_sale
          auto_attach
          custom_field
          start_date
          end_date
          task_date
          task_end_date
          media
          repeat
          custom_start
          custom_repeat
          custom_repeat_value
          custom_days
          custom_end
          public_distance
          public_start
          public_end
          latitude
          longitude
          altitude
          purchasing_date
          createdAt
          updatedAt
          tag_attach_id
          tag_level
          status
          floor
          room
          is_sold
          sold_date
          scene_tags
          is_abvertising_paid
          is_admin_approved
          spotify
          parent_id
          top_parent_id
          is_public
          is_tc_accepted
          is_master_attached
          full_day
          attached_with_master
          tagShared_tagsId
          tagChild_tagsId
          tagAttached_tag_idId
          __typename
        }
        child_tags {
          nextToken
          __typename
        }
        tag_level
        comment_list {
          nextToken
          __typename
        }
        like_dislike_list {
          nextToken
          __typename
        }
        tag_messages {
          nextToken
          __typename
        }
        status
        floor
        room
        is_sold
        sold_date
        scene_tags
        is_abvertising_paid
        is_admin_approved
        analytics_user_data {
          nextToken
          __typename
        }
        spotify
        parent_id
        top_parent_id
        is_public
        is_tc_accepted
        is_master_attached
        attached_tag_id {
          nextToken
          __typename
        }
        full_day
        attached_with_master
        tagShared_tagsId
        tagChild_tagsId
        tagAttached_tag_idId
        __typename
      }
      createdAt
      updatedAt
      tagTag_messagesId
      tagMessageRepliesId
      __typename
    }
  }
`;
export const onCreateMaps = /* GraphQL */ `
  subscription OnCreateMaps($filter: ModelSubscriptionMapsFilterInput) {
    onCreateMaps(filter: $filter) {
      id
      item_id
      item_type
      longitude
      latitude
      status
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateMaps = /* GraphQL */ `
  subscription OnUpdateMaps($filter: ModelSubscriptionMapsFilterInput) {
    onUpdateMaps(filter: $filter) {
      id
      item_id
      item_type
      longitude
      latitude
      status
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteMaps = /* GraphQL */ `
  subscription OnDeleteMaps($filter: ModelSubscriptionMapsFilterInput) {
    onDeleteMaps(filter: $filter) {
      id
      item_id
      item_type
      longitude
      latitude
      status
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateLoginQR = /* GraphQL */ `
  subscription OnCreateLoginQR($filter: ModelSubscriptionLoginQRFilterInput) {
    onCreateLoginQR(filter: $filter) {
      id
      user_id
      jwt_auth
      ip_address
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateLoginQR = /* GraphQL */ `
  subscription OnUpdateLoginQR($filter: ModelSubscriptionLoginQRFilterInput) {
    onUpdateLoginQR(filter: $filter) {
      id
      user_id
      jwt_auth
      ip_address
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteLoginQR = /* GraphQL */ `
  subscription OnDeleteLoginQR($filter: ModelSubscriptionLoginQRFilterInput) {
    onDeleteLoginQR(filter: $filter) {
      id
      user_id
      jwt_auth
      ip_address
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateAppReviews = /* GraphQL */ `
  subscription OnCreateAppReviews(
    $filter: ModelSubscriptionAppReviewsFilterInput
  ) {
    onCreateAppReviews(filter: $filter) {
      id
      user_id
      app_id
      comment
      status
      like_dislike_list {
        items {
          unique_id
          app_id
          user_id
          diamond
          duck
          createdAt
          updatedAt
          appReviewsLike_dislike_listId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateAppReviews = /* GraphQL */ `
  subscription OnUpdateAppReviews(
    $filter: ModelSubscriptionAppReviewsFilterInput
  ) {
    onUpdateAppReviews(filter: $filter) {
      id
      user_id
      app_id
      comment
      status
      like_dislike_list {
        items {
          unique_id
          app_id
          user_id
          diamond
          duck
          createdAt
          updatedAt
          appReviewsLike_dislike_listId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteAppReviews = /* GraphQL */ `
  subscription OnDeleteAppReviews(
    $filter: ModelSubscriptionAppReviewsFilterInput
  ) {
    onDeleteAppReviews(filter: $filter) {
      id
      user_id
      app_id
      comment
      status
      like_dislike_list {
        items {
          unique_id
          app_id
          user_id
          diamond
          duck
          createdAt
          updatedAt
          appReviewsLike_dislike_listId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateLikeDislikeAppReview = /* GraphQL */ `
  subscription OnCreateLikeDislikeAppReview(
    $filter: ModelSubscriptionLikeDislikeAppReviewFilterInput
  ) {
    onCreateLikeDislikeAppReview(filter: $filter) {
      unique_id
      app_id
      user_id
      like_dislike_id {
        id
        user_id
        app_id
        comment
        status
        like_dislike_list {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      diamond
      duck
      createdAt
      updatedAt
      appReviewsLike_dislike_listId
      __typename
    }
  }
`;
export const onUpdateLikeDislikeAppReview = /* GraphQL */ `
  subscription OnUpdateLikeDislikeAppReview(
    $filter: ModelSubscriptionLikeDislikeAppReviewFilterInput
  ) {
    onUpdateLikeDislikeAppReview(filter: $filter) {
      unique_id
      app_id
      user_id
      like_dislike_id {
        id
        user_id
        app_id
        comment
        status
        like_dislike_list {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      diamond
      duck
      createdAt
      updatedAt
      appReviewsLike_dislike_listId
      __typename
    }
  }
`;
export const onDeleteLikeDislikeAppReview = /* GraphQL */ `
  subscription OnDeleteLikeDislikeAppReview(
    $filter: ModelSubscriptionLikeDislikeAppReviewFilterInput
  ) {
    onDeleteLikeDislikeAppReview(filter: $filter) {
      unique_id
      app_id
      user_id
      like_dislike_id {
        id
        user_id
        app_id
        comment
        status
        like_dislike_list {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      diamond
      duck
      createdAt
      updatedAt
      appReviewsLike_dislike_listId
      __typename
    }
  }
`;
export const onCreateUserActionLog = /* GraphQL */ `
  subscription OnCreateUserActionLog(
    $filter: ModelSubscriptionUserActionLogFilterInput
  ) {
    onCreateUserActionLog(filter: $filter) {
      id
      log_type
      user_id
      remote_ip
      device_type
      user_agent
      device_os
      data
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateUserActionLog = /* GraphQL */ `
  subscription OnUpdateUserActionLog(
    $filter: ModelSubscriptionUserActionLogFilterInput
  ) {
    onUpdateUserActionLog(filter: $filter) {
      id
      log_type
      user_id
      remote_ip
      device_type
      user_agent
      device_os
      data
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteUserActionLog = /* GraphQL */ `
  subscription OnDeleteUserActionLog(
    $filter: ModelSubscriptionUserActionLogFilterInput
  ) {
    onDeleteUserActionLog(filter: $filter) {
      id
      log_type
      user_id
      remote_ip
      device_type
      user_agent
      device_os
      data
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateUserLoginActionlog = /* GraphQL */ `
  subscription OnCreateUserLoginActionlog(
    $filter: ModelSubscriptionUserLoginActionlogFilterInput
  ) {
    onCreateUserLoginActionlog(filter: $filter) {
      id
      user_id
      device_os
      device_id
      device_type
      device_name
      user_agent
      remote_ip
      login_status
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateUserLoginActionlog = /* GraphQL */ `
  subscription OnUpdateUserLoginActionlog(
    $filter: ModelSubscriptionUserLoginActionlogFilterInput
  ) {
    onUpdateUserLoginActionlog(filter: $filter) {
      id
      user_id
      device_os
      device_id
      device_type
      device_name
      user_agent
      remote_ip
      login_status
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteUserLoginActionlog = /* GraphQL */ `
  subscription OnDeleteUserLoginActionlog(
    $filter: ModelSubscriptionUserLoginActionlogFilterInput
  ) {
    onDeleteUserLoginActionlog(filter: $filter) {
      id
      user_id
      device_os
      device_id
      device_type
      device_name
      user_agent
      remote_ip
      login_status
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateUserNotifications = /* GraphQL */ `
  subscription OnCreateUserNotifications(
    $filter: ModelSubscriptionUserNotificationsFilterInput
  ) {
    onCreateUserNotifications(filter: $filter) {
      id
      user_id
      user_name
      alert_msg
      redirect_url
      status
      read_status
      added_by_id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateUserNotifications = /* GraphQL */ `
  subscription OnUpdateUserNotifications(
    $filter: ModelSubscriptionUserNotificationsFilterInput
  ) {
    onUpdateUserNotifications(filter: $filter) {
      id
      user_id
      user_name
      alert_msg
      redirect_url
      status
      read_status
      added_by_id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteUserNotifications = /* GraphQL */ `
  subscription OnDeleteUserNotifications(
    $filter: ModelSubscriptionUserNotificationsFilterInput
  ) {
    onDeleteUserNotifications(filter: $filter) {
      id
      user_id
      user_name
      alert_msg
      redirect_url
      status
      read_status
      added_by_id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateUserSavedPlaces = /* GraphQL */ `
  subscription OnCreateUserSavedPlaces(
    $filter: ModelSubscriptionUserSavedPlacesFilterInput
  ) {
    onCreateUserSavedPlaces(filter: $filter) {
      id
      user_id
      address_type
      address
      status
      createdAt
      updatedAt
      latitude
      longitude
      __typename
    }
  }
`;
export const onUpdateUserSavedPlaces = /* GraphQL */ `
  subscription OnUpdateUserSavedPlaces(
    $filter: ModelSubscriptionUserSavedPlacesFilterInput
  ) {
    onUpdateUserSavedPlaces(filter: $filter) {
      id
      user_id
      address_type
      address
      status
      createdAt
      updatedAt
      latitude
      longitude
      __typename
    }
  }
`;
export const onDeleteUserSavedPlaces = /* GraphQL */ `
  subscription OnDeleteUserSavedPlaces(
    $filter: ModelSubscriptionUserSavedPlacesFilterInput
  ) {
    onDeleteUserSavedPlaces(filter: $filter) {
      id
      user_id
      address_type
      address
      status
      createdAt
      updatedAt
      latitude
      longitude
      __typename
    }
  }
`;
export const onCreateUserStorageManagement = /* GraphQL */ `
  subscription OnCreateUserStorageManagement(
    $filter: ModelSubscriptionUserStorageManagementFilterInput
  ) {
    onCreateUserStorageManagement(filter: $filter) {
      id
      user_id
      key_name
      file_type
      file_size
      tag_type
      status
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateUserStorageManagement = /* GraphQL */ `
  subscription OnUpdateUserStorageManagement(
    $filter: ModelSubscriptionUserStorageManagementFilterInput
  ) {
    onUpdateUserStorageManagement(filter: $filter) {
      id
      user_id
      key_name
      file_type
      file_size
      tag_type
      status
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteUserStorageManagement = /* GraphQL */ `
  subscription OnDeleteUserStorageManagement(
    $filter: ModelSubscriptionUserStorageManagementFilterInput
  ) {
    onDeleteUserStorageManagement(filter: $filter) {
      id
      user_id
      key_name
      file_type
      file_size
      tag_type
      status
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateUserOnlineStatus = /* GraphQL */ `
  subscription OnCreateUserOnlineStatus(
    $filter: ModelSubscriptionUserOnlineStatusFilterInput
  ) {
    onCreateUserOnlineStatus(filter: $filter) {
      id
      user_id
      last_active_date
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateUserOnlineStatus = /* GraphQL */ `
  subscription OnUpdateUserOnlineStatus(
    $filter: ModelSubscriptionUserOnlineStatusFilterInput
  ) {
    onUpdateUserOnlineStatus(filter: $filter) {
      id
      user_id
      last_active_date
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteUserOnlineStatus = /* GraphQL */ `
  subscription OnDeleteUserOnlineStatus(
    $filter: ModelSubscriptionUserOnlineStatusFilterInput
  ) {
    onDeleteUserOnlineStatus(filter: $filter) {
      id
      user_id
      last_active_date
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateUserInAppEmails = /* GraphQL */ `
  subscription OnCreateUserInAppEmails(
    $filter: ModelSubscriptionUserInAppEmailsFilterInput
  ) {
    onCreateUserInAppEmails(filter: $filter) {
      id
      from_email
      to_email
      from_user_id
      to_user_id
      is_from_admin
      subject_line
      message_body
      read_status
      status
      category
      sub_category
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateUserInAppEmails = /* GraphQL */ `
  subscription OnUpdateUserInAppEmails(
    $filter: ModelSubscriptionUserInAppEmailsFilterInput
  ) {
    onUpdateUserInAppEmails(filter: $filter) {
      id
      from_email
      to_email
      from_user_id
      to_user_id
      is_from_admin
      subject_line
      message_body
      read_status
      status
      category
      sub_category
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteUserInAppEmails = /* GraphQL */ `
  subscription OnDeleteUserInAppEmails(
    $filter: ModelSubscriptionUserInAppEmailsFilterInput
  ) {
    onDeleteUserInAppEmails(filter: $filter) {
      id
      from_email
      to_email
      from_user_id
      to_user_id
      is_from_admin
      subject_line
      message_body
      read_status
      status
      category
      sub_category
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateMediaPlayLogs = /* GraphQL */ `
  subscription OnCreateMediaPlayLogs(
    $filter: ModelSubscriptionMediaPlayLogsFilterInput
  ) {
    onCreateMediaPlayLogs(filter: $filter) {
      id
      tag_id
      tag_type
      media_type
      user_id
      extra
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateMediaPlayLogs = /* GraphQL */ `
  subscription OnUpdateMediaPlayLogs(
    $filter: ModelSubscriptionMediaPlayLogsFilterInput
  ) {
    onUpdateMediaPlayLogs(filter: $filter) {
      id
      tag_id
      tag_type
      media_type
      user_id
      extra
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteMediaPlayLogs = /* GraphQL */ `
  subscription OnDeleteMediaPlayLogs(
    $filter: ModelSubscriptionMediaPlayLogsFilterInput
  ) {
    onDeleteMediaPlayLogs(filter: $filter) {
      id
      tag_id
      tag_type
      media_type
      user_id
      extra
      createdAt
      updatedAt
      __typename
    }
  }
`;
