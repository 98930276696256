import { LOADING_QRUI, CLEAR_ERRORS, CREATEQR, ENABLETOFETCHSETTING } from '../types'

const initialstate = {
    loading: false,
    qrobj: {},
    fetchsetting: false
}

export const onboarding = (state = initialstate, { type, payload }) => {
    switch (type) {
        case LOADING_QRUI:
            return {
                ...state,
                loading: true
            }
        case CREATEQR:
            return {
                ...state,
                qrobj: payload
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                loading: false
            }
        case ENABLETOFETCHSETTING:
            return {
                ...state,
                fetchsetting: payload
            }
        default:
            return state;
    }
}