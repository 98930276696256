import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import search from '../redux/reducers/search';
import ui from '../redux/reducers/uiReducer';
import onChatreducer from '../redux/reducers/onChatreducer';
import appreducer from '../redux/reducers/appreducer';
import { Listreducer } from './reducers/app_detailreducer'
import { settingreducer } from './reducers/usersettingreducer'
import { Profilereducer } from './reducers/previewimage'
import { Interfaceroot } from './reducers/changeinterface'
import { Dashboardreducer } from './reducers/dashboard'
import { Userdetailreducer } from './reducers/Userdetail'
import { onboarding } from './reducers/onBoardingReducer';
import { calendarReducer } from './reducers/calendarReducer';
import { paymentReducer } from './reducers/payment';
import { PwaRelatedData } from './reducers/pwaRelatedData';
import { useractionlogreducer } from './reducers/useractionlogreducer';
import { CreateAD } from './reducers/ads';
import unreademailreducer from './reducers/unreademailreducer';
import unreadnotireducer from './reducers/unreadnotireducer'
import { childPermission } from './reducers/childpermission'
import mapReducer from './reducers/mapReducer';
import spotifyReducer from './reducers/spotify';
import docuSignReducer from './reducers/docusign';
import { conditionalEditTag } from './reducers/conditionalEdit';
import { commonReducer } from './reducers/common';
const initialState = {};

const middleware = [thunk]

const reducers = combineReducers({
    search: search,
    ui: ui,
    onboarding: onboarding,
    chat: onChatreducer,
    app: appreducer,
    AppList: Listreducer,
    Privacy: settingreducer,
    Preview: Profilereducer,
    Interface: Interfaceroot,
    Dashboard: Dashboardreducer,
    User: Userdetailreducer,
    Calendar: calendarReducer,
    Payment: paymentReducer,
    PwaRelatedData: PwaRelatedData,
    Actionlog: useractionlogreducer,
    CreateAD: CreateAD,
    UnreadEmail: unreademailreducer,
    UnreadNoti: unreadnotireducer,
    childPermission: childPermission,
    mapSearch: mapReducer,
    spotify: spotifyReducer,
    docusign: docuSignReducer,
    conitionalEdit: conditionalEditTag,
    commonaction: commonReducer
});

const rootReducer = (state, action) => {
    if (action.type === 'CLEAR_STORE') return reducers(undefined, action);
    return reducers(state, action);
};

export const store = createStore(
    rootReducer,
    initialState,
    compose(
        applyMiddleware(...middleware),
        window.__REDUX_DEVTOOLS_EXTENSION__
            ? window.__REDUX_DEVTOOLS_EXTENSION__()
            : f => f)
);