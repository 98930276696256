import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import ReactSlider from '../Common/Slider';
import { toast } from 'react-toastify/dist/react-toastify.esm';

import { FetchImageInLoop } from '../../Helper/s3'
import Moment from 'moment'
import FacebookShareButton from "react-share/lib/FacebookShareButton"
import LinkedinShareButton from "react-share/lib/LinkedinShareButton"
import TwitterShareButton from "react-share/lib/TwitterShareButton"
import $ from 'jquery/dist/jquery'
import { FacebookIcon, TwitterIcon, LinkedinIcon } from "react-share";
import { getIdFromUrl, redirectToPeopleProfile, sendWexxleNotification, checkAdsAnalytics, getBoundsOfDistance } from '../../Helper/commonFunction';

// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

//api
import connect from 'react-redux/es/connect/connect'
import { TagDetail } from '../../redux/actions/dashboard';
import { calendarFeed } from '../../redux/actions/calendar';
import { createCloneVariable } from '../../Helper/cloneTag'
import API from '@aws-amplify/api';
import * as graphqlQuery from '../../graphql/queries'
import { initializeAuthCLI } from '../../Helper/InitializeApi';
import * as graphMutation from '../../graphql/mutations'
import { getUserDetail, createTagChat, fetchFriendList, fetchTagWatchDetail } from '../../api/GraphqlApi';
import QRCode from 'qrcode.react';
import * as constant from '../../Helper/constants'
import { EditTag } from '../../redux/actions/dashboard';
import { conditionalEditTag } from '../../redux/actions/conditionalEdit';
import { nestedCreateTagPopup } from '../../redux/actions/changeinterface';
import MetaTags from '../Common/MetaTags';
import { metaTagContent } from '../../Helper/util';
import ShareTagViaEmail from '../Common/ShareTagViaEmail';

class QuickDetail extends PureComponent {
    constructor() {
        super();
        this.quickText = React.createRef()
        this.state = {
            checked: false,
            loader: false,
            shareText: "",
            report: "",
            textreport: "",
            shareTagText: "",
            friendObject: [],
            conversation_id: "",
            friendList: [],
            shareTagloader: false,
            friendinformation: {},
            OwnerObject: {},
            shareTagVisibility: "private",
            connectModalOpened: false,
            sendMessage: "",
            reportType: "",
            tagUser: {
                SendMessage: [],
                ReplyMessage: []
            },
            quick_detail: [],
            duckIcon: -1,
            tagWatch: false

        };
        this.handleChange = this.handleChange.bind(this);
        this.latLong = getBoundsOfDistance(localStorage.getItem("lat"), localStorage.getItem("lng"));

    }


    handleChange(checked) {
        this.setState({ checked });
    }

    IdForQuickTag = () => {
        if (Object.keys(this.props.match.params).length) {
            let { id } = this.props.match.params
            if (id) this.Quick_tag_detail(id)
            if (id) this.fetchTagWatchList(id)
        }
    }

    componentDidMount() {
        this.IdForQuickTag()
        this.fetchUserDetail()

    }
    fetchTagWatchList = async (id) => {
        let response = await fetchTagWatchDetail(id);
        if (response) var { statusCode, body } = response;
        this.setState({
            ...this.state,
            tagWatch: statusCode === 200 ? (body ? (body.length ? true : false) : false) : false
        })

    }

    componentWillUnmount() {
        this.setState = (state, callback) => {
            return;
        };
    }

    fetchUserFriendDetail = async (id) => {
        try {
            let response = await getUserDetail("user_relationship", localStorage.getItem("id"), "", "", "", "", "", "", "", id, "", "")
            this.setState({
                ...this.state,
                friendinformation: response && response.statusCode === 200 ? (
                    response && response.body && response.body.length ? response.body[0] : {}
                ) : {}
            })
        } catch (error) {
            if (error.message) toast.error(error.message)
            else if (error && error.errors.length) toast.error(error.errors[0].message);
            else toast.error("Something Went Wrong!")
        }
    }


    Quick_tag_detail = async (id) => {
        try {
            let emp = []
            initializeAuthCLI()
            this.setState({ ...this.state, loader: true })
            let res = await API.graphql({
                query: graphqlQuery.getTag,
                variables: { id: id }
            })
            if (res.data.getTag?.visibility === 'public') {
                checkAdsAnalytics(id, 'total_clicks', false)
            }
            if (res && res.data && res.data.getTag) {
                let mixCount = await this.tagDuckDiamondCount(id)
                mixCount.items.forEach(element => {
                    if (element.user_id === parseInt(localStorage.getItem('id'))) {
                        if (element.duck === 1) {
                            this.setState({ ...this.state, duckIcon: 1 })
                        }
                        else {
                            this.setState({ ...this.state, duckIcon: 2 })
                        }
                    }
                });
                if (mixCount && Object.keys(mixCount).length) {
                    let { aggregateItems, total } = mixCount;
                    if (Array.isArray(aggregateItems) && aggregateItems.length) {
                        if (aggregateItems[0].result) {
                            var duckCount = aggregateItems[0].result.value;
                            var diamondCount = total - duckCount;
                        }
                        res.data.getTag.Diamond = diamondCount;
                        res.data.getTag.Duck = duckCount;
                    }
                }
                // {Tag Message With Connector For Name Of SEND MSG} //
                // if (tag_messages.items.length) await this.createNameFieldInTagMessage(tag_messages.items);
                // if (tag_messages.items && tag_messages.items.length) {
                //     for (let i = 0; i < tag_messages.items.length; i++) {
                //         // {Tag Message With Connector For Name Of REPLY MSG} //
                //         if (tag_messages.items[i].replies.items.length) await this.createNameFieldInTagMessage(tag_messages.items[i].replies.items);
                //     }
                // }
                // //
                emp.push(res.data.getTag)
                let quick_detail = await FetchImageInLoop(emp, id)
                this.setState({ quick_detail, loader: false })
            } else {
                toast.error("Tag Not Found!");
                this.setState({ ...this.state, loader: false });
            }
        } catch (error) {
            if (error.message) toast.error(error.message)
            else if (error && error.errors.length) toast.error(error.errors[0].message);
            else toast.error("Something Went Wrong!")
            this.setState({ ...this.state, loader: false });
        }
    }

    add_Comment = async (comment_type) => {
        try {
            if (localStorage.getItem('isKid')) {
                if (this.props.kidPermission.ADD_COMMENTS === "no") {
                    toast.error('you have no permission to comment on tag')
                    return
                }
            }
            let comment_id = getIdFromUrl()
            let content = this.quickText.current.value
            if (!content || content.trim().length === 0) return; // Check length of string.
            this.setState({ ...this.state, loader: true })
            let res = await API.graphql({
                query: graphMutation.createComment,
                variables: {
                    input: {
                        content: encodeURI(content),
                        tagComment_listId: comment_id,
                        status: "active",
                        user_id: localStorage.getItem("id") ? localStorage.getItem("id") : ""
                    }
                }
            })
            if (res && res.data && res.data.createComment) {
                this.quickText.current.value = ""
                this.IdForQuickTag()
                let opponent_user_id = this.state.quick_detail[0].user_id;
                sendWexxleNotification(opponent_user_id, "send_notifications", constant.template_module_id.tag_comment, "Tag", "", localStorage.getItem("displayName"), "", "quick", "comment", "", comment_id, "")
            }
        } catch (error) {
            if (error.message) toast.error(error.message)
            else if (error && error.errors.length) toast.error(error.errors[0].message);
            else toast.error("Something Went Wrong!")
            this.setState({ ...this.state, loader: false })
        }
    }

    // updateDuckDiamond = async (id, diamond, duck,type) => {
    //     try {
    //         this.setState({ ...this.state, loader: true })
    //         let res = await API.graphql({
    //             query: graphMutation.updateLikeDislike,
    //             variables: {
    //                 input: {
    //                     diamond,
    //                     duck,
    //                     type,
    //                     user_id: localStorage.getItem("id"),
    //                     tagLike_dislike_listId: id,
    //                     unique_id: id
    //                 },
    //                 condition: { tagLike_dislike_listId: { eq: id ? id : "" } }
    //             }
    //         })
    //         if (res && res.data && res.data.updateLikeDislike) {
    //             this.IdForMediaTag()
    //             this.setState({ ...this.state, loader: false })
    //         } else {
    //             toast.error("Something Went Wrong!")
    //             this.setState({ ...this.state, loader: false });
    //         }
    //     } catch (error) {
    //         if (error.message) toast.error(error.message)
    //         else if (error && error.errors.length) toast.error(error.errors[0].message);
    //         else toast.error("Something Went Wrong!")
    //         this.setState({ ...this.state, loader: false });
    //     }
    // }

    SearchPeople = (id) => {
        if (id) redirectToPeopleProfile(id, this.props.history);
    }

    tagDuckDiamondCount = async (tagID) => {
        try {
            let res = await API.graphql({
                query: graphqlQuery.searchLikeDislikes,
                variables: {
                    filter: {
                        tagLike_dislike_listId: { eq: tagID ? tagID : "" }
                    },
                    aggregates: { field: "duck", name: "Count", type: "sum" }
                }
            })
            if (res && res.data && res.data.searchLikeDislikes) {
                return res.data.searchLikeDislikes;
            } else {
                toast.error("Something Went Wrong!")
            }
        } catch (error) {
            if (error.message) toast.error(error.message)
            else if (error && error.errors.length) toast.error(error.errors[0].message);
            else toast.error("Something Went Wrong!")
        }
    }


    getchatlist = async (id, friend_id, name) => {
        try {
            let res = await API.graphql({
                query: graphqlQuery.searchConversations,
                variables: {
                    filter: {
                        or: [
                            { sender: { eq: id ? id : "" } },
                            { recipient: { eq: id ? id : "" } }
                        ]
                    }, sort: { field: "updatedAt", direction: "desc" }
                }
            });
            if (res && res.data && res.data.searchConversations && Object.keys(res.data.searchConversations).length) {
                let { items } = res.data.searchConversations;
                let userexist = items.filter((value) => {
                    if (value && value.recipient && value.sender) {
                        if (value.recipient !== parseInt(localStorage.getItem("id")) &&
                            value.recipient === friend_id) {
                            return value.recipient === friend_id
                        } else {
                            return value.sender === friend_id
                        }
                    }
                    return [];
                });
                if (Array.isArray(userexist) && userexist.length) {
                    this.setState({ ...this.state, conversation_id: userexist[0].id, })
                } else {
                    this.addusertochatlist(friend_id, name)
                }
            }
        } catch (error) {
            if (error.message) toast.error(error.message)
            else if (error && error.errors.length) toast.error(error.errors[0].message);
            else toast.error("Something Went Wrong!")
        }
    }


    addusertochatlist = async (friend_id, name) => {
        try {
            if (!friend_id && !name) {
                toast.error("Something Went Wrong");
                return;
            }
            let res = await API.graphql({
                query: graphMutation.createConversation,
                variables: {
                    input: {
                        sender: localStorage.getItem("id") ? localStorage.getItem("id") : "",
                        recipient: friend_id ? friend_id : "",
                        message_from: "normal"
                    }
                }
            });
            if (res && res.data && res.data.createConversation) {
                let { id } = res.data.createConversation;
                this.setState({ ...this.state, conversation_id: id })
            }
        } catch (error) {
            if (error.message) toast.error(error.message)
            else if (error && error.errors.length) toast.error(error.errors[0].message);
            else toast.error("Something Went Wrong!")
        }
    }


    // tag_Like_Dislike = async (id, diamond, duck,type) => {
    //     try {
    //         this.setState({ ...this.state, loader: true })
    //         let res = await API.graphql({
    //             query: graphMutation.createLikeDislike,
    //             variables: {
    //                 input: {
    //                     diamond,
    //                     duck,
    //                     type,
    //                     user_id: localStorage.getItem("id"),
    //                     tagLike_dislike_listId: id,
    //                     unique_id: id
    //                 }
    //             }
    //         })
    //         if (res && res.data && res.data.createLikeDislike) {
    //             this.IdForMediaTag()
    //             this.setState({ ...this.state, loader: false })
    //         } else {
    //             toast.error("Try Again")
    //             this.setState({ ...this.state, loader: false })
    //         }
    //     } catch (error) {
    //         if (error.message) toast.error(error.message)
    //         else if (error && error.errors.length) toast.error(error.errors[0].message);
    //         else toast.error("Something Went Wrong!")
    //         this.setState({ ...this.state, loader: false })
    //     }
    // }


    tag_Like_Dislike = async (id, diamond, duck, type, opponent_user_id, postType) => {
        try {
            this.setState({ ...this.state, loader: true })
            let input = {}
            input["diamond"] = diamond
            input["duck"] = duck
            input["type"] = type
            input["unique_id"] = id
            if (type === "comment") input["commentLike_dislike_listId"] = id
            if (type === "tag") input["tagLike_dislike_listId"] = id
            input["user_id"] = localStorage.getItem("id")
            let res = await API.graphql({
                query: graphMutation.createLikeDislike,
                variables: { input: input }

            })
            if (res && res.data && res.data.createLikeDislike) {
                this.IdForQuickTag();
                let template_id = type === "tag" ? constant.template_module_id.like_dislike_tag : constant.template_module_id.like_dislike_comment
                sendWexxleNotification(opponent_user_id, "send_notifications", template_id, "Tag", localStorage.getItem("displayName"), "", "", postType, "tag", id, "", diamond)
            } else {
                toast.error("Try Again");
                this.setState({ ...this.state, loader: false });
            }
        } catch (error) {
            if (error.message) toast.error(error.message)
            else if (error && error.errors.length) toast.error(error.errors[0].message);
            else toast.error("Something Went Wrong!")
            this.setState({ ...this.state, loader: false })
        }
    }


    fetchDuckDiamond = async (id, diamond, duck, type, opponent_user_id, postType) => {
        if (localStorage.getItem('isKid')) {
            if (this.props.kidPermission.LIKE_TAGS === "no") {
                toast.error('you have no permission to like or dislike tag')
                return
            }
        }
        try {
            let filterInput = {}
            filterInput["user_id"] = { "eq": localStorage.getItem("id") }
            if (type === "tag") filterInput["tagLike_dislike_listId"] = { "eq": id }
            if (type === "comment") filterInput["commentLike_dislike_listId"] = { "eq": id }
            let res = await API.graphql({
                query: graphqlQuery.searchLikeDislikes,
                variables: {
                    filter: filterInput,
                }
            })
            if (res && res.data && res.data.searchLikeDislikes) {
                if (res && res.data.searchLikeDislikes.items.length) {
                    this.updateDuckDiamond(id, diamond, duck, type, opponent_user_id, postType)
                } else {
                    this.tag_Like_Dislike(id, diamond, duck, type, opponent_user_id, postType)
                }
            }
        } catch (error) {
            if (error.message) toast.error(error.message)
            else if (error && error.errors.length) toast.error(error.errors[0].message);
            else toast.error("Something Went Wrong!")
        }
    }


    updateDuckDiamond = async (id, diamond, duck, type, opponent_user_id, postType) => {
        try {
            this.setState({ ...this.state, loader: true })
            let input = {}
            input["diamond"] = diamond
            input["duck"] = duck
            input["type"] = type
            input["unique_id"] = id
            if (type === "comment") input["commentLike_dislike_listId"] = id
            if (type === "tag") input["tagLike_dislike_listId"] = id
            input["user_id"] = localStorage.getItem("id")
            let res = await API.graphql({
                query: graphMutation.updateLikeDislike,
                variables: {
                    input: input,
                }
            })
            if (res && res.data && res.data.updateLikeDislike) {
                this.IdForQuickTag();
                let template_id = type === "tag" ? constant.template_module_id.like_dislike_tag : constant.template_module_id.like_dislike_comment
                sendWexxleNotification(opponent_user_id, "send_notifications", template_id, "Tag", localStorage.getItem("displayName"), "", "", postType, "tag", id, "", diamond)
            } else {
                toast.error("Something Went Wrong!")
                this.setState({ ...this.state, loader: false });
            }
        } catch (error) {
            if (error.message) toast.error(error.message)
            else if (error && error.errors.length) toast.error(error.errors[0].message);
            else toast.error("Something Went Wrong!")
            this.setState({ ...this.state, loader: false });
        }
    }

    textValidation = (inputField) => {
        if (!inputField || inputField.trim().length === 0) {
            toast.error("Input Field Can Not Be Empty!");
            return true;
        }
    }


    shareTag = async () => {
        let { quick_detail, shareTagText, shareTagVisibility } = this.state
        // if (localStorage.getItem('id') !== media_detail[0].user_id?.toString()) {
        //     if (this.textValidation(shareTagText)) return false;
        // } 
        if (Array.isArray(quick_detail) && !quick_detail.length) return false;
        quick_detail[0].content = shareTagText;
        quick_detail[0].visibility = shareTagVisibility;
        this.setState({ ...this.state, shareTagloader: true })
        let response = ''
        if (localStorage.getItem('id') === quick_detail[0].user_id?.toString()) {
            let variable = {
                "visibility": shareTagVisibility,
                "id": quick_detail[0].id,
            }
            if (shareTagText) variable.content = shareTagText
            response = await API.graphql({
                query: graphMutation.updateTag,
                variables: { input: variable }
            })
        }
        else {
            let variable = await createCloneVariable(quick_detail, "sharedTag");
            response = await API.graphql({
                query: graphMutation.createTag,
                variables: { input: variable }
            })
        }
        if (response.data && (response.data.updateTag || response.data.createTag)) {
            toast.success("Tag Successfully Shared !")
            $("#exampleModalsharetag").modal("hide")
            this.setState({
                ...this.state,
                shareTagText: "",
                shareTagVisibility: "private",
                shareTagloader: false
            })
            await this.props.TagDetail(localStorage.getItem("id"), "", "", "", "", "", "", "", "", "", this.latLong)
        } else {
            this.setState({ ...this.state, shareTagloader: false });
        }
    }



    shareOpenedToggle = () => {
        this.fetchListFriend()
        this.setState({
            shareOpened: !this.state.shareOpened,
        });
    }

    fetchListFriend = async () => {
        let response = await fetchFriendList()
        this.setState({
            ...this.state,
            friendList: response && response.statusCode === 200 ?
                response.body && response.body.length ? response.body : []
                : []
        })
    }


    componentDidUpdate(prevProps, prevState) {
        // Report User
        if (prevState.report !== this.state.report) {
            if (this.state.report && this.state.report !== "I don't want to see this") {
                this.handleReport(this.state.comment_id, this.state.reportType);
            }
        }

        if (JSON.stringify(prevState.quick_detail) !== JSON.stringify(this.state.quick_detail)) {
            let id = this.state.quick_detail[0].user_id;
            this.fetchFriendDetail(id);
            this.fetchUserFriendDetail(id);
        }

        if (JSON.stringify(prevState.friendObject) !== JSON.stringify(this.state.friendObject)) {
            if (Array.isArray(this.state.friendObject) && this.state.friendObject.length) {
                let { user_id, user_name } = this.state.friendObject[0]
                if (parseInt(localStorage.getItem("id")) !== user_id) this.getchatlist(localStorage.getItem("id"), user_id, user_name);
            }
        }

        if (this.props.conditionEdit.tagValue && Object.keys(this.props.conditionEdit.tagValue).length) {
            let { id, type } = this.props.conditionEdit.tagValue
            if (id && type === "quick") {
                this.Quick_tag_detail(id);
                this.props.conditionalEditTag({})
            }
        }
    }

    editTag = async (val) => {
        await this.props.EditTag(val.id)
        this.props.nestedCreateTagPopup({
            'isOpen': true,
            'extraProps': {
                "id": val.id,
                "type": "quick",
                "top_parent_id": val.top_parent_id,
                "parent_id": val.parent_id
            }
        })
    }

    // Clone Tag Creation!
    createCloneTag = async () => {
        try {
            let { quick_detail } = this.state
            if (Array.isArray(quick_detail) && !quick_detail.length) return false;
            let variable = await createCloneVariable(quick_detail)
            if (!Object.keys(variable).length) return false;
            for (const key in variable) {
                if ([undefined, null, ""].includes(variable[key])) delete (variable[key])
            }
            this.setState({ ...this.state, loading: true })
            let res = await API.graphql({
                query: graphMutation.createTag,
                variables: { input: variable }
            })
            if (res && res.data && res.data.createTag) {
                toast.success("Clone Tag Created Successfully!")
                $("#exampleModalClone").modal("hide")
                await this.props.TagDetail(localStorage.getItem("id"), "", "", "", "", "", "", "", "", "", this.latLong)
                this.setState({ ...this.state, loading: false })
            } else {
                toast.error("Try Again");
                this.setState({ ...this.state, loading: false })
            }
        } catch (error) {
            if (error.message) toast.error(error.message)
            else if (error && error.errors.length) toast.error(error.errors[0].message);
            else toast.error("Something Went Wrong!")
            this.setState({ ...this.state, loading: false })
        }
    }

    handleReport = async (id, type) => {
        let text = "";
        if (this.state.report !== "I don't want to see this") text = this.state.report;
        else text = this.state.textreport;
        try {
            this.setState({ ...this.state, loading: true });
            let res = await API.graphql({
                query: graphqlQuery.BlockAndWatch,
                variables: {
                    input: {
                        tag_type: "quick",
                        operation: "report",
                        report_code: 123,
                        tag_id: this.state.tag_id || id,
                        report_text: text ? text : "",
                        report_type: type ? type : "",
                        reported_item_id: id ? id : "",
                        user_id: localStorage.getItem("id"),
                    }
                }
            })
            if (res && res.data && res.data.BlockAndWatch) {
                let blockandwatch_friend_response = JSON.parse(res.data.BlockAndWatch)
                if (blockandwatch_friend_response && Object.keys(blockandwatch_friend_response).length) {
                    let { statusCode, body } = blockandwatch_friend_response;
                    if (statusCode === 200 && body.length) {
                        toast.success("Report Done Successfully!");
                        $("#addBugModal .close").click();
                        this.setState({ ...this.state, report: "", reportType: "", textreport: "", loading: false })
                    }
                }
            } else {
                toast.error("Something Went Wrong!");
                this.setState({ ...this.state, loading: false });
            }
        } catch (error) {
            if (error.message) toast.error(error.message);
            else if (error && error.errors.length) toast.error(error.errors[0].message);
            else toast.error("Something Went Wrong!");
            this.setState({ ...this.state, loading: false })
        }
    }

    sendMessage = async (recipient) => {
        try {
            let { conversation_id, sendMessage } = this.state;
            if (this.textValidation(sendMessage)) return false;
            let res = await API.graphql({
                query: graphMutation.createMessages,
                variables: {
                    input: {
                        content: sendMessage,
                        conversationMessagesId: conversation_id,
                        is_readed: false,
                        message_from: "normal",
                        recipient: recipient ? parseInt(recipient) : "",
                        message_timestamp: parseInt(Date.now()?.toString().slice(8, 13)),
                        sender: localStorage.getItem("id") ? parseInt(localStorage.getItem("id")) : ""
                    }
                }
            });
            if (res && res.data && res.data.createMessages) {
                toast.success("Message Send Successfully!");
                sendWexxleNotification(
                    recipient,
                    "send_notifications",
                    constant.template_module_id.chat,
                    "Chat", "", localStorage.getItem("displayName"),
                    "", "", "", "", "", "")
                this.setState({ ...this.state, sendMessage: "" });
            }
            else toast.error("Something Went Wrong While Sending Message!");
        } catch (error) {
            if (error.message) toast.error(error.message)
            else if (error && error.errors.length) toast.error(error.errors[0].message);
            else toast.error("Something Went Wrong!")
        }
    }

    replyMessage = async (replyId, sender, recipient) => {
        let replyMessage = $(`textarea[id='replyMessage ${replyId}']`).val()
        if (this.textValidation(replyMessage)) return false;
        let response = await createTagChat(replyMessage, recipient, sender, replyId)
        if (response.data && response.data.createTagMessage) {
            this.IdForQuickTag();
            $(`textarea[id='replyMessage ${replyId}']`).val("")
        } else {
            toast.error("Something Went Wrong While Sending Message")
        }
    }



    watchTag = async (id) => {
        try {
            this.setState({ ...this.state, loader: true })
            let res = await API.graphql({
                query: graphqlQuery.BlockAndWatch,
                variables: {
                    input: {
                        operation: "tag_watch",
                        user_id: localStorage.getItem("id"),
                        watching: id ? id : ""
                    }
                }
            })
            if (res && res.data && res.data.BlockAndWatch) {
                let blockandwatch_friend_response = JSON.parse(res.data.BlockAndWatch)
                if (blockandwatch_friend_response && Object.keys(blockandwatch_friend_response).length) {
                    let { statusCode, body } = blockandwatch_friend_response;
                    if (statusCode === 200 && body.length) {
                        toast.success("Tag Watch Successfully Done!")
                        this.setState({ ...this.state, loader: false, tagWatch: true })
                    } else if (statusCode === 200 && !body.length) {
                        toast.success("Tag Unwatch Successfully Done!")
                        this.setState({ ...this.state, loader: false, tagWatch: false })
                    }
                }
            } else {
                toast.error("Something Went Wrong!");
                this.setState({ ...this.state, loader: false });
            }
        } catch (error) {
            if (error.message) toast.error(error.message);
            else if (error && error.errors.length) toast.error(error.errors[0].message);
            else toast.error("Something Went Wrong!");
            this.setState({ ...this.state, loader: false })
        }
    }

    fetchFriendDetail = async (friendId) => {
        let response = await getUserDetail("user_detail", friendId)
        this.setState({
            ...this.state,
            friendObject: response && response.statusCode === 200 ? (
                response && response.body && response.body.length ? response.body : []
            ) : []
        })
    }

    fetchUserDetail = async () => {
        let response = await getUserDetail("user_detail", localStorage.getItem("id"))
        this.setState({
            ...this.state,
            OwnerObject: response && response.statusCode === 200 ? (
                response && response.body && response.body.length ? response.body[0] : {}
            ) : {}
        })
    }

    render() {
        // eslint-disable-next-line
        let { loader, quick_detail, shareOpened, comment_id, friendObject, shareTagText, shareTagVisibility, shareTagloader, friendinformation, sendMessage, friendList, OwnerObject } = this.state;
        var { f1_connection, f1_id, f1_request_status, f2_connection, f2_id, f2_request_status, follow_id, blocking_id } =
            friendinformation
            // eslint-disable-next-line
        const { user_name, profile_img, mk_profile_img } = OwnerObject || {};
        const ecoProdSliderSettings = {
            dots: true,
            arrows: false,
            infinite: false,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1
        };

        // const settingsUserList = {
        //     dots: false,
        //     infinite: false,
        //     arrows: false,
        //     speed: 500,
        //     slidesToShow: 3,
        //     slidesToScroll: 1
        // };
        const profile_image = this.props.Profilepic.profile_pic

        if (quick_detail?.length) {
            var { title, content } = quick_detail[0]
        }
        return (
            <>
                <MetaTags {...metaTagContent(title, content)["quick_detail"]} />
                <div className="profileBanner">
                    <img src={profile_image.url ? profile_image.url : process.env.PUBLIC_URL + "/images/user-icon.svg"} alt="graph" />
                </div>
                <div className="profileCard ecommerce_page">
                    <div className="profileCard__header ecom_detail_head">
                        <div className="profileCard__header__icon backIcon" onClick={() => this.props.history.goBack()}>&nbsp;</div>
                        <div className="profileCard__header__title text-center">Quick Tag</div>
                        <div className="profileCard__header__icon">&nbsp;</div>
                    </div>
                    <div className="settingInnerPages scrollSection ecom_wrap_body">
                        <div className="cardboard eco-prod-card-main">
                            {
                                loader && <div className="overlay-loader">
                                    <div className="loader dark">Loading...</div>
                                </div>
                            }
                            {
                                (Array.isArray(quick_detail) && quick_detail.length > 0) && quick_detail.map((val, index) =>
                                    <div className="eco-prod-card-section" key={index}>
                                        <div className="d-flex justify-content-between eco-prod-header">
                                            <div className="eco-prod-header__left">
                                                <h4 className="mb-2">{val.title && val.title}</h4>
                                                {
                                                    val.addresses && val.addresses.trim().length > 0 && <p className="d-flex align-items-center">
                                                        <span><img src={process.env.PUBLIC_URL + "/images/address-icon.svg"} alt="Icon" /></span>
                                                        {val.addresses}
                                                    </p>
                                                }
                                                <p className="d-flex align-items-center">
                                                    <span><img src={process.env.PUBLIC_URL + "/images/calendar-icon1.svg"} alt="Icon" /></span>
                                                    {val.created_at ? Moment(val.created_at).format("MM/DD/YYYY") : Moment(new Date()).format("MM/DD/YYYY")}
                                                </p>
                                            </div>
                                            <div className="eco-prod-header__right">
                                                {
                                                    val.user_id !== parseInt(localStorage.getItem("id")) && <figure className="fx-jcnd-aic"><img src={process.env.PUBLIC_URL + "/images/support-center.svg"} alt="Icon" data-toggle="modal" data-target="#addBugModal" onClick={() => {
                                                        this.setState({
                                                            ...this.state,
                                                            reportType: "tag",
                                                            comment_id: val.id ? val.id : ""
                                                        })
                                                    }} /></figure>
                                                }
                                                <div className="d-flex">
                                                    <figure className="mr-3 mb-0"><img src={process.env.PUBLIC_URL + "/images/recopy.svg"} alt="Icon" onClick={() => $("#exampleModalClone").modal("show")} /></figure>
                                                    {val.user_id === parseInt(localStorage.getItem("id")) &&
                                                        <figure className="mr-3 mb-0">
                                                            <img src={process.env.PUBLIC_URL + "/images/edittag.svg"} alt="Icon"
                                                                onClick={() => this.editTag(val)} />
                                                        </figure>
                                                    }
                                                    <figure className="mb-0" data-toggle="modal" data-target="#exampleModalsharetag"><img src={process.env.PUBLIC_URL + "/images/qr-code-detail.svg"} alt="Icon" /></figure>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="common-slider fullWidthSlider">
                                            <ReactSlider {...ecoProdSliderSettings}>

                                                {
                                                    (Array.isArray(val.image) && val.image.length) &&
                                                    val.image.map((key, index) =>
                                                        (['mp4'].includes(key.ext) && key.url) ?
                                                            <div className="common-slider__items event_img" key={index}>
                                                                <video controls={true} width="500.9"  >
                                                                    <source src={key.url} type="video/mp4" />
                                                                </video>
                                                            </div>
                                                            : (key.url ? <div className="common-slider__items event_img" key={index}>
                                                                <img src={key.url} alt="slide" />
                                                            </div> : <div className="common-slider__items event_img" key={index}>
                                                                <img src={process.env.PUBLIC_URL + "/images/General.svg"} alt="slide" />
                                                            </div>)
                                                    )
                                                }
                                            </ReactSlider>
                                        </div>

                                        <div className="form-group pl-4 pr-4 mb-4">
                                            <ul className="text-dark startEndDate pl-0">
                                                <li>
                                                    <label className="startEndDate__date mb-0">Date -</label>
                                                    <span className="startEndDate__value">{val.created_at ? Moment(val.created_at).format("ddd DD MMM YYYY") : Moment(new Date()).format("ddd DD MMM YYYY")} at {val.created_at ? Moment(val.created_at).format("LT") : Moment(new Date()).format("LT")}</span>
                                                </li>
                                            </ul>
                                        </div>

                                        <div className="eco-prod-status">
                                            <div className="eco-prod-status__items">
                                                <figure className="eco-prod-status__items__icon" onClick={() => this.fetchDuckDiamond(val.id, "0", "1", "tag", val.user_id, val.type)}>
                                                    <img src={this.state.duckIcon === 1 ? process.env.PUBLIC_URL + "/images/duck-active.svg" : process.env.PUBLIC_URL + "/images/duck-inactive.svg"} alt="Duck" />
                                                </figure>
                                                <h4 className="eco-prod-status__items__count">{val.Duck ? val.Duck : 0}</h4>
                                            </div>
                                            <div className="eco-prod-status__items">
                                                <figure className="eco-prod-status__items__icon" onClick={() => this.fetchDuckDiamond(val.id, "1", "0", "tag", val.user_id, val.type)}>
                                                    <img src={this.state.duckIcon === 2 ?
                                                        process.env.PUBLIC_URL + "/images/dymond-active.svg" : process.env.PUBLIC_URL + "/images/dymond-inactive.svg"} alt="Dymond" />
                                                </figure>
                                                <h4 className="eco-prod-status__items__count">{val.Diamond ? val.Diamond : 0}</h4>
                                            </div>
                                            <div className="eco-prod-status__items">
                                                <figure className="eco-prod-status__items__icon" onClick={() => this.watchTag(val.id)}>
                                                    <img src={this.state.tagWatch ? process.env.PUBLIC_URL + "/images/eye-icon.svg" : process.env.PUBLIC_URL + "/images/eye.png"} alt="Watch" height="16" />
                                                </figure>
                                                <h4 className="eco-prod-status__items__count">Watch</h4>
                                            </div>
                                            <div className="eco-prod-status__items" onClick={this.shareOpenedToggle}>
                                                <figure className="eco-prod-status__items__icon">
                                                    <img src={process.env.PUBLIC_URL + "/images/share-icon-secondary.svg"} alt="Share" height="20" />
                                                </figure>
                                                <h4 className="eco-prod-status__items__count">Share</h4>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }

                            {
                                quick_detail.length > 0 && <div className="insider-info-card">
                                    {
                                        quick_detail.map((val, index) => (
                                            val.user_id !== parseInt(localStorage.getItem("id")) && <div className="insider-info-card__head" key={index}>
                                                <h3 className="mb-0">Creator Information</h3>
                                            </div>
                                        ))
                                    }
                                    <div className="insider-info-card__body">
                                        <div className="insider-info-card__body__top">
                                            {
                                                quick_detail.map((val, index) => (
                                                    val.user_id !== parseInt(localStorage.getItem("id")) && <div className="insider-user-list-loop" key={index}>
                                                        <figure className="user-pic cursor" onClick={() => { this.SearchPeople(val.user_id) }}>
                                                            <img src={friendObject.length > 0 ? (friendObject[0].profile_img ? process.env.REACT_APP_MEDIA_BASE_URL + `/${friendObject[0].profile_img}` : process.env.PUBLIC_URL + "/images/user-icon.svg") : process.env.PUBLIC_URL + "/images/user-icon.svg"} alt="" />
                                                        </figure>
                                                        <div className="insider-user-list-loop__info" key={index}>
                                                            <button className="btn btn-gray-light btn-xs float-right con_eco_btn" onClick={() => { this.SearchPeople(val.user_id) }}>{
                                                                blocking_id ? "Block" : (
                                                                    !f1_id && !f2_id && !follow_id ? 'Connect' : (
                                                                        (f2_request_status === "1" && f2_connection === "2") || (f1_request_status === "1" && f1_connection === "2") ? "Family" : ((f2_request_status === "1" && f2_connection === "1") || (f1_request_status === "1" && f1_connection === "1") ? "Friend" : ((f2_request_status === "1" && f2_connection === "3") || (f1_request_status === "1" && f1_connection === "3") ? "Individual" : (f1_request_status === "0" && f1_id ? "Request Sent" : (f2_request_status === "0" && f2_id ? "Accept Pending" : (follow_id ? "Watcher" : ""())))))))
                                                            }</button>
                                                            <h4 className="cursor" onClick={() => { this.SearchPeople(val.user_id) }}>{friendObject.length ? friendObject[0].user_name : "wexxler"}</h4>
                                                            {
                                                                (friendObject.length && friendObject[0].address) &&
                                                                <p className="">Location - {friendObject[0].address}</p>
                                                            }

                                                            {
                                                                (friendObject.length && !["", null, undefined].includes(friendObject[0].verified)) &&
                                                                <button className="verifiedBtn ecom_ver-btn">{
                                                                    friendObject[0].verified === "0" ? "Verified" : (
                                                                        friendObject[0].verified === "1" ? "Unverified" : "Rejected"
                                                                    )
                                                                }</button>
                                                            }
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </div>

                                        {/* ((f2_request_status === "1" && f2_connection === "2" || f1_request_status === "1" && f1_connection === "2") ||
                                                (f2_request_status === "1" && f2_connection === "1" || f1_request_status === "1" && f1_connection === "1") ||
                                                (f2_request_status === "1" && f2_connection === "3" || f1_request_status === "1" && f1_connection === "3")
                                            ) */}

                                        {/* {
                                            (Array.isArray(quick_detail) && quick_detail.length > 0) && quick_detail.map((val, index) => (
                                                val.user_id !== parseInt(localStorage.getItem("id")) && <div className="insider-info-card__body__msg" key={index}>
                                                    <h3 className="flex-aic">
                                                        <img src={process.env.PUBLIC_URL + "/images/chat-icon.svg"} alt="Msg" />
                                                        <span className="ml-3">Send creator a message</span>
                                                    </h3>
                                                    <form className="comment-section">
                                                        <div className="comment-section__inside">
                                                            <textarea className="form-control" placeholder="Send a message to creator" value={sendMessage} onChange={(e) => this.setState({
                                                                ...this.state,
                                                                sendMessage: e.target.value
                                                            })}></textarea>
                                                            <input type="button" value="" className="sendBtn" onClick={() => { this.sendMessage(val.user_id) }} />
                                                        </div>
                                                    </form>
                                                </div>
                                            ))
                                        } */}

                                        {/*  */}
                                        {/* <div className='insider-info-card__body__sellerMessage scrollSection'>
                                        <ul>
                                            {
                                                (Array.isArray(media_detail) && media_detail.length > 0) && media_detail.map((val, index) =>
                                                (
                                                    (Array.isArray(val.tag_messages.items) && val.tag_messages.items.length > 0) &&
                                                    val.tag_messages.items.map((value, index) =>
                                                        <Fragment key={index}>
                                                            <li>
                                                                <div className='messageBlock'>
                                                                    <div className='profileName light-green-bg'>
                                                                        {
                                                                            (value.sender !== parseInt(localStorage.getItem("id"))) ? (value.user_name ? value.user_name.match(/\b(\w)/g).join("").toUpperCase() : "") : (OwnerObject.user_name ? OwnerObject.user_name.match(/\b(\w)/g).join("").toUpperCase() : "")


                                                                        }
                                                                    </div>
                                                                    <div className='messageDetails'>
                                                                        <div className='date'>{Moment(value.createdAt).format("DD MMM, YYYY")}</div>
                                                                        <div className='name'> {
                                                                            (value.sender !== parseInt(localStorage.getItem("id"))) ? (value.user_name ? value.user_name : "") : (OwnerObject.user_name ? OwnerObject.user_name : "")
                                                                        }</div>
                                                                        <p>{value.content}</p>
                                                                    </div>
                                                                </div>
                                                                {
                                                                    (value.sender !== parseInt(localStorage.getItem("id")) && !value.replies.items.length) && <div className='reply' onClick={() => { $(`.comment-section.${value.id}`).toggleClass("d-none") }}><a href='#'><img src={process.env.PUBLIC_URL + "/images/reply.svg"} alt="User" /> Reply</a></div>
                                                                }
                                                                {
                                                                    (value.sender !== parseInt(localStorage.getItem("id")) && !value.replies.items.length) &&
                                                                    <form className={`${"comment-section" + " " + value.id + " " + "d-none"}`}>
                                                                        <div className="comment-section__inside">
                                                                            <textarea className="form-control" placeholder="Lorem ipsum dolor sit amet, consectetur adipiscing elit." id={`replyMessage ${value.id}`}></textarea>
                                                                            <input type="button" value="" className="sendBtn" onClick={() => { this.replyMessage(value.id, value.recipient, value.sender) }} />
                                                                        </div>
                                                                    </form>
                                                                }
                                                            </li>
                                                            {
                                                                (Array.isArray(value.replies.items) && value.replies.items.length > 0) && value.replies.items.map((replies, index) =>
                                                                    <li key={index}>
                                                                        <div className='messageBlock'>
                                                                            <div className='profileName light-green-bg'>
                                                                                {
                                                                                    (replies.sender !== parseInt(localStorage.getItem("id"))) ? (replies.user_name ? replies.user_name.match(/\b(\w)/g).join("").toUpperCase() : "") : (OwnerObject.user_name ? OwnerObject.user_name.match(/\b(\w)/g).join("").toUpperCase() : "")
                                                                                }
                                                                            </div>
                                                                            <div className='messageDetails'>
                                                                                <div className='date'>{Moment(replies.createdAt).format("DD MMM, YYYY")}</div>
                                                                                <div className='name'>{
                                                                                    (replies.sender !== parseInt(localStorage.getItem("id"))) ? (replies.user_name ? replies.user_name : "") : (OwnerObject.user_name ? OwnerObject.user_name : "")
                                                                                }</div>
                                                                                <p>{replies.content}</p>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                )
                                                            }
                                                        </Fragment>
                                                    )
                                                ))
                                            }
                                        </ul>
                                    </div> */}
                                    </div>
                                </div>
                            }



                            {/* <div className="card-blank pt-0">
                                <div className="card-blank__descripton pt-0">
                                    <p>Capture aerial views smoothly in Ultra HD with this DJI Mavic Pro camera drone. Its FlightAutonomy technology uses ultrasonic range finders and vision sensors to proactively detect and avoid obstacles for safer, obstruction-free flight. This compact and foldable DJI Mavic Pro camera drone has a 7km transmission range thanks to its FCC-compliant remote controller.</p>
                                </div>
                            </div> */}

                            {/* <div className="card-blank">
                                <div className="card-blank__head">
                                    <h3 className="mb-2">Shared with</h3>
                                </div>
                                <div className="card-blank__shareWith sliderSlick">
                                    <ReactSlider {...userSliderSettings}>
                                        <div className="users-tiles">
                                            <figure className="user-pic">
                                                <img src={process.env.PUBLIC_URL + "/images/user-icon.svg"} alt="User" />
                                            </figure>
                                            <h4 className="users-tiles__name mb-0">Jane Doe</h4>
                                        </div>
                                        <div className="users-tiles">
                                            <figure className="user-pic">
                                                <img src={process.env.PUBLIC_URL + "/images/user-icon.svg"} alt="User" />
                                            </figure>
                                            <h4 className="users-tiles__name mb-0">Jane Doe</h4>
                                        </div>
                                        <div className="users-tiles">
                                            <figure className="user-pic">
                                                <img src={process.env.PUBLIC_URL + "/images/user-icon.svg"} alt="User" />
                                            </figure>
                                            <h4 className="users-tiles__name mb-0">Jane Doe</h4>
                                        </div>
                                        <div className="users-tiles">
                                            <figure className="user-pic">
                                                <img src={process.env.PUBLIC_URL + "/images/user-icon.svg"} alt="User" />
                                            </figure>
                                            <h4 className="users-tiles__name mb-0">Jane Doe</h4>
                                        </div>
                                        <div className="users-tiles">
                                            <figure className="user-pic">
                                                <img src={process.env.PUBLIC_URL + "/images/user-icon.svg"} alt="User" />
                                            </figure>
                                            <h4 className="users-tiles__name mb-0">Jane Doe</h4>
                                        </div>
                                        <div className="users-tiles">
                                            <figure className="user-pic">
                                                <img src={process.env.PUBLIC_URL + "/images/user-icon.svg"} alt="User" />
                                            </figure>
                                            <h4 className="users-tiles__name mb-0">Jane Doe</h4>
                                        </div>
                                    </ReactSlider>
                                </div>
                            </div> */}

                            {/* <div className="card-blank">
                                <div className="card-blank__head justify-content-between">
                                    <div className="fx-aic pb-3">
                                        <h3 className="mb-1 mr-2">Comments</h3>
                                        <label className="switchLabel">
                                            <Switch
                                                onChange={this.handleChange}
                                                checked={this.state.checked}
                                                onColor="#5983F0"
                                                offColor="#606574"
                                                height={20}
                                                width={36}
                                                uncheckedIcon={false}
                                                checkedIcon={false}
                                            />
                                        </label>
                                    </div>
                                    <a href="#" className="">View All</a>
                                </div>

                                <div className="review-loop-container">
                                    {
                                        (Array.isArray(media_detail) && media_detail.length > 0) &&
                                        media_detail.map(val =>
                                            val.comment_list.items.length > 0 &&
                                            val.comment_list.items.map((value, index) =>
                                                <div className="review-loop" key={index}>
                                                    <div className="review-loop__top">
                                                        <figure className="user-pic random-color">
                                                            <span className="user-pic__title">JD</span>
                                                        </figure>
                                                        <div className="review-loop__top__info">
                                                            <span className="float-right postedDate size12">{value.createdAt ? Moment(value.createdAt).format('D MMM, YYYY') : Moment(new Date()).format('D MMM, YYYY')}</span>
                                                            <h4 className="mb-1">Jane Doe</h4>
                                                            <p className="mb-1">{value.content ? decodeURI(value.content) : ""}</p>
                                                        </div>
                                                    </div>
                                                    <div className="review-loop__like">
                                                        <div className="likeDislikeContainer">
                                                            <div className="likeDislike">
                                                                <div className="likeDislike__icon" onClick={() => this.fetchDuckDiamond(value.comment_id, "0", "1", "comment")}>
                                                                    <img src={process.env.PUBLIC_URL + "/images/duck-active.svg"} alt="Duck" />
                                                                </div>
                                                                <span className="likeDislike__counter">{value.duck ? value.duck : 0}</span>
                                                            </div>

                                                            <div className="likeDislike">
                                                                <div className="likeDislike__icon" onClick={() => this.fetchDuckDiamond(value.comment_id, "1", "0", "comment")}>
                                                                    <img src={process.env.PUBLIC_URL + "/images/dymond-active.svg"} alt="Duck" />
                                                                </div>
                                                                <span className="likeDislike__counter">{value.diamond ? value.diamond : 0}</span>
                                                            </div>
                                                            <span className="iconxs spam mt-2 ml-2" data-toggle="modal" data-target="#addBugModal" onClick={() => {
                                                                this.setState({
                                                                    ...this.state,
                                                                    comment_id: value.comment_id ? value.comment_id : ""
                                                                })
                                                            }}>&nbsp;</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        )
                                    }
                                </div>

                            </div> */}

                            {/* <div className="card-blank">
                                <div className="card-blank__head">
                                    <h3 className="mb-2">Write a comment</h3>
                                </div>
                                <div className="card-blank__descripton">
                                    <form className="comment-section" onSubmit={(e) => {
                                        e.preventDefault()
                                        this.add_Comment("tag")
                                    }}>
                                        <div className="comment-section__inside">
                                            <span className="commentIcon"></span>
                                            <textarea className="form-control leftSideIcon" ref={this.mediaText} placeholder="Type Message Here..."></textarea>
                                            <input type="submit" value="" className="sendBtn" />
                                        </div>
                                    </form>
                                </div>
                            </div> */}
                        </div>
                        {/* cardboard eco-prod-card-main end */}
                    </div>
                </div >
                <div className={shareOpened ? "overlay opened" : "overlay"} onClick={this.shareOpenedToggle}></div>
                <div className={shareOpened ? "bottom-slide-card opened" : "bottom-slide-card"}>
                    <div className="bottom-slide-card__header">
                        <h4 className="mb-0">Shared With</h4>
                        <div className="iconxs closeIcon" onClick={this.shareOpenedToggle}>&nbsp;</div>
                    </div>
                    <div className="bottom-slide-card__body">
                        {/* <div className="contact-bar">
                            <ul className="users-channel-list">
                                <ReactSlider {...settingsUserList}>
                                    {
                                        friendList.length && friendList.map((val, index) =>
                                            <li key={index}>
                                                <figure className="user-pic">
                                                    <img src={val.profile_img ? process.env.REACT_APP_MEDIA_BASE_URL + `/${val.profile_img}` : process.env.PUBLIC_URL + "/images/user-icon.svg"} alt="Profile" />
                                                </figure>
                                                <h5>{val.display_name ? val.display_name : ""}</h5>
                                            </li>
                                        )
                                    }
                                </ReactSlider>
                            </ul>

                            <div className="iconxs">
                                <img src={process.env.PUBLIC_URL + "/images/more-dot-h.svg"} alt="more" />
                            </div>
                        </div> */}

                        <ul className="social-channel-list">
                            <li>
                                {/* <button className="social-btn facebook">
                                    <img src={process.env.PUBLIC_URL + "/images/fb-icon.svg"} alt="Facebook" />
                                </button> */}
                                <FacebookShareButton url={window.location.href}>
                                    <FacebookIcon className='social-btn facebook' />
                                </FacebookShareButton>


                                <TwitterShareButton url={window.location.href}>
                                    <TwitterIcon className='social-btn twitter' />
                                </TwitterShareButton>

                                <LinkedinShareButton url={window.location.href}>
                                    <LinkedinIcon className='social-btn in' />
                                </LinkedinShareButton>

                                <ShareTagViaEmail url={window.location.href}/>
                                {/* <button className="social-btn apple">
                                    <img src={process.env.PUBLIC_URL + "/images/apple-icon.svg"} alt="Apple" />
                                </button>
                                <button className="social-btn google">
                                    <img src={process.env.PUBLIC_URL + "/images/google-icon.svg"} alt="Google" />
                                </button> */}
                            </li>
                            <li>
                                {/* <button className="social-btn twitter">
                                    <img src={process.env.PUBLIC_URL + "/images/twitter-icon.svg"} alt="Twitter" />
                                </button> */}
                                {/* <TwitterShareButton url={window.location.href}>
                                    <TwitterIcon className='social-btn twitter' />
                                </TwitterShareButton>
                                <button className="social-btn instagram">
                                    <img src={process.env.PUBLIC_URL + "/images/instagram-icon.svg"} alt="Instagram" />
                                </button> */}
                                {/* <button className="social-btn in">
                                    <img src={process.env.PUBLIC_URL + "/images/linkedin-icon.svg"} alt="Linkedin" />
                                </button> */}
                                {/* <LinkedinShareButton url={window.location.href}>
                                    <LinkedinIcon className='social-btn in' />
                                </LinkedinShareButton> */}
                            </li>
                        </ul>
                    </div>
                </div>

                <div className="modal fade modal-small" id="addBugModal" tabIndex="-1" role="dialog" aria-hidden="true" data-backdrop="static" data-keyboard="false">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-body">
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => {
                                    this.setState({
                                        ...this.state,
                                        report: "",
                                        textreport: ""
                                    })
                                }}>&nbsp;</button>
                                <ul className="itemListGroup ">
                                    <li>
                                        <h4 className="listTitle title big_heading"> Why are you reporting this?</h4>
                                    </li>
                                    <li className='fx-aic cursor' style={this.state.loading ? { pointerEvents: "none" } : {}} onClick={() => this.setState({
                                        report: "Suspicious or fake"
                                    })}>
                                        <h4 className="listTitle ml-5 modlfnt">Suspicious or fake.</h4>
                                    </li>
                                    <li className='fx-aic cursor' style={this.state.loading ? { pointerEvents: "none" } : {}} onClick={() => this.setState({
                                        report: "Harassment or hateful speech"
                                    })}>
                                        <h4 className="listTitle ml-5 modlfnt" >Harassment or hateful speech.</h4>
                                    </li>
                                    <li className='fx-aic cursor' style={this.state.loading ? { pointerEvents: "none" } : {}} onClick={() => this.setState({
                                        report: "Violence or physical harm"
                                    })}>
                                        <h4 className="listTitle ml-5 modlfnt">Violence or physical harm.</h4>
                                    </li>
                                    <li className='fx-aic cursor' style={this.state.loading ? { pointerEvents: "none" } : {}} onClick={() => this.setState({
                                        report: "Adult Content"
                                    })}>
                                        <h4 className="listTitle ml-5 modlfnt">Adult Content.</h4>
                                    </li>
                                    <li className='fx-aic cursor' style={this.state.loading ? { pointerEvents: "none" } : {}} onClick={() => this.setState({
                                        report: "Intellectual property infringement or defamation"
                                    })}>
                                        <h4 className="listTitle ml-5 modlfnt">Intellectual property infringement or defamation.</h4>
                                    </li>
                                    <li className='fx-aic cursor' style={this.state.loading ? { pointerEvents: "none" } : {}} onClick={() => this.setState({
                                        report: "I don't want to see this"
                                    })}>
                                        <div className='ml-5'>
                                            <h4 className="listTitle modlfnt">I don't want to see this.</h4>
                                            <p className="description modlfnt">If none of these reasons apply, let us know why you don't like this post.</p>
                                        </div>
                                    </li>
                                </ul>
                                {
                                    this.state.report === "I don't want to see this" && <div className='form-group mt-5'>
                                        <input type="text" className='form-control' value={this.state.textreport} onChange={(e) => {
                                            this.setState({
                                                ...this.state,
                                                textreport: e.target.value
                                            })
                                        }} />
                                    </div>
                                }
                                {
                                    this.state.report === "I don't want to see this" && <div className='form-group'>
                                        <button className={this.state.report ? (
                                            this.state.report === "I don't want to see this" && (
                                                this.state.textreport ? 'btn btn-primary btn-block cursor' : 'btn btn-primary btn-block')
                                        ) : 'btn btn-primary btn-block'}
                                            id='reportbtn' disabled={this.state.report ? (
                                                this.state.report === "I don't want to see this" && (this.state.textreport && this.state.textreport.trim().length !== 0 ? (this.state.loading ? true : false) : true)
                                            ) : true} onClick={() => { this.handleReport(comment_id, this.state.reportType) }}>{this.state.loading ? "Loading" : "Submit"}</button>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal fade otp_verify clone_verify" id="exampleModalClone" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" >
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header d-block text-center">
                                <h5 className="modal-title" id="exampleModalLabel">Clone Tag</h5>
                                <p>This will clone the current Tag.</p>
                            </div>
                            <div className="modal-body">
                                <div className='cnfirm_row'> <button type="button" disabled={this.state.loading ? true : false} onClick={() => this.createCloneTag()}>Confirm</button></div>
                                <div className='cancel_btn'> <button type="button" disabled={this.state.loading ? true : false} onClick={() => { $("#exampleModalClone").modal("hide") }}>Cancel</button></div>
                            </div>
                        </div>
                    </div>
                </div>

                {/****************Share tag modal********************/}
                <div className="modal fade otp_verify clone_verify" id="exampleModalsharetag" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" >
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content share_tag">
                            {/* <div className="modal-header d-block text-center share_tag_head">
                                <h5 className="modal-title" id="exampleModalLabel">Share Tag</h5>

                            </div> */}
                            <div className="modal-body">
                                {/* <div className="insider-info-card__body__top">
                                    <div className="insider-user-list-loop share-tag_info">
                                        <figure className="user-pic share_tag_profile_pic">
                                            <img
                                                src={
                                                    mk_profile_img ? process.env.REACT_APP_MEDIA_BASE_URL + `/${mk_profile_img}` : (profile_img ? process.env.REACT_APP_MEDIA_BASE_URL + `/${profile_img}` : '/images/user-icon.svg')
                                                }
                                                onError={({ currentTarget }) => {
                                                    currentTarget.onerror = null;
                                                    currentTarget.src = process.env.PUBLIC_URL + "/images/user-icon.svg";
                                                }}
                                                alt="User"
                                            />
                                        </figure>
                                        <div className="insider-user-list-loop__info">
                                            <h4 className="mb-1">{user_name}</h4>
                                            <div className="tagItem">
                                                <div className="tagItem__selected visibility">
                                                    <i className="icon startIcon">
                                                        <img src="/images/Icon_awesome-lock-black.png" alt="Location" />
                                                    </i>
                                                    <select className="tagItem__title font" value={shareTagVisibility} onChange={(e) => {
                                                        this.setState({
                                                            ...this.state,
                                                            shareTagVisibility: e.target.value
                                                        })
                                                    }}>
                                                        <option value="private">Just Me</option>
                                                        <option value="family_only">Family</option>
                                                        <option value="friends_only">Friends</option>
                                                        <option value="acquaintances_only">Individual</option>
                                                        <option value="watchers_only">Watchers</option>
                                                        <option value="public">Public</option>
                                                    </select>
                                                </div>
                                                <i className="icon aroDown">
                                                    <img src="/images/down-aro.svg" alt="Location" /></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='share_tag_textarea'>
                                        <textarea placeholder='Write Something....' value={shareTagText} onChange={(e) => this.setState({
                                            ...this.state,
                                            shareTagText: e.target.value
                                        })}></textarea>
                                    </div>
                                </div> */}

                                <div className='qr_row'>
                                    <QRCode
                                        id="qrDesktop"
                                        value={window.location.href}
                                        size={130}
                                    />
                                    {/* <div className='qr_date'>
                                        <p>{Moment(new Date()).format("MMMM, DD, YYYY | hh:mm a")}</p>
                                        <h4>By {localStorage.getItem("displayName")}</h4>
                                    </div> */}
                                </div>
                            </div>
                            {/* <div className='modal_footer'>

                                <button type="button" className='cnfrm_btn' onClick={() => !shareTagloader && this.shareTag()}>Share</button>
                                <button type="button" className='cancel_btn' data-dismiss="modal">Cancel</button>

                            </div> */}
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

const mapDispatchToProps = {
    TagDetail,
    calendarFeed,
    EditTag,
    conditionalEditTag,
    nestedCreateTagPopup
}

const mapStateToProps = (state) => ({
    Profilepic: state.Preview,
    conditionEdit: state.conitionalEdit,
    kidPermission: state.childPermission.permission
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(QuickDetail));
