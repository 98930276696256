// Fetch cookies based on particular name;
export const fetchCookies = (cookiename) => {
    var cookieArr = document.cookie.split(";");
    for (var i = 0; i < cookieArr.length; i++) {
        var cookiePair = cookieArr[i].split("=");
        if (cookiename === cookiePair[0].trim()) return decodeURIComponent(cookiePair[1].split(",")[0]);
    }
    return null;
}

// Create cookies as well as update cookies with the same expiry time;
export const createCookies = (cookieName, cookieValue, minutes) => {
    let cookieVal = cookieValue;
    var expireDate = document.cookie.indexOf(cookieName) === -1
        ? new Date(new Date().setTime(new Date().getTime() + minutes * 60 * 1000)).toGMTString()
        : unescape(document.cookie).split('expireDate=')[1];
    document.cookie = `${cookieName}=${cookieVal},expireDate=${expireDate};expires=${expireDate}; path=/`;
}


export const fetchCookiesConditionally = (cookiename) => {
    var cookieArr = document.cookie.split(";");
    for (var i = 0; i < cookieArr.length; i++) {
        var cookiePair = cookieArr[i].split("=");
        if (cookiename === cookiePair[0].trim()) return decodeURIComponent(cookiePair[1].split(",exp")[0]);
    }
    return null;
}


export const deleteCookies = (name) => {
    document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
};