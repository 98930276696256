import React, { PureComponent } from 'react'

export default class CreatePdf extends PureComponent {
  render() {
    return (
      <div className='mobileDesktopBlock'>
        <div className=" subscription_wrapper noShadow">
          <div className="pdf_wrapper">
            <div className="pdf_header">
              <div className="pdf_logo_sec">
                <img src= {process.env.PUBLIC_URL + "/images/logo-white.svg"} alt="Logo" />
              </div>

              <div className="pdf_head_righ">
                <h1>invoice</h1>
                <h4>Invoice Number : 451</h4>
              </div>
            </div>
            <div className="invoice_bdy">
              <div className="invoice_date_sec">
                <div className="issue_date">
                  <h4>Date of Issue: 06/08/2022</h4>
                  <h4>Due Date: 15/12/2022</h4>
                </div>
                <div className="invoice_payment">
                  <h5>Invoice Total</h5>
                  <h2>$4520</h2>
                </div>

              </div>
              <div className="invoice_table">
                <table>
                  <tr>
                    <th>Description</th>
                    <th>Unit Cost</th>
                    <th>Amount</th>
                  </tr>
                  <tr>
                    <td>Your Item Name</td>
                    <td>$1000</td>
                    <td>$100</td>
                  </tr>
                  <tr>
                    <td>Your Item Name</td>
                    <td>$1000</td>
                    <td>$100</td>
                  </tr>
                  <tr>
                    <td>Your Item Name</td>
                    <td>$1000</td>
                    <td>$100</td>
                  </tr>
                  <tr className="total_invoice">
                    <td>Invoice Total</td>
                    <td></td>
                    <td>$300</td>
                  </tr>
                </table>
              </div>
              <div className="invoice_subtotal">
                <ul>
                <li>
                  <h4>Subtotal:</h4>
                  <p>$300</p>
                </li>
                <li>
                  <h4>Tax:</h4>
                  <p>$30</p>
                </li>
                <li>
                  <span>Total:</span>
                  <p>$330</p>
                </li>
                </ul>
                


              </div>
            </div>

          </div>
        </div>
      </div>

    )
  }
}
