import React from 'react';
import { Link } from 'react-router-dom';
import {toast} from 'react-toastify/dist/react-toastify.esm';


//api
import { updatePassword } from '../../api/LoginSignupApi';

class NewPassword extends React.PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            password: '',
            loading: false,
            errors: {},
            confirmPassword: '',
            showPassword: false,
            showConfirmPassword: false
        }
    }

    // showHidePassword = (action, e) => {
    //     if (action === 'password') {
    //         var showPassword = this.state.showPassword;
    //         this.setState({ showPassword: !showPassword })
    //     }
    //     else if (action === 'confirm_password') {
    //         var showConfirmPassword = this.state.showConfirmPassword;
    //         this.setState({ showConfirmPassword: !showConfirmPassword })
    //     }
    // }

    componentDidMount() {
        // this.updateWindowDimensions();
        // window.addEventListener('resize', this.updateWindowDimensions)
        // window.addEventListener('load', this.updateWindowDimensions)
        // window.onresize(function(){
        //     this.updateWindowDimensions();
        // })

        // let vh = window.innerHeight * 0.01;
        // document.documentElement.style.setProperty('--vh', `${vh}px`);
      }
    
    //   updateWindowDimensions = () => {
    //     const doc = document.documentElement
    //     doc.style.setProperty('--app-height', `${window.innerHeight}px`)
    //     console.log('chekc');
    //   }


    handleNewPassword = async () => {
        var params = {};
        params.phone_number = this.props.phone
        params.password = this.state.password;
        params.confirmPassword = this.state.confirmPassword;
        this.setState({ loading: true, errors: {} });
        try {
            let res = await updatePassword(params);
            if (res && res.success === true) {
                toast.success(res.message);
                if (res.success) {
                    this.setState({
                        password: '',
                        confirmPassword: ""
                    })
                    this.props.LoginSignupToggle("login")
                }
            }
            else if (res && res.success === false) {
                toast.error(res.message);
                console.log(res.message)
            } else if (res && res.error) {
                console.log(res.error)
                this.setState({ errors: res.error });
            }
            this.setState({ loading: false });
        } catch (error) {
            toast.error(error.message)
            console.log(error.message)
            this.setState({ loading: false });
        }
    }


    componentWillUnmount() {
        this.setState = (state, callback) => {
            return;
        };
    }

    render() {
        const { errors } = this.state;
        const { showConfirmPassword, showPassword, showHidePassword } = this.props
        return (
            <>
                <div className="onboarding-card-container NewPassword " id="newpassword">
                    <div className="onboarding-card-header">
                        <figure>
                            <img src={process.env.PUBLIC_URL + "/images/logo.svg"} alt="logo" height="45" />
                        </figure>
                        <h4 className="onboarding-card-header__title">wexxle</h4>
                    </div>
                    <div className="onboarding-card PasswordRecovery_page comon_space ">
                        <span className="onboarding-card__backAro" onClick={this.props.getBack}></span>
                        <span className="onboarding-card__handle"></span>
                        <div className="onboarding-card__logo">
                            <Link to="" className="anchor">
                                <img src={process.env.PUBLIC_URL + "/images/logo.svg"} alt="logo" height="30" />
                                <span className="logoText">wexxle</span>
                            </Link>
                        </div>

                        <div className="welcome-text mobileDesktoSame newpassword_body">
                            <h1>New Password</h1>
                            <p>Reset your password</p>
                        </div>

                        <form action="" className="custom-form">
                            <div className="onlyFilleds new_pass_spce">

                                <div className="form-group password_space">
                                    {/* <label htmlFor="password" className="d-none d-md-block">Password</label> */}
                                    <div className="filledWithIcon ">
                                        <span className="inputIcons passwordIcon">&nbsp;</span>
                                        {errors.password ?
                                            <span className="inputIconsRight validateCross">
                                                <span className="validTooltip requiredMsg" dangerouslySetInnerHTML={{ __html: errors.password }}></span>
                                            </span>
                                            : <span className={!showPassword ? "inputIconsRight hidepassword" : "inputIconsRight showPassword"} onClick={() => { showHidePassword('password') }}></span>
                                        }
                                        <input id="password" maxLength={12} value={this.state.password} name="password" type={showPassword ? "text" : "password"} placeholder="* * * * * * *" className="form-control new_pass_padd only_mble_shw" onChange={(e) => {
                                            this.setState({
                                                ...this.state,
                                                password: e.target.value,
                                                errors: {
                                                    ...this.state.errors,
                                                    [e.target.name]: ""
                                                }
                                            })
                                        }} />
                                    </div>
                                </div>

                                <div className="form-group ">
                                    {/* <label htmlFor="password" className="d-none d-md-block">Confirm Password</label> */}
                                    <div className="filledWithIcon">
                                        <span className="inputIcons passwordConfIcon">&nbsp;</span>
                                        {errors.confirmPassword ?
                                            <span className="inputIconsRight validateCross">
                                                <span className="validTooltip requiredMsg">{errors.confirmPassword}</span>
                                            </span>
                                            : <span className={!showConfirmPassword ? "inputIconsRight hidepassword" : "inputIconsRight showPassword"} onClick={() => { showHidePassword('confirm_password') }}></span>
                                        }
                                        <input id="confirmPassword" maxLength={12} value={this.state.confirmPassword} name="confirmPassword" type={showConfirmPassword ? "text" : "password"} placeholder="* * * * * * *" className="form-control new_pass_padd only_mble_shw" onChange={(e) => {
                                            this.setState({
                                                ...this.state,
                                                confirmPassword: e.target.value,
                                                errors: {
                                                    ...this.state.errors,
                                                    [e.target.name]: ""
                                                }
                                            })
                                        }} />
                                    </div>
                                </div>

                            </div>
                            <div className="form-group new_pass_btn create_btn">
                                <input type="button" disabled={this.state.loading ? true : false} value={this.state.loading ? "Loading" : "Confirm"} onClick={this.handleNewPassword} className="btn  btn-block" />
                            </div>
                        </form>
                        <div className="onboardingQrcode passw_recov_qr" style={{
                                pointerEvents: this.state.loading ? "none" : ""
                            }}>
                                <div className="onboardingQrcode__inside" data-toggle="modal" data-target="#qrCodeModal">
                                    <span className="">Or Scan QR Code</span>
                                    <div className="qrcodeImg">
                                        <img src={process.env.PUBLIC_URL + "/images/qrcode.svg"} alt="QR" />
                                    </div>
                                </div>
                                <div className="onboardingQrcode__mobileOnly">
                                    <span>Scan QR Code</span>
                                </div>
                            </div>
                    </div>
                </div>
            </>
        );
    }
}
export default NewPassword;