import exifr from 'exifr'
import Moment from 'moment';
import Geocode from "react-geocode";
import Resizer from "react-image-file-resizer";
import { toast } from 'react-toastify/dist/react-toastify.esm';


export function resetToken() {
    localStorage.clear()
    window.location = "/"
}

const positionError = (error) => {
    switch (error.code) {
        case error.PERMISSION_DENIED:
            // toast.error("User denied the request for Geolocation.")
            break;
        case error.POSITION_UNAVAILABLE:
            toast.error("Location information is unavailable.");
            break;
        case error.TIMEOUT:
            toast.error("The request to get user location timed out.");
            break;
        case error.UNKNOWN_ERROR:
            toast.error("An unknown error occurred.");
            break;
        default:
    }
}

export function getCurrentLocation() {
    if (navigator?.geolocation) {
        navigator.geolocation.getCurrentPosition(
            async position => {
                localStorage.setItem('lat', position.coords.latitude);
                localStorage.setItem('lng', position.coords.longitude);
                await fetchCurrentAddress();
            },
            positionError,
            {
                maximumAge: 250,
                enableHighAccuracy: true
            }
        );
    }
}

export const trackCurrentLocation = (callback) => {
    if (navigator?.geolocation) {
        navigator.geolocation.watchPosition(
            async position => {
                localStorage.setItem('lat', position.coords.latitude);
                localStorage.setItem('lng', position.coords.longitude);
                await fetchCurrentAddress();
                if (callback) callback(position.coords.latitude, position.coords.longitude)
            },
            positionError,
            {
                maximumAge: 250,
                enableHighAccuracy: true
            }
        );
    }
}



if (navigator?.permissions?.query) {
    navigator.permissions.query({ name: 'geolocation' }).then((permissionStatus) => {
        permissionStatus.onchange = () => {
            if (permissionStatus.state === "granted") getCurrentLocation();
            else if (permissionStatus.state === "denied") {
                localStorage.removeItem('lat');
                localStorage.removeItem('lng');
                localStorage.removeItem('address');
            }
        };
    });
}

async function fetchCurrentAddress() {
    Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAP_API_KEY);
    let response = await Geocode.fromLatLng(localStorage.getItem('lat'), localStorage.getItem('lng'))
    localStorage.setItem('address', response.results[0].formatted_address);
}

export async function fetchMetaInfo(file) {
    try {
        let { latitude, longitude } = await exifr.gps(file);
        return { latitude, longitude };
    } catch (e) {
        console.error('"No gps Meta Data :) Try another pix"');
    }
}

export async function findAddress() {
    getCurrentLocation();
    if (localStorage.getItem('lat') && localStorage.getItem('lng')) {
        try {
            Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAP_API_KEY);
            let response = await Geocode.fromLatLng(localStorage.getItem('lat'), localStorage.getItem('lng'))
            localStorage.setItem('address', response.results[0].formatted_address);
            return response;
        } catch (error) {
            console.error(error);
        }
    }
}

export const getSelectedAddress = async () => {
    let response = await findAddress();
    if (response?.status === "OK") {
        return {
            address: localStorage.getItem('address'),
            lat: localStorage.getItem('lat'),
            lng: localStorage.getItem('lng')
        }
    }
}

export function getDateDiff(date) {
    var date2 = Moment.utc(date);
    date2.utcOffset("+05:30").format("YYYY-MM-DD HH:mm:ss");
    var date1 = Moment();
    date1.utcOffset("+05:30").format("YYYY-MM-DD HH:mm:ss");

    let years = date1.diff(date2, 'year');
    date2.add(years, 'years');

    let months = date1.diff(date2, 'months');
    date2.add(months, 'months');

    let days = date1.diff(date2, 'days');
    date2.add(days, 'days');

    let hours = date1.diff(date2, 'hours');
    date2.add(hours, 'hours');

    let minutes = date1.diff(date2, 'minutes');
    date2.add(minutes, 'minutes');

    let seconds = date1.diff(date2, 'seconds');

    var date_diff = (years !== 0) ? years + " years" : ((months !== 0) ? months + " months" : ((days !== 0) ? days + " days" : ((hours !== 0) ? hours + " hours" : ((minutes !== 0) ? minutes + " minutes" : (seconds !== 0) ? seconds + " seconds" : ""))));

    return (date_diff !== "") ? date_diff + " ago" : "";
}

export const spotifyEnable = () => {
    const expiresIn = sessionStorage.getItem('expires_in');
    const accessToken = sessionStorage.getItem("access_token")
    const refreshToken = sessionStorage.getItem('refresh_token')
    return expiresIn && accessToken && refreshToken;
}
export const docusignEnable = () => {
    const expiresIn = sessionStorage.getItem('d_expires_in');
    const accessToken = sessionStorage.getItem("d_access_token")
    return expiresIn && accessToken
}

export const removeDocuCred = () => {
    sessionStorage.removeItem("d_expires_in");
    sessionStorage.removeItem("d_access_token");
}

export const spotifyClientId = ["localhost", "127.0.0.1", ""].includes(window.location.hostname) ? process.env.REACT_APP_CLIENT_ID_DEV : process.env.REACT_APP_CLIENT_ID_PROD;

export const spotifyClientSecret = ["localhost", "127.0.0.1", ""].includes(window.location.hostname) ? process.env.REACT_APP_CLIENT_SECRET_DEV : process.env.REACT_APP_CLIENT_SECRET_PROD;

export const Redirect_URl = ["localhost", "127.0.0.1", ""].includes(window.location.hostname) ? "https://localhost:3000" : "https://wexxle.io"

export const spotifyAuthUrl = `https://accounts.spotify.com/en/authorize?response_type=code&client_id=${spotifyClientId}&scope=streaming%20user-read-email%20user-read-private%20user-top-read&redirect_uri=${Redirect_URl}`

export const docuSignUrl = ["localhost", "127.0.0.1", ""].includes(window.location.hostname) ? process.env.REACT_APP_DOCUSIGN_URL_DEV : process.env.REACT_APP_DOCUSIGN_URL_PROD


export const docuSignAuthUrl =
    `${docuSignUrl}/oauth/auth?` +
    `response_type=token&` +
    `prompt=login` +
    `scope=${process.env.REACT_APP_DOCUSIGN_IMPLICIT_SCOPES}&` +
    `client_id=${process.env.REACT_APP_DOCUSIGN_CLIENT_ID}&` +
    `redirect_uri=${encodeURIComponent(Redirect_URl)}`;

export const fetchParamsFromUrlWithHash = () => {
    if (!window.location.hash) return;
    const params_tmp = window.location.hash.substr(1).split('&')
    let params = {};
    params_tmp.forEach(function (val) {
        var splitter = val.split('=');
        params[splitter[0]] = splitter[1];
    });
    return params;
}

export const lazyRetry = (componentImport) =>
    new Promise((resolve, reject) => {
        const storageKey = `retry-lazy-refreshed${btoa(componentImport.toString())}`;
        const hasRefreshed = JSON.parse(window.sessionStorage.getItem(storageKey) || 'false');
        componentImport()
            .then((component) => {
                window.sessionStorage.setItem(storageKey, 'false');
                if (component === undefined) {
                    window.sessionStorage.setItem(storageKey, 'true');
                    return window.location.reload(); // refresh the page
                }
                resolve(component);
            })
            .catch((error) => {
                if (!hasRefreshed) {
                    // not been refreshed yet
                    window.sessionStorage.setItem(storageKey, 'true');
                    window.location.reload();
                }
                reject(error); // Default error behaviour as already tried refresh
            });
    });




export const ImageResizer = (file, height, width) => {
    if (!file) return;
    return new Promise((resolve, reject) => {
        try {
            Resizer.imageFileResizer(
                file,
                width,
                height,
                "JPEG",
                100,
                0,
                (blob) => {
                    resolve(blob)
                },
                "file",
                width
            );
        } catch (err) {
            reject(err)
        }
    })
}

export const checkImageDimension = (file) => {
    return new Promise(resolve => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.addEventListener('load', event => {
            const _loadedImageUrl = event.target?.result
            const image = document.createElement('img')
            image.src = _loadedImageUrl
            image.addEventListener('load', () => {
                const { width } = image
                resolve(width)
            })
        })
    })
}

export const fetchAndUpdateList = (Array, current_value) => {
    if (!Array?.length) return;
    if (current_value) {
        let currentValue = JSON.parse("[" + current_value + "]")
        return Array.map((val) => currentValue.includes(JSON.parse(val.id)) ? { ...val, is_default: true } : { ...val, is_default: false })
    }
    else return null
}

export const customChecker = (e, position, array, no_one_pos, all_wex_pos) => {
    let { checked } = e.target
    return array.map((item, index) => {
        if (index === position) {
            return {
                ...item, "is_default": checked
            }
        } else {
            return position === no_one_pos ? { ...item, "is_default": false } : (
                index === no_one_pos ? { ...item, "is_default": false } : item
            )
        }
    }).map((value, i, emp) => {
        if (i === all_wex_pos && value.is_default) {
            for (let j = 0; j < all_wex_pos; j++) {
                emp[j].is_default = false;
            }
            return value
        } else {
            if (position !== all_wex_pos) emp[all_wex_pos].is_default = false;
            return value
        }
    })
}

export const lengthConversion = (distance) => {
    let feet;
    let yard;
    if (distance >= 1) return distance.toFixed(2) + " Mile";
    let inches = distance * 63360;
    if (inches >= 12) {
        feet = inches / 12;
        if (feet >= 3) {
            yard = feet / 3;
            return yard.toFixed(2) + " Yard"
        }
        else return feet.toFixed(2) + " Feet";
    }
    else return inches.toFixed(2) + " Inch";
}


export const connectionChecker = (connection) => {
    let str = ""
    if (connection.includes(1)) {
        str += 'Family ';
    }
    if (connection.includes(2)) {
        str += 'Friend ';
    }
    if (connection.includes(3)) {
        str += 'Individual ';
    }
    return str.split(/\s+/).filter(val => val).join(",");
}

export const pointsToDrawPolygon = (
    map,
    maps,
    lat,
    lng,
    miles,
    showCenterPoint = false,
    showVertexCircle = false,
    strokeColor,
    strokeOpacity,
    strokeWeight,
    fillColor,
    fillOpacity,
    vertexCircleStrokeColor,
    vertexCircleStrokeOpacity,
    vertexCircleStrokeWeight,
    vertexCircleFillColor,
    vertexCircleFillOpacity,
    fnToSetPoints
) => {
    var geocoder = new maps.Geocoder();
    geocoder.geocode(
        {
            latLng: new window.google.maps.LatLng(lat, lng),
        },
        (results, status) => {
            if (status === maps.GeocoderStatus.OK) {
                //searchLocationsNear(results[0].geometry.location);
                var cordinate = results[0].geometry.location;
                // alert(cordinate);
                let tempCircleCodRefArray = [];
                if (showCenterPoint) {
                    let centerPointRef = new maps.Circle({
                        strokeColor: vertexCircleStrokeColor,
                        strokeOpacity: vertexCircleStrokeOpacity,
                        strokeWeight: vertexCircleStrokeWeight,
                        fillColor: vertexCircleFillColor,
                        fillOpacity: vertexCircleFillOpacity,
                        map,
                        center: new maps.LatLng(cordinate.lat(), cordinate.lng()),
                        radius: miles === 1 ? 50 : miles === 5 ? 180 : 200,
                    });
                    tempCircleCodRefArray.push(centerPointRef);
                }
                var radius = miles;
                var latitude = cordinate.lat();
                var longitude = cordinate.lng();
                //Degrees to radians
                var d2r = Math.PI / 180;

                //  Radians to degrees
                var r2d = 180 / Math.PI;
                // Earth radius is 3,963 miles
                var cLat = (radius / 3963) * r2d;

                var cLng = cLat / Math.cos(latitude * d2r);
                //Store points in array
                var points = [];
                // alert("declare array");

                var bounds = new maps.LatLngBounds();

                // Calculate the points
                // Work around 360 points on circle
                for (var i = 0; i < 360; i += 360 / 8) {
                    var theta = Math.PI * (i / 180);

                    // Calculate next X point
                    var circleY = longitude + cLng * Math.cos(theta);
                    //console.log("CircleY:"+circleY);
                    // Calculate next Y point
                    var circleX = latitude + cLat * Math.sin(theta);
                    //console.log("circleX:"+circleX);
                    // Add point to array
                    var aPoint = new maps.LatLng(circleX, circleY);
                    points.push(aPoint);
                    bounds.extend(aPoint);
                }

                points.push(points[0]);
                // console.log(points);
                //to complete circle

                showVertexCircle &&
                    points.forEach((item) => {
                        let tempCicrleRef = new maps.Circle({
                            strokeColor: vertexCircleStrokeColor,
                            strokeOpacity: vertexCircleStrokeOpacity,
                            strokeWeight: vertexCircleStrokeWeight,
                            fillColor: vertexCircleFillColor,
                            fillOpacity: vertexCircleFillOpacity,
                            map,
                            center: new maps.LatLng(item.lat(), item.lng()),
                            radius: miles === 1 ? 50 : miles === 5 ? 180 : 200,
                        });
                        tempCircleCodRefArray.push(tempCicrleRef);
                    });

                var Polyline_Path = new maps.Polygon({
                    paths: points,
                    strokeColor: strokeColor,
                    strokeOpacity: strokeOpacity,
                    strokeWeight: strokeWeight,
                    fillColor: fillColor,
                    fillOpacity: fillOpacity,
                });
                Polyline_Path.setMap(map);
                new window.google.maps.event.addListener(Polyline_Path, 'dblclick', (event) => {
                    this.createTagBasedOnMapDblClick(event)
                });
                fnToSetPoints &&
                    fnToSetPoints((prev) => ({
                        ...prev,
                        map,
                        maps,
                        Polyline_Path,
                        ...(tempCircleCodRefArray.length > 0 && {
                            circleRef: tempCircleCodRefArray,
                        }),
                    }));
            } else {
                // console.log(address + " not found");
            }
        }
    );
};