import { toast } from 'react-toastify/dist/react-toastify.esm';
import API from '@aws-amplify/api';
import * as graphqlQuery from '../graphql/queries'
import { initializeAuthCLI } from './InitializeApi';
import { fetchFriendList } from '../api/GraphqlApi';
toast.configure();
initializeAuthCLI();

export const friendConnectionFilter = (friend_list, privacy) => {
    let friendConnection = [];
    for (let i = 0; i < friend_list.length; i++) {
        let friendObj = {}
        if (privacy === "friends_only" && friend_list[i].connection === "1") {
            // friendObj["visibility"] = { "eq": "friends_only" };
            friendObj["user_id"] = { "eq": friend_list[i].friend_id };
        } else if (privacy === "family_only" && friend_list[i].connection === "2") {
            // friendObj["visibility"] = { "eq": "family_only" };
            friendObj["user_id"] = { "eq": friend_list[i].friend_id };
        } else if (privacy === "acquaintances_only" && friend_list[i].connection === "3") {
            // friendObj["visibility"] = { "eq": "acquaintances_only" };
            friendObj["user_id"] = { "eq": friend_list[i].friend_id };
        }
        if (Object.keys(friendObj).length) friendConnection.push(friendObj);
    }
    if (!friendConnection.length) {
        let friendObj = {}
        friendObj["visibility"] = { "eq": privacy };
        friendObj["user_id"] = { "eq": 0 };
        friendConnection.push(friendObj);
    }
    return friendConnection;
}

export const CalendarTag = async (from, to, privacy, latitude, longitude, tagType) => {
    try {
        let variableObject = {}
        let visibilityCase = []
        let privacyObjectCase = []
        let visibilityBlockCase = [];
        if (localStorage.getItem("id") && !["private", "public"].includes(privacy)) {
            // For FriendList //
            let friendList = await API.graphql({
                query: graphqlQuery.newsfeedfriend,
                variables: { user_id: localStorage.getItem("id") }
            })
            let friendlist = JSON.parse(friendList.data.newsfeedfriend)
            let { statusCode, body: { blocked_list, watchersList } } = friendlist
            if (!["watchers_only"].includes(privacy)) {
                let response = await fetchFriendList();
                let { statusCode, body } = response;
                if (statusCode === 200 && body.length) {
                    let frnd = friendConnectionFilter(body, privacy)
                    var frndList = frnd.filter(value => Object.keys(value).length !== 0);
                    if (frndList.length) visibilityCase.push(...frndList);
                }
            } else if (statusCode === 200 && watchersList.length) {
                let watcherList = watchersList.map(val => ({ "visibility": { "eq": "watchers_only" }, "user_id": { "eq": val.follower_id } }))
                if (watcherList.length) visibilityCase.push(...watcherList);
            }
            // For Blocklist //
            if (statusCode === 200 && blocked_list.length) {
                for (let i = 0; i < blocked_list.length; i++) {
                    let block = {
                        "user_id": {
                            "ne": blocked_list[i].blocked_user_id
                        }
                    }
                    visibilityBlockCase.push(block)
                }
            }
        }
        // For Self
        if (["private"].includes(privacy) && localStorage.getItem("id")) {
            let privateprivacy = { "user_id": { "eq": localStorage.getItem("id") } }
            visibilityCase.push(privateprivacy)
        } else if (!localStorage.getItem("id")) {
            //without login
            let withoutloginprivacy = { "visibility": { "eq": "public" } }
            visibilityCase.push(withoutloginprivacy)
        }
        // For status must be active only //
        variableObject["status"] = { "eq": "active" }
        // For privacy public //
        if (privacy === "public") {
            let publicprivacy = { "visibility": { "eq": "public" } }
            visibilityCase.push(publicprivacy)
        }
        // Privacy Object
        if (!tagType || ["task"].includes(tagType)) {
            privacyObjectCase.push({ "type": { "eq": "task" }, "task_date": { "lte": to, "gte": from } })
        }
        if (!tagType || ["event"].includes(tagType)) {
            privacyObjectCase.push({ "type": { "eq": "event" }, "start_date": { "lte": to, "gte": from } })
        }
        if (!tagType || ["memory"].includes(tagType)) {
            privacyObjectCase.push({ "type": { "eq": "memory" }, "memory_date": { "lte": to, "gte": from } })
        }
        if (!tagType || ["post"].includes(tagType)) {
            privacyObjectCase.push({ "type": { "eq": "post" }, "createdAt": { "lte": to, "gte": from } })
        }
        if (!tagType || ["asset"].includes(tagType)) {
            privacyObjectCase.push({ "type": { "eq": "asset" }, "createdAt": { "lte": to, "gte": from } })
        }
        if (!tagType || ["ecommerce"].includes(tagType)) {
            privacyObjectCase.push({ "type": { "eq": "ecommerce" }, "createdAt": { "lte": to, "gte": from } })
        }
        if (!tagType || ["quick"].includes(tagType)) {
            privacyObjectCase.push({ "type": { "eq": "quick" }, "createdAt": { "lte": to, "gte": from } })
        }
        if (latitude) variableObject["latitude"] = { "eq": latitude }
        if (longitude) variableObject["longitude"] = { "eq": longitude }

        let variable = {};
        variable["filter"] = {
            ...variableObject,
            ...(visibilityCase.length && { "or": visibilityCase }),
            ...((visibilityBlockCase.length || privacyObjectCase.length) && {
                "and": {
                    ...(visibilityBlockCase.length && { "and": visibilityBlockCase }),
                    ...(privacyObjectCase.length && { "or": privacyObjectCase })
                }
            }),
        }
        let res = await API.graphql({ query: graphqlQuery.searchTags, variables: variable })
        if (res && res.data && res.data.searchTags && res.data.searchTags.items && res.data.searchTags.items.length) return res.data.searchTags.items
        else return [];
    } catch (error) {
        if (error.message) toast.error(error.message);
        else if (error && error.errors.length) toast.error(error.errors[0].message);
        else toast.error("Something Went Wrong!");
    }
}
