import { isEmpty, validateEmail } from "../Helper/util";
import { getCurrentLocation } from "../functions";
import { toast } from 'react-toastify/dist/react-toastify.esm';


import { Customerror } from '../Helper/CustomError'
import * as myConstants from "../Helper/constants";
import { validatePassword } from '../Helper/Validation'
import { isValidPhoneNumber } from 'react-phone-number-input'
var count = 1;
function validateLoginParam(params) {
    let errors = {};
    if (isEmpty(params.email)) errors.email = 'Please enter email';
    else if (!(validateEmail(params.email))) errors.email = 'Please enter valid email';
    else if (params.email.trim().length > 60) errors.email = 'Max length should be of 60 char';
    if (isEmpty(params.password)) errors.password = 'Please enter Password.';
    else if (validatePassword(params.password)) errors.password = `<ul>
        <li> At least 8 characters and maximum 12 characters</li>
        <li> Contain at least one number</li>
        <li> Contain at least one special character</li>
        <li> Mixture of uppercase and lowercase letters</li>
    </ul>`
    return errors;
}
// 'Password must be at least 8 characters and maximum 12 characters, contain at least one number and one special character and have a mixture of uppercase and lowercase letters.'
export async function login(params) {
    getCurrentLocation();
    const errors = validateLoginParam(params);
    if (Object.keys(errors).length !== 0) {
        return { 'error': errors };
    }

    let userData = {
        "username": params.email,
        "password": params.password,
        "user_type": params.user_type
    };
    if (localStorage.getItem("checkEmail") !== params.email) {
        count = 1;
        userData["login_count"] = count
    } else {
        if (localStorage.getItem("count")) {
            userData["login_count"] = localStorage.getItem("count")
        } else {
            userData["login_count"] = 1;
        }
    }
    let res = await fetch(myConstants.api_url + 'default/login', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(userData)
    })
    if (res.ok && res.status === 200) {
        localStorage.setItem("count", 0)
        let result = await res.json()
        return result;
    } else {
        return res.text()
            .then(result => JSON.parse(result))
            .then(result => {
                if (res.status !== 405) Customerror(res.status, result.message);
                if (res.status === 403) {
                    if (result.message === "Account has been locked. Please contact Administrator.") {
                        count = 1;
                    } else {
                        count = count + 1;
                    }
                    localStorage.setItem("count", count)
                    localStorage.setItem("checkEmail", params.email)
                } else if (res.status === 405) {
                    return {
                        "activation": result.message,
                        "data": result.data
                    }
                }
            }
            );
    }
}

// function isValid(phone) {
//     var phoneRe = /^[6-9]\d{2}[2-9]\d{2}\d{4}$/;
//     return phoneRe.test(phone);
// }

function validateRegisterParam(params) {
    let errors = {};
    if (isEmpty(params.name)) errors.name = 'Please enter name';
    if (isEmpty(params.email)) errors.email = 'Please enter email';
    else if (!(validateEmail(params.email))) errors.email = 'Please enter valid email';
    else if (params.email.trim().length > 60) errors.email = 'Max length should be of 60 char';
    if (isEmpty(params.phone)) errors.phone = 'Please enter phone number';
    else if (!isValidPhoneNumber(params.phone)) errors.phone = "Invalid phone number"
    if (isEmpty(params.signUpPassword)) errors.signUpPassword = 'Please enter Password';
    else if (validatePassword(params.signUpPassword)) errors.signUpPassword = `<ul>
    <li> At least 8 characters and maximum 12 characters</li>
    <li> Contain at least one number</li>
    <li> Contain at least one special character</li>
    <li> Mixture of uppercase and lowercase letters</li>
</ul>`
    if (isEmpty(params.confirmPassword)) errors.confirmPassword = 'Please enter confirm Password';
    else if (params.signUpPassword !== params.confirmPassword) errors.confirmPassword = 'Password does not match';
    return errors;
}

export async function register(params) {
    getCurrentLocation();
    const errors = validateRegisterParam(params);
    if (Object.keys(errors).length !== 0) {
        return { 'error': errors };
    }
    let userData = {
        "email": params.email,
        "password": params.signUpPassword,
        "phone_number": params.phone,
        "full_name": params.name,
        "lat": localStorage.getItem('lat'),
        "lng": localStorage.getItem('lng'),
        "user_type": params.user_type
    };
    console.log("Request Param = " + JSON.stringify(userData));
    let res = await fetch(myConstants.api_url + 'register/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(userData)
    })
    if (res.ok && res.status === 200) {
        let result = await res.json()
        return result;
    } else {
        return res.text()
            .then(result => JSON.parse(result))
            .then(result => {
                if (res.status === 400) return { "message": result.message };
                else if (res.status === 404) toast.error("Something went wrong, Please Try Some Time Later");
                else Customerror(res.status, result.message);
            });
    }
}

function validateEmailParam(params) {
    let errors = {};
    if (isEmpty(params.email)) errors.email = 'Please enter email';
    else if (!(validateEmail(params.email))) errors.email = 'Please enter valid email';
    else if (params.email.trim().length > 60) errors.email = 'Max length should be of 60 char';
    return errors;
}

export async function checkRegisterEmail(params) {
    const errors = validateEmailParam(params);
    if (Object.keys(errors).length !== 0) {
        return { 'error': errors };
    }
    let userData = {
        "email": params.email
    };
    console.log("Request Param " + JSON.stringify(userData))
    let res = await fetch(myConstants.api_url + 'default/check-email', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(userData)
    })
    if (res.ok && res.status === 200) {
        let result = await res.json()
        return result;
    } else {
        return res.text()
            .then(result => JSON.parse(result))
            .then(result => { Customerror(res.status, result.message) });
    }
}

const validatePhoneParam = (params) => {
    let errors = {};
    if (isEmpty(params.phone)) errors.phone = 'Please enter phone number';
    else if (!isValidPhoneNumber(params.phone)) errors.phone = "Invalid phone number"
    return errors;
}

export async function passwordRecovery(params) {
    const errors = validatePhoneParam(params);
    if (Object.keys(errors).length !== 0) {
        return { 'error': errors };
    }
    let userData = {
        "phone_number": params.phone,
        "is_registration": params.isregistered
    };
    let res = await fetch(myConstants.api_url + 'default/send_otp', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(userData)
    })
    if (res.ok && res.status === 200) {
        let result = await res.json()
        return result;
    } else {
        return res.text()
            .then(result => JSON.parse(result))
            .then(result => { Customerror(res.status, result.message) });
    }
}

export async function otpVerify(params) {
    let userData = {
        "otp": params.otp,
        "phone_number": params.phone_number
    };
    console.log("Request Param " + JSON.stringify(userData))
    let res = await fetch(myConstants.api_url + 'default/verify_otp', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(userData)
    })
    if (res.ok && res.status === 200) {
        let result = await res.json()
        return result;
    } else {
        return res.text()
            .then(result => JSON.parse(result))
            .then(result => {
                if (res.status === 404) return { "message": result.message };
                else Customerror(res.status, result.message)
            });
    }

}

function validatePasswordParam(params) {
    let errors = {};
    if (isEmpty(params.password)) errors.password = 'Please enter Password';
    else if (validatePassword(params.password)) errors.password = `<ul>
    <li> At least 8 characters and maximum 12 characters</li>
    <li> Contain at least one number</li>
    <li> Contain at least one special character</li>
    <li> Mixture of uppercase and lowercase letters</li>
</ul>`
    if (isEmpty(params.confirmPassword)) errors.confirmPassword = 'Please enter confirm Password';
    else if (params.password !== params.confirmPassword) errors.confirmPassword = 'Password does not match';
    return errors;
}

export async function updatePassword(params) {
    const errors = validatePasswordParam(params);
    if (Object.keys(errors).length !== 0) {
        return { 'error': errors };
    }
    let userData = {
        "phone_number": params.phone_number,
        "password": params.password
    };
    console.log("Request Param " + JSON.stringify(userData))
    let res = await fetch(myConstants.api_url + 'default/password-recovery/', {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(userData)
    })
    if (res.ok && res.status === 200) {
        let result = await res.json()
        return result;
    } else {
        return res.text()
            .then(result => JSON.parse(result))
            .then(result => { Customerror(res.status, result.message) });
    }
}

export async function activateUser(activationKey) {
    let userData = {
        "activation_key": activationKey
    };
    let res = await fetch(myConstants.api_url + 'default/activate-user', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(userData)
    })
    if (res.ok && res.status === 200) {
        let result = await res.json()
        return result;
    } else {
        return res.text()
            .then(result => JSON.parse(result))
            .then(result => { Customerror(res.status, result.message) });
    }
}

export async function socialLogin(params) {
    let userData = {
        "email": params.email,
        "full_name": params.name,
        "social_media_type": params.social_media_type,
        "access_token": params.access_token
    };
    getCurrentLocation();
    console.log("Request Param " + JSON.stringify(userData))
    let res = await fetch(myConstants.api_url + 'default/login-with-social-account', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(userData)
    })
    if (res.ok && res.status === 200) {
        let result = await res.json()
        return result;
    } else {
        return res.text()
            .then(result => JSON.parse(result))
            .then(result => { Customerror(res.status, result.message) });
    }

}

export const OtpVerification = async (params) => {
    let userData = {
        "otp": params.otp,
        "phone_number": params.phone_number,
        // "is_registration": 1
    };
    console.log("Request Param " + JSON.stringify(userData))
    let res = await fetch(myConstants.api_url + 'default/verify_otp', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(userData)
    })
    if (res.ok && res.status === 200) {
        let result = await res.json()
        return result;
    } else {
        return res.text()
            .then(result => JSON.parse(result))
            .then(result => {
                if (res.status === 404) return { "message": result.message };
                else Customerror(res.status, result.message)
            });
    }
}