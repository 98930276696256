import { FETCHTAGCALENDAR, CLEARCALENDARTAG } from '../types'

const initialState = {
    action: "",
    CalendarFeed: []
}

export const calendarReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCHTAGCALENDAR:
            return {
                ...state,
                action: !Array.isArray(action.payload) ? action.payload.type : "",
                CalendarFeed: !Array.isArray(action.payload) ? [...action.payload.Feed] : []
            }
        case CLEARCALENDARTAG:
            return {
                action: "",
                CalendarFeed: []
            }
        default:
            return state;
    }
}

