import React, { PureComponent } from 'react';
import {Link} from 'react-router-dom';
export default class PageNotFound extends PureComponent {
    render() {
        return (
            <>
                <div className="container" >
                    <div className="row">
                        <div className="col-md-12">
                            <div className="error-template">
                                <figure className="mb-10">
                                    <img src="../images/sad-emoji.png" alt="sad" height="70" />
                                </figure>
                                <h1>
                                    Oops!</h1>
                                <h2>
                                    404 Not Found</h2>
                                <div className="error-details">
                                    Sorry, an error has occured, Requested page not found!
                                </div>
                                <div className="error-actions">
                                    <Link to='/' className="btn btn-outline-secondary"><i className="fas fa-home"></i>&nbsp;
                                        Take me home</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </>
        );
    }
}
