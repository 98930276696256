import axios from 'axios'
import qs from 'qs'
import {toast} from 'react-toastify/dist/react-toastify.esm';

const axiosoption = {
    baseURL: process.env.REACT_APP_STRIPE_URL,
    headers: {
        "Authorization": `Bearer ${process.env.REACT_APP_STRIPE_SECRET_KEY}`,
        "Content-Type": "application/x-www-form-urlencoded"
    },
};
let instance = axios.create(axiosoption);

export const getCustomers = async (email) => {
    try {
        let { data, status } = await instance({
            method: "GET",
            url: `customers?email=${email}`,
        })
        return { data, status };
    } catch (error) {
        if (error.response) toast.error(error.response.data.error.message);
        else if (error.request) toast.error(error.message);
        else toast.error(error.message)
    }
}

export const createCustomer = async (name, email) => {
    try {
        let { data, status } = await instance({
            method: "POST",
            url: "customers",
            data: qs.stringify({
                "name": name,
                "email": email,
            })
        })
        return { data, status };
    } catch (error) {
        if (error.response) toast.error(error.response.data.error.message);
        else if (error.request) toast.error(error.message);
        else toast.error(error.message)
    }
}

export const CreateSetUpIntent = async () => {
    try {
        let { data, status } = await instance({
            method: "POST",
            url: "setup_intents",
            data: qs.stringify({
                "payment_method_types[]": "card",
            })
        })
        return { data, status };
    } catch (error) {
        if (error.response) toast.error(error.response.data.error.message);
        else if (error.request) toast.error(error.message);
        else toast.error(error.message)
    }
}

export const setUpPaymentMethod = async (id, customer_id) => {
    try {
        let { data, status } = await instance({
            method: "POST",
            url: `payment_methods/${id}/attach`,
            data: qs.stringify({ "customer": customer_id, })
        })
        return { data, status };
    } catch (error) {
        if (error.response) toast.error(error.response.data.error.message);
        else if (error.request) toast.error(error.message);
        else toast.error(error.message)
    }
}

export const paymentIntent = async (payment, savecard, customerId) => {
    try {
        let obj = {}
        obj["amount"] = payment.total ? Math.floor(payment.total * 100) : Math.floor(0 * 100);
        obj["currency"] = 'inr'

        obj["description"] = "Wexxle services"
        obj["shipping"] = {}
        obj["shipping"]["name"] = "Mike";
        obj["shipping"]["address"] = {}
        obj["shipping"]["address"]["line1"] = "510 Townsend St"
        obj["shipping"]["address"]["postal_code"] = "98140"
        obj["shipping"]["address"]["city"] = "San Francisco"
        obj["shipping"]["address"]["state"] = "CA"
        obj["shipping"]["address"]["country"] = "US"

        obj["setup_future_usage"] = "off_session"
        obj["payment_method_types[]"] = "card"
        obj['metadata'] = {};
        obj['metadata']['user_id'] = localStorage.getItem("id") ? localStorage.getItem("id") : ""
        obj["metadata"]["plan_id"] = payment && Object.keys(payment).length ? (
            payment.plan_id ? payment.plan_id : 0
        ) : 0
        obj['metadata']['moduleType'] = payment && payment.moduleTypeId ? payment.moduleTypeId : 0
        obj['metadata']['to_user_id'] = payment && payment.to_user_id ? payment.to_user_id : ""
        obj['metadata']['to_email'] = payment && payment.to_email ? payment.to_email : ""
        obj['metadata']['notes'] = payment && payment.notes ? payment.notes : ""
        obj['metadata']['tag_id'] = payment && payment.tag_id ? payment.tag_id : ""
        obj['metadata']['discount_applied'] = payment && payment.discount_applied ? payment.discount_applied : 0
        obj['metadata']['discount_percent'] = payment && payment.discount_percent ? payment.discount_percent : 0

        if (savecard) obj["customer"] = customerId ? customerId : ""
        let { data, status } = await instance({
            method: "POST",
            url: "payment_intents",
            data: qs.stringify(obj)
        })
        return { data, status };
    } catch (error) {
        if (error.response) toast.error(error.response.data.error.message);
        else if (error.request) toast.error(error.message);
        else toast.error(error.message)
    }
}

export const removePaymentPayment = async (payment_id) => {
    try {
        let { data, status } = await instance({
            method: "POST",
            url: `payment_methods/${payment_id}/detach`,
        })
        return { data, status };
    } catch (error) {
        if (error.response) toast.error(error.response.data.error.message);
        else if (error.request) toast.error(error.message);
        else toast.error(error.message)
    }
}


export const getSubscription = async (customer_id) => {
    try {
        let { data, status } = await instance({
            method: "GET",
            url: "subscriptions",
            data: qs.stringify({ "customer": customer_id })
        })
        return { data, status };
    } catch (error) {
        if (error.response) toast.error(error.response.data.error.message);
        else if (error.request) toast.error(error.message);
        else toast.error(error.message)
    }
}