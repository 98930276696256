import React, { Suspense, lazy } from 'react';
import './App.css';
import { store } from './redux/stores';
import ScrollToTop from './ScrollToTop';
import { withRouter } from 'react-router-dom';
// import API from '@aws-amplify/api';
// import * as graphqlQuery from "../src/graphql/queries"
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
// import MapCreateTag from './components/layout/MapCreateTag';
// import MapSelectLocation from './components/layout/MapSelectLocation';
import Home from './components/screens/Home'
// import SettingsAccountMyMarketplace from './components/layout/SettingsAccountMyMarketplace';
// import SettingsAccountMyMarketplaceCategories from './components/layout/SettingsAccountMyMarketplaceCategories';
// import SettingsAccountMyMarketplaceProductDetail from './components/layout/SettingsAccountMyMarketplaceProductDetail';
// import SettingsAccountMyMarketplaceCart from './components/layout/SettingsAccountMyMarketplaceCart';

// import CompanyProfile from './components/layout/CompanyProfile'
// import ProfileSettingsAppStoreList from './components/layout/ProfileSettingsAppStoreList'
// import ProfileSettingsMapDisplay from './components/layout/ProfileSettingsMapDisplay';

import Errorpage from './components/layout/Errorpage'
import PageNotFound from './components/layout/PageNotFound'
// import DuckDiamondDetail from './components/layout/DuckDiamondDetail';
// import NotificationToken from './components/layout/NotificationToken'
// import Notify from './components/layout/Notify';
import CreatePdf from './components/layout/CreatePdf'
// import PlaceSection from './components/layout/PlaceSection';
// import ExploreTopics from './components/layout/ExploreTopics';
import jwtDecode from 'jwt-decode';
import axios from './Helper/axios';
import { SET_AUTHENTICATED } from './redux/types';
import { showAppInstallPrompt } from './serviceWorker';
import { fetchSpotifyToken, refreshAccessToken } from './redux/actions/spotify';
import 'react-spring-bottom-sheet/dist/style.css'
import { isMobile } from 'react-device-detect';
import connect from 'react-redux/es/connect/connect';
import { childPermission } from './redux/actions/childPermission'
import { useLayoutEffect } from 'react';
import { useDispatch } from 'react-redux/es/hooks/useDispatch'
import { useSelector } from 'react-redux/es/hooks/useSelector';
import Provider from 'react-redux/lib/components/Provider'
import { fetchAndCreateCustomer } from './redux/actions/payment';
import { fetchParamsFromUrlWithHash, lazyRetry } from './functions';
import { fetchDocusignToken, getUserInfo } from './redux/actions/docusign';
import QuickDetail from './components/layout/QuickDetail';
import VoiceRecording from './components/Common/VoiceRecording'
import { getAddressVerification } from './api/GraphqlApi';
import { fetchAddressVerified } from './redux/actions/Userdetail';
const SearchPeopleProfile = lazy(() => lazyRetry(() => import('./components/layout/SearchPeopleProfile')))
const EcommerceDetail = lazy(() => lazyRetry(() => import('./components/layout/EcommerceDetail')))
const AssetsDetail = lazy(() => lazyRetry(() => import('./components/layout/AssetsDetail')))
const EventDetail = lazy(() => lazyRetry(() => import('./components/layout/EventDetail')))
const MemoryDetail = lazy(() => lazyRetry(() => import('./components/layout/MemoryDetail')))
const TaskDetail = lazy(() => lazyRetry(() => import('./components/layout/TaskDetail')))
const MediaDetail = lazy(() => lazyRetry(() => import('./components/layout/MediaDetail')))
const VideoDetail = lazy(() => lazyRetry(() => import('./components/layout/VideoDetail')))
const SceneDetail = lazy(() => lazyRetry(() => import('./components/layout/SceneDetail')))
const NewsDetail = lazy(() => lazyRetry(() => import('./components/layout/NewsDetail')))
const MusicDetail = lazy(() => lazyRetry(() => import('./components/layout/MusicDetail')))
const TagList = lazy(() => lazyRetry(() => import('./components/layout/TagList')))
const Chat = lazy(() => lazyRetry(() => import('./components/layout/Chat')))
const MapSetting = lazy(() => lazyRetry(() => import('./components/layout/MapSetting')))
const CalendarView = lazy(() => lazyRetry(() => import('./components/layout/CalendarView')))
const Connections = lazy(() => lazyRetry(() => import('./components/layout/Connections')))
const EcommerceHome = lazy(() => lazyRetry(() => import('./components/layout/EcommerceHome')))
const EcommerceCategoryDetail = lazy(() => lazyRetry(() => import('./components/layout/EcommerceCategoryDetail')))
const EcommerceSubcategory = lazy(() => lazyRetry(() => import('./components/layout/EcommerceSubcategory')))
const EcommerceGraph = lazy(() => lazyRetry(() => import('./components/layout/EcommerceGraph')))
const EcommerceCategory = lazy(() => lazyRetry(() => import('./components/layout/EcommerceCategory')))
const SettingsAccount = lazy(() => lazyRetry(() => import('./components/layout/SettingsAccount')))
const EcommerceProduct = lazy(() => lazyRetry(() => import('./components/layout/EcommerceProduct')))
const PaymentSummary = lazy(() => lazyRetry(() => import('./components/layout/PaymentSummary')))
const PaymentCheckout = lazy(() => lazyRetry(() => import('./components/layout/PaymentCheckout')))
const SettingsAccountPlan = lazy(() => lazyRetry(() => import('./components/layout/SettingsAccountPlan')))
const ProfileVerification = lazy(() => lazyRetry(() => import('./components/layout/ProfileVerification')))
const ActiveTransaction = lazy(() => lazyRetry(() => import('./components/layout/ActiveTransaction')))
const PersonalAdvertisement = lazy(() => lazyRetry(() => import('./components/layout/PersonalAdvertisement')))
const AnalyticsOverview = lazy(() => lazyRetry(() => import('./components/layout/AnalyticsOverview')))
const SettingsDashboard = lazy(() => lazyRetry(() => import('./components/layout/SettingsDashboard')))
const Charts = lazy(() => lazyRetry(() => import('./components/layout/Chart')))
const WexxleCloud = lazy(() => lazyRetry(() => import('./components/layout/WexxleCloud')))
const BugTicket = lazy(() => lazyRetry(() => import('./components/layout/BugTicket')))
const BugTicketAnswer = lazy(() => lazyRetry(() => import('./components/layout/BugTicketAnswer')))
const ManageAccounts = lazy(() => lazyRetry(() => import('./components/layout/ManageAccounts')))
const Explore = lazy(() => lazyRetry(() => import('./components/layout/Explore')))
const Commonlayout = lazy(() => lazyRetry(() => import('./components/layout/Commonlayout')))
const SettingsAccountAppLock = lazy(() => lazyRetry(() => import('./components/layout/SettingsAccountAppLock')))
const SettingsCloseAccount = lazy(() => lazyRetry(() => import('./components/layout/SettingsCloseAccount')))
const SettingsReportBug = lazy(() => lazyRetry(() => import('./components/layout/SettingsReportBug')))
const SettingsAccountSelectMode = lazy(() => lazyRetry(() => import('./components/layout/SettingsAccountSelectMode')))
const SettingsMarketSellTo = lazy(() => lazyRetry(() => import('./components/layout/SettingsMarketSellTo')))
const SettingsMarketEditProfile = lazy(() => lazyRetry(() => import('./components/layout/SettingsMarketEditProfile')))
const SettingsPrivacySearchYou = lazy(() => lazyRetry(() => import('./components/layout/SettingsPrivacySearchYou')))
const SettingsPrivacyFollowYou = lazy(() => lazyRetry(() => import('./components/layout/SettingsPrivacyFollowYou')))
const SettingsPrivacyTagYou = lazy(() => lazyRetry(() => import('./components/layout/SettingsPrivacyTagYou')))
const SettingsPrivacyMessageYou = lazy(() => lazyRetry(() => import('./components/layout/SettingsPrivacyMessageYou')))
const SettingsPrivacyRealtimeLocation = lazy(() => lazyRetry(() => import('./components/layout/SettingsPrivacyRealtimeLocation')))
const SettingsConnectedAccounts = lazy(() => lazyRetry(() => import('./components/layout/SettingsConnectedAccounts')))
const AddComment = lazy(() => lazyRetry(() => import('./components/layout/AddComment')))
const Profile_3 = lazy(() => lazyRetry(() => import('./components/layout/Profile_3')))
const Profile = lazy(() => lazyRetry(() => import('./components/layout/Profile')))
const ProfileEdit = lazy(() => lazyRetry(() => import('./components/layout/ProfileEdit')))
const EditProfileSetting = lazy(() => lazyRetry(() => import('./components/layout/EditProfileSetting')))
const ProfileSettings = lazy(() => lazyRetry(() => import('./components/layout/ProfileSettings')))
const KidProfile = lazy(() => lazyRetry(() => import('./components/layout/KidProfile')))
const Permissions = lazy(() => lazyRetry(() => import('./components/layout/Permissions')))
const AddAdult = lazy(() => lazyRetry(() => import('./components/layout/AddAdult')))
const RecycleBin = lazy(() => lazyRetry(() => import('./components/layout/RecycleBin')))
const SpotifyDetail = lazy(() => lazyRetry(() => import('./components/layout/SpotifyDetail')))
const ProfileSettingsAppStore = lazy(() => lazyRetry(() => import('./components/layout/ProfileSettingsAppStore')))
const ProfileSettingsAppStoreDetail = lazy(() => lazyRetry(() => import('./components/layout/ProfileSettingsAppStoreDetail')))
const InAppEmailList = lazy(() => lazyRetry(() => import('./components/layout/InAppEmailList')))
const Notification = lazy(() => lazyRetry(() => import('./components/layout/Notification')))
const ProfileSettingsNavigation = lazy(() => lazyRetry(() => import('./components/layout/ProfileSettingsNavigation')))
const ProfileSettingsCarDetail = lazy(() => lazyRetry(() => import('./components/layout/ProfileSettingsCarDetail')))
const SettingsChangePassword = lazy(() => lazyRetry(() => import('./components/layout/SettingsChangePassword')))
const SettingsAccountNotification = lazy(() => lazyRetry(() => import('./components/layout/SettingsAccountNotification')))
const CustomizeArea = lazy(() => lazyRetry(() => import('./components/layout/CustomizeArea')))
const AlertSetting = lazy(() => lazyRetry(() => import('./components/layout/AlertSetting')))
const CouponPage = lazy(() => lazyRetry(() => import('./components/layout/CouponPage')))
const Privacypolicy = lazy(() => lazyRetry(() => import('./components/layout/privacy-policy')))
const TermAndCondition = lazy(() => lazyRetry(() => import('./components/layout/TermAndCondition')))

const token = localStorage.getItem('token');
if (token) {
	const decodedToken = jwtDecode(token);
	if (decodedToken.exp * 1000 < Date.now()) {
		localStorage.removeItem('token');
		delete axios.defaults.headers.common['Authorization'];
		window.location.href = '/';
	} else {
		store.dispatch({ type: SET_AUTHENTICATED });
		axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
	}
}

const getDetailFromOsLogin = () => {
	const queryParams = new URLSearchParams(window.location.search);
	let userId = queryParams.get('user_id');
	let token = queryParams.get('token');
	let source = queryParams.get('source');
	let email = queryParams.get('email');
	let displayName = queryParams.get('displayName');
	if (userId && token && source && email && displayName) {
		localStorage.setItem('token', token);
		localStorage.setItem('id', userId);
		localStorage.setItem('source', source);
		localStorage.setItem('email', email);
		localStorage.setItem('displayName', displayName);
		return { "user_id": userId, "token": token }
	}
}
// const fetchTagVisibility = async (id) => {
// 	let response = await API.graphql({
// 		query: graphqlQuery.getTag,
// 		variables: { id: id }
// 	})
// 	const { visibility } = response?.data?.getTag || {}
// 	return visibility;
// }


const PrivateRoute = ({ component: Component, ...rest }) => {
	const { id } = rest?.computedMatch?.params || {};
	const session_token = localStorage.getItem('token')
	if (id && !session_token) localStorage.setItem("Default", process.env.REACT_APP_KEY)
	const default_token = localStorage.getItem("Default")
	const iosObject = getDetailFromOsLogin();
	return (
		<Route
			{...rest}
			render={(props) =>
				default_token || session_token || (iosObject && iosObject.user_id && iosObject.token) ?
					<Commonlayout>
						<Component />
					</Commonlayout>
					:
					<Redirect
						to={{
							pathname: "/"
						}}
					/>
			}
		/>
	);
}

const App = (props) => {
	if (!["/term-and-condition", "/privacy-policy", "/"].includes(window.location.pathname)) {
		localStorage.setItem('url', window.location.pathname)
	}
	const dispatch = useDispatch()
	// const { verified } = useSelector(state => state.User);
	const { customer } = useSelector(state => state.Payment);
	const { access_token } = useSelector(state => state.docusign);
	const refreshToken = sessionStorage.getItem('refresh_token')
	const queryParams = new URLSearchParams(window.location.search);
	const code = queryParams.get('code');
	const docu_access_token = sessionStorage.getItem('d_access_token')
	const docu_expire_time = sessionStorage.getItem('d_expires_in')
	const docuObject = fetchParamsFromUrlWithHash() || {}

	useLayoutEffect(() => {
		if (code) dispatch(fetchSpotifyToken(code))
		if (refreshToken) dispatch(refreshAccessToken(refreshToken))
		// eslint-disable-next-line
	}, [code, refreshToken])

	useLayoutEffect(() => {
		if (docuObject && Object.keys(docuObject).length) dispatch(fetchDocusignToken(docuObject));
		else if (docu_access_token && docu_expire_time) dispatch(fetchDocusignToken({ access_token: docu_access_token, expires_time: docu_expire_time }));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [JSON.stringify(docuObject), docu_access_token, docu_expire_time])

	useLayoutEffect(() => {
		if (localStorage.getItem('isKid') === "true") {
			if (props) props.childPermission()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useLayoutEffect(() => {
		if (!customer && localStorage.getItem("email") && localStorage.getItem("displayName")) props.fetchAndCreateCustomer(localStorage.getItem("email"), localStorage.getItem("displayName"))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [localStorage.getItem("email"), localStorage.getItem("displayName")])

	useLayoutEffect(() => {
		if (localStorage.getItem("id")) getaddressverification()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [localStorage.getItem("id")])

	const getaddressverification = async () => {
		let response = await getAddressVerification();
		if (response && response.body && response.body.length) {
			let { status } = response.body[0]
			localStorage.setItem('verification', status)
			props.fetchAddressVerified(status)
		} else {
			localStorage.setItem('verification', "1")
			props.fetchAddressVerified("1")
		}
	}

	useLayoutEffect(() => {
		if (access_token) dispatch(getUserInfo());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [access_token])

	return (
		<div onClick={() => {
			if ((JSON.parse(localStorage.getItem('hideAppInstallPrompt')) === false && isMobile === false) || (localStorage.getItem('hideAppInstallPrompt') === null && isMobile === false)) {
				showAppInstallPrompt()
				localStorage.setItem('hideAppInstallPrompt', true)
			}
		}}>
			{/* <NotificationToken />
			<Notify /> */}
			<Provider store={store}>
				<Suspense
					fallback={
						<div className="overlay-loader">
							<div className="loader dark">Loading...</div>
						</div>
					}
				>
					<Router>
						<ScrollToTop>
							<Switch>
								<Route exact path='/' component={Home} />
								<PrivateRoute path='/dashboard' component={() =>
									<div className={localStorage.getItem("Default") ?
										"mobileDesktopBlock" : "mobileDesktopBlock"}>
										<AddComment />
										<TagList />
									</div>
								} />
								{/* <PrivateRoute path="/duck" component={DuckDiamondDetail} /> */}
								<PrivateRoute path='/explore' component={Explore} />
								{/* <Route path='/map-create-tag' component={MapCreateTag} />
								<Route path='/map-select-location' component={MapSelectLocation} /> */}
								{/* <PrivateRoute exact path='/marketplace' component={SettingsAccountMyMarketplace} />
								<PrivateRoute path='/settings-account-my-marketplace-cat' component={SettingsAccountMyMarketplaceCategories} />
								<PrivateRoute path='/settings-account-my-marketplace-product-detail' component={SettingsAccountMyMarketplaceProductDetail} />
								<PrivateRoute path='/settings-account-my-marketplace-cart' component={SettingsAccountMyMarketplaceCart} /> */}
								<PrivateRoute path='/search-people-profile/:id?' component={SearchPeopleProfile} />
								<PrivateRoute path='/ecommerce-detail/:id/:isconverted?' component={EcommerceDetail} />
								<PrivateRoute path='/asset-detail/:id' component={AssetsDetail} />
								<PrivateRoute path='/event-detail/:id' component={EventDetail} />
								<PrivateRoute path='/memory-detail/:id' component={MemoryDetail}  {...props} />
								<PrivateRoute path='/task-detail/:id' component={TaskDetail} />
								<PrivateRoute path='/media-detail/:id' component={MediaDetail} />
								<PrivateRoute path='/video-detail/:id' component={VideoDetail} />
								<PrivateRoute path='/scene-detail/:id' component={SceneDetail} />
								<PrivateRoute path='/quick-detail/:id' component={QuickDetail} />
								<PrivateRoute path='/news-detail/:id' component={NewsDetail} />
								<PrivateRoute path='/music-detail/:id' component={MusicDetail} />
								<PrivateRoute path='/spotify-detail/:id' component={SpotifyDetail} />
								<PrivateRoute path='/app-store' component={ProfileSettingsAppStore} />
								<PrivateRoute path='/in-app-email-list' component={InAppEmailList} />
								<Route exact path='/privacy-policy' component={Privacypolicy} />
								<Route exact path='/term-and-condition' component={TermAndCondition} />
								<PrivateRoute path='/chart' component={Charts} />
								<PrivateRoute path='/app-store-detail/:id' exact component={ProfileSettingsAppStoreDetail} />
								<PrivateRoute path='/wexxle-cloud' component={WexxleCloud} />
								<PrivateRoute path='/help-support' component={BugTicket} />
								<Route path='/bug-ticket-answer' component={BugTicketAnswer} />
								<PrivateRoute path='/user-profile-current-manage-accounts' component={ManageAccounts} />
								<PrivateRoute path='/user-profile-current-kid-profile' component={KidProfile} />
								<Route path='/permissions' component={Permissions} />
								<PrivateRoute path='/user-profile-current-add-adult' component={AddAdult} />
								<PrivateRoute path='/personal-advertisement' component={PersonalAdvertisement} />
								<Route path='/analytics-overview' component={AnalyticsOverview} />
								<PrivateRoute path='/notification' component={Notification} />
								<PrivateRoute exact path='/ecommerce-home/category' component={EcommerceHome} />
								<PrivateRoute path={['/ecommerce-home/category/:key_name', '/ecommerce-home/subcategory/:subcategory_name']} component={EcommerceProduct} />
								<PrivateRoute path='/ecommerce-home/subcategory' component={EcommerceSubcategory} />
								<PrivateRoute path='/ecommerce-home/allcategory' component={EcommerceCategory} />
								<PrivateRoute path='/ecommerce-home/categorydetail/:key_name' component={EcommerceCategoryDetail} />
								<PrivateRoute path='/marketplace/transaction-graph' component={EcommerceGraph} />
								{/* <PrivateRoute path='/company-profile' component={CompanyProfile} />
								<PrivateRoute path='/app-store-list' component={ProfileSettingsAppStoreList} /> */}
								<PrivateRoute path='/coupon' component={CouponPage} />
								<PrivateRoute path='/profile' component={Profile_3} />
								<PrivateRoute path='/places' component={Profile} />
								<PrivateRoute path='/alert-setting' component={AlertSetting} />
								<PrivateRoute path='/user-profile-current-edit' component={ProfileEdit} />
								<PrivateRoute path='/user-profile-verification' component={ProfileVerification} />
								<PrivateRoute path='/user-edit-current-profile-setting' component={EditProfileSetting} />
								<PrivateRoute path='/profile-settings' component={ProfileSettings} />
								{/* <PrivateRoute path='/profile-setting-mapdisplay' component={ProfileSettingsMapDisplay} /> */}
								<PrivateRoute path='/navigation' component={ProfileSettingsNavigation} />
								<PrivateRoute path='/profile-setting-cardetail' component={ProfileSettingsCarDetail} />
								<PrivateRoute path='/chat' component={Chat} />

								<PrivateRoute path='/map-setting' component={MapSetting} />
								<PrivateRoute path='/setting-dashboard' component={SettingsDashboard} />
								<PrivateRoute path='/calendar-view' component={CalendarView} />
								<PrivateRoute path='/connection' component={Connections} />
								<PrivateRoute path={["/setting/account", "/setting/account/membership", "/setting/account/manageAccounts", '/setting/marketplace', '/setting/privacy', '/setting/finance', '/setting/personalAdvertisement', '/setting']} exact component={SettingsAccount} />
								<PrivateRoute path="/user-profile-current-connected_account" component={SettingsConnectedAccounts} />
								<PrivateRoute path="/user-profile-current-setting-account-notification" component={SettingsAccountNotification} />
								<PrivateRoute path="/user-profile-current-app_lock" component={SettingsAccountAppLock} />
								<PrivateRoute path="/user-profile-current-close_account" component={SettingsCloseAccount} />
								<PrivateRoute path="/user-profile-current-plan" component={SettingsAccountPlan} />
								<PrivateRoute path="/user-profile-current-select_mode" component={SettingsAccountSelectMode} />
								<PrivateRoute path="/user-profile-current-sell_to" component={SettingsMarketSellTo} />
								<PrivateRoute path="/user-profile-current-market-edit-profile" component={SettingsMarketEditProfile} />
								<PrivateRoute path="/user-profile-current-search_you" component={SettingsPrivacySearchYou} />
								<PrivateRoute path="/user-profile-current-follow_you" component={SettingsPrivacyFollowYou} />
								<PrivateRoute path="/user-profile-current-tag_you" component={SettingsPrivacyTagYou} />
								<PrivateRoute path="/user-profile-current-message_you" component={SettingsPrivacyMessageYou} />
								<PrivateRoute path="/user-profile-current-realtime_location" component={SettingsPrivacyRealtimeLocation} />
								<PrivateRoute path='/setting-reportbug' component={SettingsReportBug} />
								<PrivateRoute path='/vr' component={VoiceRecording} />
								{/* <PrivateRoute path='/place-section' component={PlaceSection} /> */}
								<PrivateRoute path='/user-profile-setting-change-password' component={SettingsChangePassword} />
								<PrivateRoute path='/payment-summary' component={PaymentSummary} />
								<PrivateRoute path="/payment-checkout" component={PaymentCheckout} />
								<PrivateRoute path='/customize-area' component={CustomizeArea} />
								{/* <PrivateRoute path='/Explore-topics' component={ExploreTopics} /> */}
								<PrivateRoute path="/active-transaction" component={ActiveTransaction} />
								<PrivateRoute path="/recycle-bin" component={RecycleBin} />
								<Route path="/create-pdf" component={CreatePdf} />
								<Route path="/error" component={Errorpage} />
								<Route component={PageNotFound} />

							</Switch>
						</ScrollToTop>
					</Router>
				</Suspense>
			</Provider>
		</div>
	)
}

//export default App;
const mapDispatchToProps = {
	childPermission,
	fetchSpotifyToken,
	fetchAddressVerified,
	fetchAndCreateCustomer


}
const mapStateToProps = (state) => ({
	Profilepic: state.Preview,
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(App))
