import { GETUSERPRIVACYSETTING, CHANGEUSERPRIVACYSETTING, LoadUI, SetError, Reset } from '../types'

const initialstate = {
    loader: false,
    Usersetting: {},
    obj: {},
    checked: false
}

export const settingreducer = (state = initialstate, action) => {
    switch (action.type) {
        case SetError:
            return {
                ...state,
                loader: false
            }
        case LoadUI:
            return {
                ...state,
                loader: true
            }
        case GETUSERPRIVACYSETTING:
            return {
                ...state,
                Usersetting: action.payload,
                loader: false
            }
        case CHANGEUSERPRIVACYSETTING:
            return {
                ...state,
                Usersetting: action.payload,
                loader: false
            }
        case Reset:
            return {
                ...state,
                Usersetting: {},
                loader: false,
                checked: false
            }
        default:
            return state;
    }
}