import React from 'react';
import {Link} from 'react-router-dom';
import {toast} from 'react-toastify/dist/react-toastify.esm';

import OtpInput from 'react-otp-input';
import $ from 'jquery/dist/jquery'
//layout
// import Otp from '../layout/Otp';
// import PhoneInput from 'react-phone-number-input'
import { otpVerify } from '../../api/LoginSignupApi';
import NewPassword from '../layout/NewPassword';

import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

//api
import { passwordRecovery } from '../../api/LoginSignupApi';
import { isValidPhoneNumber } from 'react-phone-number-input'
import { countryCode, removeClass } from '../../Helper/commonFunction';
import { activate_User } from '../../api/GraphqlApi';
class PasswordRecovery extends React.PureComponent {
    constructor(props) {
        super(props)
        this.token = localStorage.getItem("Default")
        this.state = {
            phone: '',
            loading: false,
            otp: '',
            seconds: 0,
            minutes: 1,
            errors: {},
            hasErrored: false,
            isDisabled: false,
            resend: false,
            otpVerified: false,
            isoCode: "",
            recoveryScreen: 'phone'
        }
    }

    checkPhone = async () => {
        let phone_number = this.state.phone
        if (!phone_number) {
            this.setState({
                ...this.state,
                loading: false,
                errors: {
                    ...this.state.errors,
                    "phone": "Please Enter Phone Number."
                }
            })
            return;
        }
        if (!isValidPhoneNumber(phone_number)) {
            this.setState({
                ...this.state,
                loading: false,
                errors: {
                    ...this.state.errors,
                    "phone": "Invalid Phone Number."
                }
            })
            return;
        }
        const response = await activate_User(phone_number, "check_phone_number");
        if (response) { var { statusCode } = response }
        this.setState(prev => ({
            ...prev,
            ...(statusCode === 400 ?
                { errors: {} }
                : (statusCode === 200) ?
                    { errors: { ...prev.errors, "phone": "Phone Number Not exists." }, loading: false, } :
                    { errors: { ...prev.errors, "phone": "Something Went Wrong." }, loading: false, })
        }))
    }

    handlePasswordRecovery = async () => {
        var params = {};
        params.phone = this.state.phone;
        params.isregistered = false;
        await this.setState({
            loading: true,
            seconds: 0,
            minutes: 1,
            otpVerified: false,
            otp: "",
            hasErrored: false
        }, () => removeClass());
        await this.checkPhone();
        if (Object.keys(this.state.errors).length) return false;
        try {
            let res = await passwordRecovery(params);
            if (res && res.success === true) {
                this.OtpTimer()
                toast.success("Check your device for the OTP!");
                $("#exampleModal").modal("show")
                this.setState({ ...this.state, resend: true });
            } else if (res && res.error) {
                this.setState({
                    ...this.state,
                    errors: {
                        ...this.state.errors, ...res.error
                    }
                })
            } else if (res && res.message) {
                var errors = this.state.errors;
                errors.phone = res.message
                this.setState({ errors });
            }
            this.setState({ loading: false });
        } catch (error) {
            toast.error(error.message)
            console.log(error.message)
            this.setState({ loading: false });
        }
    }

    handleChange = (otp) => this.setState({ otp });

    getBack = () => {
        this.setState({
            phone: '',
            loading: false,
            errors: {},
            recoveryScreen: 'phone'
        });
    }

    componentDidUpdate(_, prevState) {
        if (prevState.otp !== this.state.otp) {
            if (this.state.otp.length === 4) {
                this.OtpVerification(this.state.otp, this.state.phone)
            }
        }
    }

    isoCountryCode = async () => {
        let response = await countryCode();
        this.setState({
            ...this.state,
            isoCode: response ? response : 'us'
        })
    }

    componentDidMount() {
        this.isoCountryCode()
    }

    OtpVerification = async (otp, phone_number) => {
        try {
            this.setState({ ...this.state, hasErrored: false, isDisabled: true })
            let res = await otpVerify({ "otp": otp, "phone_number": phone_number });
            if (res && res.success === true) {
                toast(res.message);
                $("#exampleModal").modal("hide")
                this.setState({
                    ...this.state,
                    recoveryScreen: "Password",
                    otpVerified: true,
                    otp: "",
                    errors: {}
                })
            } else if (res && res.message) {
                toast.error(res.message)
                this.setState({
                    ...this.state,
                    isDisabled: false,
                    hasErrored: true
                })
            }
        } catch (error) {
            console.log(error.message)
            toast.error(error.message)
            this.setState({
                ...this.state,
                isDisabled: false,
                hasErrored: false
            })
        }
    }

    // Otp Timer For Resend Otp
    OtpTimer = () => {
        let OtpInterval = setInterval(() => {
            if (this.state.otpVerified) {
                clearInterval(OtpInterval)
                this.setState({ resend: false })
            }
            if (this.state.seconds > 0) {
                this.setState({
                    seconds: this.state.seconds - 1
                })
            } else if (this.state.seconds === 0) {
                if (this.state.minutes === 0) {
                    clearInterval(OtpInterval)
                    this.setState({ resend: false })
                } else {
                    this.setState({
                        minutes: this.state.minutes - 1,
                        seconds: 59
                    })
                }
            }
        }, 1000);
    }



    render() {
        const { errors, isoCode, loading } = this.state;
        console.log(loading)
        return (
            <>
                {(this.state.recoveryScreen === 'phone') ?
                    <div className="onboarding-card-container PasswordRecovery">
                        <div className="onboarding-card-header">
                            <figure>
                                <img src={process.env.PUBLIC_URL + "/images/logo.svg"} alt="logo" height="45" />
                            </figure>
                            <h4 className="onboarding-card-header__title">wexxle</h4>
                        </div>
                        <div className="onboarding-card PasswordRecovery_page comon_space">
                            {
                                this.token ? <div className="backAro" onClick={() => { this.props.LoginSignupToggle('login') }}></div> : <div className="backAro d-md-none" onClick={() => { this.props.LoginSignupToggle('login') }}></div>
                            }
                            <span className="onboarding-card__handle" onClick={() => this.token ? this.props.isClosedToggle() : this.props.isClosedSplashToggle()}></span>
                            <div className="onboarding-card__logo">
                                <Link to="" className="anchor" style={{ pointerEvents: "none" }}>
                                    <img src={process.env.PUBLIC_URL + "/images/logo.svg"} alt="logo" height="30" />
                                    <span className="logoText">wexxle</span>
                                </Link>
                            </div>

                            <div className="welcome-text-desktop pass_lg_scren">
                                <h2>Password Recovery</h2>
                                <p>Enter your Phone Number to recover your password</p>
                            </div>

                            <div className="welcome-text">
                                <h1>Password Recovery</h1>
                                <p>Enter your Phone Number to recover your password</p>
                            </div>

                            <form action="" className="custom-form">
                                <div className="onlyFilleds">
                                    <div className="form-group common_label_mb mb-20 pass_phn_filed">
                                        <label className="d-none d-md-block">Phone </label>
                                        <div className="filledWithIcon">
                                            {/* <span className="inputIcons userIcon">&nbsp;</span> */}
                                            {(Object.keys(errors).length) > 0 &&
                                                <>
                                                    {errors.phone ?
                                                        <span className="inputIconsRight validateCross">
                                                            <span className="validTooltip requiredMsg">{errors.phone}</span>
                                                        </span>
                                                        :
                                                        <span className="inputIconsRight validateCheck">&nbsp;</span>
                                                    }
                                                </>
                                            }
                                            {/* <input id="email" placeholder="Email" type="email" className="form-control" onChange={(e) => this.setState({
                                                ...this.state,
                                                email: e.target.value,
                                                errors: {
                                                    email: ""
                                                }
                                            })} /> */}
                                            {/* <PhoneInput
                                                className="form-control"
                                                defaultCountry={"IN"}
                                                international={true}
                                                placeholder="Enter Phone Number"
                                                limitMaxLength={true}
                                                value={this.state.phone}
                                                onChange={(e) => this.setState({
                                                    ...this.state,
                                                    phone: e ? e : "",
                                                    errors: {
                                                        ...this.state.errors,
                                                        phone: ""
                                                    }
                                                })}
                                            /> */}


                                            <PhoneInput
                                                country={isoCode}
                                                masks={{ in: '(...) ...-....' }}
                                                enableAreaCodes={true}
                                                placeholder="Enter Phone Number"
                                                inputClass={'com_phone_filed'}
                                                value={this.state.phone}
                                                onChange={(e) => this.setState({
                                                    ...this.state,
                                                    phone: e ? "+" + e : "",
                                                    errors: {
                                                        ...this.state.errors,
                                                        phone: ""
                                                    }
                                                })}
                                            />

                                        </div>
                                    </div>

                                </div>
                                <div className="form-group text-center msg_recovery_mble">
                                    <p>Make sure you already confirmed your phone. By pressing below button, you’ll get a Recovery Code on your phone. Input this code on the next page to reset your password.</p>
                                </div>
                                <div className="form-group create_btn">
                                    <input type="button" disabled={this.state.loading ? true : false} value={this.state.loading ? "Loading" : "Send OTP"} className="btn btn-success btn-block" onClick={this.handlePasswordRecovery} />
                                </div>
                            </form>
                            <div className="onboardingQrcode passw_recov_qr" style={{
                                pointerEvents: this.state.loading ? "none" : ""
                            }}>
                                <div className="onboardingQrcode__inside" data-toggle="modal" data-target="#qrCodeModal">
                                    <span className="">Or Scan QR Code</span>
                                    <div className="qrcodeImg">
                                        <img src={process.env.PUBLIC_URL + "/images/qrcode.svg"} alt="QR" />
                                    </div>
                                </div>
                                <div className="onboardingQrcode__mobileOnly">
                                    <span>Scan QR Code</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    <NewPassword token={this.state.otp} getBack={this.getBack} phone={this.state.phone} LoginSignupToggle={this.props.LoginSignupToggle} showHidePassword={this.props.showHidePassword} showPassword={this.props.showPassword} showConfirmPassword={this.props.showConfirmPassword} />
                    // <Otp Resendmail={this.handlePasswordRecovery} getBack={this.getBack} phone={this.state.phone} LoginSignupToggle={this.props.LoginSignupToggle} />
                }


                <div className="modal fade otp_verify" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content otp_box_fill">
                            <div className="modal-header d-block text-center">
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => this.setState({
                                    ...this.state,
                                    otpVerified: true
                                })}>
                                </button>
                                <h5 className="modal-title" id="exampleModalLabel">Recovery</h5>
                                <p>Enter the received recovery code</p>
                                {
                                    this.state.resend ? <><p><span>{this.state.minutes < 10 ? "0" + this.state.minutes : this.state.minutes}</span>&nbsp;:&nbsp;<span>{this.state.seconds < 10 ? "0" + this.state.seconds : this.state.seconds}</span></p></> : <a href="void:(0)" style={this.state.signUpLoading ? { pointerEvents: "none" } : {}} onClick={(e) => {
                                        e.preventDefault()
                                        this.setState({ ...this.state, otp: "", hasErrored: false }, () => this.handlePasswordRecovery())
                                    }}><img src={process.env.PUBLIC_URL + "/images/resend-icon.png"} alt="resend-icon"></img>Resend</a>
                                }

                            </div>
                            <div className="modal-body">
                                <OtpInput
                                    isInputNum={true}
                                    value={this.state.otp}
                                    onChange={this.handleChange}
                                    numInputs={4}
                                    containerStyle={"otpBox"}
                                    inputStyle={"otpInput"}
                                    separator={<span>&nbsp;</span>}
                                    isDisabled={this.state.isDisabled}
                                    hasErrored={this.state.hasErrored}
                                    errorStyle={"otpInputError"}
                                    focusStyle={"focusblueclr"}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
export default PasswordRecovery;