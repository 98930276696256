import { FETCHDOCUSIGNTOKEN, DOCUSIGNUSER } from "../types";

const initialState = {
    access_token: null,
    expires_in: "",
    account: {
        account_id: "",
        is_default: false,
        account_name: "",
        base_uri: ""
    }
}

export default function docuSignReducer(state = initialState, action) {
    switch (action.type) {
        case FETCHDOCUSIGNTOKEN:
            const { payload } = action
            const { access_token, expires_time } = payload;
            sessionStorage.setItem("d_access_token", access_token)
            sessionStorage.setItem('d_expires_in', expires_time || Date.now())
            return {
                ...state,
                access_token,
                expires_in: expires_time || Date.now()
            };
        case DOCUSIGNUSER:
            return {
                ...state,
                account: { ...action.payload }
            }
        default:
            return state;
    }
}