import {toast} from 'react-toastify/dist/react-toastify.esm';
toast.configure();
export const Customerror = (statuscode, error) => {
    switch (true) {
        case (statuscode === 400):
            if (error) toast.error(error);
            else toast.error("Bad Request");
            break;
        case (statuscode === 401):
            if (error) toast.error(error);
            else toast.error("Unauthorized Error");
            break;
        case (statuscode === 403):
            if (error) toast.error(error)
            else toast.error("Wrong Credential");
            break;
        case (statuscode === 404):
            if (error) toast.error(error);
            else toast.error("Page Not Found")
            break;
        case (statuscode >= 500):
            toast.error("Server Error")
            break;
        default:
            toast.error("Something Went Wrong")
            break;
    }
}
