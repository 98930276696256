import { EXPLORETAB } from "../types";

let initailstate = {
    activetab: "search people"
}

export const commonReducer = (state = initailstate, { type, payload }) => {
    switch (type) {
        case EXPLORETAB:
            return {
                ...state,
                activetab: payload
            }
        default:
            return state;
    }
}
