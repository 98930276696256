import { PREVIEWIMAGE } from '../types'

let initialstate = {
    profile_pic: {
        key: "",
        url: "",
        Isloading: true
    }
}

export const Profilereducer = (state = initialstate, action) => {
    switch (action.type) {
        case PREVIEWIMAGE:
            return {
                profile_pic: {
                    key: action.payload.Profileimage.key,
                    url: action.payload.Profileimage.url,
                    Isloading: false
                }
            }
        default:
            return state
    }
}