/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const newsfeedfriend = /* GraphQL */ `
  query Newsfeedfriend($user_id: Int) {
    newsfeedfriend(user_id: $user_id)
  }
`;
export const user_detail = /* GraphQL */ `
  query User_detail($input: user_detail) {
    user_detail(input: $input)
  }
`;
export const friend_operations = /* GraphQL */ `
  query Friend_operations(
    $user_id: Int!
    $friend_id: Int!
    $connection: Int!
    $unfriend: Int!
  ) {
    friend_operations(
      user_id: $user_id
      friend_id: $friend_id
      connection: $connection
      unfriend: $unfriend
    )
  }
`;
export const accept_reject_cancel_connection = /* GraphQL */ `
  query Accept_reject_cancel_connection(
    $user_id: Int!
    $friend_id: Int!
    $connection: Int
    $operation: String!
  ) {
    accept_reject_cancel_connection(
      user_id: $user_id
      friend_id: $friend_id
      connection: $connection
      operation: $operation
    )
  }
`;
export const connection_list = /* GraphQL */ `
  query Connection_list(
    $user_id: Int!
    $connection: Int
    $operation: String!
    $limit: Int
    $page_no: Int
    $search: String
  ) {
    connection_list(
      user_id: $user_id
      connection: $connection
      operation: $operation
      limit: $limit
      page_no: $page_no
      search: $search
    )
  }
`;
export const BlockAndWatch = /* GraphQL */ `
  query BlockAndWatch($input: BlockAndWatch) {
    BlockAndWatch(input: $input)
  }
`;
export const MembershipPlans = /* GraphQL */ `
  query MembershipPlans($input: MembershipPlans) {
    MembershipPlans(input: $input)
  }
`;
export const userActivity = /* GraphQL */ `
  query UserActivity(
    $user_id: Int!
    $operation: String!
    $activity_id: String
    $activity_type: String
    $activity_size: String
    $user_type: Int
  ) {
    userActivity(
      user_id: $user_id
      operation: $operation
      activity_id: $activity_id
      activity_type: $activity_type
      activity_size: $activity_size
      user_type: $user_type
    )
  }
`;
export const user_payment_details = /* GraphQL */ `
  query User_payment_details($input: user_payment_details) {
    user_payment_details(input: $input)
  }
`;
export const stripe_subscription = /* GraphQL */ `
  query Stripe_subscription(
    $operation: String!
    $paymentMethodId: String
    $customerId: String
    $priceId: String
    $subscriptionId: String
    $user_id: Int
    $plan_id: Int
    $trial_period_days: Int
  ) {
    stripe_subscription(
      operation: $operation
      paymentMethodId: $paymentMethodId
      customerId: $customerId
      priceId: $priceId
      subscriptionId: $subscriptionId
      user_id: $user_id
      plan_id: $plan_id
      trial_period_days: $trial_period_days
    )
  }
`;
export const tag_creation = /* GraphQL */ `
  query Tag_creation($input: tag_creation) {
    tag_creation(input: $input)
  }
`;
export const Map = /* GraphQL */ `
  query Map($input: Map) {
    Map(input: $input)
  }
`;
export const AppStore = /* GraphQL */ `
  query AppStore($input: AppStore) {
    AppStore(input: $input)
  }
`;
export const Notifications = /* GraphQL */ `
  query Notifications($input: Notifications) {
    Notifications(input: $input)
  }
`;
export const MarketPlace = /* GraphQL */ `
  query MarketPlace($input: MarketPlace) {
    MarketPlace(input: $input)
  }
`;
export const UserWallet = /* GraphQL */ `
  query UserWallet($input: UserWallet) {
    UserWallet(input: $input)
  }
`;
export const UserSettings = /* GraphQL */ `
  query UserSettings($input: UserSettings) {
    UserSettings(input: $input)
  }
`;
export const extrasDynamoDB = /* GraphQL */ `
  query ExtrasDynamoDB($input: extrasDynamoDB) {
    extrasDynamoDB(input: $input)
  }
`;
export const searchTags = /* GraphQL */ `
  query SearchTags(
    $filter: SearchableTagFilterInput
    $sort: [SearchableTagSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableTagAggregationInput]
  ) {
    searchTags(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        user_id
        type
        title
        content
        addresses
        visibility
        comments
        tagged_user_id
        memory_date
        occasion
        category
        sub_category
        price
        asset_price
        sold_price
        quantity
        available_for_sale
        auto_attach
        custom_field
        start_date
        end_date
        task_date
        task_end_date
        media
        repeat
        custom_start
        custom_repeat
        custom_repeat_value
        custom_days
        custom_end
        public_distance
        public_start
        public_end
        latitude
        longitude
        altitude
        purchasing_date
        createdAt
        updatedAt
        tag_attach_id
        shared_tag_id {
          id
          user_id
          type
          title
          content
          addresses
          visibility
          comments
          tagged_user_id
          memory_date
          occasion
          category
          sub_category
          price
          asset_price
          sold_price
          quantity
          available_for_sale
          auto_attach
          custom_field
          start_date
          end_date
          task_date
          task_end_date
          media
          repeat
          custom_start
          custom_repeat
          custom_repeat_value
          custom_days
          custom_end
          public_distance
          public_start
          public_end
          latitude
          longitude
          altitude
          purchasing_date
          createdAt
          updatedAt
          tag_attach_id
          tag_level
          status
          floor
          room
          is_sold
          sold_date
          scene_tags
          is_abvertising_paid
          is_admin_approved
          spotify
          parent_id
          top_parent_id
          is_public
          is_tc_accepted
          is_master_attached
          full_day
          attached_with_master
          tagShared_tagsId
          tagChild_tagsId
          tagAttached_tag_idId
          __typename
        }
        shared_tags {
          nextToken
          __typename
        }
        parent_tag_id {
          id
          user_id
          type
          title
          content
          addresses
          visibility
          comments
          tagged_user_id
          memory_date
          occasion
          category
          sub_category
          price
          asset_price
          sold_price
          quantity
          available_for_sale
          auto_attach
          custom_field
          start_date
          end_date
          task_date
          task_end_date
          media
          repeat
          custom_start
          custom_repeat
          custom_repeat_value
          custom_days
          custom_end
          public_distance
          public_start
          public_end
          latitude
          longitude
          altitude
          purchasing_date
          createdAt
          updatedAt
          tag_attach_id
          tag_level
          status
          floor
          room
          is_sold
          sold_date
          scene_tags
          is_abvertising_paid
          is_admin_approved
          spotify
          parent_id
          top_parent_id
          is_public
          is_tc_accepted
          is_master_attached
          full_day
          attached_with_master
          tagShared_tagsId
          tagChild_tagsId
          tagAttached_tag_idId
          __typename
        }
        child_tags {
          nextToken
          __typename
        }
        tag_level
        comment_list {
          items {
            comment_type
            content
            createdAt
            id
            tagComment_listId
            updatedAt
            user_id
            status
            like_dislike_list {
              items {
                diamond
                duck
                user_id
              }
              nextToken
            }
          }
          nextToken
          __typename
        }
        like_dislike_list {
           items {
            diamond
            duck
            user_id
          }
          nextToken
          __typename
        }
        tag_messages {
          nextToken
          __typename
        }
        status
        floor
        room
        is_sold
        sold_date
        scene_tags
        is_abvertising_paid
        is_admin_approved
        analytics_user_data {
          nextToken
          __typename
        }
        spotify
        parent_id
        top_parent_id
        is_public
        is_tc_accepted
        is_master_attached
        attached_tag_id {
          nextToken
          __typename
        }
        full_day
        attached_with_master
        tagShared_tagsId
        tagChild_tagsId
        tagAttached_tag_idId
        __typename
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
              __typename
            }
          }
        }
        __typename
      }
      __typename
    }
  }
`;
export const searchTagAnalyticsUserData = /* GraphQL */ `
  query SearchTagAnalyticsUserData(
    $filter: SearchableTagAnalyticsUserDataFilterInput
    $sort: [SearchableTagAnalyticsUserDataSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableTagAnalyticsUserDataAggregationInput]
  ) {
    searchTagAnalyticsUserData(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        tag_id {
          id
          user_id
          type
          title
          content
          addresses
          visibility
          comments
          tagged_user_id
          memory_date
          occasion
          category
          sub_category
          price
          asset_price
          sold_price
          quantity
          available_for_sale
          auto_attach
          custom_field
          start_date
          end_date
          task_date
          task_end_date
          media
          repeat
          custom_start
          custom_repeat
          custom_repeat_value
          custom_days
          custom_end
          public_distance
          public_start
          public_end
          latitude
          longitude
          altitude
          purchasing_date
          createdAt
          updatedAt
          tag_attach_id
          tag_level
          status
          floor
          room
          is_sold
          sold_date
          scene_tags
          is_abvertising_paid
          is_admin_approved
          spotify
          parent_id
          top_parent_id
          is_public
          is_tc_accepted
          is_master_attached
          full_day
          attached_with_master
          tagShared_tagsId
          tagChild_tagsId
          tagAttached_tag_idId
          __typename
        }
        user_id
        ipAddress
        browser
        deviceType
        is_view_only
        createdAt
        updatedAt
        tagAnalytics_user_dataId
        __typename
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
              __typename
            }
          }
        }
        __typename
      }
      __typename
    }
  }
`;
export const searchComments = /* GraphQL */ `
  query SearchComments(
    $filter: SearchableCommentFilterInput
    $sort: [SearchableCommentSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableCommentAggregationInput]
  ) {
    searchComments(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        tag_id {
          id
          user_id
          type
          title
          content
          addresses
          visibility
          comments
          tagged_user_id
          memory_date
          occasion
          category
          sub_category
          price
          asset_price
          sold_price
          quantity
          available_for_sale
          auto_attach
          custom_field
          start_date
          end_date
          task_date
          task_end_date
          media
          repeat
          custom_start
          custom_repeat
          custom_repeat_value
          custom_days
          custom_end
          public_distance
          public_start
          public_end
          latitude
          longitude
          altitude
          purchasing_date
          createdAt
          updatedAt
          tag_attach_id
          tag_level
          status
          floor
          room
          is_sold
          sold_date
          scene_tags
          is_abvertising_paid
          is_admin_approved
          spotify
          parent_id
          top_parent_id
          is_public
          is_tc_accepted
          is_master_attached
          full_day
          attached_with_master
          tagShared_tagsId
          tagChild_tagsId
          tagAttached_tag_idId
          __typename
        }
        user_id
        content
        comment_type
        like_dislike_list {
          items {
            diamond
            duck
            user_id
          }
          nextToken
          __typename
        }
        status
        createdAt
        updatedAt
        tagComment_listId
        __typename
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
              __typename
            }
          }
        }
        __typename
      }
      __typename
    }
  }
`;
export const searchLikeDislikes = /* GraphQL */ `
  query SearchLikeDislikes(
    $filter: SearchablelikeDislikeFilterInput
    $sort: [SearchablelikeDislikeSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchablelikeDislikeAggregationInput]
  ) {
    searchLikeDislikes(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        unique_id
        tag_id {
          id
          user_id
          type
          title
          content
          addresses
          visibility
          comments
          tagged_user_id
          memory_date
          occasion
          category
          sub_category
          price
          asset_price
          sold_price
          quantity
          available_for_sale
          auto_attach
          custom_field
          start_date
          end_date
          task_date
          task_end_date
          media
          repeat
          custom_start
          custom_repeat
          custom_repeat_value
          custom_days
          custom_end
          public_distance
          public_start
          public_end
          latitude
          longitude
          altitude
          purchasing_date
          createdAt
          updatedAt
          tag_attach_id
          tag_level
          status
          floor
          room
          is_sold
          sold_date
          scene_tags
          is_abvertising_paid
          is_admin_approved
          spotify
          parent_id
          top_parent_id
          is_public
          is_tc_accepted
          is_master_attached
          full_day
          attached_with_master
          tagShared_tagsId
          tagChild_tagsId
          tagAttached_tag_idId
          __typename
        }
        like_dislike_id {
          id
          user_id
          content
          comment_type
          status
          createdAt
          updatedAt
          tagComment_listId
          __typename
        }
        type
        user_id
        diamond
        duck
        createdAt
        updatedAt
        tagLike_dislike_listId
        commentLike_dislike_listId
        __typename
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
              __typename
            }
          }
        }
        __typename
      }
      __typename
    }
  }
`;
export const searchConversations = /* GraphQL */ `
  query SearchConversations(
    $filter: SearchableConversationFilterInput
    $sort: [SearchableConversationSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableConversationAggregationInput]
  ) {
    searchConversations(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        sender
        recipient
        messages {
          items {
            content
            conversationMessagesId
            createdAt
            id
            is_readed
            media
            message_timestamp
            message_from
            recipient
            sender
            updatedAt
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        message_from
        tag_id
        status
        __typename
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
              __typename
            }
          }
        }
        __typename
      }
      __typename
    }
  }
`;
export const searchMessages = /* GraphQL */ `
  query SearchMessages(
    $filter: SearchableMessagesFilterInput
    $sort: [SearchableMessagesSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableMessagesAggregationInput]
  ) {
    searchMessages(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        conversation_id {
          id
          sender
          recipient
          createdAt
          updatedAt
          message_from
          tag_id
          status
          __typename
        }
        sender
        content
        recipient
        media
        is_readed
        message_timestamp
        createdAt
        message_from
        tag_id
        status
        updatedAt
        conversationMessagesId
        __typename
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
              __typename
            }
          }
        }
        __typename
      }
      __typename
    }
  }
`;
export const searchTagMessages = /* GraphQL */ `
  query SearchTagMessages(
    $filter: SearchabletagMessageFilterInput
    $sort: [SearchabletagMessageSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchabletagMessageAggregationInput]
  ) {
    searchTagMessages(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        sender
        recipient
        content
        parent_id {
          id
          sender
          recipient
          content
          createdAt
          updatedAt
          tagTag_messagesId
          tagMessageRepliesId
          __typename
        }
        replies {
          nextToken
          __typename
        }
        tag_id {
          id
          user_id
          type
          title
          content
          addresses
          visibility
          comments
          tagged_user_id
          memory_date
          occasion
          category
          sub_category
          price
          asset_price
          sold_price
          quantity
          available_for_sale
          auto_attach
          custom_field
          start_date
          end_date
          task_date
          task_end_date
          media
          repeat
          custom_start
          custom_repeat
          custom_repeat_value
          custom_days
          custom_end
          public_distance
          public_start
          public_end
          latitude
          longitude
          altitude
          purchasing_date
          createdAt
          updatedAt
          tag_attach_id
          tag_level
          status
          floor
          room
          is_sold
          sold_date
          scene_tags
          is_abvertising_paid
          is_admin_approved
          spotify
          parent_id
          top_parent_id
          is_public
          is_tc_accepted
          is_master_attached
          full_day
          attached_with_master
          tagShared_tagsId
          tagChild_tagsId
          tagAttached_tag_idId
          __typename
        }
        createdAt
        updatedAt
        tagTag_messagesId
        tagMessageRepliesId
        __typename
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
              __typename
            }
          }
        }
        __typename
      }
      __typename
    }
  }
`;
export const searchMaps = /* GraphQL */ `
  query SearchMaps(
    $filter: SearchableMapsFilterInput
    $sort: [SearchableMapsSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableMapsAggregationInput]
  ) {
    searchMaps(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        item_id
        item_type
        longitude
        latitude
        status
        createdAt
        updatedAt
        __typename
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
              __typename
            }
          }
        }
        __typename
      }
      __typename
    }
  }
`;
export const searchAppReviews = /* GraphQL */ `
  query SearchAppReviews(
    $filter: SearchableappReviewsFilterInput
    $sort: [SearchableappReviewsSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableappReviewsAggregationInput]
  ) {
    searchAppReviews(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        user_id
        app_id
        comment
        status
        like_dislike_list {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
              __typename
            }
          }
        }
        __typename
      }
      __typename
    }
  }
`;
export const searchUserActionLogs = /* GraphQL */ `
  query SearchUserActionLogs(
    $filter: SearchableuserActionLogFilterInput
    $sort: [SearchableuserActionLogSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableuserActionLogAggregationInput]
  ) {
    searchUserActionLogs(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        log_type
        user_id
        remote_ip
        device_type
        user_agent
        device_os
        data
        createdAt
        updatedAt
        __typename
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
              __typename
            }
          }
        }
        __typename
      }
      __typename
    }
  }
`;
export const searchUserLoginActionlogs = /* GraphQL */ `
  query SearchUserLoginActionlogs(
    $filter: SearchableuserLoginActionlogFilterInput
    $sort: [SearchableuserLoginActionlogSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableuserLoginActionlogAggregationInput]
  ) {
    searchUserLoginActionlogs(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        user_id
        device_os
        device_id
        device_type
        device_name
        user_agent
        remote_ip
        login_status
        createdAt
        updatedAt
        __typename
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
              __typename
            }
          }
        }
        __typename
      }
      __typename
    }
  }
`;
export const searchUserNotifications = /* GraphQL */ `
  query SearchUserNotifications(
    $filter: SearchableuserNotificationsFilterInput
    $sort: [SearchableuserNotificationsSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableuserNotificationsAggregationInput]
  ) {
    searchUserNotifications(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        user_id
        user_name
        alert_msg
        redirect_url
        status
        read_status
        added_by_id
        createdAt
        updatedAt
        __typename
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
              __typename
            }
          }
        }
        __typename
      }
      __typename
    }
  }
`;
export const searchUserSavedPlaces = /* GraphQL */ `
  query SearchUserSavedPlaces(
    $filter: SearchableuserSavedPlacesFilterInput
    $sort: [SearchableuserSavedPlacesSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableuserSavedPlacesAggregationInput]
  ) {
    searchUserSavedPlaces(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        user_id
        address_type
        address
        status
        createdAt
        updatedAt
        latitude
        longitude
        __typename
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
              __typename
            }
          }
        }
        __typename
      }
      __typename
    }
  }
`;
export const searchUserStorageManagements = /* GraphQL */ `
  query SearchUserStorageManagements(
    $filter: SearchableuserStorageManagementFilterInput
    $sort: [SearchableuserStorageManagementSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableuserStorageManagementAggregationInput]
  ) {
    searchUserStorageManagements(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        user_id
        key_name
        file_type
        file_size
        tag_type
        status
        createdAt
        updatedAt
        __typename
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
              __typename
            }
          }
        }
        __typename
      }
      __typename
    }
  }
`;
export const searchUserOnlineStatuses = /* GraphQL */ `
  query SearchUserOnlineStatuses(
    $filter: SearchableuserOnlineStatusFilterInput
    $sort: [SearchableuserOnlineStatusSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableuserOnlineStatusAggregationInput]
  ) {
    searchUserOnlineStatuses(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        user_id
        last_active_date
        createdAt
        updatedAt
        __typename
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
              __typename
            }
          }
        }
        __typename
      }
      __typename
    }
  }
`;
export const searchUserInAppEmails = /* GraphQL */ `
  query SearchUserInAppEmails(
    $filter: SearchableuserInAppEmailsFilterInput
    $sort: [SearchableuserInAppEmailsSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableuserInAppEmailsAggregationInput]
  ) {
    searchUserInAppEmails(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        from_email
        to_email
        from_user_id
        to_user_id
        is_from_admin
        subject_line
        message_body
        read_status
        status
        category
        sub_category
        createdAt
        updatedAt
        __typename
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
              __typename
            }
          }
        }
        __typename
      }
      __typename
    }
  }
`;
export const searchMediaPlayLogs = /* GraphQL */ `
  query SearchMediaPlayLogs(
    $filter: SearchablemediaPlayLogsFilterInput
    $sort: [SearchablemediaPlayLogsSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchablemediaPlayLogsAggregationInput]
  ) {
    searchMediaPlayLogs(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        tag_id
        tag_type
        media_type
        user_id
        extra
        createdAt
        updatedAt
        __typename
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
              __typename
            }
          }
        }
        __typename
      }
      __typename
    }
  }
`;
export const getTag = /* GraphQL */ `
  query GetTag($id: ID!) {
    getTag(id: $id) {
      id
      user_id
      type
      title
      content
      addresses
      visibility
      comments
      tagged_user_id
      memory_date
      occasion
      category
      sub_category
      price
      asset_price
      sold_price
      quantity
      available_for_sale
      auto_attach
      custom_field
      start_date
      end_date
      task_date
      task_end_date
      media
      repeat
      custom_start
      custom_repeat
      custom_repeat_value
      custom_days
      custom_end
      public_distance
      public_start
      public_end
      latitude
      longitude
      altitude
      purchasing_date
      createdAt
      updatedAt
      tag_attach_id
      shared_tag_id {
        id
        user_id
        type
        title
        content
        addresses
        visibility
        comments
        tagged_user_id
        memory_date
        occasion
        category
        sub_category
        price
        asset_price
        sold_price
        quantity
        available_for_sale
        auto_attach
        custom_field
        start_date
        end_date
        task_date
        task_end_date
        media
        repeat
        custom_start
        custom_repeat
        custom_repeat_value
        custom_days
        custom_end
        public_distance
        public_start
        public_end
        latitude
        longitude
        altitude
        purchasing_date
        createdAt
        updatedAt
        tag_attach_id
        shared_tag_id {
          id
          user_id
          type
          title
          content
          addresses
          visibility
          comments
          tagged_user_id
          memory_date
          occasion
          category
          sub_category
          price
          asset_price
          sold_price
          quantity
          available_for_sale
          auto_attach
          custom_field
          start_date
          end_date
          task_date
          task_end_date
          media
          repeat
          custom_start
          custom_repeat
          custom_repeat_value
          custom_days
          custom_end
          public_distance
          public_start
          public_end
          latitude
          longitude
          altitude
          purchasing_date
          createdAt
          updatedAt
          tag_attach_id
          tag_level
          status
          floor
          room
          is_sold
          sold_date
          scene_tags
          is_abvertising_paid
          is_admin_approved
          spotify
          parent_id
          top_parent_id
          is_public
          is_tc_accepted
          is_master_attached
          full_day
          attached_with_master
          tagShared_tagsId
          tagChild_tagsId
          tagAttached_tag_idId
          __typename
        }
        shared_tags {
          nextToken
          __typename
        }
        parent_tag_id {
          id
          user_id
          type
          title
          content
          addresses
          visibility
          comments
          tagged_user_id
          memory_date
          occasion
          category
          sub_category
          price
          asset_price
          sold_price
          quantity
          available_for_sale
          auto_attach
          custom_field
          start_date
          end_date
          task_date
          task_end_date
          media
          repeat
          custom_start
          custom_repeat
          custom_repeat_value
          custom_days
          custom_end
          public_distance
          public_start
          public_end
          latitude
          longitude
          altitude
          purchasing_date
          createdAt
          updatedAt
          tag_attach_id
          tag_level
          status
          floor
          room
          is_sold
          sold_date
          scene_tags
          is_abvertising_paid
          is_admin_approved
          spotify
          parent_id
          top_parent_id
          is_public
          is_tc_accepted
          is_master_attached
          full_day
          attached_with_master
          tagShared_tagsId
          tagChild_tagsId
          tagAttached_tag_idId
          __typename
        }
        child_tags {
          nextToken
          __typename
        }
        tag_level
        comment_list {
          nextToken
          __typename
        }
        like_dislike_list {
          nextToken
          __typename
        }
        tag_messages {
          items {
            content
            createdAt
            id
            recipient
            sender
            tagMessageRepliesId
            tagTag_messagesId
            updatedAt
            replies {
              items {
                content
                createdAt
                id
                recipient
                sender
                tagMessageRepliesId
                tagTag_messagesId
                updatedAt
              }
            }
          }
          nextToken
          __typename
        }
        status
        floor
        room
        is_sold
        sold_date
        scene_tags
        is_abvertising_paid
        is_admin_approved
        analytics_user_data {
          nextToken
          __typename
        }
        spotify
        parent_id
        top_parent_id
        is_public
        is_tc_accepted
        is_master_attached
        attached_tag_id {
          nextToken
          __typename
        }
        full_day
        attached_with_master
        tagShared_tagsId
        tagChild_tagsId
        tagAttached_tag_idId
        __typename
      }
      shared_tags {
        items {
          id
          user_id
          type
          title
          content
          addresses
          visibility
          comments
          tagged_user_id
          memory_date
          occasion
          category
          sub_category
          price
          asset_price
          sold_price
          quantity
          available_for_sale
          auto_attach
          custom_field
          start_date
          end_date
          task_date
          task_end_date
          media
          repeat
          custom_start
          custom_repeat
          custom_repeat_value
          custom_days
          custom_end
          public_distance
          public_start
          public_end
          latitude
          longitude
          altitude
          purchasing_date
          createdAt
          updatedAt
          tag_attach_id
          tag_level
          status
          floor
          room
          is_sold
          sold_date
          scene_tags
          is_abvertising_paid
          is_admin_approved
          spotify
          parent_id
          top_parent_id
          is_public
          is_tc_accepted
          is_master_attached
          full_day
          attached_with_master
          tagShared_tagsId
          tagChild_tagsId
          tagAttached_tag_idId
          __typename
        }
        nextToken
        __typename
      }
      parent_tag_id {
        id
        user_id
        type
        title
        content
        addresses
        visibility
        comments
        tagged_user_id
        memory_date
        occasion
        category
        sub_category
        price
        asset_price
        sold_price
        quantity
        available_for_sale
        auto_attach
        custom_field
        start_date
        end_date
        task_date
        task_end_date
        media
        repeat
        custom_start
        custom_repeat
        custom_repeat_value
        custom_days
        custom_end
        public_distance
        public_start
        public_end
        latitude
        longitude
        altitude
        purchasing_date
        createdAt
        updatedAt
        tag_attach_id
        shared_tag_id {
          id
          user_id
          type
          title
          content
          addresses
          visibility
          comments
          tagged_user_id
          memory_date
          occasion
          category
          sub_category
          price
          asset_price
          sold_price
          quantity
          available_for_sale
          auto_attach
          custom_field
          start_date
          end_date
          task_date
          task_end_date
          media
          repeat
          custom_start
          custom_repeat
          custom_repeat_value
          custom_days
          custom_end
          public_distance
          public_start
          public_end
          latitude
          longitude
          altitude
          purchasing_date
          createdAt
          updatedAt
          tag_attach_id
          tag_level
          status
          floor
          room
          is_sold
          sold_date
          scene_tags
          is_abvertising_paid
          is_admin_approved
          spotify
          parent_id
          top_parent_id
          is_public
          is_tc_accepted
          is_master_attached
          full_day
          attached_with_master
          tagShared_tagsId
          tagChild_tagsId
          tagAttached_tag_idId
          __typename
        }
        shared_tags {
          nextToken
          __typename
        }
        parent_tag_id {
          id
          user_id
          type
          title
          content
          addresses
          visibility
          comments
          tagged_user_id
          memory_date
          occasion
          category
          sub_category
          price
          asset_price
          sold_price
          quantity
          available_for_sale
          auto_attach
          custom_field
          start_date
          end_date
          task_date
          task_end_date
          media
          repeat
          custom_start
          custom_repeat
          custom_repeat_value
          custom_days
          custom_end
          public_distance
          public_start
          public_end
          latitude
          longitude
          altitude
          purchasing_date
          createdAt
          updatedAt
          tag_attach_id
          tag_level
          status
          floor
          room
          is_sold
          sold_date
          scene_tags
          is_abvertising_paid
          is_admin_approved
          spotify
          parent_id
          top_parent_id
          is_public
          is_tc_accepted
          is_master_attached
          full_day
          attached_with_master
          tagShared_tagsId
          tagChild_tagsId
          tagAttached_tag_idId
          __typename
        }
        child_tags {
          nextToken
          __typename
        }
        tag_level
        comment_list {
          nextToken
          __typename
        }
        like_dislike_list {
          nextToken
          __typename
        }
        tag_messages {
          nextToken
          __typename
        }
        status
        floor
        room
        is_sold
        sold_date
        scene_tags
        is_abvertising_paid
        is_admin_approved
        analytics_user_data {
          nextToken
          __typename
        }
        spotify
        parent_id
        top_parent_id
        is_public
        is_tc_accepted
        is_master_attached
        attached_tag_id {
          nextToken
          __typename
        }
        full_day
        attached_with_master
        tagShared_tagsId
        tagChild_tagsId
        tagAttached_tag_idId
        __typename
      }
      child_tags {
        items {
          id
          user_id
          type
          title
          content
          addresses
          visibility
          comments
          tagged_user_id
          memory_date
          occasion
          category
          sub_category
          price
          asset_price
          sold_price
          quantity
          available_for_sale
          auto_attach
          custom_field
          start_date
          end_date
          task_date
          task_end_date
          media
          repeat
          custom_start
          custom_repeat
          custom_repeat_value
          custom_days
          custom_end
          public_distance
          public_start
          public_end
          latitude
          longitude
          altitude
          purchasing_date
          createdAt
          updatedAt
          tag_attach_id
          tag_level
          status
          floor
          room
          is_sold
          sold_date
          scene_tags
          is_abvertising_paid
          is_admin_approved
          spotify
          parent_id
          top_parent_id
          is_public
          is_tc_accepted
          is_master_attached
          full_day
          attached_with_master
          tagShared_tagsId
          tagChild_tagsId
          tagAttached_tag_idId
          __typename
        }
        nextToken
        __typename
      }
      tag_level
      comment_list {
        items {
          id
          user_id
          content
          comment_type
          status
          createdAt
          updatedAt
          tagComment_listId
          __typename
        }
        nextToken
        __typename
      }
      like_dislike_list {
        items {
          unique_id
          type
          user_id
          diamond
          duck
          createdAt
          updatedAt
          tagLike_dislike_listId
          commentLike_dislike_listId
          __typename
        }
        nextToken
        __typename
      }
      tag_messages {
        items {
          id
          sender
          recipient
          content
          createdAt
          updatedAt
          tagTag_messagesId
          tagMessageRepliesId
          __typename
        }
        nextToken
        __typename
      }
      status
      floor
      room
      is_sold
      sold_date
      scene_tags
      is_abvertising_paid
      is_admin_approved
      analytics_user_data {
        items {
          id
          user_id
          ipAddress
          browser
          deviceType
          is_view_only
          createdAt
          updatedAt
          tagAnalytics_user_dataId
          __typename
        }
        nextToken
        __typename
      }
      spotify
      parent_id
      top_parent_id
      is_public
      is_tc_accepted
      is_master_attached
      attached_tag_id {
        items {
          id
          user_id
          type
          title
          content
          addresses
          visibility
          comments
          tagged_user_id
          memory_date
          occasion
          category
          sub_category
          price
          asset_price
          sold_price
          quantity
          available_for_sale
          auto_attach
          custom_field
          start_date
          end_date
          task_date
          task_end_date
          media
          repeat
          custom_start
          custom_repeat
          custom_repeat_value
          custom_days
          custom_end
          public_distance
          public_start
          public_end
          latitude
          longitude
          altitude
          purchasing_date
          createdAt
          updatedAt
          tag_attach_id
          tag_level
          status
          floor
          room
          is_sold
          sold_date
          scene_tags
          is_abvertising_paid
          is_admin_approved
          spotify
          parent_id
          top_parent_id
          is_public
          is_tc_accepted
          is_master_attached
          full_day
          attached_with_master
          tagShared_tagsId
          tagChild_tagsId
          tagAttached_tag_idId
          __typename
        }
        nextToken
        __typename
      }
      full_day
      attached_with_master
      tagShared_tagsId
      tagChild_tagsId
      tagAttached_tag_idId
      __typename
    }
  }
`;
export const listTags = /* GraphQL */ `
  query ListTags(
    $filter: ModelTagFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTags(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        user_id
        type
        title
        content
        addresses
        visibility
        comments
        tagged_user_id
        memory_date
        occasion
        category
        sub_category
        price
        asset_price
        sold_price
        quantity
        available_for_sale
        auto_attach
        custom_field
        start_date
        end_date
        task_date
        task_end_date
        media
        repeat
        custom_start
        custom_repeat
        custom_repeat_value
        custom_days
        custom_end
        public_distance
        public_start
        public_end
        latitude
        longitude
        altitude
        purchasing_date
        createdAt
        updatedAt
        tag_attach_id
        shared_tag_id {
          id
          user_id
          type
          title
          content
          addresses
          visibility
          comments
          tagged_user_id
          memory_date
          occasion
          category
          sub_category
          price
          asset_price
          sold_price
          quantity
          available_for_sale
          auto_attach
          custom_field
          start_date
          end_date
          task_date
          task_end_date
          media
          repeat
          custom_start
          custom_repeat
          custom_repeat_value
          custom_days
          custom_end
          public_distance
          public_start
          public_end
          latitude
          longitude
          altitude
          purchasing_date
          createdAt
          updatedAt
          tag_attach_id
          tag_level
          status
          floor
          room
          is_sold
          sold_date
          scene_tags
          is_abvertising_paid
          is_admin_approved
          spotify
          parent_id
          top_parent_id
          is_public
          is_tc_accepted
          is_master_attached
          full_day
          attached_with_master
          tagShared_tagsId
          tagChild_tagsId
          tagAttached_tag_idId
          __typename
        }
        shared_tags {
          nextToken
          __typename
        }
        parent_tag_id {
          id
          user_id
          type
          title
          content
          addresses
          visibility
          comments
          tagged_user_id
          memory_date
          occasion
          category
          sub_category
          price
          asset_price
          sold_price
          quantity
          available_for_sale
          auto_attach
          custom_field
          start_date
          end_date
          task_date
          task_end_date
          media
          repeat
          custom_start
          custom_repeat
          custom_repeat_value
          custom_days
          custom_end
          public_distance
          public_start
          public_end
          latitude
          longitude
          altitude
          purchasing_date
          createdAt
          updatedAt
          tag_attach_id
          tag_level
          status
          floor
          room
          is_sold
          sold_date
          scene_tags
          is_abvertising_paid
          is_admin_approved
          spotify
          parent_id
          top_parent_id
          is_public
          is_tc_accepted
          is_master_attached
          full_day
          attached_with_master
          tagShared_tagsId
          tagChild_tagsId
          tagAttached_tag_idId
          __typename
        }
        child_tags {
          nextToken
          __typename
        }
        tag_level
        comment_list {
          nextToken
          __typename
        }
        like_dislike_list {
          nextToken
          __typename
        }
        tag_messages {
          nextToken
          __typename
        }
        status
        floor
        room
        is_sold
        sold_date
        scene_tags
        is_abvertising_paid
        is_admin_approved
        analytics_user_data {
          nextToken
          __typename
        }
        spotify
        parent_id
        top_parent_id
        is_public
        is_tc_accepted
        is_master_attached
        attached_tag_id {
          nextToken
          __typename
        }
        full_day
        attached_with_master
        tagShared_tagsId
        tagChild_tagsId
        tagAttached_tag_idId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getTagAnalyticsUserData = /* GraphQL */ `
  query GetTagAnalyticsUserData($id: ID!) {
    getTagAnalyticsUserData(id: $id) {
      id
      tag_id {
        id
        user_id
        type
        title
        content
        addresses
        visibility
        comments
        tagged_user_id
        memory_date
        occasion
        category
        sub_category
        price
        asset_price
        sold_price
        quantity
        available_for_sale
        auto_attach
        custom_field
        start_date
        end_date
        task_date
        task_end_date
        media
        repeat
        custom_start
        custom_repeat
        custom_repeat_value
        custom_days
        custom_end
        public_distance
        public_start
        public_end
        latitude
        longitude
        altitude
        purchasing_date
        createdAt
        updatedAt
        tag_attach_id
        shared_tag_id {
          id
          user_id
          type
          title
          content
          addresses
          visibility
          comments
          tagged_user_id
          memory_date
          occasion
          category
          sub_category
          price
          asset_price
          sold_price
          quantity
          available_for_sale
          auto_attach
          custom_field
          start_date
          end_date
          task_date
          task_end_date
          media
          repeat
          custom_start
          custom_repeat
          custom_repeat_value
          custom_days
          custom_end
          public_distance
          public_start
          public_end
          latitude
          longitude
          altitude
          purchasing_date
          createdAt
          updatedAt
          tag_attach_id
          tag_level
          status
          floor
          room
          is_sold
          sold_date
          scene_tags
          is_abvertising_paid
          is_admin_approved
          spotify
          parent_id
          top_parent_id
          is_public
          is_tc_accepted
          is_master_attached
          full_day
          attached_with_master
          tagShared_tagsId
          tagChild_tagsId
          tagAttached_tag_idId
          __typename
        }
        shared_tags {
          nextToken
          __typename
        }
        parent_tag_id {
          id
          user_id
          type
          title
          content
          addresses
          visibility
          comments
          tagged_user_id
          memory_date
          occasion
          category
          sub_category
          price
          asset_price
          sold_price
          quantity
          available_for_sale
          auto_attach
          custom_field
          start_date
          end_date
          task_date
          task_end_date
          media
          repeat
          custom_start
          custom_repeat
          custom_repeat_value
          custom_days
          custom_end
          public_distance
          public_start
          public_end
          latitude
          longitude
          altitude
          purchasing_date
          createdAt
          updatedAt
          tag_attach_id
          tag_level
          status
          floor
          room
          is_sold
          sold_date
          scene_tags
          is_abvertising_paid
          is_admin_approved
          spotify
          parent_id
          top_parent_id
          is_public
          is_tc_accepted
          is_master_attached
          full_day
          attached_with_master
          tagShared_tagsId
          tagChild_tagsId
          tagAttached_tag_idId
          __typename
        }
        child_tags {
          nextToken
          __typename
        }
        tag_level
        comment_list {
          nextToken
          __typename
        }
        like_dislike_list {
          nextToken
          __typename
        }
        tag_messages {
          nextToken
          __typename
        }
        status
        floor
        room
        is_sold
        sold_date
        scene_tags
        is_abvertising_paid
        is_admin_approved
        analytics_user_data {
          nextToken
          __typename
        }
        spotify
        parent_id
        top_parent_id
        is_public
        is_tc_accepted
        is_master_attached
        attached_tag_id {
          nextToken
          __typename
        }
        full_day
        attached_with_master
        tagShared_tagsId
        tagChild_tagsId
        tagAttached_tag_idId
        __typename
      }
      user_id
      ipAddress
      browser
      deviceType
      is_view_only
      createdAt
      updatedAt
      tagAnalytics_user_dataId
      __typename
    }
  }
`;
export const listTagAnalyticsUserData = /* GraphQL */ `
  query ListTagAnalyticsUserData(
    $filter: ModelTagAnalyticsUserDataFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTagAnalyticsUserData(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tag_id {
          id
          user_id
          type
          title
          content
          addresses
          visibility
          comments
          tagged_user_id
          memory_date
          occasion
          category
          sub_category
          price
          asset_price
          sold_price
          quantity
          available_for_sale
          auto_attach
          custom_field
          start_date
          end_date
          task_date
          task_end_date
          media
          repeat
          custom_start
          custom_repeat
          custom_repeat_value
          custom_days
          custom_end
          public_distance
          public_start
          public_end
          latitude
          longitude
          altitude
          purchasing_date
          createdAt
          updatedAt
          tag_attach_id
          tag_level
          status
          floor
          room
          is_sold
          sold_date
          scene_tags
          is_abvertising_paid
          is_admin_approved
          spotify
          parent_id
          top_parent_id
          is_public
          is_tc_accepted
          is_master_attached
          full_day
          attached_with_master
          tagShared_tagsId
          tagChild_tagsId
          tagAttached_tag_idId
          __typename
        }
        user_id
        ipAddress
        browser
        deviceType
        is_view_only
        createdAt
        updatedAt
        tagAnalytics_user_dataId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getComment = /* GraphQL */ `
  query GetComment($id: ID!) {
    getComment(id: $id) {
      id
      tag_id {
        id
        user_id
        type
        title
        content
        addresses
        visibility
        comments
        tagged_user_id
        memory_date
        occasion
        category
        sub_category
        price
        asset_price
        sold_price
        quantity
        available_for_sale
        auto_attach
        custom_field
        start_date
        end_date
        task_date
        task_end_date
        media
        repeat
        custom_start
        custom_repeat
        custom_repeat_value
        custom_days
        custom_end
        public_distance
        public_start
        public_end
        latitude
        longitude
        altitude
        purchasing_date
        createdAt
        updatedAt
        tag_attach_id
        shared_tag_id {
          id
          user_id
          type
          title
          content
          addresses
          visibility
          comments
          tagged_user_id
          memory_date
          occasion
          category
          sub_category
          price
          asset_price
          sold_price
          quantity
          available_for_sale
          auto_attach
          custom_field
          start_date
          end_date
          task_date
          task_end_date
          media
          repeat
          custom_start
          custom_repeat
          custom_repeat_value
          custom_days
          custom_end
          public_distance
          public_start
          public_end
          latitude
          longitude
          altitude
          purchasing_date
          createdAt
          updatedAt
          tag_attach_id
          tag_level
          status
          floor
          room
          is_sold
          sold_date
          scene_tags
          is_abvertising_paid
          is_admin_approved
          spotify
          parent_id
          top_parent_id
          is_public
          is_tc_accepted
          is_master_attached
          full_day
          attached_with_master
          tagShared_tagsId
          tagChild_tagsId
          tagAttached_tag_idId
          __typename
        }
        shared_tags {
          nextToken
          __typename
        }
        parent_tag_id {
          id
          user_id
          type
          title
          content
          addresses
          visibility
          comments
          tagged_user_id
          memory_date
          occasion
          category
          sub_category
          price
          asset_price
          sold_price
          quantity
          available_for_sale
          auto_attach
          custom_field
          start_date
          end_date
          task_date
          task_end_date
          media
          repeat
          custom_start
          custom_repeat
          custom_repeat_value
          custom_days
          custom_end
          public_distance
          public_start
          public_end
          latitude
          longitude
          altitude
          purchasing_date
          createdAt
          updatedAt
          tag_attach_id
          tag_level
          status
          floor
          room
          is_sold
          sold_date
          scene_tags
          is_abvertising_paid
          is_admin_approved
          spotify
          parent_id
          top_parent_id
          is_public
          is_tc_accepted
          is_master_attached
          full_day
          attached_with_master
          tagShared_tagsId
          tagChild_tagsId
          tagAttached_tag_idId
          __typename
        }
        child_tags {
          nextToken
          __typename
        }
        tag_level
        comment_list {
          nextToken
          __typename
        }
        like_dislike_list {
          nextToken
          __typename
        }
        tag_messages {
          nextToken
          __typename
        }
        status
        floor
        room
        is_sold
        sold_date
        scene_tags
        is_abvertising_paid
        is_admin_approved
        analytics_user_data {
          nextToken
          __typename
        }
        spotify
        parent_id
        top_parent_id
        is_public
        is_tc_accepted
        is_master_attached
        attached_tag_id {
          nextToken
          __typename
        }
        full_day
        attached_with_master
        tagShared_tagsId
        tagChild_tagsId
        tagAttached_tag_idId
        __typename
      }
      user_id
      content
      comment_type
      like_dislike_list {
        items {
          unique_id
          type
          user_id
          diamond
          duck
          createdAt
          updatedAt
          tagLike_dislike_listId
          commentLike_dislike_listId
          __typename
        }
        nextToken
        __typename
      }
      status
      createdAt
      updatedAt
      tagComment_listId
      __typename
    }
  }
`;
export const listComments = /* GraphQL */ `
  query ListComments(
    $filter: ModelCommentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listComments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        tag_id {
          id
          user_id
          type
          title
          content
          addresses
          visibility
          comments
          tagged_user_id
          memory_date
          occasion
          category
          sub_category
          price
          asset_price
          sold_price
          quantity
          available_for_sale
          auto_attach
          custom_field
          start_date
          end_date
          task_date
          task_end_date
          media
          repeat
          custom_start
          custom_repeat
          custom_repeat_value
          custom_days
          custom_end
          public_distance
          public_start
          public_end
          latitude
          longitude
          altitude
          purchasing_date
          createdAt
          updatedAt
          tag_attach_id
          tag_level
          status
          floor
          room
          is_sold
          sold_date
          scene_tags
          is_abvertising_paid
          is_admin_approved
          spotify
          parent_id
          top_parent_id
          is_public
          is_tc_accepted
          is_master_attached
          full_day
          attached_with_master
          tagShared_tagsId
          tagChild_tagsId
          tagAttached_tag_idId
          __typename
        }
        user_id
        content
        comment_type
        like_dislike_list {
          nextToken
          __typename
        }
        status
        createdAt
        updatedAt
        tagComment_listId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getLikeDislike = /* GraphQL */ `
  query GetLikeDislike($unique_id: ID!, $user_id: Int!) {
    getLikeDislike(unique_id: $unique_id, user_id: $user_id) {
      unique_id
      tag_id {
        id
        user_id
        type
        title
        content
        addresses
        visibility
        comments
        tagged_user_id
        memory_date
        occasion
        category
        sub_category
        price
        asset_price
        sold_price
        quantity
        available_for_sale
        auto_attach
        custom_field
        start_date
        end_date
        task_date
        task_end_date
        media
        repeat
        custom_start
        custom_repeat
        custom_repeat_value
        custom_days
        custom_end
        public_distance
        public_start
        public_end
        latitude
        longitude
        altitude
        purchasing_date
        createdAt
        updatedAt
        tag_attach_id
        shared_tag_id {
          id
          user_id
          type
          title
          content
          addresses
          visibility
          comments
          tagged_user_id
          memory_date
          occasion
          category
          sub_category
          price
          asset_price
          sold_price
          quantity
          available_for_sale
          auto_attach
          custom_field
          start_date
          end_date
          task_date
          task_end_date
          media
          repeat
          custom_start
          custom_repeat
          custom_repeat_value
          custom_days
          custom_end
          public_distance
          public_start
          public_end
          latitude
          longitude
          altitude
          purchasing_date
          createdAt
          updatedAt
          tag_attach_id
          tag_level
          status
          floor
          room
          is_sold
          sold_date
          scene_tags
          is_abvertising_paid
          is_admin_approved
          spotify
          parent_id
          top_parent_id
          is_public
          is_tc_accepted
          is_master_attached
          full_day
          attached_with_master
          tagShared_tagsId
          tagChild_tagsId
          tagAttached_tag_idId
          __typename
        }
        shared_tags {
          nextToken
          __typename
        }
        parent_tag_id {
          id
          user_id
          type
          title
          content
          addresses
          visibility
          comments
          tagged_user_id
          memory_date
          occasion
          category
          sub_category
          price
          asset_price
          sold_price
          quantity
          available_for_sale
          auto_attach
          custom_field
          start_date
          end_date
          task_date
          task_end_date
          media
          repeat
          custom_start
          custom_repeat
          custom_repeat_value
          custom_days
          custom_end
          public_distance
          public_start
          public_end
          latitude
          longitude
          altitude
          purchasing_date
          createdAt
          updatedAt
          tag_attach_id
          tag_level
          status
          floor
          room
          is_sold
          sold_date
          scene_tags
          is_abvertising_paid
          is_admin_approved
          spotify
          parent_id
          top_parent_id
          is_public
          is_tc_accepted
          is_master_attached
          full_day
          attached_with_master
          tagShared_tagsId
          tagChild_tagsId
          tagAttached_tag_idId
          __typename
        }
        child_tags {
          nextToken
          __typename
        }
        tag_level
        comment_list {
          nextToken
          __typename
        }
        like_dislike_list {
          nextToken
          __typename
        }
        tag_messages {
          nextToken
          __typename
        }
        status
        floor
        room
        is_sold
        sold_date
        scene_tags
        is_abvertising_paid
        is_admin_approved
        analytics_user_data {
          nextToken
          __typename
        }
        spotify
        parent_id
        top_parent_id
        is_public
        is_tc_accepted
        is_master_attached
        attached_tag_id {
          nextToken
          __typename
        }
        full_day
        attached_with_master
        tagShared_tagsId
        tagChild_tagsId
        tagAttached_tag_idId
        __typename
      }
      like_dislike_id {
        id
        tag_id {
          id
          user_id
          type
          title
          content
          addresses
          visibility
          comments
          tagged_user_id
          memory_date
          occasion
          category
          sub_category
          price
          asset_price
          sold_price
          quantity
          available_for_sale
          auto_attach
          custom_field
          start_date
          end_date
          task_date
          task_end_date
          media
          repeat
          custom_start
          custom_repeat
          custom_repeat_value
          custom_days
          custom_end
          public_distance
          public_start
          public_end
          latitude
          longitude
          altitude
          purchasing_date
          createdAt
          updatedAt
          tag_attach_id
          tag_level
          status
          floor
          room
          is_sold
          sold_date
          scene_tags
          is_abvertising_paid
          is_admin_approved
          spotify
          parent_id
          top_parent_id
          is_public
          is_tc_accepted
          is_master_attached
          full_day
          attached_with_master
          tagShared_tagsId
          tagChild_tagsId
          tagAttached_tag_idId
          __typename
        }
        user_id
        content
        comment_type
        like_dislike_list {
          nextToken
          __typename
        }
        status
        createdAt
        updatedAt
        tagComment_listId
        __typename
      }
      type
      user_id
      diamond
      duck
      createdAt
      updatedAt
      tagLike_dislike_listId
      commentLike_dislike_listId
      __typename
    }
  }
`;
export const listLikeDislikes = /* GraphQL */ `
  query ListLikeDislikes(
    $unique_id: ID
    $user_id: ModelIntKeyConditionInput
    $filter: ModelLikeDislikeFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listLikeDislikes(
      unique_id: $unique_id
      user_id: $user_id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        unique_id
        tag_id {
          id
          user_id
          type
          title
          content
          addresses
          visibility
          comments
          tagged_user_id
          memory_date
          occasion
          category
          sub_category
          price
          asset_price
          sold_price
          quantity
          available_for_sale
          auto_attach
          custom_field
          start_date
          end_date
          task_date
          task_end_date
          media
          repeat
          custom_start
          custom_repeat
          custom_repeat_value
          custom_days
          custom_end
          public_distance
          public_start
          public_end
          latitude
          longitude
          altitude
          purchasing_date
          createdAt
          updatedAt
          tag_attach_id
          tag_level
          status
          floor
          room
          is_sold
          sold_date
          scene_tags
          is_abvertising_paid
          is_admin_approved
          spotify
          parent_id
          top_parent_id
          is_public
          is_tc_accepted
          is_master_attached
          full_day
          attached_with_master
          tagShared_tagsId
          tagChild_tagsId
          tagAttached_tag_idId
          __typename
        }
        like_dislike_id {
          id
          user_id
          content
          comment_type
          status
          createdAt
          updatedAt
          tagComment_listId
          __typename
        }
        type
        user_id
        diamond
        duck
        createdAt
        updatedAt
        tagLike_dislike_listId
        commentLike_dislike_listId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getConversation = /* GraphQL */ `
  query GetConversation($id: ID!) {
    getConversation(id: $id) {
      id
      sender
      recipient
      messages {
        items {
          id
          sender
          content
          recipient
          media
          is_readed
          message_timestamp
          createdAt
          message_from
          tag_id
          status
          updatedAt
          conversationMessagesId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      message_from
      tag_id
      status
      __typename
    }
  }
`;
export const listConversations = /* GraphQL */ `
  query ListConversations(
    $filter: ModelConversationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listConversations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        sender
        recipient
        messages {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        message_from
        tag_id
        status
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getMessages = /* GraphQL */ `
  query GetMessages($id: ID!, $message_timestamp: Int!) {
    getMessages(id: $id, message_timestamp: $message_timestamp) {
      id
      conversation_id {
        id
        sender
        recipient
        messages {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        message_from
        tag_id
        status
        __typename
      }
      sender
      content
      recipient
      media
      is_readed
      message_timestamp
      createdAt
      message_from
      tag_id
      status
      updatedAt
      conversationMessagesId
      __typename
    }
  }
`;
export const listMessages = /* GraphQL */ `
  query ListMessages(
    $id: ID
    $message_timestamp: ModelIntKeyConditionInput
    $filter: ModelMessagesFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listMessages(
      id: $id
      message_timestamp: $message_timestamp
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        conversation_id {
          id
          sender
          recipient
          createdAt
          updatedAt
          message_from
          tag_id
          status
          __typename
        }
        sender
        content
        recipient
        media
        is_readed
        message_timestamp
        createdAt
        message_from
        tag_id
        status
        updatedAt
        conversationMessagesId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getTagMessage = /* GraphQL */ `
  query GetTagMessage($id: ID!) {
    getTagMessage(id: $id) {
      id
      sender
      recipient
      content
      parent_id {
        id
        sender
        recipient
        content
        parent_id {
          id
          sender
          recipient
          content
          createdAt
          updatedAt
          tagTag_messagesId
          tagMessageRepliesId
          __typename
        }
        replies {
          nextToken
          __typename
        }
        tag_id {
          id
          user_id
          type
          title
          content
          addresses
          visibility
          comments
          tagged_user_id
          memory_date
          occasion
          category
          sub_category
          price
          asset_price
          sold_price
          quantity
          available_for_sale
          auto_attach
          custom_field
          start_date
          end_date
          task_date
          task_end_date
          media
          repeat
          custom_start
          custom_repeat
          custom_repeat_value
          custom_days
          custom_end
          public_distance
          public_start
          public_end
          latitude
          longitude
          altitude
          purchasing_date
          createdAt
          updatedAt
          tag_attach_id
          tag_level
          status
          floor
          room
          is_sold
          sold_date
          scene_tags
          is_abvertising_paid
          is_admin_approved
          spotify
          parent_id
          top_parent_id
          is_public
          is_tc_accepted
          is_master_attached
          full_day
          attached_with_master
          tagShared_tagsId
          tagChild_tagsId
          tagAttached_tag_idId
          __typename
        }
        createdAt
        updatedAt
        tagTag_messagesId
        tagMessageRepliesId
        __typename
      }
      replies {
        items {
          id
          sender
          recipient
          content
          createdAt
          updatedAt
          tagTag_messagesId
          tagMessageRepliesId
          __typename
        }
        nextToken
        __typename
      }
      tag_id {
        id
        user_id
        type
        title
        content
        addresses
        visibility
        comments
        tagged_user_id
        memory_date
        occasion
        category
        sub_category
        price
        asset_price
        sold_price
        quantity
        available_for_sale
        auto_attach
        custom_field
        start_date
        end_date
        task_date
        task_end_date
        media
        repeat
        custom_start
        custom_repeat
        custom_repeat_value
        custom_days
        custom_end
        public_distance
        public_start
        public_end
        latitude
        longitude
        altitude
        purchasing_date
        createdAt
        updatedAt
        tag_attach_id
        shared_tag_id {
          id
          user_id
          type
          title
          content
          addresses
          visibility
          comments
          tagged_user_id
          memory_date
          occasion
          category
          sub_category
          price
          asset_price
          sold_price
          quantity
          available_for_sale
          auto_attach
          custom_field
          start_date
          end_date
          task_date
          task_end_date
          media
          repeat
          custom_start
          custom_repeat
          custom_repeat_value
          custom_days
          custom_end
          public_distance
          public_start
          public_end
          latitude
          longitude
          altitude
          purchasing_date
          createdAt
          updatedAt
          tag_attach_id
          tag_level
          status
          floor
          room
          is_sold
          sold_date
          scene_tags
          is_abvertising_paid
          is_admin_approved
          spotify
          parent_id
          top_parent_id
          is_public
          is_tc_accepted
          is_master_attached
          full_day
          attached_with_master
          tagShared_tagsId
          tagChild_tagsId
          tagAttached_tag_idId
          __typename
        }
        shared_tags {
          nextToken
          __typename
        }
        parent_tag_id {
          id
          user_id
          type
          title
          content
          addresses
          visibility
          comments
          tagged_user_id
          memory_date
          occasion
          category
          sub_category
          price
          asset_price
          sold_price
          quantity
          available_for_sale
          auto_attach
          custom_field
          start_date
          end_date
          task_date
          task_end_date
          media
          repeat
          custom_start
          custom_repeat
          custom_repeat_value
          custom_days
          custom_end
          public_distance
          public_start
          public_end
          latitude
          longitude
          altitude
          purchasing_date
          createdAt
          updatedAt
          tag_attach_id
          tag_level
          status
          floor
          room
          is_sold
          sold_date
          scene_tags
          is_abvertising_paid
          is_admin_approved
          spotify
          parent_id
          top_parent_id
          is_public
          is_tc_accepted
          is_master_attached
          full_day
          attached_with_master
          tagShared_tagsId
          tagChild_tagsId
          tagAttached_tag_idId
          __typename
        }
        child_tags {
          nextToken
          __typename
        }
        tag_level
        comment_list {
          nextToken
          __typename
        }
        like_dislike_list {
          nextToken
          __typename
        }
        tag_messages {
          nextToken
          __typename
        }
        status
        floor
        room
        is_sold
        sold_date
        scene_tags
        is_abvertising_paid
        is_admin_approved
        analytics_user_data {
          nextToken
          __typename
        }
        spotify
        parent_id
        top_parent_id
        is_public
        is_tc_accepted
        is_master_attached
        attached_tag_id {
          nextToken
          __typename
        }
        full_day
        attached_with_master
        tagShared_tagsId
        tagChild_tagsId
        tagAttached_tag_idId
        __typename
      }
      createdAt
      updatedAt
      tagTag_messagesId
      tagMessageRepliesId
      __typename
    }
  }
`;
export const listTagMessages = /* GraphQL */ `
  query ListTagMessages(
    $filter: ModelTagMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTagMessages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        sender
        recipient
        content
        parent_id {
          id
          sender
          recipient
          content
          createdAt
          updatedAt
          tagTag_messagesId
          tagMessageRepliesId
          __typename
        }
        replies {
          nextToken
          __typename
        }
        tag_id {
          id
          user_id
          type
          title
          content
          addresses
          visibility
          comments
          tagged_user_id
          memory_date
          occasion
          category
          sub_category
          price
          asset_price
          sold_price
          quantity
          available_for_sale
          auto_attach
          custom_field
          start_date
          end_date
          task_date
          task_end_date
          media
          repeat
          custom_start
          custom_repeat
          custom_repeat_value
          custom_days
          custom_end
          public_distance
          public_start
          public_end
          latitude
          longitude
          altitude
          purchasing_date
          createdAt
          updatedAt
          tag_attach_id
          tag_level
          status
          floor
          room
          is_sold
          sold_date
          scene_tags
          is_abvertising_paid
          is_admin_approved
          spotify
          parent_id
          top_parent_id
          is_public
          is_tc_accepted
          is_master_attached
          full_day
          attached_with_master
          tagShared_tagsId
          tagChild_tagsId
          tagAttached_tag_idId
          __typename
        }
        createdAt
        updatedAt
        tagTag_messagesId
        tagMessageRepliesId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getMaps = /* GraphQL */ `
  query GetMaps($id: ID!) {
    getMaps(id: $id) {
      id
      item_id
      item_type
      longitude
      latitude
      status
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listMaps = /* GraphQL */ `
  query ListMaps(
    $filter: ModelMapsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMaps(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        item_id
        item_type
        longitude
        latitude
        status
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getLoginQR = /* GraphQL */ `
  query GetLoginQR($id: ID!) {
    getLoginQR(id: $id) {
      id
      user_id
      jwt_auth
      ip_address
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listLoginQRS = /* GraphQL */ `
  query ListLoginQRS(
    $filter: ModelLoginQRFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLoginQRS(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        user_id
        jwt_auth
        ip_address
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getAppReviews = /* GraphQL */ `
  query GetAppReviews($id: ID!) {
    getAppReviews(id: $id) {
      id
      user_id
      app_id
      comment
      status
      like_dislike_list {
        items {
          unique_id
          app_id
          user_id
          diamond
          duck
          createdAt
          updatedAt
          appReviewsLike_dislike_listId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listAppReviews = /* GraphQL */ `
  query ListAppReviews(
    $filter: ModelAppReviewsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAppReviews(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        user_id
        app_id
        comment
        status
        like_dislike_list {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getLikeDislikeAppReview = /* GraphQL */ `
  query GetLikeDislikeAppReview($unique_id: ID!, $user_id: Int!) {
    getLikeDislikeAppReview(unique_id: $unique_id, user_id: $user_id) {
      unique_id
      app_id
      user_id
      like_dislike_id {
        id
        user_id
        app_id
        comment
        status
        like_dislike_list {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      diamond
      duck
      createdAt
      updatedAt
      appReviewsLike_dislike_listId
      __typename
    }
  }
`;
export const listLikeDislikeAppReviews = /* GraphQL */ `
  query ListLikeDislikeAppReviews(
    $unique_id: ID
    $user_id: ModelIntKeyConditionInput
    $filter: ModelLikeDislikeAppReviewFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listLikeDislikeAppReviews(
      unique_id: $unique_id
      user_id: $user_id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        unique_id
        app_id
        user_id
        like_dislike_id {
          id
          user_id
          app_id
          comment
          status
          createdAt
          updatedAt
          __typename
        }
        diamond
        duck
        createdAt
        updatedAt
        appReviewsLike_dislike_listId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getUserActionLog = /* GraphQL */ `
  query GetUserActionLog($id: ID!) {
    getUserActionLog(id: $id) {
      id
      log_type
      user_id
      remote_ip
      device_type
      user_agent
      device_os
      data
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listUserActionLogs = /* GraphQL */ `
  query ListUserActionLogs(
    $filter: ModelUserActionLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserActionLogs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        log_type
        user_id
        remote_ip
        device_type
        user_agent
        device_os
        data
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getUserLoginActionlog = /* GraphQL */ `
  query GetUserLoginActionlog($id: ID!) {
    getUserLoginActionlog(id: $id) {
      id
      user_id
      device_os
      device_id
      device_type
      device_name
      user_agent
      remote_ip
      login_status
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listUserLoginActionlogs = /* GraphQL */ `
  query ListUserLoginActionlogs(
    $filter: ModelUserLoginActionlogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserLoginActionlogs(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        user_id
        device_os
        device_id
        device_type
        device_name
        user_agent
        remote_ip
        login_status
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getUserNotifications = /* GraphQL */ `
  query GetUserNotifications($id: ID!) {
    getUserNotifications(id: $id) {
      id
      user_id
      user_name
      alert_msg
      redirect_url
      status
      read_status
      added_by_id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listUserNotifications = /* GraphQL */ `
  query ListUserNotifications(
    $filter: ModelUserNotificationsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserNotifications(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        user_id
        user_name
        alert_msg
        redirect_url
        status
        read_status
        added_by_id
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getUserSavedPlaces = /* GraphQL */ `
  query GetUserSavedPlaces($id: ID!) {
    getUserSavedPlaces(id: $id) {
      id
      user_id
      address_type
      address
      status
      createdAt
      updatedAt
      latitude
      longitude
      __typename
    }
  }
`;
export const listUserSavedPlaces = /* GraphQL */ `
  query ListUserSavedPlaces(
    $filter: ModelUserSavedPlacesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserSavedPlaces(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        user_id
        address_type
        address
        status
        createdAt
        updatedAt
        latitude
        longitude
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getUserStorageManagement = /* GraphQL */ `
  query GetUserStorageManagement($id: ID!) {
    getUserStorageManagement(id: $id) {
      id
      user_id
      key_name
      file_type
      file_size
      tag_type
      status
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listUserStorageManagements = /* GraphQL */ `
  query ListUserStorageManagements(
    $filter: ModelUserStorageManagementFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserStorageManagements(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        user_id
        key_name
        file_type
        file_size
        tag_type
        status
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getUserOnlineStatus = /* GraphQL */ `
  query GetUserOnlineStatus($id: ID!, $user_id: Int!) {
    getUserOnlineStatus(id: $id, user_id: $user_id) {
      id
      user_id
      last_active_date
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listUserOnlineStatuses = /* GraphQL */ `
  query ListUserOnlineStatuses(
    $id: ID
    $user_id: ModelIntKeyConditionInput
    $filter: ModelUserOnlineStatusFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listUserOnlineStatuses(
      id: $id
      user_id: $user_id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        user_id
        last_active_date
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getUserInAppEmails = /* GraphQL */ `
  query GetUserInAppEmails($id: ID!) {
    getUserInAppEmails(id: $id) {
      id
      from_email
      to_email
      from_user_id
      to_user_id
      is_from_admin
      subject_line
      message_body
      read_status
      status
      category
      sub_category
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listUserInAppEmails = /* GraphQL */ `
  query ListUserInAppEmails(
    $filter: ModelUserInAppEmailsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserInAppEmails(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        from_email
        to_email
        from_user_id
        to_user_id
        is_from_admin
        subject_line
        message_body
        read_status
        status
        category
        sub_category
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getMediaPlayLogs = /* GraphQL */ `
  query GetMediaPlayLogs($id: ID!) {
    getMediaPlayLogs(id: $id) {
      id
      tag_id
      tag_type
      media_type
      user_id
      extra
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listMediaPlayLogs = /* GraphQL */ `
  query ListMediaPlayLogs(
    $filter: ModelMediaPlayLogsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMediaPlayLogs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        tag_id
        tag_type
        media_type
        user_id
        extra
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
