// MembershipPlans
import API from '@aws-amplify/api';
import { toast } from 'react-toastify/dist/react-toastify.esm';
import * as graphqlMutation from '../graphql/mutations'
import * as graphqlQuery from '../graphql/queries'
import { initializeAuthCLI } from '../Helper/InitializeApi';

import { deviceOs, deviceType, getIpv4, aggregatelikedislike, getBrowser } from '../Helper/commonFunction'
import { getUniqueDetailByIds } from '../Helper/commonFunction';
import { fetchusersdetail } from '../redux/actions/dashboard';
import { friendConnectionFilter } from '../Helper/CalendarTag';
initializeAuthCLI();

export const add_User_Plan = async (amount, add_days, payment_intent_id, subscription_id) => {
    try {
        if (!localStorage.getItem("id") || !amount || !add_days || !payment_intent_id) {
            toast.error("Add User Plan Parameter Missing!")
            return;
        }
        let input = {}
        if (amount) input["amount"] = amount;
        if (add_days) input["add_days"] = add_days;
        if (payment_intent_id) input["payment_intent_id"] = payment_intent_id;
        if (subscription_id) input["subscription_id"] = subscription_id;
        input["user_id"] = localStorage.getItem("id");
        input["operation"] = "add_user_plan";
        let response = await API.graphql({
            query: graphqlQuery.MembershipPlans,
            variables: { input: input }
        })
        return response;
    } catch (error) {
        if (error.message) toast.error(error.message)
        else if (error && error.errors.length) toast.error(error.errors[0].message);
        else toast.error("Something Went Wrong!")
    }
}

export const get_User_Plan = async (operation, planId, intentId, pageno, from_date, to_date) => {
    try {
        if (!localStorage.getItem("id")) {
            toast.error("Get User Plan Parameter Missing!");
            return;
        }
        let input = {}
        if (operation) input["operation"] = operation;
        if (planId) input["plan_id"] = planId;
        if (intentId) input["payment_intent_id"] = intentId;
        if (pageno) input["pagination_start"] = pageno;
        if (from_date) input["from_date"] = from_date;
        if (to_date) input["to_date"] = to_date;
        input["user_id"] = localStorage.getItem("id");
        let response = await API.graphql({
            query: graphqlQuery.MembershipPlans,
            variables: { input: input }
        })
        return JSON.parse(response.data.MembershipPlans)
    } catch (error) {
        if (error.message) toast.error(error.message)
        else if (error && error.errors.length) toast.error(error.errors[0].message);
        else toast.error("Something Went Wrong!")
    }
}

export const add_User_Activity = async (activity_id, activity_size, activity_type) => {
    try {
        if (!localStorage.getItem("id") || !activity_id || !activity_size || !activity_type) {
            toast.error("Add User Activity Parameter Missing!")
            return;
        }
        let response = await API.graphql({
            query: graphqlQuery.userActivity,
            variables: {
                operation: "add_activity",
                user_id: localStorage.getItem("id"),
                activity_id: activity_id,
                activity_size: activity_size,
                activity_type: activity_type
            }
        })
        return response;
    } catch (error) {
        if (error.message) toast.error(error.message)
        else if (error && error.errors.length) toast.error(error.errors[0].message);
        else toast.error("Something Went Wrong!")
    }
}

export const get_User_Activity = async (activity_type) => {
    try {
        if (!localStorage.getItem("id") || !activity_type) {
            toast.error("Get User Activity Parameter Missing!")
            return;
        }
        let response = await API.graphql({
            query: graphqlQuery.userActivity,
            variables: {
                operation: "get_can_create",
                user_id: localStorage.getItem("id"),
                user_type: 1,
                activity_type: activity_type
            }
        })
        return JSON.parse(response.data.userActivity);
    } catch (error) {
        if (error.message) toast.error(error.message)
        else if (error && error.errors.length) toast.error(error.errors[0].message);
        else toast.error("Something Went Wrong!")
    }
}

export const get_All_Subscription = async (customer_id) => {
    try {
        if (!localStorage.getItem("id")) {
            toast.error("Get All Subscription Parameter Missing!")
            return;
        }
        let response = await API.graphql({
            query: graphqlQuery.user_payment_details,
            variables: {
                input: {
                    operation: "get_subscription",
                    user_id: localStorage.getItem("id"),
                }
            }
        });
        return JSON.parse(response.data.user_payment_details);
    } catch (error) {
        if (error.message) toast.error(error.message);
        else if (error && error.errors.length) toast.error(error.errors[0].message);
        else toast.error("Something Went Wrong!");
    }
}


export const activate_User = async (phone_number, operation) => {
    try {
        if (!phone_number) {
            toast.error("Activate User Parameter Missing!")
            return;
        }
        let response = await API.graphql({
            query: graphqlQuery.user_detail,
            variables: {
                input: {
                    operation: operation || "activate_user_phone",
                    phone: phone_number
                }
            }
        });
        return JSON.parse(response.data.user_detail);
    } catch (error) {
        if (error.message) toast.error(error.message);
        else if (error && error.errors.length) toast.error(error.errors[0].message);
        else toast.error("Something Went Wrong!");
    }
}

export const fetchMapDetail = async () => {
    try {
        if (!localStorage.getItem("id")) return;
        let response = await API.graphql({
            query: graphqlQuery.listMaps,
            variables: {
                filter: { item_id: { eq: localStorage.getItem("id") } }
            }
        });
        return response;
    } catch (error) {
        if (error.message) toast.error(error.message);
        else if (error && error.errors.length) toast.error(error.errors[0].message);
        else toast.error("Something Went Wrong!");
    }
}

export const CreateMapDetail = async (type, lat, lng, status) => {
    try {
        if (!localStorage.getItem("id") || !type || !lat || !lng || !status) {
            toast.error("Creating Map Detail Parameter Missing!")
            return;
        }
        let response = await API.graphql({
            query: graphqlMutation.createMaps,
            variables: {
                input: {
                    item_id: localStorage.getItem("id"),
                    item_type: type,
                    latitude: lat,
                    longitude: lng,
                    status: status
                }
            }
        });
        return response;
    } catch (error) {
        if (error.message) toast.error(error.message);
        else if (error && error.errors.length) toast.error(error.errors[0].message);
        else toast.error("Something Went Wrong!");
    }
}

export const UpdateMapDetail = async (id, lat, lng) => {
    try {
        if (!localStorage.getItem("id") || !id || !lat || !lng) {
            toast.error("Updating Map Detail Parameter Missing!")
            return;
        }
        let response = await API.graphql({
            query: graphqlMutation.updateMaps,
            variables: {
                input: { id: id, latitude: lat, longitude: lng },
                condition: { item_id: { eq: localStorage.getItem("id") }, item_type: { eq: "user" } }
            }
        });
        return response;
    } catch (error) {
        if (error.message) toast.error(error.message);
        else if (error && error.errors.length) toast.error(error.errors[0].message);
        else toast.error("Something Went Wrong!");
    }
}

export const cancelSubscription = async (subscriptionId) => {
    try {
        if (!subscriptionId) {
            toast.error("Cancel Subscription Parameter Missing!")
            return;
        }
        let response = await API.graphql({
            query: graphqlQuery.stripe_subscription,
            variables: {
                operation: "cancel_subscription",
                subscriptionId: subscriptionId
            }
        });
        return JSON.parse(response.data.stripe_subscription);
    } catch (error) {
        if (error.message) toast.error(error.message);
        else if (error && error.errors.length) toast.error(error.errors[0].message);
        else toast.error("Something Went Wrong!");
    }
}

export const mapSetting = async (operation, type, value, share_with, share_till, radius) => {
    try {
        if (!localStorage.getItem("id") || !operation) return;
        let input = {}
        if (operation) input["operation"] = operation;
        if (type) input["type"] = type;
        if (value) input["value"] = value;
        if (share_with) input["share_with"] = share_with;
        if (share_till) input["share_till"] = share_till;
        if (radius) input["radius"] = radius;
        input["user_id"] = localStorage.getItem("id");
        let response = await API.graphql({
            query: graphqlQuery.Map,
            variables: { input: input }
        });
        return JSON.parse(response.data.Map);
    } catch (error) {
        if (error.message) toast.error(error.message);
        else if (error && error.errors.length) toast.error(error.errors[0].message);
        else toast.error("Something Went Wrong!");
    }
}

export const appStoreOperation = async (operation, app_id, enable) => {
    try {
        let input = {}
        if (operation) input["operation"] = operation;
        if (app_id) input["app_id"] = app_id;
        if (enable !== undefined) input["enable"] = enable;
        input["user_id"] = localStorage.getItem("id");
        let response = await API.graphql({
            query: graphqlQuery.AppStore,
            variables: { input: input }
        });
        return JSON.parse(response.data.AppStore)
    } catch (error) {
        if (error.message) toast.error(error.message);
        else if (error && error.errors.length) toast.error(error.errors[0].message);
        else toast.error("Something Went Wrong!");
    }
}

export const updateDuckDimondAppStore = async (input) => {
    try {
        let response = await API.graphql({
            query: graphqlQuery.AppStore,
            variables: { input: input }
        });
        return JSON.parse(response.data.AppStore)
    } catch (error) {
        if (error.message) toast.error(error.message);
        else if (error && error.errors.length) toast.error(error.errors[0].message);
        else toast.error("Something Went Wrong!");
    }
}

export const updateWatchUnwatch = async (input) => {
    try {
        let response = await API.graphql({
            query: graphqlQuery.AppStore,
            variables: { input: input }
        });
        return JSON.parse(response.data.AppStore)
    } catch (error) {
        if (error.message) toast.error(error.message);
        else if (error && error.errors.length) toast.error(error.errors[0].message);
        else toast.error("Something Went Wrong!");
    }
}

export const fetchComments = async (app_id) => {
    try {
        let input = {}
        input["app_id"] = app_id;
        let response = await API.graphql({
            query: graphqlQuery.searchAppReviews,
            variables: {
                filter: { app_id: { eq: app_id } },
                sort: { direction: "desc", field: "createdAt" },
            }
        });
        return response.data.searchAppReviews
    } catch (error) {
        if (error.message) toast.error("Fetch Comment:-", error.message);
        else if (error && error.errors.length) toast.error("Fetch Comment:-", error.errors[0].message);
        else toast.error("Something Went Wrong!");
    }
}

export const fetchLikeDislikeAppReviews = async (appID) => {
    try {
        let input = {}
        input["operation"] = "user_detail";
        input["user_id"] = localStorage.getItem("id");
        let response = await API.graphql({
            query: graphqlQuery.listLikeDislikeAppReviews,
            variables: { filter: { app_id: { eq: appID } } }
        });
        return response.data;
    } catch (error) {
        if (error.message) toast.error(error.message);
        else if (error && error.errors.length) toast.error(error.errors[0].message);
        else toast.error("Something Went Wrong!");
    }
}

export const updateLikeDislikeAppReview = async (input) => {
    try {
        let response = await API.graphql({
            query: graphqlMutation.updateLikeDislikeAppReview,
            variables: { input: input }
        });
        return response;
    } catch (error) {
        if (error.message) toast.error("update Duck Daimond", error.message);
        else if (error && error.errors.length) toast.error("update:", error.errors[0].message);
        else toast.error("Something Went Wrong!");
    }
}

export const createLikeDislikeAppReview = async (input) => {
    try {
        let response = await API.graphql({
            query: graphqlMutation.createLikeDislikeAppReview,
            variables: { input: input }
        });
        return response;
    } catch (error) {
        if (error.message) toast.error("Create Duck Daimond", error.message);
        else if (error && error.errors.length) toast.error(error.errors[0].message);
        else toast.error("Something Went Wrong!");
    }
}



export const createAppReviews = async (input) => {
    try {
        let response = await API.graphql({
            query: graphqlMutation.createAppReviews,
            variables: { input: input }
        });
        return response;
    } catch (error) {
        if (error.message) toast.error(error.message);
        else if (error && error.errors.length) toast.error(error.errors[0].message);
        else toast.error("Something Went Wrong!");
    }
}

export const tagOperation = async (operation, type, media_key, conversation_id, tag_id) => {
    try {
        if (!localStorage.getItem("id") || !operation) {
            toast.error("Fetching Tag Operation Parameter Missing!")
            return;
        }
        let input = {}
        if (operation) input["operation"] = operation;
        if (type) input["type"] = type;
        if (media_key) input["media_key"] = media_key;
        if (conversation_id) input["conversation_id"] = conversation_id;
        if (tag_id) input["tag_id"] = tag_id
        input["user_id"] = localStorage.getItem("id");
        let response = await API.graphql({
            query: graphqlQuery.tag_creation,
            variables: { input: input }
        });
        return JSON.parse(response.data.tag_creation)
    } catch (error) {
        if (error.message) toast.error(error.message);
        else if (error && error.errors.length) toast.error(error.errors[0].message);
        else toast.error("Something Went Wrong!");
    }
}


export const getUserDetail = async (operation, userid, first_name, last_name, phone, about_me, gender, dob, address, friend_id, user_activation_key, search, profile_img, setting_name, setting_type, setting_value) => {
    try {
        if (!userid || !operation) {
            toast.error("User Detail Parameter Missing!")
            return;
        }
        let input = {}
        if (operation) input["operation"] = operation;
        if (user_activation_key) input["user_activation_key"] = user_activation_key
        if (first_name) input["first_name"] = first_name
        if (last_name) input["last_name"] = last_name
        if (phone) input["phone"] = phone
        if (gender) input["gender"] = gender
        if (dob) input["dob"] = dob
        if (address) input["address"] = address
        if (about_me) input["about_me"] = about_me
        if (friend_id) input["friend_id"] = friend_id
        if (profile_img) input["profile_img"] = profile_img
        if (search) input["search"] = search
        if (setting_name) input["setting_name"] = setting_name
        if (setting_type) input["setting_type"] = setting_type
        if (setting_value) input["setting_value"] = setting_value
        input["user_id"] = userid
        let response = await API.graphql({
            query: graphqlQuery.user_detail,
            variables: { input: input }
        });
        return JSON.parse(response.data.user_detail)
    } catch (error) {
        if (error.message) toast.error(error.message);
        else if (error && error.errors.length) toast.error(error.errors[0].message);
        else toast.error("Something Went Wrong!");
    }
}

export const getUsersDetail = async (operation, user_ids) => {
    try {
        let input = {}
        if (operation) input["operation"] = operation;
        if (user_ids) input["user_ids"] = user_ids
        let response = await API.graphql({
            query: graphqlQuery.user_detail,
            variables: { input: input }
        });
        return JSON.parse(response.data.user_detail)
    } catch (error) {
        if (error.message) toast.error(error.message);
        else if (error && error.errors.length) toast.error(error.errors[0].message);
        else toast.error("Something Went Wrong!");
    }
}

export const attachedTags = async (limit, nextToken) => {
    try {
        if (!localStorage.getItem("id")) return;
        let response = await API.graphql({
            query: graphqlQuery.searchTags,
            variables: {
                ...(limit && { "limit": limit }),
                ...(nextToken !== undefined && { "nextToken": nextToken }),
                filter: {
                    attached_with_master: { eq: true },
                    user_id: { eq: localStorage.getItem("id") }
                }
            }
        });
        return response;
    } catch (error) {
        if (error.message) toast.error(error.message);
        else if (error && error.errors.length) toast.error(error.errors[0].message);
        else toast.error("Something Went Wrong!");
    }
}

export const detachedTags = async (id) => {
    try {
        if (!id || !localStorage.getItem("lng") || !localStorage.getItem("lat")) return;
        let response = await API.graphql({
            query: graphqlMutation.updateTag,
            variables: {
                input: {
                    id: id,
                    tag_attach_id: null,
                    attached_with_master: false,
                    longitude: localStorage.getItem("lng"),
                    latitude: localStorage.getItem("lat")
                }
            }
        });
        return response;
    } catch (error) {
        if (error.message) toast.error(error.message);
        else if (error && error.errors.length) toast.error(error.errors[0].message);
        else toast.error("Something Went Wrong!");
    }
}

export const updateTagsBasedOnDragNDrop = async (id, parent_id, top_parent_id) => {
    try {
        if (!id) return;
        let response = await API.graphql({
            query: graphqlMutation.updateTag,
            variables: {
                input: {
                    id: id,
                    parent_id,
                    top_parent_id,
                }
            }
        });
        return response;
    } catch (error) {
        if (error.message) toast.error(error.message);
        else if (error && error.errors.length) toast.error(error.errors[0].message);
        else toast.error("Something Went Wrong!");
    }
}

export const updateTagStatus = async (id) => {
    try {
        let response = await API.graphql({
            query: graphqlMutation.updateTag,
            variables: { input: { id: id, status: "active" } }
        })
        return response;
    } catch (error) {
        if (error.message) toast.error(error.message)
        else if (error && error.errors.length) toast.error(error.errors[0].message);
        else toast.error("Something Went Wrong!")
    }
}

export const closeAccount = async (user_id, reason_value) => {
    try {
        let response = await API.graphql({
            query: graphqlQuery.user_detail,
            variables: {
                input: {
                    operation: "close_account",
                    user_id: user_id,
                    reason_value: reason_value
                }
            }
        });
        return response;

    } catch (error) {
        if (error.message) toast.error(error.message);
        else if (error && error.errors.length) toast.error(error.errors[0].message);
        else toast.error("Something Went Wrong!");
    }
}
export const marketPlaceChart = async (user_id, lte, gte) => {
    try {
        let variable = {};
        if (gte) variable["fromDate"] = gte;
        if (lte) variable["toDate"] = lte;
        variable["operation"] = 'sold_chart_data';
        variable["seller_id"] = user_id;
        let response = await API.graphql({ query: graphqlQuery.tag_creation, variables: { input: variable } });
        return JSON.parse(response.data.tag_creation);
    } catch (error) {
        if (error.message) toast.error(error.message);
        else if (error && error.errors.length) toast.error(error.errors[0].message);
        else toast.error("Something Went Wrong!");
    }
}
export const countMarketPlaceDate = async (user_id, startDate, endDate) => {
    try {
        let variable = {};
        let variableObject = {}
        variableObject["user_id"] = { "eq": user_id }
        variableObject["type"] = { eq: "ecommerce" }
        variableObject['or'] = [{ status: { eq: "active" } }, { status: { eq: "pending" } }, { status: { eq: "sold" } }]
        let variablAggreate = {}
        variablAggreate["field"] = 'status'
        variablAggreate["name"] = 'name'
        variablAggreate["type"] = 'terms'
        let variableStatus = {}
        variableStatus['or'] = [{ and: [{ createdAt: { gte: endDate } }, { createdAt: { lte: startDate } }, { is_sold: { eq: false } }] },
        { and: [{ sold_date: { gte: endDate } }, { sold_date: { lte: startDate } }, { is_sold: { eq: true } }] }]
        variable["filter"] = variableObject;
        variableObject['and'] = variableStatus;
        variable['aggregates'] = variablAggreate;
        //  variable["visibility"] = { ne: "private" }
        let response = await API.graphql({ query: graphqlQuery.searchTags, variables: variable });
        return response;
    } catch (error) {
        if (error.message) toast.error(error.message);
        else if (error && error.errors.length) toast.error(error.errors[0].message);
        else toast.error("Something Went Wrong!");
    }
}
export const countSold = async (user_id, lte, gte) => {
    try {
        let variable = {};
        if (gte) variable["fromDate"] = gte;
        if (lte) variable["toDate"] = lte;
        variable["operation"] = 'total_sale_buy_count';
        variable["user_id"] = user_id;

        let response = await API.graphql({ query: graphqlQuery.tag_creation, variables: { input: variable } });
        return JSON.parse(response.data.tag_creation);
    } catch (error) {
        if (error.message) toast.error(error.message);
        else if (error && error.errors.length) toast.error(error.errors[0].message);
        else toast.error("Something Went Wrong!");
    }
}
export const countMarketPlace = async (user_id, startDate, endDate) => {
    try {
        let variable = {};
        let variableObject = {}
        variableObject["user_id"] = { "eq": user_id }
        variableObject["type"] = { eq: "ecommerce" }
        if (startDate && endDate) variableObject["sold_date"] = { "lte": startDate, "gte": endDate }
        let variablAggreate = {}
        variablAggreate["field"] = 'status'
        variablAggreate["name"] = 'name'
        variablAggreate["type"] = 'terms'
        let variableStatus = {}
        variableStatus['or'] = [{ status: { eq: "active" } }, { status: { eq: "pending" } }, { status: { eq: "sold" } }]
        variable["filter"] = variableObject;
        variable['and'] = variableStatus;
        variable['aggregates'] = variablAggreate;

        //  variable["visibility"] = { ne: "private" }
        let response = await API.graphql({ query: graphqlQuery.searchTags, variables: variable });
        return response;
    } catch (error) {
        if (error.message) toast.error(error.message);
        else if (error && error.errors.length) toast.error(error.errors[0].message);
        else toast.error("Something Went Wrong!");
    }
}

export const createTagChat = async (content, recipient, sender, tagMessageRepliesId, tagTag_messagesId) => {
    try {
        let input = {}
        if (content) input["content"] = content;
        if (recipient) input["recipient"] = recipient;
        if (sender) input["sender"] = sender;
        if (tagMessageRepliesId) input["tagMessageRepliesId"] = tagMessageRepliesId;
        if (tagTag_messagesId) input["tagTag_messagesId"] = tagTag_messagesId;
        let response = await API.graphql({
            query: graphqlMutation.createTagMessage,
            variables: { input: input }
        });
        return response;
    } catch (error) {
        if (error.message) toast.error(error.message);
        else if (error && error.errors.length) toast.error(error.errors[0].message);
        else toast.error("Something Went Wrong!");
    }
}


export const createQR = async (ip_address, unique_key, jwt_auth, user_id) => {
    try {
        let input = {}
        if (ip_address) input["ip_address"] = ip_address;
        if (unique_key) input["id"] = unique_key;
        if (jwt_auth) input["jwt_auth"] = jwt_auth;
        if (user_id) input["user_id"] = user_id;
        let response = await API.graphql({
            query: graphqlMutation.createLoginQR,
            variables: { input: input }
        });
        return response;
    } catch (error) {
        if (error.message) toast.error(error.message);
        else if (error && error.errors.length) toast.error(error.errors[0].message);
        else toast.error("Something Went Wrong!");
    }
}

export const deleteQR = async (id) => {
    try {
        let input = {}
        if (id) input["id"] = id;
        let response = await API.graphql({
            query: graphqlMutation.deleteLoginQR,
            variables: { input: input }
        });
        return response;
    } catch (error) {
        if (error.message) toast.error(error.message);
        else if (error && error.errors.length) toast.error(error.errors[0].message);
        else toast.error("Something Went Wrong!");
    }
}



export const requestVerification = async (business_name, address1, address2, city, state, zip) => {
    try {
        let input = {}
        if (business_name) input["business_name"] = business_name;
        if (address1) input["address1"] = address1;
        if (address2) input["address2"] = address2;
        if (city) input["city"] = city;
        if (state) input["state"] = state;
        if (zip) input["zip"] = zip;
        input["operation"] = "verification_request";
        input["user_id"] = localStorage.getItem("id");

        let response = await API.graphql({
            query: graphqlQuery.user_detail,
            variables: { input: input }
        });
        return JSON.parse(response.data.user_detail);
    } catch (error) {
        if (error.message) toast.error(error.message);
        else if (error && error.errors.length) toast.error(error.errors[0].message);
        else toast.error("Something Went Wrong!");
    }
}

export const requestAutomaticVerification = async (business_name, address1, address2, city, state, zip) => {
    try {
        let input = {}
        if (business_name) input["business_name"] = business_name;
        if (address1) input["address1"] = address1;
        if (address2) input["address2"] = address2;
        if (city) input["city"] = city;
        if (state) input["state"] = state;
        if (zip) input["zip"] = zip;
        input["operation"] = "automatic_verification";
        input["user_id"] = localStorage.getItem("id");

        let response = await API.graphql({
            query: graphqlQuery.user_detail,
            variables: { input: input }
        });
        return JSON.parse(response.data.user_detail);
    } catch (error) {
        if (error.message) toast.error(error.message);
        else if (error && error.errors.length) toast.error(error.errors[0].message);
        else toast.error("Something Went Wrong!");
    }
}

export const fetchFriendList = async () => {
    try {
        let response = await API.graphql({
            query: graphqlQuery.connection_list,
            variables: {
                operation: "friend_list",
                user_id: localStorage.getItem("id")
            }

        })
        return JSON.parse(response.data.connection_list)
    } catch (error) {
        if (error.message) toast.error(error.message);
        else if (error && error.errors.length) toast.error(error.errors[0].message);
        else toast.error("Something Went Wrong!");
    }
}

export const fetchWexxleFriendList = async (user_id) => {
    try {
        let response = await API.graphql({
            query: graphqlQuery.connection_list,
            variables: {
                operation: "friend_list",
                user_id: user_id
            }

        })
        return JSON.parse(response.data.connection_list)
    } catch (error) {
        if (error.message) toast.error(error.message);
        else if (error && error.errors.length) toast.error(error.errors[0].message);
        else toast.error("Something Went Wrong!");
    }
}


export const searchFriendList = async (search) => {
    try {
        let response = await API.graphql({
            query: graphqlQuery.connection_list,
            variables: {
                search: search,
                operation: "friend_list",
                user_id: localStorage.getItem("id")
            }

        })
        return JSON.parse(response.data.connection_list)
    } catch (error) {
        if (error.message) toast.error(error.message);
        else if (error && error.errors.length) toast.error(error.errors[0].message);
        else toast.error("Something Went Wrong!");
    }
}

export const getNotificationDetail = async (notificationId) => {
    try {
        let response = await API.graphql({
            query: graphqlQuery.Notifications,
            variables: {
                input: {
                    notification_id: notificationId,
                    operation: "get_notification"
                }
            }
        })
        return JSON.parse(response.data.Notifications)
    } catch (error) {
        if (error.message) toast.error(error.message);
        else if (error && error.errors.length) toast.error(error.errors[0].message);
        else toast.error("Something Went Wrong!");
    }
}

export const marketPlaceOperation = async (operation, parent_id, status, name, tag_id, key_name_list, tag_type, limit) => {
    try {
        let input = {}
        if (name) input["name"] = name;
        if (limit) input["limit"] = limit;
        if (tag_id) input["tag_id"] = tag_id;
        if (status) input["status"] = status;
        if (tag_type) input["tag_type"] = tag_type;
        if (parent_id) input["parent_id"] = parent_id;
        if (operation) input["operation"] = operation;
        if (key_name_list) input["key_name_list"] = key_name_list;
        input["user_id"] = localStorage.getItem("id");
        let response = await API.graphql({
            query: graphqlQuery.MarketPlace,
            variables: { input: input }
        })
        return JSON.parse(response.data.MarketPlace)
    } catch (error) {
        if (error.message) toast.error(error.message);
        else if (error && error.errors.length) toast.error(error.errors[0].message);
        else toast.error("Something Went Wrong!");
    }
}

export const getVerification = async () => {
    try {
        let input = {}
        input["operation"] = "user_detail";
        input["user_id"] = localStorage.getItem("id");

        let response = await API.graphql({
            query: graphqlQuery.user_detail,
            variables: { input: input }
        });
        return JSON.parse(response.data.user_detail);
    } catch (error) {
        if (error.message) toast.error(error.message);
        else if (error && error.errors.length) toast.error(error.errors[0].message);
        else toast.error("Something Went Wrong!");
    }
}

export const getAddressVerification = async () => {
    try {
        let input = {}
        input["operation"] = "get_verification_data";
        input["user_id"] = localStorage.getItem("id");

        let response = await API.graphql({
            query: graphqlQuery.user_detail,
            variables: { input: input }
        });
        return JSON.parse(response?.data?.user_detail);
    } catch (error) {
        if (error.message) toast.error(error.message);
        else if (error && error.errors.length) toast.error(error.errors[0].message);
        else toast.error("Something Went Wrong!");
    }
}

export const connectionListOperation = async (operation, page_no, limit) => {
    try {
        let response = await API.graphql({
            query: graphqlQuery.connection_list,
            variables: {
                operation: operation,
                user_id: localStorage.getItem("id"),
                page_no: page_no,
                limit: limit
            }
        })
        return JSON.parse(response.data.connection_list)
    } catch (error) {
        if (error.message) toast.error(error.message);
        else if (error && error.errors.length) toast.error(error.errors[0].message);
        else toast.error("Something Went Wrong!");
    }
}


export const userActionLogForEvent = async (logtype, data) => {
    try {
        let input = {}
        if (!localStorage.getItem("id")) return;
        input["log_type"] = logtype;
        input["data"] = data ? data : {}
        input["remote_ip"] = await getIpv4();
        input["device_os"] = deviceOs();
        input["user_agent"] = getBrowser();
        input["device_type"] = deviceType();
        input["user_id"] = localStorage.getItem("id");
        let response = await API.graphql({
            query: graphqlMutation.createUserActionLog,
            variables: { input: input }
        })
        return response;
    } catch (error) {
        if (error.message) toast.error(error.message);
        else if (error && error.errors.length) toast.error(error.errors[0].message);
        else toast.error("Something Went Wrong!");
    }
}


export const chatList = async () => {
    try {
        // let input = {}
        // let filter = {}
        // filter["or"] = [];
        // filter["message_from"] = { eq: "normal" }
        // input["sender"] = { eq: localStorage.getItem("id") }
        // input["recipient"] = { eq: localStorage.getItem("id") }
        // Object.keys(input).map(val => filter["or"].push({ [val]: input[val] }))
        let response = await API.graphql({
            query: graphqlQuery.searchConversations,
            variables: {
                filter: {
                    message_from: { eq: "normal" },
                    or:
                        [
                            { sender: { eq: localStorage.getItem("id") } },
                            { recipient: { eq: localStorage.getItem("id") } }
                        ]

                },
                sort: { field: "createdAt", direction: "desc" }
            }
        });
        return response;
    } catch (error) {
        if (error.message) toast.error(error.message);
        else if (error && error.errors.length) toast.error(error.errors[0].message);
        else toast.error("Something Went Wrong!");
    }
}

export const sendFriendRequest = async (friend_id) => {
    try {

        let input = {}
        input["unfriend"] = 0
        input["connection"] = 1;
        input["friend_id"] = friend_id
        input["user_id"] = localStorage.getItem("id")
        let response = await API.graphql({
            query: graphqlQuery.friend_operations,
            variables: input
        })
        return JSON.parse(response.data.friend_operations)
    } catch (error) {
        if (error.message) toast.error(error.message);
        else if (error && error.errors.length) toast.error(error.errors[0].message);
        else toast.error("Something Went Wrong!");
    }
}


export const saveUserAddress = async (address, address_type, latitude, longitude) => {
    try {

        let input = {}
        input["status"] = "active"
        input["address"] = address
        input["latitude"] = latitude
        input["longitude"] = longitude
        input["address_type"] = address_type
        input["user_id"] = localStorage.getItem("id")
        let response = await API.graphql({
            query: graphqlMutation.createUserSavedPlaces,
            variables: { input: input }
        })
        return response
    } catch (error) {
        if (error.message) toast.error(error.message);
        else if (error && error.errors.length) toast.error(error.errors[0].message);
        else toast.error("Something Went Wrong!");
    }
}

export const fetchUserAddress = async (limit, nextToken) => {
    try {
        let variables = {}
        variables["filter"] = {}
        if (limit) variables["limit"] = limit
        if (nextToken !== undefined) variables["nextToken"] = nextToken
        variables["filter"]["user_id"] = { eq: localStorage.getItem("id") }
        let response = await API.graphql({
            query: graphqlQuery.searchUserSavedPlaces,
            variables: variables
        })
        return response
    } catch (error) {
        if (error.message) toast.error(error.message);
        else if (error && error.errors.length) toast.error(error.errors[0].message);
        else toast.error("Something Went Wrong!");
    }
}
export const fetchAddressUserId = async (userID, limit, nextToken) => {
    try {
        let variables = {}
        variables["filter"] = {}
        if (limit) variables["limit"] = limit
        if (nextToken !== undefined) variables["nextToken"] = nextToken
        variables["filter"]["user_id"] = { eq: userID }

        let response = await API.graphql({
            query: graphqlQuery.searchUserSavedPlaces,
            variables: variables
        })
        return response
    } catch (error) {
        if (error.message) toast.error(error.message);
        else if (error && error.errors.length) toast.error(error.errors[0].message);
        else toast.error("Something Went Wrong!");
    }
}


export const updateUserAddress = async (id, address, address_type, latitude, longitude) => {
    try {

        let input = {}
        input["id"] = id
        input["status"] = "active"
        input["address"] = address
        input["latitude"] = latitude
        input["longitude"] = longitude
        input["address_type"] = address_type
        input["user_id"] = localStorage.getItem("id")
        let response = await API.graphql({
            query: graphqlMutation.updateUserSavedPlaces,
            variables: { input: input }
        })
        return response
    } catch (error) {
        if (error.message) toast.error(error.message);
        else if (error && error.errors.length) toast.error(error.errors[0].message);
        else toast.error("Something Went Wrong!");
    }
}

export const deleteUserAddress = async (id) => {
    try {
        let input = {}
        input["id"] = id
        let response = await API.graphql({
            query: graphqlMutation.deleteUserSavedPlaces,
            variables: { input: input }
        })
        return response
    } catch (error) {
        if (error.message) toast.error(error.message);
        else if (error && error.errors.length) toast.error(error.errors[0].message);
        else toast.error("Something Went Wrong!");
    }
}


export const followUser = async (following_id) => {
    try {
        let input = {}
        input["following_type"] = "master"
        input["friend_id"] = following_id;
        input["operation"] = "follow_unfollow"
        input["user_id"] = localStorage.getItem("id")
        let response = await API.graphql({
            query: graphqlQuery.BlockAndWatch,
            variables: { input }
        })
        return JSON.parse(response.data.BlockAndWatch)
    } catch (error) {
        if (error.message) toast.error(error.message);
        else if (error && error.errors.length) toast.error(error.errors[0].message);
        else toast.error("Something Went Wrong!");
    }
}


export const fetchUserSetting = async (operation, setting_key, setting_value) => {
    try {
        let input = {}
        input["operation"] = operation;
        input["setting_key"] = setting_key;
        input["setting_value"] = setting_value;
        input["user_id"] = localStorage.getItem("id");
        let response = await API.graphql({
            query: graphqlQuery.UserSettings,
            variables: { input: input }
        })
        return JSON.parse(response.data.UserSettings);
    } catch (error) {
        if (error.message) toast.error(error.message);
        else if (error && error.errors.length) toast.error(error.errors[0].message);
        else toast.error("Something Went Wrong!");
    }
}

export const fetchFriendSetting = async (operation, setting_key, user_id) => {
    try {
        let input = {}
        input["operation"] = operation;
        input["setting_key"] = setting_key;
        input["user_id"] = user_id
        let response = await API.graphql({
            query: graphqlQuery.UserSettings,
            variables: { input: input }
        })
        return JSON.parse(response.data.UserSettings);
    } catch (error) {
        if (error.message) toast.error(error.message);
        else if (error && error.errors.length) toast.error(error.errors[0].message);
        else toast.error("Something Went Wrong!");
    }
}

export const fetchkidSetting = async (operation, setting_key, setting_value, user_id) => {
    try {
        let input = {}
        input["operation"] = operation;
        input["setting_key"] = setting_key;
        if (setting_value) input["setting_value"] = setting_value;
        input["user_id"] = user_id
        input["parent_id"] = localStorage.getItem('id')
        let response = await API.graphql({
            query: graphqlQuery.UserSettings,
            variables: { input: input }
        })
        return JSON.parse(response.data.UserSettings);
    } catch (error) {
        if (error.message) toast.error(error.message);
        else if (error && error.errors.length) toast.error(error.errors[0].message);
        else toast.error(error.msg);
    }
}
export const addAdult = async (to_email, relation_name) => {
    try {
        let input = {}
        input["operation"] = "invite_user";
        input["to_email"] = to_email;
        input["relation_name"] = relation_name;
        input["user_id"] = localStorage.getItem("id")
        let response = await API.graphql({
            query: graphqlQuery.user_detail,
            variables: { input: input }
        })
        return JSON.parse(response.data.user_detail)
    } catch (error) {
        if (error.message) toast.error(error.message);
        else if (error && error.errors.length) toast.error(error.errors[0].message);
        else toast.error("Something Went Wrong!");
    }
}

export const getMembers = async () => {
    try {
        let input = {}
        input["operation"] = "get_user_members_list";
        input["user_id"] = localStorage.getItem("id")
        let response = await API.graphql({
            query: graphqlQuery.user_detail,
            variables: { input: input }
        })
        return JSON.parse(response.data.user_detail)
    } catch (error) {
        if (error.message) toast.error(error.message);
        else if (error && error.errors.length) toast.error(error.errors[0].message);
        else toast.error("Something Went Wrong!");
    }
}
export const getDevicesOfMember = async () => {
    try {
        let input = {}
        input["operation"] = "get_user_members_list";
        input["user_id"] = localStorage.getItem("id")
        let response = await API.graphql({
            query: graphqlQuery.user_detail,
            variables: { input: input, filter: { login_status: true } }
        })
        return JSON.parse(response.data.user_detail)
    } catch (error) {
        if (error.message) toast.error(error.message);
        else if (error && error.errors.length) toast.error(error.errors[0].message);
        else toast.error("Something Went Wrong!");
    }
}

export const registrationOnInvitation = async (operation, user_id, invite_code) => {
    try {
        let input = {};
        input["user_id"] = user_id;
        input["operation"] = operation;
        input["invite_code"] = invite_code;
        let response = await API.graphql({
            query: graphqlQuery.user_detail,
            variables: { input: input },
        });
        const result = JSON.parse(response.data.user_detail);
        return result;
    } catch (error) {
        if (error.message) toast.error(error.message);
        else if (error && error.errors.length) toast.error(error.errors[0].message);
        else toast.error("Something Went Wrong!");
    }
};
export const addKid = async (email, profile_img, dob, is_alias, password, display_name) => {
    try {
        let input = {}
        input["operation"] = "setup_parent_kid_user";
        input["email"] = email;
        input["dob"] = dob;
        if (is_alias) input["is_alias"] = is_alias;
        input["password"] = password;
        input["display_name"] = display_name;
        input["profile_img"] = profile_img;
        input["user_id"] = localStorage.getItem("id")
        let response = await API.graphql({
            query: graphqlQuery.user_detail,
            variables: { input: input }
        })
        return JSON.parse(response.data.user_detail)
    } catch (error) {
        if (error.message) toast.error(error.message);
        else if (error && error.errors.length) toast.error(error.errors[0].message);
        else toast.error("Something Went Wrong!");
    }
}
export const getKidDetails = async (kid_id) => {
    try {
        let input = {}
        input["operation"] = "kid_details";
        input["kid_id"] = kid_id;
        input["user_id"] = localStorage.getItem("id")
        let response = await API.graphql({
            query: graphqlQuery.user_detail,
            variables: { input: input }
        })
        return JSON.parse(response.data.user_detail)
    } catch (error) {
        if (error.message) toast.error(error.message);
        else if (error && error.errors.length) toast.error(error.errors[0].message);
        else toast.error("Something Went Wrong!");
    }
}
export const updateKidProfile = async (email, profile_img, dob, is_alias, password, display_name, kid_id) => {
    try {
        let input = {}
        input["operation"] = "update_kid_profile";
        input["email"] = email;
        input["dob"] = dob;
        if (is_alias) input["is_alias"] = is_alias ? 1 : 0;
        if (password) input["password"] = password;
        input["display_name"] = display_name;
        input["profile_img"] = profile_img;
        input["user_id"] = localStorage.getItem("id")
        input["kid_id"] = kid_id
        let response = await API.graphql({
            query: graphqlQuery.user_detail,
            variables: { input: input }
        })
        return JSON.parse(response.data.user_detail)
    } catch (error) {
        if (error.message) toast.error(error.message);
        else if (error && error.errors.length) toast.error(error.errors[0].message);
        else toast.error("Something Went Wrong!");
    }
}

export const fetchUserLoginDetail = async (ip) => {
    try {
        let response = await API.graphql({
            query: graphqlQuery.searchUserLoginActionlogs,
            variables: {
                filter: {
                    remote_ip: { eq: ip },
                    device_os: { eq: deviceOs() },
                    device_type: { eq: deviceType() },
                    user_agent: { eq: getBrowser() },
                    user_id: { eq: localStorage.getItem("id") }
                }
            }
        })
        return response;
    } catch (error) {
        if (error.message) toast.error(error.message);
        else if (error && error.errors.length) toast.error(error.errors[0].message);
        else toast.error("Something Went Wrong!");
    }
}

export const createUserLoginAction = async (device_id) => {
    try {
        let input = {}
        input["device_name"] = "";
        input["device_id"] = device_id;
        input["device_os"] = deviceOs();
        input["device_type"] = deviceType();
        input["remote_ip"] = await getIpv4();
        input["user_agent"] = getBrowser();
        input["user_id"] = localStorage.getItem("id")
        input["login_status"] = localStorage.getItem("id") ? true : false
        let response = await API.graphql({
            query: graphqlMutation.createUserLoginActionlog,
            variables: { input: input }
        })
        return response;
    } catch (error) {
        if (error.message) toast.error(error.message);
        else if (error && error.errors.length) toast.error(error.errors[0].message);
        else toast.error("Something Went Wrong!");
    }
}

export const updateUserLoginAction = async (id, login_status) => {
    try {
        let input = {}
        input["id"] = id
        input["login_status"] = login_status;
        let response = await API.graphql({
            query: graphqlMutation.updateUserLoginActionlog,
            variables: { input: input }
        })
        return response;
    } catch (error) {
        if (error.message) toast.error(error.message);
        else if (error && error.errors.length) toast.error(error.errors[0].message);
        else toast.error("Something Went Wrong!");
    }
}

export const deleteUserLoginAction = async (id) => {
    try {
        let input = {}
        input["id"] = id
        let response = await API.graphql({
            query: graphqlMutation.deleteUserLoginActionlog,
            variables: { input: input }
        })
        return response;
    } catch (error) {
        if (error.message) toast.error(error.message);
        else if (error && error.errors.length) toast.error(error.errors[0].message);
        else toast.error("Something Went Wrong!");
    }
}

export const fetchUserLoginAction = async (limit, nextToken, userId) => {
    try {
        let response = await API.graphql({
            query: graphqlQuery.searchUserLoginActionlogs,
            variables: {
                limit: limit,
                nextToken: nextToken,
                filter: { user_id: { eq: userId } },
                sort: { field: "updatedAt", direction: "desc" }
            }
        })
        return response;
    } catch (error) {
        if (error.message) toast.error(error.message);
        else if (error && error.errors.length) toast.error(error.errors[0].message);
        else toast.error("Something Went Wrong!");
    }
}

export const setKidPhoneNumber = async (kid_id, phone) => {
    try {
        let input = {}
        input["operation"] = "setup_kid_phone_number";
        input["kid_id"] = kid_id;
        input["phone"] = phone
        input["user_id"] = localStorage.getItem("id")
        let response = await API.graphql({
            query: graphqlQuery.user_detail,
            variables: { input: input }
        })
        return JSON.parse(response.data.user_detail)
    } catch (error) {
        if (error.message) toast.error(error.message);
        else if (error && error.errors.length) toast.error(error.errors[0].message);
        else toast.error("Something Went Wrong!");
    }
}
export const getADS = async () => {
    try {

        let response = await API.graphql({
            query: graphqlQuery.searchTags,
            variables: {
                filter: {
                    visibility: { eq: "public" },
                    user_id: { eq: localStorage.getItem("id") }
                },
                sort: { direction: "desc", field: "createdAt" }

            }
        })
        return response.data.searchTags
    }
    catch (error) {
        if (error.message) toast.error(error.message);
        else if (error && error.errors.length) toast.error(error.errors[0].message);
        else toast.error("Something Went Wrong!");
    }
}
export const fetchStoreCapacity = async () => {
    try {
        let response = await API.graphql({
            query: graphqlQuery.searchUserStorageManagements,
            variables: {
                filter: {
                    user_id: { eq: localStorage.getItem("id") }
                }
            }
        })
        return response;
    } catch (error) {
        if (error.message) toast.error(error.message);
        else if (error && error.errors.length) toast.error(error.errors[0].message);
        else toast.error("Something Went Wrong!");
    }

}
export const ADSPrice = async (tag_radius, tag_type) => {
    try {
        let input = {}
        input["operation"] = "tag_pricing_settings";
        input["tag_radius"] = tag_radius?.toString();
        input["tag_type"] = tag_type?.toString();
        let response = await API.graphql({
            query: graphqlQuery.tag_creation,
            variables: { input: input }
        });
        return JSON.parse(response.data.tag_creation)
    }
    catch (error) {
        if (error.message) toast.error(error.message);
        else if (error && error.errors.length) toast.error(error.errors[0].message);
        else toast.error("Something Went Wrong!");
    }
}



export const createStoreManagement = async (file_size, file_type, tag_type, key_name, status) => {
    try {
        let input = {}
        input["status"] = status
        input["tag_type"] = tag_type
        input["key_name"] = key_name;
        input["file_size"] = file_size;
        input["file_type"] = file_type;
        input["user_id"] = localStorage.getItem("id")
        let response = await API.graphql({
            query: graphqlMutation.createUserStorageManagement,
            variables: { input: input }
        })
        return response;
    } catch (error) {
        if (error.message) toast.error(error.message);
        else if (error && error.errors.length) toast.error(error.errors[0].message);
        else toast.error("Something Went Wrong!");
    }
}


export const deleteStoreManagement = async (id) => {
    try {
        let input = {}
        input["id"] = id
        let response = await API.graphql({
            query: graphqlMutation.deleteUserStorageManagement,
            variables: { input: input }
        })
        return response;
    } catch (error) {
        if (error.message) toast.error(error.message);
        else if (error && error.errors.length) toast.error(error.errors[0].message);
        else toast.error("Something Went Wrong!");
    }
}

export const inAppEmailList = async (limit, nextToken) => {
    try {
        let response = await API.graphql({
            query: graphqlQuery.searchUserInAppEmails,
            variables: {
                limit: limit,
                nextToken: nextToken,
                sort: { direction: "desc", field: "createdAt" },
                filter: {
                    status: { eq: "active" },
                    or: { to_email: { eq: localStorage.getItem("email") }, to_user_id: { eq: localStorage.getItem("id") } }
                }
            }
        })
        return response;
    } catch (error) {
        if (error.message) toast.error(error.message);
        else if (error && error.errors.length) toast.error(error.errors[0].message);
        else toast.error("Something Went Wrong!");
    }
}

export const deleteInAppEmailList = async (id) => {
    try {
        let input = {}
        input["id"] = id
        let response = await API.graphql({
            query: graphqlMutation.deleteUserInAppEmails,
            variables: { input: input }
        })
        return response;
    } catch (error) {
        if (error.message) toast.error(error.message);
        else if (error && error.errors.length) toast.error(error.errors[0].message);
        else toast.error("Something Went Wrong!");
    }
}

export const fetchInAppUnreadEmail = async () => {
    try {
        let response = await API.graphql({
            query: graphqlQuery.searchUserInAppEmails,
            variables: {
                filter: {
                    read_status: { eq: "false" },
                    or: {
                        to_email: { eq: localStorage.getItem("email") },
                        to_user_id: { eq: localStorage.getItem("id") }
                    }
                }
            }
        })
        return response;
    } catch (error) {
        if (error.message) toast.error(error.message);
        else if (error && error.errors.length) toast.error(error.errors[0].message);
        else toast.error("Something Went Wrong!");
    }
}

export const updateInAppEmail = async (id, read_status) => {
    try {
        let input = {}
        input["id"] = id
        input["read_status"] = read_status
        let response = await API.graphql({
            query: graphqlMutation.updateUserInAppEmails,
            variables: { input: input }
        })
        return response;
    } catch (error) {
        if (error.message) toast.error(error.message);
        else if (error && error.errors.length) toast.error(error.errors[0].message);
        else toast.error("Something Went Wrong!");
    }
}

export const sendAppNotification = async (opponent_user_id, operation, template_id, extra_data, activity, email_id) => {
    try {
        let input = {}
        input["operation"] = operation
        input["template_id"] = template_id
        input["user_id"] = opponent_user_id
        if (activity) input["activity"] = activity
        input["extra_data"] = JSON.stringify(extra_data)
        if (email_id) input["email_id"] = email_id
        let response = await API.graphql({
            query: graphqlQuery.Notifications,
            variables: { input: input }
        })
        return JSON.parse(response.data.Notifications)
    } catch (error) {
        if (error.message) toast.error(error.message);
        else if (error && error.errors.length) toast.error(error.errors[0].message);
        else toast.error("Something Went Wrong!");
    }
}

export const updateNotificationReadStatus = async (id, read_status) => {
    try {
        let input = {}
        input["id"] = id
        input["read_status"] = read_status
        let response = await API.graphql({
            query: graphqlMutation.updateUserNotifications,
            variables: { input: input }
        })
        return response;
    } catch (error) {
        if (error.message) toast.error(error.message);
        else if (error && error.errors.length) toast.error(error.errors[0].message);
        else toast.error("Something Went Wrong!");
    }
}


export const userDetail = async (id) => {
    try {
        let input = {}
        input["user_id"] = id
        input["operation"] = "user_detail";
        let response = await API.graphql({
            query: graphqlQuery.user_detail,
            variables: { input: input }
        });
        return JSON.parse(response.data.user_detail)
    } catch (error) {
        if (error.message) toast.error(error.message)
        else if (error && error.errors.length) toast.error(error.errors[0].message);
        else toast.error("Something Went Wrong!")
    }
}

export const deleteconversation = async (id) => {
    try {
        let response = await API.graphql({
            query: graphqlMutation.deleteConversation,
            variables: { input: { id } }
        });
        return response;
    } catch (error) {
        if (error.message) toast.error(error.message);
        else if (error && error.errors.length) toast.error(error.errors[0].message);
        else toast.error("Something Went Wrong!");
    }
}

export const getComments = async (id) => {
    try {
        let input = {}
        input["tagComment_listId"] = { eq: id }
        let response = await API.graphql({
            query: graphqlQuery.searchComments,
            variables: { filter: input, limit: 5 }
        });
        return response
    } catch (error) {
        if (error.message) toast.error(error.message)
        else if (error && error.errors.length) toast.error(error.errors[0].message);
        else toast.error("Something Went Wrong!")
    }
}

export const recentAddress = async (limit, nextToken) => {
    try {
        let response = await API.graphql({
            query: graphqlQuery.searchTags,
            variables: {
                limit: limit,
                nextToken: nextToken,
                filter: { addresses: { exists: true } },
                sort: { direction: "desc", field: "createdAt" }
            }
        })
        return response;
    } catch (error) {
        if (error.message) toast.error(error.message);
        else if (error && error.errors.length) toast.error(error.errors[0].message);
        else toast.error("Something Went Wrong!");
    }
}


export const extrasDynamoDBOperation = async (operation, tag_id) => {
    try {
        let input = {}
        input["operation"] = operation;
        if (tag_id) input["tag_id"] = tag_id;
        input["user_id"] = localStorage.getItem("id");
        let response = await API.graphql({
            query: graphqlQuery.extrasDynamoDB,
            variables: { input: input }
        })
        return JSON.parse(response.data.extrasDynamoDB);
    } catch (error) {
        if (error.message) toast.error(error.message);
        else if (error && error.errors.length) toast.error(error.errors[0].message);
        else toast.error("Something Went Wrong!");
    }
}

export const deleteComment = async (id) => {
    try {
        let input = {}
        input["id"] = id;
        let response = await API.graphql({
            query: graphqlMutation.deleteComment,
            variables: { input: input }
        })
        return response;
    } catch (error) {
        if (error.message) toast.error(error.message);
        else if (error && error.errors.length) toast.error(error.errors[0].message);
        else toast.error("Something Went Wrong!");
    }
}

let comment_user_ids = []
let comment_user_list = []
export const getAllTagComments = async (tagid, limit, nextToken) => {
    try {
        let response = await API.graphql({
            query: graphqlQuery.searchComments,
            variables: {
                limit: limit,
                nextToken: nextToken,
                sort: { direction: "desc", field: "createdAt" },
                filter: { tagComment_listId: { eq: tagid } }
            }
        })
        if (response && response.data && response.data.searchComments && response.data.searchComments.items && response.data.searchComments.items.length) {
            let UsersIdListing = response.data.searchComments.items.map(val => val.user_id)
            let unique_id = [...new Set(UsersIdListing)]
            let getuniqueIds = await getUniqueDetailByIds(unique_id, comment_user_ids)
            if (getuniqueIds) await fetchusersdetail(getuniqueIds, comment_user_list);
            response.data.searchComments.items = response.data.searchComments.items.map(value => {
                let userFind = comment_user_list.length ? comment_user_list.find(val => val.user_id === value.user_id) : undefined;
                if (userFind) {
                    value["profile_img"] = userFind["profile_img"]
                    value["user_name"] = userFind["display_name"]
                    if (userFind.mk_profile_img) value["mk_profile_img"] = userFind["mk_profile_img"]
                    if (userFind.mk_first_name && userFind.mk_last_name) value["mk_user_name"] = userFind["mk_first_name"] + " " + userFind["mk_last_name"]
                }
                let duckcount = aggregatelikedislike(value.like_dislike_list.items, 'duck')
                let diamondcount = aggregatelikedislike(value.like_dislike_list.items, 'diamond')
                value["Duck"] = duckcount;
                value["Diamond"] = diamondcount
                return value;
            })
        }
        return response;
    } catch (error) {
        if (error.message) toast.error(error.message);
        else if (error && error.errors.length) toast.error(error.errors[0].message);
        else toast.error("Something Went Wrong!");
    }
}


export const mapSearch = async (searchtext, viewPort, tagtype, startDate, endDate) => {
    try {
        if (searchtext && !searchtext.trim().length) return;
        let variable = {};
        let visibilityCase = [];
        let visibilityBlockCase = [];
        variable["filter"] = {};
        if (searchtext) {
            let title = { "title": { "matchPhrasePrefix": searchtext } }
            visibilityCase.push(title);
            let content = { "content": { "matchPhrasePrefix": searchtext } }
            visibilityCase.push(content);
        }
        if (viewPort &&
            viewPort.bottom_right &&
            viewPort.top_left &&
            viewPort.bottom_right.lat &&
            viewPort.bottom_right.long &&
            viewPort.top_left.lat &&
            viewPort.top_left.long) {
            let lat_coords = {
                "latitude": {
                    "gt": viewPort.bottom_right.lat,
                    "lt": viewPort.top_left.lat
                }
            }
            visibilityBlockCase.push(lat_coords);
            let long_coords = {
                "longitude": {
                    "gt": viewPort.bottom_right.long,
                    "lt": viewPort.top_left.long
                }
            }
            visibilityBlockCase.push(long_coords);
        }
        if (tagtype) {
            let tagType = {
                "type": {
                    "eq": tagtype
                }
            }
            visibilityBlockCase.push(tagType);
        }
        if (startDate && endDate) {
            let tagstartDate = {
                "createdAt": {
                    "gte": startDate,
                    "lte": endDate
                }
            }
            visibilityBlockCase.push(tagstartDate);
        }
        variable["filter"] = { "status": { "ne": "inactive" } }
        variable["filter"]["or"] = visibilityCase;
        variable["sort"] = { direction: "desc", field: "createdAt" }
        if (visibilityBlockCase.length) variable["filter"]["and"] = visibilityBlockCase;
        let response = await API.graphql({
            query: graphqlQuery.searchTags,
            variables: variable
        })
        return response;
    } catch (error) {
        if (error.message) toast.error(error.message);
        else if (error && error.errors.length) toast.error(error.errors[0].message);
        else toast.error("Something Went Wrong!");
    }
}

export const tagAnalticsCreateUser = async (tag_id, is_view_only) => {
    let ipAddress = await getIpv4();
    let browser = getBrowser()?.toString();
    let device_Type = deviceType();
    try {
        let res = await API.graphql({
            query: graphqlMutation.createTagAnalyticsUserData,
            variables: {
                input: {
                    tagAnalytics_user_dataId: tag_id,
                    deviceType: device_Type,
                    ipAddress: ipAddress,
                    browser: browser,
                    user_id: localStorage.getItem('id'),
                    is_view_only: is_view_only
                }
            }
        })
        return res
    }
    catch (error) {
        if (error.message) toast.error(error.message);
        else if (error && error.errors.length) toast.error(error.errors[0].message);
        else toast.error("Something Went Wrong!");
    }
}
//for Impression, unique visitor
export const AdsAnalyticsForChart = async (tagId, start_date, end_date) => {

    let input = {}
    input['tagId'] = tagId
    input['user_id'] = localStorage.getItem('id')
    input['operation'] = 'get_tag_analytics'
    // if (start_date) input['start_date']=start_date
    // if(end_date) input['end_date']=end_date
    try {
        let user = await API.graphql({
            query: graphqlQuery.extrasDynamoDB,
            variables: {
                input: {
                    operation: "get_tag_analytics",
                    tag_id: tagId,
                    user_id: localStorage.getItem('id'),
                    start_date: start_date,
                    end_date: end_date,
                }
            }
        })
        let data = JSON.parse(user.data.extrasDynamoDB)
        return data.data
    }
    catch (error) {
        if (error.message) toast.error(error.message);
        else if (error && error.errors.length) toast.error(error.errors[0].message);
        else toast.error("Something Went Wrong!");
    }
}
export const AdsAnalyticsForChartAllTime = async (tagId) => {

    let input = {}
    input['tagId'] = tagId
    input['user_id'] = localStorage.getItem('id')
    input['operation'] = 'get_tag_analytics'
    // if (start_date) input['start_date']=start_date
    // if(end_date) input['end_date']=end_date
    try {
        let user = await API.graphql({
            query: graphqlQuery.extrasDynamoDB,
            variables: {
                input: {
                    operation: "get_tag_analytics",
                    tag_id: tagId,
                    user_id: localStorage.getItem('id'),

                }
            }
        })
        let data = JSON.parse(user.data.extrasDynamoDB)
        //console.log({user},{data})
        return data.data
    }
    catch (error) {
        if (error.message) toast.error(error.message);
        else if (error && error.errors.length) toast.error(error.errors[0].message);
        else toast.error("Something Went Wrong!");
    }
}
///For ADS analytics Chart Data
export const AdsAnalyticsDataForChart = async (tagId, toDate) => {

    try {
        let countResponse = await API.graphql({
            query: graphqlQuery.searchTagAnalyticsUserData,
            variables: {
                filter: {
                    tagAnalytics_user_dataId: { eq: tagId },
                    is_view_only: { eq: true },
                    createdAt: { gte: toDate, lte: new Date().toISOString() }

                }
            }
        })
        return countResponse.data.searchTagAnalyticsUserData.items
    }
    catch (error) {
        if (error.message) toast.error(error.message);
        else if (error && error.errors.length) toast.error(error.errors[0].message);
        else toast.error("Something Went Wrong!");
    }
}

export const storeEcommerseHistory = async (price, buyer_id, tag_id, tagCategory, tagSubCategory) => {
    try {
        let input = {}
        input["operation"] = 'ownership_transfer';
        input["seller_id"] = localStorage.getItem("id");
        input["buyer_id"] = buyer_id
        input["tag_id"] = tag_id
        input["price"] = price
        input["category"] = tagCategory
        input["sub_category"] = tagSubCategory
        let response = await API.graphql({
            query: graphqlQuery.tag_creation,
            variables: { input: input }
        })
        return response
    }
    catch (error) {
        if (error.message) toast.error(error.message);
        else if (error && error.errors.length) toast.error(error.errors[0].message);
        else toast.error("Something Went Wrong!");
    }
}

export const recentlyBuySoldItems = async (operation, seller_id, limit, page_no, buyer_id) => {
    try {
        let input = {}
        input["operation"] = operation;
        input["seller_id"] = localStorage.getItem("id");
        if (buyer_id) input["buyer_id"] = buyer_id
        if (seller_id) input["seller_id"] = seller_id
        input['limit'] = limit
        input["page_no"] = page_no
        let response = await API.graphql({
            query: graphqlQuery.tag_creation,
            variables: { input: input }
        })
        return JSON.parse(response.data.tag_creation)
    }
    catch (error) {
        if (error.message) toast.error(error.message);
        else if (error && error.errors.length) toast.error(error.errors[0].message);
        else toast.error("Something Went Wrong!");
    }

}

export const fetchTagWatchDetail = async (tag_ids) => {
    try {
        if (!localStorage.getItem("id")) return;
        let input = {}
        input["tag_id"] = tag_ids
        input["operation"] = "tag_watch_list"
        input["user_id"] = localStorage.getItem("id")
        let response = await API.graphql({
            query: graphqlQuery.BlockAndWatch,
            variables: { input }
        })
        return JSON.parse(response.data.BlockAndWatch)
    } catch (error) {
        if (error.message) toast.error(error.message);
        else if (error && error.errors.length) toast.error(error.errors[0].message);
        else toast.error("Something Went Wrong!");
    }
}


export const downloadRecipt = async (operation, tag_id) => {
    try {
        let input = {}
        input["operation"] = operation;
        input["user_id"] = localStorage.getItem("id")
        input["tag_id"] = tag_id
        let response = await API.graphql({
            query: graphqlQuery.tag_creation,
            variables: { input: input }
        })
        return JSON.parse(response.data.tag_creation)
    }
    catch (error) {
        if (error.message) toast.error(error.message);
        else if (error && error.errors.length) toast.error(error.errors[0].message);
        else toast.error("Something Went Wrong!");
    }
}

export const fetchTaskTag = async (tagtype, privacy) => {
    try {
        let variableObject = {}
        let visibilityCase = []
        let visibilityBlockCase = [];
        if (localStorage.getItem("id") && !["private", "public"].includes(privacy)) {
            // For FriendList //
            let friendList = await API.graphql({
                query: graphqlQuery.newsfeedfriend,
                variables: { user_id: localStorage.getItem("id") }
            })
            let friendlist = JSON.parse(friendList.data.newsfeedfriend)
            let { statusCode, body: { blocked_list, watchersList } } = friendlist
            if (!["watchers_only"].includes(privacy)) {
                let response = await fetchFriendList();
                let { statusCode, body } = response;
                if (statusCode === 200 && body.length) {
                    let frnd = friendConnectionFilter(body, privacy)
                    var frndList = frnd.filter(value => Object.keys(value).length !== 0);
                    if (frndList.length) visibilityCase.push(...frndList);
                }
            } else if (statusCode === 200 && watchersList.length) {
                let watcherList = watchersList.map(val => ({ "visibility": { "eq": "watchers_only" }, "user_id": { "eq": val.follower_id } }))
                if (watcherList.length) visibilityCase.push(...watcherList);
            }
            // For Blocklist //
            if (statusCode === 200 && blocked_list.length) {
                for (let i = 0; i < blocked_list.length; i++) {
                    let block = {
                        "user_id": {
                            "ne": blocked_list[i].blocked_user_id
                        }
                    }
                    visibilityBlockCase.push(block)
                }
            }
        }
        // For Self
        if (["private"].includes(privacy) && localStorage.getItem("id")) {
            let privateprivacy = { "user_id": { "eq": localStorage.getItem("id") } }
            visibilityCase.push(privateprivacy)
        } else if (!localStorage.getItem("id")) {
            //without login
            let withoutloginprivacy = { "visibility": { "eq": "public" } }
            visibilityCase.push(withoutloginprivacy)
        }
        // For status must be active only //
        variableObject["status"] = { "eq": "active" }
        // for tagtype equal to Task or Event  //
        variableObject["type"] = { "match": tagtype }
        // For privacy public //
        if (privacy === "public") {
            let publicprivacy = { "visibility": { "eq": "public" } }
            visibilityCase.push(publicprivacy)
        }
        let variable = {};
        variable["sort"] = { direction: "desc", field: "createdAt" }
        variable["filter"] = {
            ...variableObject,
            ...(visibilityCase.length && { "or": visibilityCase }),
            ...((visibilityBlockCase.length) && {
                "and": {
                    ...(visibilityBlockCase.length && { "and": visibilityBlockCase })
                }
            }),
        }
        let response = await API.graphql({
            query: graphqlQuery.searchTags,
            variables: variable
        })
        if (response?.data?.searchTags?.items?.length) return response.data.searchTags.items
        else return [];
    } catch (error) {
        if (error.message) toast.error(error.message);
        else if (error && error.errors.length) toast.error(error.errors[0].message);
        else toast.error("Something Went Wrong!");
    }
}

export const createMediaplayLog = async (media_type, tag_id, tag_type, extra) => {
    try {
        let input = {};
        if (tag_id) input["tag_id"] = tag_id
        if (tag_type) input["tag_type"] = tag_type
        if (media_type) input["media_type"] = media_type
        if (extra) input["extra"] = JSON.stringify(extra)
        input["user_id"] = localStorage.getItem("id")
        let response = await API.graphql({
            query: graphqlMutation.createMediaPlayLogs,
            variables: { input: input }
        })
        return response;
    } catch (error) {
        if (error.message) toast.error(error.message);
        else if (error && error.errors.length) toast.error(error.errors[0].message);
        else toast.error("Something Went Wrong!");
    }
}

export const updateMediaplayLog = async (id, media_type, tag_id, tag_type, extra) => {
    try {
        let input = {};
        if (id) input["id"] = id
        if (tag_id) input["tag_id"] = tag_id
        if (tag_type) input["tag_type"] = tag_type
        if (media_type) input["media_type"] = media_type
        if (extra) input["extra"] = JSON.stringify(extra)
        input["user_id"] = localStorage.getItem("id")
        let response = await API.graphql({
            query: graphqlMutation.updateMediaPlayLogs,
            variables: { input: input }
        })
        return response;
    } catch (error) {
        if (error.message) toast.error(error.message);
        else if (error && error.errors.length) toast.error(error.errors[0].message);
        else toast.error("Something Went Wrong!");
    }
}


export const getMediaplayLogById = async (id) => {
    try {
        let variable = {};
        if (id) variable["id"] = id
        let response = await API.graphql({
            query: graphqlQuery.getMediaPlayLogs,
            variables: variable
        })
        return response;
    } catch (error) {
        if (error.message) toast.error(error.message);
        else if (error && error.errors.length) toast.error(error.errors[0].message);
        else toast.error("Something Went Wrong!");
    }
}


export const getMediaplayLog = async (tag_id) => {
    try {
        let variable = {};
        variable["filter"] = {};
        variable["filter"]["tag_id"] = { "eq": tag_id }
        variable["filter"]["user_id"] = { "eq": localStorage.getItem("id") }
        let response = await API.graphql({
            query: graphqlQuery.searchMediaPlayLogs,
            variables: variable
        })
        if (response?.data?.searchMediaPlayLogs?.items?.length) return response.data.searchMediaPlayLogs.items
        else return [];
    } catch (error) {
        if (error.message) toast.error(error.message);
        else if (error && error.errors.length) toast.error(error.errors[0].message);
        else toast.error("Something Went Wrong!");
    }
}


export const createTag = async (variable) => {
    try {
        let response = await API.graphql({
            query: graphqlMutation.createTag,
            variables: { input: variable }
        });
        return response;
    } catch (error) {
        if (error.message) toast.error(error.message);
        else if (error && error.errors.length) toast.error(error.errors[0].message);
        else toast.error("Something Went Wrong!");
    }
}

export const fetchAdultDetail = async (variables) => {
    try {
        let response = await API.graphql({
            query: graphqlQuery.user_detail,
            ...(variables && { variables })
        });
        return JSON.parse(response.data.user_detail);
    } catch (error) {
        if (error.message) toast.error(error.message);
        else if (error && error.errors.length) toast.error(error.errors[0].message);
        else toast.error("Something Went Wrong!");
    }
}

export const updateTag = async (variables) => {
    try {
        let response = await API.graphql({
            query: graphqlMutation.updateTag,
            ...(variables && { variables })
        })
        return response;
    } catch (error) {
        if (error.message) toast.error(error.message)
        else if (error && error.errors.length) toast.error(error.errors[0].message);
        else toast.error("Something Went Wrong!")
    }
}