import axios from "axios";
import { store } from "../stores";
import { DOCUSIGNUSER, FETCHDOCUSIGNTOKEN } from "../types";
import { Redirect_URl, docuSignUrl, removeDocuCred } from "../../functions";


export const fetchDocusignToken = payload => async dispatch => {
    dispatch({
        type: FETCHDOCUSIGNTOKEN,
        payload: payload
    })
}

export const getUserInfo = () => async dispatch => {
    try {
        const response = await axios({
            baseURL: docuSignUrl,
            url: '/oauth/userinfo',
            method: "GET",
            headers: {
                ...(store.getState().docusign.access_token &&
                    { 'Authorization': `Bearer ${store.getState().docusign.access_token}` })
            }
        })
        const { data, status } = response
        if (status === 200) {
            dispatch({
                type: DOCUSIGNUSER,
                payload: data?.accounts[0]
            })
        }
        return { data, status };
    } catch (error) {
        if (error.response) {
            const { status, statusText } = error.response;
            if (status === 401 && statusText === "Unauthorized") removeDocuCred()
        }
    }
}


export const createEnvelop = (payload, baseuri, accountId) => async dispatch => {
    try {

        const response = await axios({
            url: `${baseuri}/restapi/v2.1/accounts/${accountId}/envelopes`,
            method: "POST",
            data: JSON.stringify(payload),
            headers: {
                ...(store.getState().docusign.access_token &&
                    { 'Authorization': `Bearer ${store.getState().docusign.access_token}` }),
                'Content-Type': 'application/json'
            }
        })
        const { status, statusText } = response;
        return { status, statusText };
    } catch (error) {
        console.log(error)
    }
}


export const embeddedURI = (baseuri, accountId) => async dispatch => {
    try {
        const response = await axios({
            url: `${baseuri}/restapi/v2.1/accounts/${accountId}/views/console`,
            method: "POST",
            data: JSON.stringify({ "returnUrl": Redirect_URl}),
            headers: {
                ...(store.getState().docusign.access_token &&
                    { 'Authorization': `Bearer ${store.getState().docusign.access_token}` }),
                'Content-Type': 'application/json'
            }
        })
        const { status, statusText, data } = response;
        return { status, statusText, data };
    } catch (error) {
        console.log(error)
    }
}