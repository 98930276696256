import { App_Detail } from '../types'

let initailstate = {
    obj: {},
    loader: false
}
// eslint-disable-next-line
export default (state = initailstate, action) => {
    switch (action.type) {
        case App_Detail:
            return {
                obj: action.payload,
                loader: true
            }
        default:
            return state;
    }
}