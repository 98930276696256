// user reducer types
export const SET_AUTHENTICATED = 'SET_AUTHENTICATED'
export const SET_UNAUTHENTICATED = 'SET_UNAUTHENTICATED'
export const SET_USER = 'SET_USER'
export const LOADING_USER = 'LOADING_USER'
export const LOADING_ORDER = 'LOADING_ORDER'

// UI reducer types
export const SET_ERRORS = 'SET_ERRORS'
export const LOADING_UI = 'Loading...'
export const BOOKING_LOADING_UI = 'Loading...'
export const CARD_LOADING_UI = 'Loading...'
export const CLEAR_ERRORS = 'CLEAR_ERRORS'
export const LOADING_DATA = 'LOADING_DATA'

// Search Reducer types
export const SET_PEOPLEORTAG_SEARCH = 'SET_PEOPLEORTAG_SEARCH'
export const PEOPLEORTAG_DETAIL = 'PEOPLEORTAG_DETAIL'


// Chat Data

export const CHAT_DETAIL = "CHAT_DETAIL"

// Appstore Data
export const App_Detail = "App_Detail"

export const App_Listing = 'App_Listing'
export const SetError = 'SetError'
export const LoadUI = 'LoadUI'
export const AppDetail = 'AppDetail'
export const AppDetailPage = 'AppDetailPage'
export const Reset = "Reset"
export const UPDATEAPP = "UPDATEAPP"
// Privacy Setting
export const GETUSERPRIVACYSETTING = 'GETUSERPRIVACYSETTING'
export const CHANGEUSERPRIVACYSETTING = 'CHANGEUSERPRIVACYSETTING'
// s3 Image 
export const PREVIEWIMAGE = 'PREVIEWIMAGE'
//
export const CHANGEINTERFACE = "CHANGEINTERFACE"
export const RESETINTERFACE = "RESETINTERFACE"
// Create Tag Open
export const CREATETAGOPENPOPUP = "CREATETAGOPENPOPUP"
// Change Route Search Output close
// DashBoard Tag List Detail
export const TAGLISTDETAIL = "TAGLISTDETAIL"
export const LISTUI = "LISTUI"
// Clear Store
export const CLEAR_STORE = "CLEAR_STORE"
//
export const USERPROFILEDETAIL = "USERPROFILEDETAIL"
//
export const CREATEQR = "CREATEQR"

export const FETCHTAGCALENDAR = "FETCHTAGCALENDAR"
export const CLEARCALENDARTAG = "CLEARCALENDARTAG"

export const LOADING_QRUI = "LOADING_QRUI"
export const PROFILE_UI = "PROFILE_UI"
export const CLEAR_PROFILE_ERROR = "CLEAR_PROFILE_ERROR"
export const RESET_CHAT_USER = "RESET_CHAT_USER"

export const UPDATE_LOADING = "UPDATE_LOADING"
export const UPDATE_ERROR = "UPDATE_ERROR"
export const TAG_UPDATE = "TAG_UPDATE"
export const CLEAR_UPDATE = "CLEAR_UPDATE"
export const RESET_TAG = "RESET_TAG"

export const PAYMENT_UI = "PAYMENT_UI"
export const CREATE_PAYMENT = "CREATE_PAYMENT"
export const PAYMENT_ERROR = "PAYMENT_ERROR"


export const MAP_UI = "MAP_UI"
export const UPDATEMASTERTAG = "UPDATEMASTERTAG"

export const CreateOverlayOverMaps = "CreateOverlayOverMaps";

export const HIDE_INSTALL_PROMPT = "HIDE_INSTALL_PROMPT";
export const GETAPPREVIEW = "GETAPPREVIEW";
export const CREATEAD = "CREATEAD";
export const WALLETBALANCE = "WALLETBALANCE";
export const ISPAYMENTSUCESS = "ISPAYMENTSUCESS";
export const FETCHUSERACTIONLOG = "FETCHUSERACTIONLOG";
export const CHILDPERMISSION = "CHILDPERMISSION";
export const NOCHILD = "NOCHILD"
export const UNREADEMAILCOUNT = "UNREADEMAILCOUNT";
export const UNREADNOTIFICATIONCOUNT = "UNREADNOTIFICATIONCOUNT";

export const FETCHUSERACTION = "UNREADNOTIFICATIONCOUNT";

export const ISDEVICEDEVICELOGIN = "ISDEVICEDEVICELOGIN"
export const CLEARISDEVICEDEVICELOGIN = "CLEARISDEVICEDEVICELOGIN"
export const ENABLETOFETCHSETTING = "ENABLETOFETCHSETTING"
export const ADSFROMADVERTISMENTTAB = "ADSFROMADVERTISMENTTAB"
export const TAGSEARCHONMAP = "TAGSEARCHONMAP"
export const RESETTAGSEARCHONMAP = "RESETTAGSEARCHONMAP"

export const FETCHSPOTIFYTOKEN = "FETCHSPOTIFYTOKEN"
export const FETCHDOCUSIGNTOKEN = "FETCHDOCUSIGNTOKEN"
export const DOCUSIGNUSER = "DOCUSIGNUSER"
export const ENABLEAPPLIST = "ENABLEAPPLIST"


export const OPENNESTEDCREATEDTAG = "OPENNESTEDCREATEDTAG"
export const OPENCREATETAGPOPUP = "OPENCREATETAGPOPUP"
export const CONDITIONALEDITTAG = "CONDITIONALEDITTAG"
export const EXPLORETAB = "EXPLORETAB"
export const EXPLORETAGS = "EXPLORETAGS"
export const AR2DSWITCH = "AR2DSWITCH"
export const FETCHCUSTOMER = "FETCHCUSTOMER"
export const ADDRESSVERIFIED = "ADDRESSVERIFIED"
export const FETCHTAGDETAILFORMAP = "FETCHTAGDETAILFORMAP"
export const FETCHADS = "FETCHADS"
export const ISPERSONALADS = "ISPERSONALADS"