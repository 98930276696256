import { Redirect_URl } from "../../functions";
import { FETCHSPOTIFYTOKEN } from "../types";
import axios from "axios";
import qs from 'qs'
import Moment from "moment";
import {toast} from 'react-toastify/dist/react-toastify.esm';
import { store } from '../stores';
import { spotifyClientId as client_id, spotifyClientSecret as client_secret } from "../../functions";

const access_token_url = process.env.REACT_APP_SPOTIFY_AUTH_URL

export const fetchSpotifyToken = payload => async dispatch => {
    try {
        const response = await axios({
            url: access_token_url,
            method: "POST",
            data: qs.stringify({
                'grant_type': 'authorization_code',
                'code': payload,
                'redirect_uri': Redirect_URl
            }),
            headers: {
                'Authorization': `Basic ${btoa(`${client_id}:${client_secret}`)}`,
                'Content-Type': 'application/x-www-form-urlencoded',
            }
        })
        const { data, status } = response
        if (status === 200) {
            dispatch({
                type: FETCHSPOTIFYTOKEN,
                payload: data
            })
        }
        return { data, status };
    } catch (error) {
        console.log(error)
    }
}

export const refreshAccessToken = (refresh_token) => async dispatch => {
    try {
        let response = await axios({
            url: access_token_url,
            method: "POST",
            data: qs.stringify({
                grant_type: 'refresh_token',
                refresh_token: refresh_token
            }),
            headers: {
                'Authorization': `Basic ${btoa(`${client_id}:${client_secret}`)}`,
                'Content-Type': 'application/x-www-form-urlencoded',
            }
        })
        const { data, status } = response
        if (status === 200) {
            dispatch({
                type: FETCHSPOTIFYTOKEN,
                payload: {
                    access_token: data.access_token,
                    expires_in: data.expires_in,
                    refresh_token: sessionStorage.getItem('refresh_token')
                }
            })
        }
        return { data, status };
    } catch (error) {
        console.log(error)
    }
}


export const getSpotifyError = err => dispatch => {
    if (err.statusCode === 401 && ["An error occurred while communicating with Spotify's Web API.\nDetails: Invalid access token.", "An error occurred while communicating with Spotify's Web API.\nDetails: The access token expired."].includes(err.message)) {
        const session = sessionStorage.getItem("refresh_token")
        if (session) dispatch(refreshAccessToken(session));
    } else {
        toast.error(err.message);
    }
}


const spotiTokenExpire = () => {
    const expireTime = store.getState().spotify.expires_in;
    if (!expireTime) return null;
    const tokenTime = Moment(expireTime);
    const currentTime = Moment(Date.now());
    const duration = Moment.duration(currentTime.diff(tokenTime)).asSeconds();
    if (duration < 3550) return false;
    else return true;
}

// const sleep = (ms) => new Promise(resolve => setTimeout(resolve, ms));

// <---- if token is expired then ---->

export const requestForAccessToken = payload => async dispatch => {
    const response = spotiTokenExpire();
    const session = sessionStorage.getItem("refresh_token")
    if (!session || response === null) return;
    if (response) return await dispatch(refreshAccessToken(session))
}
