import { TAGSEARCHONMAP, RESETTAGSEARCHONMAP, AR2DSWITCH, FETCHTAGDETAILFORMAP } from "../types";

const initialState = {
    url: "",
    module: "",
    tagDetail: {},
    mapsearchtag: []
}


const mapReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case TAGSEARCHONMAP:
            return {
                ...state,
                module: payload.mode,
                mapsearchtag: payload.item
            }
        case RESETTAGSEARCHONMAP:
            return {
                ...state,
                module: "",
                mapsearchtag: payload.item
            }
        case AR2DSWITCH:
            return {
                ...state,
                url: payload
            }
        case FETCHTAGDETAILFORMAP:
            return {
                ...state,
                tagDetail: payload
            }
        default:
            return state;
    }
}
export default mapReducer

