import React, { PureComponent } from 'react';
import {Link} from 'react-router-dom';

export default class Errorpage extends PureComponent {
  render() {
    return (
      <div>
        <div className="error-page" >
          <div className="error-wrap" >
            <figure className="mb-10">
              <img src="../images/sad-emoji.png" alt="sad" height="70" />
            </figure>
            <h1 className="error-card__title">Oops, page not found</h1>
            <p style={{ marginBottom: '10px', fontSize: '20px' }}>We are very sorry for the inconvenience. It looks like you are trying to access a page that has been deleted or never even existed.</p>
            <Link to='/' className="btn btn-primary"><i className="fas fa-home"></i>&nbsp;
              Take Me Home</Link>
          </div>
        </div>
      </div>
    );
  }
}
