export const checkNumberFieldLength = (elem) => {
    if (elem.target.value.length > 10) {
        elem.target.value = elem.target.value.slice(0, 10);
    }
}

export const validateText = (val) => {
    if (!val || val.trim().length === 0) return true;
}
export const validateZip = (val) => {
    if (val.length <= 4) return true;
}

export const validateDob = (val) => {
    if (val === false || val === null) return true;
}

export const validateNum = (val) => {
    if (val.trim() === "") return true;
}

export const validatePassword = (val) => {
    let Passwordregex = new RegExp("^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,12}$");
    if (!Passwordregex.test(val)) return true;
    else return false;
}

export const validatePasswordMatch = (prev, post) => {
    if (prev !== post) return true;
}

export const thousandSeparator = (x) => {
    return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}