import { CREATETAGOPENPOPUP, CreateOverlayOverMaps, OPENCREATETAGPOPUP, OPENNESTEDCREATEDTAG } from '../types'

const initialstate = {
    route: "",
    field: {},
    Isopen: false,
    extraProps: {},
    tagProperty: {},
    calendarTagProperty: {}
}

export const Interfaceroot = (state = initialstate, action) => {
    switch (action.type) {
        case CREATETAGOPENPOPUP:
            return {
                ...state,
                Isopen: action.payload,
                tagProperty: {},
            }
        case CreateOverlayOverMaps:
            return {
                ...state,
                Isopen: action.payload.overLayOpen,
                tagProperty: action.payload.tagProps
            }
        case OPENNESTEDCREATEDTAG:
            return {
                ...state,
                Isopen: action.payload.isOpen,
                extraProps: action.payload.extraProps
            }
        case OPENCREATETAGPOPUP:
            const { isOpen, extra, calendarTag } = action.payload
            return {
                ...state,
                field: extra,
                Isopen: isOpen,
                calendarTagProperty: calendarTag || {}
            }
        default:
            return state;
    }
}
