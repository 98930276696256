import { FETCHTAGCALENDAR, CLEARCALENDARTAG } from '../types'
import { toast } from 'react-toastify/dist/react-toastify.esm';
import { CalendarTag } from '../../Helper/CalendarTag';
import { fetchTaskTag } from '../../api/GraphqlApi';


export const calendarFeed = payload => async dispatch => {
    try {
        let tasktag = []
        const { from, to, type, privacy, latitude, longitude, tagType } = payload;
        if (["event", "task"].includes(tagType)) {
            const response = await fetchTaskTag(tagType,privacy);
            if (response?.length) tasktag.push(...response);
        }
        const calendartag = await CalendarTag(from, to, privacy, latitude, longitude, tagType);
        const ids = new Set(calendartag.map(d => d.id));
        const CalendarFeed = tasktag?.length ? [...calendartag, ...tasktag.filter(d => !ids.has(d.id))] : [...calendartag];
        dispatch({
            type: FETCHTAGCALENDAR,
            payload: Array.isArray(CalendarFeed) && CalendarFeed.length
                ? { "Feed": CalendarFeed, "type": type } : []
        })
    } catch (error) {
        if (error.message) toast.error(error.message);
        else if (error && error.errors.length) toast.error(error.errors[0].message);
        else toast.error("Something Went Wrong!");
    }
}

export const CalendarTagReset = () => dispatch => { dispatch({ type: CLEARCALENDARTAG }) }
