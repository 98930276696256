import { TAGLISTDETAIL, RESET_TAG, LISTUI, CLEAR_ERRORS, UPDATE_LOADING, UPDATE_ERROR, TAG_UPDATE, CLEAR_UPDATE } from '../types'
import { s3Imagefetch } from '../../Helper/s3';
import { toast } from 'react-toastify/dist/react-toastify.esm';
import API from '@aws-amplify/api';
import * as graphqlQuery from '../../graphql/queries'
import { initializeAuthCLI } from '../../Helper/InitializeApi';
import { getMediaplayLog, getUsersDetail } from '../../api/GraphqlApi';
import { getUniqueDetailByIds, aggregatelikedislike, publicTagFilter, FetchTagByIds } from '../../Helper/commonFunction';
import { listsorted } from '../../Helper/commonFunction';
import moment from 'moment';
import * as geolib from "geolib";

toast.configure();
initializeAuthCLI();
export const TagDetail = (id, viewPort, tagtype, startDate, endDate, latitude, longitude, isPublic, searchtext, multitagtype = [], latLong, extraProps) => async (dispatch) => {
    try {
        let user_ids = [];
        let user_list = [];
        let visibilityCase = [];
        let comment_user_ids = [];
        let comment_user_list = [];
        let visibilityBlockCase = [];
        dispatch({ type: LISTUI })
        if (id && !["user_profile"].includes(isPublic)) {
            let friendList = await API.graphql({
                query: graphqlQuery.newsfeedfriend,
                variables: { user_id: id }
            })
            let friendlist = JSON.parse(friendList.data.newsfeedfriend)
            let { statusCode, body: { friend_list, blocked_list } } = friendlist
            if (statusCode === 200 && friend_list.length) {
                for (let i = 0; i < friend_list.length; i++) {
                    let frnd = {
                        "user_id": {
                            "eq": friend_list[i].friend_id
                        },
                        "visibility": {
                            "eq": friend_list[i].connection === "1" ? "friends_only" : (
                                friend_list[i].connection === "2" ? "family_only" : (
                                    friend_list[i].connection === "3" && "acquaintances_only"
                                )
                            )
                        }
                    }
                    visibilityCase.push(frnd)
                }
            }
            if (statusCode === 200 && blocked_list.length) {
                for (let i = 0; i < blocked_list.length; i++) {
                    let block = { "user_id": { "ne": blocked_list[i].blocked_user_id } }
                    visibilityBlockCase.push(block)
                }
            }
        }

        if (!["user_profile"].includes(isPublic)) {
            let pblc = { "visibility": { "eq": "public" } }
            visibilityCase.push(pblc);
        }

        let prvt = { "user_id": { "eq": id } }
        if (id) visibilityCase.push(prvt)

        if (viewPort &&
            viewPort.bottom_right &&
            viewPort.top_left &&
            viewPort.bottom_right.lat &&
            viewPort.bottom_right.long &&
            viewPort.top_left.lat &&
            viewPort.top_left.long) {
            let lat_coords = {
                "latitude": {
                    "gt": viewPort.bottom_right.lat,
                    "lt": viewPort.top_left.lat
                }
            }
            visibilityBlockCase.push(lat_coords);
            let long_coords = {
                "longitude": {
                    "gt": viewPort.bottom_right.long,
                    "lt": viewPort.top_left.long
                }
            }
            visibilityBlockCase.push(long_coords);
            let non_attached = {
                "tag_attach_id": {
                    "exists": false
                }
            }
            visibilityBlockCase.push(non_attached);
        }

        if (tagtype) {
            let tagType = {
                "type": {
                    "eq": tagtype
                }
            }
            visibilityBlockCase.push(tagType);
        }
        if (startDate && endDate) {
            let tagstartDate = {
                "createdAt": {
                    "gte": startDate,
                    "lte": endDate
                }
            }
            visibilityBlockCase.push(tagstartDate);
        }
        // Check for tags without location
        if (latitude && longitude) {
            let location_with_lat = {
                "latitude": {
                    "gt": latitude,
                }
            }
            visibilityBlockCase.push(location_with_lat);
            let location_with_lng = {
                "longitude": {
                    "gt": longitude,
                }
            }
            visibilityBlockCase.push(location_with_lng);
        }
        if (searchtext) {
            // let title = { "title": { "matchPhrasePrefix": searchtext } }
            // visibilityBlockCase.push(title);
            // let content = { "content": { "matchPhrasePrefix": searchtext } }
            // visibilityBlockCase.push(content);
            const tagsearchtext = {
                "or": [
                    {
                        "title": {
                            "match": searchtext
                        }
                    },
                    {
                        "content": {
                            "match": searchtext
                        }
                    }
                ]
            }
            visibilityBlockCase.push(tagsearchtext);
        }

        let variable = {};
        variable["filter"] = {};
        if (extraProps) variable["filter"] = extraProps
        variable["filter"]['status'] = { "eq": "active" }
        variable["filter"]["or"] = visibilityCase;
        variable["sort"] = [
            { direction: "desc", field: "is_public" },
            { direction: "desc", field: "updatedAt" }
        ]
        if (multitagtype.length) variable["filter"]['type'] = { match: multitagtype?.toString() }
        if (latLong && latLong.maxLat && latLong.maxLon && latLong.minLat && latLong.minLon) {
            variable["filter"]['latitude'] = { "lte": latLong.maxLat, "gte": latLong.minLat }
            variable["filter"]['longitude'] = { "lte": latLong.maxLon, "gte": latLong.minLon }
        }
        if (visibilityBlockCase.length) variable["filter"]["and"] = visibilityBlockCase;
        const { or, ...rest } = variable.filter
        let res = await API.graphql({
            query: graphqlQuery.searchTags,
            variables: {
                filter: {
                    or: [
                        {
                            and: [rest],
                            or: [...or]
                        },
                        ...(!searchtext ? [{ public_distance: { eq: 0 } }] : [])
                    ]
                }
            }
        })
        if (res && res.data && res.data.searchTags && res.data.searchTags.items) {
            if (Array.isArray(res.data.searchTags.items) && res.data.searchTags.items.length) {
                var tagshowList = await Promise.all(res.data.searchTags.items.map(async val => {
                    var { media, user_id, comment_list, like_dislike_list, spotify, latitude, longitude, id, tagAttached_tag_idId } = val
                    //For Media file of Tag
                    let playlist = await getMediaplayLog(id)
                    if (Array.isArray(media) && media.length) {
                        var mediaImage = []
                        media.forEach(async (key) => {
                            var mediaObject = {}
                            if (key) {
                                let s3Image = await s3Imagefetch(key)
                                let ext = key.substring(key.lastIndexOf('.') + 1)
                                mediaObject["ext"] = ext
                                mediaObject["url"] = s3Image
                                if (['mp4', 'mp3'].includes(ext) && playlist && playlist.length) {
                                    playlist.forEach((value) => {
                                        const { extra, id } = value
                                        let info = JSON.parse(extra)
                                        if (info.key === key) {
                                            mediaObject["id"] = id;
                                            mediaObject["duration"] = info.duration;
                                            mediaObject["currentTime"] = info.currentTime;
                                        }
                                    })
                                }
                                mediaImage.push(mediaObject)
                            }
                        })
                        val.image = mediaImage
                    } else {
                        val.image = [{ "ext": "", "url": "" }]
                    }


                    // Fetch commented User Ids//
                    let UsersIdListing = Array.isArray(comment_list.items) && comment_list.items.length ?
                        comment_list.items.map(val => val.user_id) :
                        []
                    let unique_id = [...new Set(UsersIdListing)]
                    let response = await getUniqueDetailByIds(unique_id, comment_user_ids)
                    if (response) await Promise.all([fetchusersdetail(response, comment_user_list)])

                    // Fetch tag User Detail//
                    let responseitem = await getUniqueDetailByIds([user_id], user_ids);
                    if (responseitem) await Promise.all([fetchusersdetail(responseitem, user_list)])

                    // For User Image of Comment//
                    if (Array.isArray(comment_list) && comment_list.length) {
                        comment_list.map(async (value, index) => {
                            if (Array.isArray(value.user_detail) && value.user_detail.length) {
                                if (value.user_detail[0].profile_img) {
                                    let commentPic = await s3Imagefetch(value.user_detail[0].profile_img)
                                    val.comment_list[index].user_detail[0]["commentPic"] = commentPic
                                } else {
                                    val.comment_list[index].user_detail[0]["commentPic"] = ""
                                }
                            }
                        })
                    }

                    // Total Count of duck and diamond of Comment 
                    if (Array.isArray(comment_list.items) && comment_list.items.length) {
                        comment_list.items.map((_, index) => {
                            comment_list.items[index].Diamond = aggregatelikedislike(comment_list.items[index].like_dislike_list.items, 'diamond')
                            comment_list.items[index].Duck = aggregatelikedislike(comment_list.items[index].like_dislike_list.items, 'duck');
                            comment_list.items[index].commentduckdiamond = comment_list.items[index].like_dislike_list.items.length ? comment_list.items[index].like_dislike_list.items : []
                            return null
                        })
                    }
                    // Total Count of duck and diamond  
                    val.Diamond = aggregatelikedislike(like_dislike_list.items, 'diamond')
                    val.Duck = aggregatelikedislike(like_dislike_list.items, 'duck');
                    val.tagduckdiamond = like_dislike_list.items.length ? like_dislike_list.items : []

                    if (Array.isArray(comment_list.items) && comment_list.items.length) {
                        comment_list.items = listsorted(comment_list.items, "createdAt").reverse()
                    }
                    if (spotify) val.spotify = JSON.parse(spotify);
                    if (localStorage.getItem("lat") && localStorage.getItem("lng") && latitude && longitude) {
                        let totalProximityDistance = geolib.getDistance(
                            { latitude: localStorage.getItem("lat"), longitude: localStorage.getItem("lng") },
                            { latitude: latitude, longitude: longitude },
                            0.01
                        )
                        val.distance = totalProximityDistance * 0.000621371192
                    }
                    if (tagAttached_tag_idId) {
                        let taggedItems = await FetchTagByIds(tagAttached_tag_idId);
                        val.autoAttachedTags = taggedItems
                    }
                    return val;
                }))
            }

            tagshowList = publicTagFilter(tagshowList)
            //for close account //
            if (Array.isArray(tagshowList) && tagshowList.length) {
                // eslint-disable-next-line
                tagshowList = tagshowList.filter(value => {
                    let userFind = user_list.length ? user_list.find(val => val.user_id === value.user_id) : "";
                    if (userFind !== undefined) return value;
                })
            }
            if (Array.isArray(tagshowList) && tagshowList.length) {
                tagshowList = tagshowList.filter(val => {
                    if (val.visibility === "public") {
                        if (val.is_admin_approved && val.is_abvertising_paid && moment(val.public_end).isSameOrAfter(moment()) &&
                            moment(val.public_start).isSameOrBefore(moment())) {
                            // eslint-disable-next-line 
                            return val
                        }
                        else return false
                    }
                    else return val
                })
            }
            //for close account //
            if (Array.isArray(tagshowList) && tagshowList.length) {
                tagshowList = tagshowList.map(value => {
                    let userFind = user_list.length ? user_list.find(val => val.user_id === value.user_id) : undefined;
                    if (userFind) {
                        value["profile_img"] = userFind["profile_img"]
                        value["user_name"] = userFind["display_name"]
                        if (userFind.mk_profile_img) value["mk_profile_img"] = userFind["mk_profile_img"]
                        if (userFind.mk_first_name && userFind.mk_last_name) value["mk_user_name"] = userFind["mk_first_name"] + " " + userFind["mk_last_name"]
                    }
                    return value;
                })
            }
            if (Array.isArray(tagshowList) && tagshowList.length) {
                tagshowList = tagshowList.map(value => {
                    for (let i = 0; i < value.comment_list.items.length; i++) {
                        let isfind = comment_user_list.length ? comment_user_list.find(val => val.user_id === value.comment_list.items[i].user_id) : undefined
                        if (isfind) {
                            value.comment_list.items[i]["user_name"] = isfind["display_name"]
                            value.comment_list.items[i]["profile_img"] = isfind["profile_img"]
                            if (isfind.mk_first_name && isfind.mk_last_name) value.comment_list.items[i]["mk_user_name"] = isfind["mk_first_name"] + " " + isfind["mk_last_name"]
                            if (isfind.mk_profile_img) value.comment_list.items[i]["mk_profile_img"] = isfind["mk_profile_img"]
                        }
                    }
                    return value
                })
            }
            dispatch({
                type: TAGLISTDETAIL,
                payload: {
                    Taglist: Array.isArray(tagshowList) && tagshowList.length ? [...tagshowList].map(item => ({ ...item, show: true })) : []
                }
            })
        } else {
            dispatch({ type: CLEAR_ERRORS });
        }
    } catch (error) {
        if (error.message) toast.error(error.message);
        else if (error && error.errors.length) toast.error(error.errors[0].message);
        else toast.error("Something Went Wrong!");
        dispatch({ type: CLEAR_ERRORS });
    }
}


export const EditTag = (id) => async dispatch => {
    try {
        initializeAuthCLI()
        if (id) {
            dispatch({ type: UPDATE_LOADING })
            let res = await API.graphql({
                query: graphqlQuery.getTag,
                variables: { id: id }
            })
            if (res && res.data && res.data.getTag) {
                dispatch({
                    type: TAG_UPDATE,
                    payload: {
                        TagDetail: Object.keys(res.data.getTag).length
                            ? res.data.getTag : {}
                    }
                })
            } else {
                dispatch({ type: UPDATE_ERROR });
            }
        } else {
            dispatch({ type: CLEAR_UPDATE });
        }
    } catch (error) {
        if (error.message) toast.error(error.message)
        else if (error && error.errors.length) toast.error(error.errors[0].message);
        else toast.error("Something Went Wrong!")
        dispatch({ type: UPDATE_ERROR });
    }
}

export const tagDuckDiamondCount = async (id, type) => {
    try {
        let filterInput = {}
        if (type === "tag") filterInput["tagLike_dislike_listId"] = { "eq": id }
        if (type === "comment") filterInput["commentLike_dislike_listId"] = { "eq": id }
        let res = await API.graphql({
            query: graphqlQuery.searchLikeDislikes,
            variables: {
                filter: filterInput,
                aggregates: { field: "duck", name: "Count", type: "sum" }
            }
        })
        if (res && res.data && res.data.searchLikeDislikes) {
            return res.data.searchLikeDislikes;
        } else {
            toast.error("Something Went Wrong!")
        }
    } catch (error) {
        if (error.message) toast.error(error.message)
        else if (error && error.errors.length) toast.error(error.errors[0].message);
        else toast.error("Something Went Wrong!")
    }
}

export const fetchusersdetail = async (ids, usersDetails) => {
    let response = await getUsersDetail("users_name_image", ids?.toString())
    if (response && response.body && response.body.length) usersDetails.push(...response.body);
}


export const TagReset = () => dispatch => { dispatch({ type: RESET_TAG }) }