import { CHILDPERMISSION } from '../types'
import { NOCHILD } from '../types'

let initailstate = {
    permission: {},
}

export const childPermission = (state = initailstate, action) => {
    const { permission } = state
    const { payload } = action
    
    switch (action.type) {
        case CHILDPERMISSION:
            return {
                ...state,
                permission: Object.assign(permission, payload.object)
            }
        case NOCHILD:
            return {
                ...state,
                permission: {}
            }
        default:
            return state;
    }
}
