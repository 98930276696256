import { API } from "@aws-amplify/api";
import * as graphqlQuery from "../graphql/queries";
import { initializeAuthCLI } from "../Helper/InitializeApi";
import {toast} from 'react-toastify/dist/react-toastify.esm';
initializeAuthCLI();

export const getWalletBalance = async (operation) => {
  try {
    if (!localStorage.getItem("id")) {
      toast.error("Get User Plan Parameter Missing!");
      return;
    }
    let input = {};
    if (operation) input["operation"] = operation;
    input["user_id"] = localStorage.getItem("id");
    let response = await API.graphql({
      query: graphqlQuery.UserWallet,
      variables: { input: input },
    });
    const result = JSON.parse(response.data.UserWallet);
    return result;
  } catch (error) {
    if (error.message) toast.error(error.message);
    else if (error && error.errors.length) toast.error(error.errors[0].message);
    else toast.error("Something Went Wrong!");
  }
};

export const transactionLog = async (operation, limit, page_no) => {
  try {
    if (!localStorage.getItem("id")) {
      toast.error("Get User Plan Parameter Missing!");
      return;
    }
    let input = {};
    if (operation) input["operation"] = operation;
    input["user_id"] = localStorage.getItem("id");
    input["limit"] = limit
    input["page_no"] = page_no
    let response = await API.graphql({
      query: graphqlQuery.UserWallet,
      variables: { input: input },
    });
    const result = JSON.parse(response.data.UserWallet);
    return result;
  } catch (error) {
    if (error.message) toast.error(error.message);
    else if (error && error.errors.length) toast.error(error.errors[0].message);
    else toast.error("Something Went Wrong!");
  }
};

export const getPeopleSearchList = async (params) => {
  try {
    let response = await API.graphql({
      query: graphqlQuery.connection_list,
      variables: {
        operation: "friend_list",
        user_id: localStorage.getItem("id"),
        search: params
      }

    })
    return JSON.parse(response.data.connection_list)
  } catch (error) {
    if (error.message) toast.error(error.message);
    else if (error && error.errors.length) toast.error(error.errors[0].message);
    else toast.error("Something Went Wrong!");
  }
}
export const giftCardToUseridFromWallet = async (operation, to_user_id, wexxle_points, notes) => {
  try {
    if (!localStorage.getItem("id")) {
      toast.error("Get User Plan Parameter Missing!");
      return;
    }
    let input = {};
    let userID = parseInt(localStorage.getItem("id"));

    if (operation) input["operation"] = operation;
    input["user_id"] = userID
    input["wexxle_points"] = wexxle_points
    input["to_user_id"] = to_user_id
    input["notes"] = notes
    let response = await API.graphql({
      query: graphqlQuery.UserWallet,
      variables: { input: input },
    });
    const result = JSON.parse(response.data.UserWallet);
    return result;
  } catch (error) {
    if (error.message) toast.error(error.message);
    else if (error && error.errors.length) toast.error(error.errors[0].message);
    else toast.error("Something Went Wrong!");
  }
};
export const giftCardToEmailFromWallet = async (operation, to_email, wexxle_points, notes) => {
  try {
    if (!localStorage.getItem("id")) {
      toast.error("Please Login again!");
      return;
    }
    let input = {};
    let userID = parseInt(localStorage.getItem("id"));
    if (operation) input["operation"] = operation;
    input["user_id"] = userID
    input["wexxle_points"] = wexxle_points
    input["to_email"] = to_email
    input["notes"] = notes
    let response = await API.graphql({
      query: graphqlQuery.UserWallet,
      variables: { input: input },
    });
    const result = JSON.parse(response.data.UserWallet);
    return result;
  } catch (error) {
    if (error.message) toast.error(error.message);
    else if (error && error.errors.length) toast.error(error.errors[0].message);
    else toast.error("Something Went Wrong!");
  }
};
export const giftCardToEmailFromCard = async (operation, to_email, amount, transactionId, notes) => {
  try {
    if (!localStorage.getItem("id")) {
      toast.error("Get User Plan Parameter Missing!");
      return;
    }
    let input = {};
    let userID = parseInt(localStorage.getItem("id"));
    if (operation) input["operation"] = operation;
    input["user_id"] = userID
    input["amount"] = amount
    input["to_email"] = to_email
    input["notes"] = notes
    input["transactionId"] = transactionId
    let response = await API.graphql({
      query: graphqlQuery.UserWallet,
      variables: { input: input },
    });
    const result = JSON.parse(response.data.UserWallet);
    return result;
  } catch (error) {
    if (error.message) toast.error(error.message);
    else if (error && error.errors.length) toast.error(error.errors[0].message);
    else toast.error("Something Went Wrong!");
  }
};
export const giftCardToUseridFromCard = async (operation, to_user_id, amount, transactionId, notes) => {
  try {
    if (!localStorage.getItem("id")) {
      toast.error("Get User Plan Parameter Missing!");
      return;
    }
    let input = {};
    let userID = parseInt(localStorage.getItem("id"));
    if (operation) input["operation"] = operation;
    input["user_id"] = userID
    input["amount"] = amount
    input["to_user_id"] = to_user_id
    input["notes"] = notes
    input["transactionId"] = transactionId
    let response = await API.graphql({
      query: graphqlQuery.UserWallet,
      variables: { input: input },
    });
    const result = JSON.parse(response.data.UserWallet);
    return result;
  } catch (error) {
    if (error.message) toast.error(error.message);
    else if (error && error.errors.length) toast.error(error.errors[0].message);
    else toast.error("Something Went Wrong!");
  }
};
export const giftCardRedeem = async (operation, gift_card_id) => {
  try {
    if (!localStorage.getItem("id")) {
      toast.error("Get User Plan Parameter Missing!");
      return;
    }
    let input = {};
    let userID = parseInt(localStorage.getItem("id"));
    if (operation) input["operation"] = operation;
    input["user_id"] = userID
    input["gift_card_id"] = gift_card_id

    let response = await API.graphql({
      query: graphqlQuery.UserWallet,
      variables: { input: input },
    });
    const result = JSON.parse(response.data.UserWallet);
    return result;
  } catch (error) {
    if (error.message) toast.error(error.message);
    else if (error && error.errors.length) toast.error(error.errors[0].message);
    else toast.error("Something Went Wrong!");
  }
};


export const registrationBasedOnReferral = async (operation, email_id, referral_code) => {
  try {
    let input = {};
    input["email_id"] = email_id;
    input["operation"] = operation;
    input["referral_code"] = referral_code;
    let response = await API.graphql({
      query: graphqlQuery.UserWallet,
      variables: { input: input },
    });
    const result = JSON.parse(response.data.UserWallet);
    return result;
  } catch (error) {
    if (error.message) toast.error(error.message);
    else if (error && error.errors.length) toast.error(error.errors[0].message);
    else toast.error("Something Went Wrong!");
  }
};
export const referToFriend = async (to_email) => {
  try {
    if (!localStorage.getItem("id")) {
      toast.error("Get User Plan Parameter Missing!");
      return;
    }
    let userID = parseInt(localStorage.getItem("id"));
    let input = {};
    input["operation"] = "set_user_referral";
    input["user_id"] = userID
    input["to_email"] = to_email
    let response = await API.graphql({
      query: graphqlQuery.UserWallet,
      variables: { input: input },
    });
    const result = JSON.parse(response.data.UserWallet);
    return result;
  } catch (error) {
    if (error.message) toast.error(error.message);
    else if (error && error.errors.length) toast.error(error.errors[0].message);
    else toast.error("Something Went Wrong!");
  }
};
export const filterTransctionsLogs = async (from_date, to_date, limit, page_no, show_all) => {
  try {
    if (!localStorage.getItem("id")) {
      toast.error("Get User Plan Parameter Missing!");
      return;
    }
    let userID = parseInt(localStorage.getItem("id"));
    let input = {};
    input["operation"] = "get_wallet_transaction_logs";
    input["user_id"] = userID
    input["from_date"] = from_date
    input["to_date"] = to_date
    if (limit) input["limit"] = limit
    if (page_no) input["page_no"] = page_no
    if (show_all) input["show_all"] = show_all
    let response = await API.graphql({
      query: graphqlQuery.UserWallet,
      variables: { input: input },
    });
    const result = JSON.parse(response.data.UserWallet);
    return result;
  } catch (error) {
    if (error.message) toast.error(error.message);
    else if (error && error.errors.length) toast.error(error.errors[0].message);
    else toast.error("Something Went Wrong!");
  }
};
export const getChart = async () => {
  try {
    if (!localStorage.getItem("id")) {
      toast.error("Get User Plan Parameter Missing!");
      return;
    }
    let userID = parseInt(localStorage.getItem("id"));
    let input = {};
    input["operation"] = "get_chart_states";
    input["user_id"] = userID

    let response = await API.graphql({
      query: graphqlQuery.UserWallet,
      variables: { input: input },
    });
    const result = JSON.parse(response.data.UserWallet);
    return result;
  } catch (error) {
    if (error.message) toast.error(error.message);
    else if (error && error.errors.length) toast.error(error.errors[0].message);
    else toast.error("Something Went Wrong!");
  }
};
export const wexxlePontsToUSDconvert = async () => {
  try {
    let input = {};
    input["operation"] = "wexxle_points_ratio";
    let response = await API.graphql({
      query: graphqlQuery.UserWallet,
      variables: { input: input },
    });
    const result = JSON.parse(response.data.UserWallet);
    return result;
  } catch (error) {
    if (error.message) toast.error(error.message);
    else if (error && error.errors.length) toast.error(error.errors[0].message);
    else toast.error("Something Went Wrong!");
  }
};
export const AD_PaymentByWallet = async (wexxle_points, tagId) => {
  try {
    if (!localStorage.getItem("id")) {
      toast.error("Get User Plan Parameter Missing!");
      return;
    }
    let userID = parseInt(localStorage.getItem("id"));
    let input = {};
    input["operation"] = "proceed_tag_payment";
    input["wexxle_points"] = wexxle_points?.toString();
    input["tagId"] = tagId
    input["user_id"] = userID

    let response = await API.graphql({
      query: graphqlQuery.UserWallet,
      variables: { input: input },
    });
    const result = JSON.parse(response.data.UserWallet);
    return result;
  } catch (error) {
    if (error.message) toast.error(error.message);
    else if (error && error.errors.length) toast.error(error.errors[0].message);
    else toast.error("Something Went Wrong!");
  }
};