import {toast} from 'react-toastify/dist/react-toastify.esm';
import { fetchkidSetting } from '../../api/GraphqlApi';
import { CHILDPERMISSION } from '../types'
import { NOCHILD } from '../types'
export const childPermission = () => async (dispatch) => {
    try {
        if (localStorage.getItem('isKid') === "false") {
            dispatch(
                {
                    type: NOCHILD,
                    payload: {}
                })
                return
        }
        let permissions = "accept_friend_request,chat_with_friends,create_tags,like_tags,add_comments,change_profile_picture,change_password"
        let res = await fetchkidSetting("user_general_setting", permissions, '', localStorage.getItem('id'))
        let obj = res.body.data.map(function (name, key) {
            return {
                name: name.setting_key,
                value: res.body.data[key].current_value ? res.body.data[key].current_value : res.body.data[key].default_value,
            }
        });
        let object = obj.reduce(
            (obj, item) => Object.assign(obj, { [item.name]: item.value }), {})
        dispatch(
            {
                type: CHILDPERMISSION,
                payload: { object }
            })
    }
    catch (e) {
        toast.error(e)
    }
}

