/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createTag = /* GraphQL */ `
  mutation CreateTag(
    $input: CreateTagInput!
    $condition: ModelTagConditionInput
  ) {
    createTag(input: $input, condition: $condition) {
      id
      user_id
      type
      title
      content
      addresses
      visibility
      comments
      tagged_user_id
      memory_date
      occasion
      category
      sub_category
      price
      asset_price
      sold_price
      quantity
      available_for_sale
      auto_attach
      custom_field
      start_date
      end_date
      task_date
      task_end_date
      media
      repeat
      custom_start
      custom_repeat
      custom_repeat_value
      custom_days
      custom_end
      public_distance
      public_start
      public_end
      latitude
      longitude
      altitude
      purchasing_date
      createdAt
      updatedAt
      tag_attach_id
      shared_tag_id {
        id
        user_id
        type
        title
        content
        addresses
        visibility
        comments
        tagged_user_id
        memory_date
        occasion
        category
        sub_category
        price
        asset_price
        sold_price
        quantity
        available_for_sale
        auto_attach
        custom_field
        start_date
        end_date
        task_date
        task_end_date
        media
        repeat
        custom_start
        custom_repeat
        custom_repeat_value
        custom_days
        custom_end
        public_distance
        public_start
        public_end
        latitude
        longitude
        altitude
        purchasing_date
        createdAt
        updatedAt
        tag_attach_id
        shared_tag_id {
          id
          user_id
          type
          title
          content
          addresses
          visibility
          comments
          tagged_user_id
          memory_date
          occasion
          category
          sub_category
          price
          asset_price
          sold_price
          quantity
          available_for_sale
          auto_attach
          custom_field
          start_date
          end_date
          task_date
          task_end_date
          media
          repeat
          custom_start
          custom_repeat
          custom_repeat_value
          custom_days
          custom_end
          public_distance
          public_start
          public_end
          latitude
          longitude
          altitude
          purchasing_date
          createdAt
          updatedAt
          tag_attach_id
          tag_level
          status
          floor
          room
          is_sold
          sold_date
          scene_tags
          is_abvertising_paid
          is_admin_approved
          spotify
          parent_id
          top_parent_id
          is_public
          is_tc_accepted
          is_master_attached
          full_day
          attached_with_master
          tagShared_tagsId
          tagChild_tagsId
          tagAttached_tag_idId
          __typename
        }
        shared_tags {
          nextToken
          __typename
        }
        parent_tag_id {
          id
          user_id
          type
          title
          content
          addresses
          visibility
          comments
          tagged_user_id
          memory_date
          occasion
          category
          sub_category
          price
          asset_price
          sold_price
          quantity
          available_for_sale
          auto_attach
          custom_field
          start_date
          end_date
          task_date
          task_end_date
          media
          repeat
          custom_start
          custom_repeat
          custom_repeat_value
          custom_days
          custom_end
          public_distance
          public_start
          public_end
          latitude
          longitude
          altitude
          purchasing_date
          createdAt
          updatedAt
          tag_attach_id
          tag_level
          status
          floor
          room
          is_sold
          sold_date
          scene_tags
          is_abvertising_paid
          is_admin_approved
          spotify
          parent_id
          top_parent_id
          is_public
          is_tc_accepted
          is_master_attached
          full_day
          attached_with_master
          tagShared_tagsId
          tagChild_tagsId
          tagAttached_tag_idId
          __typename
        }
        child_tags {
          nextToken
          __typename
        }
        tag_level
        comment_list {
          nextToken
          __typename
        }
        like_dislike_list {
          nextToken
          __typename
        }
        tag_messages {
          nextToken
          __typename
        }
        status
        floor
        room
        is_sold
        sold_date
        scene_tags
        is_abvertising_paid
        is_admin_approved
        analytics_user_data {
          nextToken
          __typename
        }
        spotify
        parent_id
        top_parent_id
        is_public
        is_tc_accepted
        is_master_attached
        attached_tag_id {
          nextToken
          __typename
        }
        full_day
        attached_with_master
        tagShared_tagsId
        tagChild_tagsId
        tagAttached_tag_idId
        __typename
      }
      shared_tags {
        items {
          id
          user_id
          type
          title
          content
          addresses
          visibility
          comments
          tagged_user_id
          memory_date
          occasion
          category
          sub_category
          price
          asset_price
          sold_price
          quantity
          available_for_sale
          auto_attach
          custom_field
          start_date
          end_date
          task_date
          task_end_date
          media
          repeat
          custom_start
          custom_repeat
          custom_repeat_value
          custom_days
          custom_end
          public_distance
          public_start
          public_end
          latitude
          longitude
          altitude
          purchasing_date
          createdAt
          updatedAt
          tag_attach_id
          tag_level
          status
          floor
          room
          is_sold
          sold_date
          scene_tags
          is_abvertising_paid
          is_admin_approved
          spotify
          parent_id
          top_parent_id
          is_public
          is_tc_accepted
          is_master_attached
          full_day
          attached_with_master
          tagShared_tagsId
          tagChild_tagsId
          tagAttached_tag_idId
          __typename
        }
        nextToken
        __typename
      }
      parent_tag_id {
        id
        user_id
        type
        title
        content
        addresses
        visibility
        comments
        tagged_user_id
        memory_date
        occasion
        category
        sub_category
        price
        asset_price
        sold_price
        quantity
        available_for_sale
        auto_attach
        custom_field
        start_date
        end_date
        task_date
        task_end_date
        media
        repeat
        custom_start
        custom_repeat
        custom_repeat_value
        custom_days
        custom_end
        public_distance
        public_start
        public_end
        latitude
        longitude
        altitude
        purchasing_date
        createdAt
        updatedAt
        tag_attach_id
        shared_tag_id {
          id
          user_id
          type
          title
          content
          addresses
          visibility
          comments
          tagged_user_id
          memory_date
          occasion
          category
          sub_category
          price
          asset_price
          sold_price
          quantity
          available_for_sale
          auto_attach
          custom_field
          start_date
          end_date
          task_date
          task_end_date
          media
          repeat
          custom_start
          custom_repeat
          custom_repeat_value
          custom_days
          custom_end
          public_distance
          public_start
          public_end
          latitude
          longitude
          altitude
          purchasing_date
          createdAt
          updatedAt
          tag_attach_id
          tag_level
          status
          floor
          room
          is_sold
          sold_date
          scene_tags
          is_abvertising_paid
          is_admin_approved
          spotify
          parent_id
          top_parent_id
          is_public
          is_tc_accepted
          is_master_attached
          full_day
          attached_with_master
          tagShared_tagsId
          tagChild_tagsId
          tagAttached_tag_idId
          __typename
        }
        shared_tags {
          nextToken
          __typename
        }
        parent_tag_id {
          id
          user_id
          type
          title
          content
          addresses
          visibility
          comments
          tagged_user_id
          memory_date
          occasion
          category
          sub_category
          price
          asset_price
          sold_price
          quantity
          available_for_sale
          auto_attach
          custom_field
          start_date
          end_date
          task_date
          task_end_date
          media
          repeat
          custom_start
          custom_repeat
          custom_repeat_value
          custom_days
          custom_end
          public_distance
          public_start
          public_end
          latitude
          longitude
          altitude
          purchasing_date
          createdAt
          updatedAt
          tag_attach_id
          tag_level
          status
          floor
          room
          is_sold
          sold_date
          scene_tags
          is_abvertising_paid
          is_admin_approved
          spotify
          parent_id
          top_parent_id
          is_public
          is_tc_accepted
          is_master_attached
          full_day
          attached_with_master
          tagShared_tagsId
          tagChild_tagsId
          tagAttached_tag_idId
          __typename
        }
        child_tags {
          nextToken
          __typename
        }
        tag_level
        comment_list {
          nextToken
          __typename
        }
        like_dislike_list {
          nextToken
          __typename
        }
        tag_messages {
          nextToken
          __typename
        }
        status
        floor
        room
        is_sold
        sold_date
        scene_tags
        is_abvertising_paid
        is_admin_approved
        analytics_user_data {
          nextToken
          __typename
        }
        spotify
        parent_id
        top_parent_id
        is_public
        is_tc_accepted
        is_master_attached
        attached_tag_id {
          nextToken
          __typename
        }
        full_day
        attached_with_master
        tagShared_tagsId
        tagChild_tagsId
        tagAttached_tag_idId
        __typename
      }
      child_tags {
        items {
          id
          user_id
          type
          title
          content
          addresses
          visibility
          comments
          tagged_user_id
          memory_date
          occasion
          category
          sub_category
          price
          asset_price
          sold_price
          quantity
          available_for_sale
          auto_attach
          custom_field
          start_date
          end_date
          task_date
          task_end_date
          media
          repeat
          custom_start
          custom_repeat
          custom_repeat_value
          custom_days
          custom_end
          public_distance
          public_start
          public_end
          latitude
          longitude
          altitude
          purchasing_date
          createdAt
          updatedAt
          tag_attach_id
          tag_level
          status
          floor
          room
          is_sold
          sold_date
          scene_tags
          is_abvertising_paid
          is_admin_approved
          spotify
          parent_id
          top_parent_id
          is_public
          is_tc_accepted
          is_master_attached
          full_day
          attached_with_master
          tagShared_tagsId
          tagChild_tagsId
          tagAttached_tag_idId
          __typename
        }
        nextToken
        __typename
      }
      tag_level
      comment_list {
        items {
          id
          user_id
          content
          comment_type
          status
          createdAt
          updatedAt
          tagComment_listId
          __typename
        }
        nextToken
        __typename
      }
      like_dislike_list {
        items {
          unique_id
          type
          user_id
          diamond
          duck
          createdAt
          updatedAt
          tagLike_dislike_listId
          commentLike_dislike_listId
          __typename
        }
        nextToken
        __typename
      }
      tag_messages {
        items {
          id
          sender
          recipient
          content
          createdAt
          updatedAt
          tagTag_messagesId
          tagMessageRepliesId
          __typename
        }
        nextToken
        __typename
      }
      status
      floor
      room
      is_sold
      sold_date
      scene_tags
      is_abvertising_paid
      is_admin_approved
      analytics_user_data {
        items {
          id
          user_id
          ipAddress
          browser
          deviceType
          is_view_only
          createdAt
          updatedAt
          tagAnalytics_user_dataId
          __typename
        }
        nextToken
        __typename
      }
      spotify
      parent_id
      top_parent_id
      is_public
      is_tc_accepted
      is_master_attached
      attached_tag_id {
        items {
          id
          user_id
          type
          title
          content
          addresses
          visibility
          comments
          tagged_user_id
          memory_date
          occasion
          category
          sub_category
          price
          asset_price
          sold_price
          quantity
          available_for_sale
          auto_attach
          custom_field
          start_date
          end_date
          task_date
          task_end_date
          media
          repeat
          custom_start
          custom_repeat
          custom_repeat_value
          custom_days
          custom_end
          public_distance
          public_start
          public_end
          latitude
          longitude
          altitude
          purchasing_date
          createdAt
          updatedAt
          tag_attach_id
          tag_level
          status
          floor
          room
          is_sold
          sold_date
          scene_tags
          is_abvertising_paid
          is_admin_approved
          spotify
          parent_id
          top_parent_id
          is_public
          is_tc_accepted
          is_master_attached
          full_day
          attached_with_master
          tagShared_tagsId
          tagChild_tagsId
          tagAttached_tag_idId
          __typename
        }
        nextToken
        __typename
      }
      full_day
      attached_with_master
      tagShared_tagsId
      tagChild_tagsId
      tagAttached_tag_idId
      __typename
    }
  }
`;
export const updateTag = /* GraphQL */ `
  mutation UpdateTag(
    $input: UpdateTagInput!
    $condition: ModelTagConditionInput
  ) {
    updateTag(input: $input, condition: $condition) {
      id
      user_id
      type
      title
      content
      addresses
      visibility
      comments
      tagged_user_id
      memory_date
      occasion
      category
      sub_category
      price
      asset_price
      sold_price
      quantity
      available_for_sale
      auto_attach
      custom_field
      start_date
      end_date
      task_date
      task_end_date
      media
      repeat
      custom_start
      custom_repeat
      custom_repeat_value
      custom_days
      custom_end
      public_distance
      public_start
      public_end
      latitude
      longitude
      altitude
      purchasing_date
      createdAt
      updatedAt
      tag_attach_id
      shared_tag_id {
        id
        user_id
        type
        title
        content
        addresses
        visibility
        comments
        tagged_user_id
        memory_date
        occasion
        category
        sub_category
        price
        asset_price
        sold_price
        quantity
        available_for_sale
        auto_attach
        custom_field
        start_date
        end_date
        task_date
        task_end_date
        media
        repeat
        custom_start
        custom_repeat
        custom_repeat_value
        custom_days
        custom_end
        public_distance
        public_start
        public_end
        latitude
        longitude
        altitude
        purchasing_date
        createdAt
        updatedAt
        tag_attach_id
        shared_tag_id {
          id
          user_id
          type
          title
          content
          addresses
          visibility
          comments
          tagged_user_id
          memory_date
          occasion
          category
          sub_category
          price
          asset_price
          sold_price
          quantity
          available_for_sale
          auto_attach
          custom_field
          start_date
          end_date
          task_date
          task_end_date
          media
          repeat
          custom_start
          custom_repeat
          custom_repeat_value
          custom_days
          custom_end
          public_distance
          public_start
          public_end
          latitude
          longitude
          altitude
          purchasing_date
          createdAt
          updatedAt
          tag_attach_id
          tag_level
          status
          floor
          room
          is_sold
          sold_date
          scene_tags
          is_abvertising_paid
          is_admin_approved
          spotify
          parent_id
          top_parent_id
          is_public
          is_tc_accepted
          is_master_attached
          full_day
          attached_with_master
          tagShared_tagsId
          tagChild_tagsId
          tagAttached_tag_idId
          __typename
        }
        shared_tags {
          nextToken
          __typename
        }
        parent_tag_id {
          id
          user_id
          type
          title
          content
          addresses
          visibility
          comments
          tagged_user_id
          memory_date
          occasion
          category
          sub_category
          price
          asset_price
          sold_price
          quantity
          available_for_sale
          auto_attach
          custom_field
          start_date
          end_date
          task_date
          task_end_date
          media
          repeat
          custom_start
          custom_repeat
          custom_repeat_value
          custom_days
          custom_end
          public_distance
          public_start
          public_end
          latitude
          longitude
          altitude
          purchasing_date
          createdAt
          updatedAt
          tag_attach_id
          tag_level
          status
          floor
          room
          is_sold
          sold_date
          scene_tags
          is_abvertising_paid
          is_admin_approved
          spotify
          parent_id
          top_parent_id
          is_public
          is_tc_accepted
          is_master_attached
          full_day
          attached_with_master
          tagShared_tagsId
          tagChild_tagsId
          tagAttached_tag_idId
          __typename
        }
        child_tags {
          nextToken
          __typename
        }
        tag_level
        comment_list {
          nextToken
          __typename
        }
        like_dislike_list {
          nextToken
          __typename
        }
        tag_messages {
          nextToken
          __typename
        }
        status
        floor
        room
        is_sold
        sold_date
        scene_tags
        is_abvertising_paid
        is_admin_approved
        analytics_user_data {
          nextToken
          __typename
        }
        spotify
        parent_id
        top_parent_id
        is_public
        is_tc_accepted
        is_master_attached
        attached_tag_id {
          nextToken
          __typename
        }
        full_day
        attached_with_master
        tagShared_tagsId
        tagChild_tagsId
        tagAttached_tag_idId
        __typename
      }
      shared_tags {
        items {
          id
          user_id
          type
          title
          content
          addresses
          visibility
          comments
          tagged_user_id
          memory_date
          occasion
          category
          sub_category
          price
          asset_price
          sold_price
          quantity
          available_for_sale
          auto_attach
          custom_field
          start_date
          end_date
          task_date
          task_end_date
          media
          repeat
          custom_start
          custom_repeat
          custom_repeat_value
          custom_days
          custom_end
          public_distance
          public_start
          public_end
          latitude
          longitude
          altitude
          purchasing_date
          createdAt
          updatedAt
          tag_attach_id
          tag_level
          status
          floor
          room
          is_sold
          sold_date
          scene_tags
          is_abvertising_paid
          is_admin_approved
          spotify
          parent_id
          top_parent_id
          is_public
          is_tc_accepted
          is_master_attached
          full_day
          attached_with_master
          tagShared_tagsId
          tagChild_tagsId
          tagAttached_tag_idId
          __typename
        }
        nextToken
        __typename
      }
      parent_tag_id {
        id
        user_id
        type
        title
        content
        addresses
        visibility
        comments
        tagged_user_id
        memory_date
        occasion
        category
        sub_category
        price
        asset_price
        sold_price
        quantity
        available_for_sale
        auto_attach
        custom_field
        start_date
        end_date
        task_date
        task_end_date
        media
        repeat
        custom_start
        custom_repeat
        custom_repeat_value
        custom_days
        custom_end
        public_distance
        public_start
        public_end
        latitude
        longitude
        altitude
        purchasing_date
        createdAt
        updatedAt
        tag_attach_id
        shared_tag_id {
          id
          user_id
          type
          title
          content
          addresses
          visibility
          comments
          tagged_user_id
          memory_date
          occasion
          category
          sub_category
          price
          asset_price
          sold_price
          quantity
          available_for_sale
          auto_attach
          custom_field
          start_date
          end_date
          task_date
          task_end_date
          media
          repeat
          custom_start
          custom_repeat
          custom_repeat_value
          custom_days
          custom_end
          public_distance
          public_start
          public_end
          latitude
          longitude
          altitude
          purchasing_date
          createdAt
          updatedAt
          tag_attach_id
          tag_level
          status
          floor
          room
          is_sold
          sold_date
          scene_tags
          is_abvertising_paid
          is_admin_approved
          spotify
          parent_id
          top_parent_id
          is_public
          is_tc_accepted
          is_master_attached
          full_day
          attached_with_master
          tagShared_tagsId
          tagChild_tagsId
          tagAttached_tag_idId
          __typename
        }
        shared_tags {
          nextToken
          __typename
        }
        parent_tag_id {
          id
          user_id
          type
          title
          content
          addresses
          visibility
          comments
          tagged_user_id
          memory_date
          occasion
          category
          sub_category
          price
          asset_price
          sold_price
          quantity
          available_for_sale
          auto_attach
          custom_field
          start_date
          end_date
          task_date
          task_end_date
          media
          repeat
          custom_start
          custom_repeat
          custom_repeat_value
          custom_days
          custom_end
          public_distance
          public_start
          public_end
          latitude
          longitude
          altitude
          purchasing_date
          createdAt
          updatedAt
          tag_attach_id
          tag_level
          status
          floor
          room
          is_sold
          sold_date
          scene_tags
          is_abvertising_paid
          is_admin_approved
          spotify
          parent_id
          top_parent_id
          is_public
          is_tc_accepted
          is_master_attached
          full_day
          attached_with_master
          tagShared_tagsId
          tagChild_tagsId
          tagAttached_tag_idId
          __typename
        }
        child_tags {
          nextToken
          __typename
        }
        tag_level
        comment_list {
          nextToken
          __typename
        }
        like_dislike_list {
          nextToken
          __typename
        }
        tag_messages {
          nextToken
          __typename
        }
        status
        floor
        room
        is_sold
        sold_date
        scene_tags
        is_abvertising_paid
        is_admin_approved
        analytics_user_data {
          nextToken
          __typename
        }
        spotify
        parent_id
        top_parent_id
        is_public
        is_tc_accepted
        is_master_attached
        attached_tag_id {
          nextToken
          __typename
        }
        full_day
        attached_with_master
        tagShared_tagsId
        tagChild_tagsId
        tagAttached_tag_idId
        __typename
      }
      child_tags {
        items {
          id
          user_id
          type
          title
          content
          addresses
          visibility
          comments
          tagged_user_id
          memory_date
          occasion
          category
          sub_category
          price
          asset_price
          sold_price
          quantity
          available_for_sale
          auto_attach
          custom_field
          start_date
          end_date
          task_date
          task_end_date
          media
          repeat
          custom_start
          custom_repeat
          custom_repeat_value
          custom_days
          custom_end
          public_distance
          public_start
          public_end
          latitude
          longitude
          altitude
          purchasing_date
          createdAt
          updatedAt
          tag_attach_id
          tag_level
          status
          floor
          room
          is_sold
          sold_date
          scene_tags
          is_abvertising_paid
          is_admin_approved
          spotify
          parent_id
          top_parent_id
          is_public
          is_tc_accepted
          is_master_attached
          full_day
          attached_with_master
          tagShared_tagsId
          tagChild_tagsId
          tagAttached_tag_idId
          __typename
        }
        nextToken
        __typename
      }
      tag_level
      comment_list {
        items {
          id
          user_id
          content
          comment_type
          status
          createdAt
          updatedAt
          tagComment_listId
          __typename
        }
        nextToken
        __typename
      }
      like_dislike_list {
        items {
          unique_id
          type
          user_id
          diamond
          duck
          createdAt
          updatedAt
          tagLike_dislike_listId
          commentLike_dislike_listId
          __typename
        }
        nextToken
        __typename
      }
      tag_messages {
        items {
          id
          sender
          recipient
          content
          createdAt
          updatedAt
          tagTag_messagesId
          tagMessageRepliesId
          __typename
        }
        nextToken
        __typename
      }
      status
      floor
      room
      is_sold
      sold_date
      scene_tags
      is_abvertising_paid
      is_admin_approved
      analytics_user_data {
        items {
          id
          user_id
          ipAddress
          browser
          deviceType
          is_view_only
          createdAt
          updatedAt
          tagAnalytics_user_dataId
          __typename
        }
        nextToken
        __typename
      }
      spotify
      parent_id
      top_parent_id
      is_public
      is_tc_accepted
      is_master_attached
      attached_tag_id {
        items {
          id
          user_id
          type
          title
          content
          addresses
          visibility
          comments
          tagged_user_id
          memory_date
          occasion
          category
          sub_category
          price
          asset_price
          sold_price
          quantity
          available_for_sale
          auto_attach
          custom_field
          start_date
          end_date
          task_date
          task_end_date
          media
          repeat
          custom_start
          custom_repeat
          custom_repeat_value
          custom_days
          custom_end
          public_distance
          public_start
          public_end
          latitude
          longitude
          altitude
          purchasing_date
          createdAt
          updatedAt
          tag_attach_id
          tag_level
          status
          floor
          room
          is_sold
          sold_date
          scene_tags
          is_abvertising_paid
          is_admin_approved
          spotify
          parent_id
          top_parent_id
          is_public
          is_tc_accepted
          is_master_attached
          full_day
          attached_with_master
          tagShared_tagsId
          tagChild_tagsId
          tagAttached_tag_idId
          __typename
        }
        nextToken
        __typename
      }
      full_day
      attached_with_master
      tagShared_tagsId
      tagChild_tagsId
      tagAttached_tag_idId
      __typename
    }
  }
`;
export const deleteTag = /* GraphQL */ `
  mutation DeleteTag(
    $input: DeleteTagInput!
    $condition: ModelTagConditionInput
  ) {
    deleteTag(input: $input, condition: $condition) {
      id
      user_id
      type
      title
      content
      addresses
      visibility
      comments
      tagged_user_id
      memory_date
      occasion
      category
      sub_category
      price
      asset_price
      sold_price
      quantity
      available_for_sale
      auto_attach
      custom_field
      start_date
      end_date
      task_date
      task_end_date
      media
      repeat
      custom_start
      custom_repeat
      custom_repeat_value
      custom_days
      custom_end
      public_distance
      public_start
      public_end
      latitude
      longitude
      altitude
      purchasing_date
      createdAt
      updatedAt
      tag_attach_id
      shared_tag_id {
        id
        user_id
        type
        title
        content
        addresses
        visibility
        comments
        tagged_user_id
        memory_date
        occasion
        category
        sub_category
        price
        asset_price
        sold_price
        quantity
        available_for_sale
        auto_attach
        custom_field
        start_date
        end_date
        task_date
        task_end_date
        media
        repeat
        custom_start
        custom_repeat
        custom_repeat_value
        custom_days
        custom_end
        public_distance
        public_start
        public_end
        latitude
        longitude
        altitude
        purchasing_date
        createdAt
        updatedAt
        tag_attach_id
        shared_tag_id {
          id
          user_id
          type
          title
          content
          addresses
          visibility
          comments
          tagged_user_id
          memory_date
          occasion
          category
          sub_category
          price
          asset_price
          sold_price
          quantity
          available_for_sale
          auto_attach
          custom_field
          start_date
          end_date
          task_date
          task_end_date
          media
          repeat
          custom_start
          custom_repeat
          custom_repeat_value
          custom_days
          custom_end
          public_distance
          public_start
          public_end
          latitude
          longitude
          altitude
          purchasing_date
          createdAt
          updatedAt
          tag_attach_id
          tag_level
          status
          floor
          room
          is_sold
          sold_date
          scene_tags
          is_abvertising_paid
          is_admin_approved
          spotify
          parent_id
          top_parent_id
          is_public
          is_tc_accepted
          is_master_attached
          full_day
          attached_with_master
          tagShared_tagsId
          tagChild_tagsId
          tagAttached_tag_idId
          __typename
        }
        shared_tags {
          nextToken
          __typename
        }
        parent_tag_id {
          id
          user_id
          type
          title
          content
          addresses
          visibility
          comments
          tagged_user_id
          memory_date
          occasion
          category
          sub_category
          price
          asset_price
          sold_price
          quantity
          available_for_sale
          auto_attach
          custom_field
          start_date
          end_date
          task_date
          task_end_date
          media
          repeat
          custom_start
          custom_repeat
          custom_repeat_value
          custom_days
          custom_end
          public_distance
          public_start
          public_end
          latitude
          longitude
          altitude
          purchasing_date
          createdAt
          updatedAt
          tag_attach_id
          tag_level
          status
          floor
          room
          is_sold
          sold_date
          scene_tags
          is_abvertising_paid
          is_admin_approved
          spotify
          parent_id
          top_parent_id
          is_public
          is_tc_accepted
          is_master_attached
          full_day
          attached_with_master
          tagShared_tagsId
          tagChild_tagsId
          tagAttached_tag_idId
          __typename
        }
        child_tags {
          nextToken
          __typename
        }
        tag_level
        comment_list {
          nextToken
          __typename
        }
        like_dislike_list {
          nextToken
          __typename
        }
        tag_messages {
          nextToken
          __typename
        }
        status
        floor
        room
        is_sold
        sold_date
        scene_tags
        is_abvertising_paid
        is_admin_approved
        analytics_user_data {
          nextToken
          __typename
        }
        spotify
        parent_id
        top_parent_id
        is_public
        is_tc_accepted
        is_master_attached
        attached_tag_id {
          nextToken
          __typename
        }
        full_day
        attached_with_master
        tagShared_tagsId
        tagChild_tagsId
        tagAttached_tag_idId
        __typename
      }
      shared_tags {
        items {
          id
          user_id
          type
          title
          content
          addresses
          visibility
          comments
          tagged_user_id
          memory_date
          occasion
          category
          sub_category
          price
          asset_price
          sold_price
          quantity
          available_for_sale
          auto_attach
          custom_field
          start_date
          end_date
          task_date
          task_end_date
          media
          repeat
          custom_start
          custom_repeat
          custom_repeat_value
          custom_days
          custom_end
          public_distance
          public_start
          public_end
          latitude
          longitude
          altitude
          purchasing_date
          createdAt
          updatedAt
          tag_attach_id
          tag_level
          status
          floor
          room
          is_sold
          sold_date
          scene_tags
          is_abvertising_paid
          is_admin_approved
          spotify
          parent_id
          top_parent_id
          is_public
          is_tc_accepted
          is_master_attached
          full_day
          attached_with_master
          tagShared_tagsId
          tagChild_tagsId
          tagAttached_tag_idId
          __typename
        }
        nextToken
        __typename
      }
      parent_tag_id {
        id
        user_id
        type
        title
        content
        addresses
        visibility
        comments
        tagged_user_id
        memory_date
        occasion
        category
        sub_category
        price
        asset_price
        sold_price
        quantity
        available_for_sale
        auto_attach
        custom_field
        start_date
        end_date
        task_date
        task_end_date
        media
        repeat
        custom_start
        custom_repeat
        custom_repeat_value
        custom_days
        custom_end
        public_distance
        public_start
        public_end
        latitude
        longitude
        altitude
        purchasing_date
        createdAt
        updatedAt
        tag_attach_id
        shared_tag_id {
          id
          user_id
          type
          title
          content
          addresses
          visibility
          comments
          tagged_user_id
          memory_date
          occasion
          category
          sub_category
          price
          asset_price
          sold_price
          quantity
          available_for_sale
          auto_attach
          custom_field
          start_date
          end_date
          task_date
          task_end_date
          media
          repeat
          custom_start
          custom_repeat
          custom_repeat_value
          custom_days
          custom_end
          public_distance
          public_start
          public_end
          latitude
          longitude
          altitude
          purchasing_date
          createdAt
          updatedAt
          tag_attach_id
          tag_level
          status
          floor
          room
          is_sold
          sold_date
          scene_tags
          is_abvertising_paid
          is_admin_approved
          spotify
          parent_id
          top_parent_id
          is_public
          is_tc_accepted
          is_master_attached
          full_day
          attached_with_master
          tagShared_tagsId
          tagChild_tagsId
          tagAttached_tag_idId
          __typename
        }
        shared_tags {
          nextToken
          __typename
        }
        parent_tag_id {
          id
          user_id
          type
          title
          content
          addresses
          visibility
          comments
          tagged_user_id
          memory_date
          occasion
          category
          sub_category
          price
          asset_price
          sold_price
          quantity
          available_for_sale
          auto_attach
          custom_field
          start_date
          end_date
          task_date
          task_end_date
          media
          repeat
          custom_start
          custom_repeat
          custom_repeat_value
          custom_days
          custom_end
          public_distance
          public_start
          public_end
          latitude
          longitude
          altitude
          purchasing_date
          createdAt
          updatedAt
          tag_attach_id
          tag_level
          status
          floor
          room
          is_sold
          sold_date
          scene_tags
          is_abvertising_paid
          is_admin_approved
          spotify
          parent_id
          top_parent_id
          is_public
          is_tc_accepted
          is_master_attached
          full_day
          attached_with_master
          tagShared_tagsId
          tagChild_tagsId
          tagAttached_tag_idId
          __typename
        }
        child_tags {
          nextToken
          __typename
        }
        tag_level
        comment_list {
          nextToken
          __typename
        }
        like_dislike_list {
          nextToken
          __typename
        }
        tag_messages {
          nextToken
          __typename
        }
        status
        floor
        room
        is_sold
        sold_date
        scene_tags
        is_abvertising_paid
        is_admin_approved
        analytics_user_data {
          nextToken
          __typename
        }
        spotify
        parent_id
        top_parent_id
        is_public
        is_tc_accepted
        is_master_attached
        attached_tag_id {
          nextToken
          __typename
        }
        full_day
        attached_with_master
        tagShared_tagsId
        tagChild_tagsId
        tagAttached_tag_idId
        __typename
      }
      child_tags {
        items {
          id
          user_id
          type
          title
          content
          addresses
          visibility
          comments
          tagged_user_id
          memory_date
          occasion
          category
          sub_category
          price
          asset_price
          sold_price
          quantity
          available_for_sale
          auto_attach
          custom_field
          start_date
          end_date
          task_date
          task_end_date
          media
          repeat
          custom_start
          custom_repeat
          custom_repeat_value
          custom_days
          custom_end
          public_distance
          public_start
          public_end
          latitude
          longitude
          altitude
          purchasing_date
          createdAt
          updatedAt
          tag_attach_id
          tag_level
          status
          floor
          room
          is_sold
          sold_date
          scene_tags
          is_abvertising_paid
          is_admin_approved
          spotify
          parent_id
          top_parent_id
          is_public
          is_tc_accepted
          is_master_attached
          full_day
          attached_with_master
          tagShared_tagsId
          tagChild_tagsId
          tagAttached_tag_idId
          __typename
        }
        nextToken
        __typename
      }
      tag_level
      comment_list {
        items {
          id
          user_id
          content
          comment_type
          status
          createdAt
          updatedAt
          tagComment_listId
          __typename
        }
        nextToken
        __typename
      }
      like_dislike_list {
        items {
          unique_id
          type
          user_id
          diamond
          duck
          createdAt
          updatedAt
          tagLike_dislike_listId
          commentLike_dislike_listId
          __typename
        }
        nextToken
        __typename
      }
      tag_messages {
        items {
          id
          sender
          recipient
          content
          createdAt
          updatedAt
          tagTag_messagesId
          tagMessageRepliesId
          __typename
        }
        nextToken
        __typename
      }
      status
      floor
      room
      is_sold
      sold_date
      scene_tags
      is_abvertising_paid
      is_admin_approved
      analytics_user_data {
        items {
          id
          user_id
          ipAddress
          browser
          deviceType
          is_view_only
          createdAt
          updatedAt
          tagAnalytics_user_dataId
          __typename
        }
        nextToken
        __typename
      }
      spotify
      parent_id
      top_parent_id
      is_public
      is_tc_accepted
      is_master_attached
      attached_tag_id {
        items {
          id
          user_id
          type
          title
          content
          addresses
          visibility
          comments
          tagged_user_id
          memory_date
          occasion
          category
          sub_category
          price
          asset_price
          sold_price
          quantity
          available_for_sale
          auto_attach
          custom_field
          start_date
          end_date
          task_date
          task_end_date
          media
          repeat
          custom_start
          custom_repeat
          custom_repeat_value
          custom_days
          custom_end
          public_distance
          public_start
          public_end
          latitude
          longitude
          altitude
          purchasing_date
          createdAt
          updatedAt
          tag_attach_id
          tag_level
          status
          floor
          room
          is_sold
          sold_date
          scene_tags
          is_abvertising_paid
          is_admin_approved
          spotify
          parent_id
          top_parent_id
          is_public
          is_tc_accepted
          is_master_attached
          full_day
          attached_with_master
          tagShared_tagsId
          tagChild_tagsId
          tagAttached_tag_idId
          __typename
        }
        nextToken
        __typename
      }
      full_day
      attached_with_master
      tagShared_tagsId
      tagChild_tagsId
      tagAttached_tag_idId
      __typename
    }
  }
`;
export const createTagAnalyticsUserData = /* GraphQL */ `
  mutation CreateTagAnalyticsUserData(
    $input: CreateTagAnalyticsUserDataInput!
    $condition: ModelTagAnalyticsUserDataConditionInput
  ) {
    createTagAnalyticsUserData(input: $input, condition: $condition) {
      id
      tag_id {
        id
        user_id
        type
        title
        content
        addresses
        visibility
        comments
        tagged_user_id
        memory_date
        occasion
        category
        sub_category
        price
        asset_price
        sold_price
        quantity
        available_for_sale
        auto_attach
        custom_field
        start_date
        end_date
        task_date
        task_end_date
        media
        repeat
        custom_start
        custom_repeat
        custom_repeat_value
        custom_days
        custom_end
        public_distance
        public_start
        public_end
        latitude
        longitude
        altitude
        purchasing_date
        createdAt
        updatedAt
        tag_attach_id
        shared_tag_id {
          id
          user_id
          type
          title
          content
          addresses
          visibility
          comments
          tagged_user_id
          memory_date
          occasion
          category
          sub_category
          price
          asset_price
          sold_price
          quantity
          available_for_sale
          auto_attach
          custom_field
          start_date
          end_date
          task_date
          task_end_date
          media
          repeat
          custom_start
          custom_repeat
          custom_repeat_value
          custom_days
          custom_end
          public_distance
          public_start
          public_end
          latitude
          longitude
          altitude
          purchasing_date
          createdAt
          updatedAt
          tag_attach_id
          tag_level
          status
          floor
          room
          is_sold
          sold_date
          scene_tags
          is_abvertising_paid
          is_admin_approved
          spotify
          parent_id
          top_parent_id
          is_public
          is_tc_accepted
          is_master_attached
          full_day
          attached_with_master
          tagShared_tagsId
          tagChild_tagsId
          tagAttached_tag_idId
          __typename
        }
        shared_tags {
          nextToken
          __typename
        }
        parent_tag_id {
          id
          user_id
          type
          title
          content
          addresses
          visibility
          comments
          tagged_user_id
          memory_date
          occasion
          category
          sub_category
          price
          asset_price
          sold_price
          quantity
          available_for_sale
          auto_attach
          custom_field
          start_date
          end_date
          task_date
          task_end_date
          media
          repeat
          custom_start
          custom_repeat
          custom_repeat_value
          custom_days
          custom_end
          public_distance
          public_start
          public_end
          latitude
          longitude
          altitude
          purchasing_date
          createdAt
          updatedAt
          tag_attach_id
          tag_level
          status
          floor
          room
          is_sold
          sold_date
          scene_tags
          is_abvertising_paid
          is_admin_approved
          spotify
          parent_id
          top_parent_id
          is_public
          is_tc_accepted
          is_master_attached
          full_day
          attached_with_master
          tagShared_tagsId
          tagChild_tagsId
          tagAttached_tag_idId
          __typename
        }
        child_tags {
          nextToken
          __typename
        }
        tag_level
        comment_list {
          nextToken
          __typename
        }
        like_dislike_list {
          nextToken
          __typename
        }
        tag_messages {
          nextToken
          __typename
        }
        status
        floor
        room
        is_sold
        sold_date
        scene_tags
        is_abvertising_paid
        is_admin_approved
        analytics_user_data {
          nextToken
          __typename
        }
        spotify
        parent_id
        top_parent_id
        is_public
        is_tc_accepted
        is_master_attached
        attached_tag_id {
          nextToken
          __typename
        }
        full_day
        attached_with_master
        tagShared_tagsId
        tagChild_tagsId
        tagAttached_tag_idId
        __typename
      }
      user_id
      ipAddress
      browser
      deviceType
      is_view_only
      createdAt
      updatedAt
      tagAnalytics_user_dataId
      __typename
    }
  }
`;
export const updateTagAnalyticsUserData = /* GraphQL */ `
  mutation UpdateTagAnalyticsUserData(
    $input: UpdateTagAnalyticsUserDataInput!
    $condition: ModelTagAnalyticsUserDataConditionInput
  ) {
    updateTagAnalyticsUserData(input: $input, condition: $condition) {
      id
      tag_id {
        id
        user_id
        type
        title
        content
        addresses
        visibility
        comments
        tagged_user_id
        memory_date
        occasion
        category
        sub_category
        price
        asset_price
        sold_price
        quantity
        available_for_sale
        auto_attach
        custom_field
        start_date
        end_date
        task_date
        task_end_date
        media
        repeat
        custom_start
        custom_repeat
        custom_repeat_value
        custom_days
        custom_end
        public_distance
        public_start
        public_end
        latitude
        longitude
        altitude
        purchasing_date
        createdAt
        updatedAt
        tag_attach_id
        shared_tag_id {
          id
          user_id
          type
          title
          content
          addresses
          visibility
          comments
          tagged_user_id
          memory_date
          occasion
          category
          sub_category
          price
          asset_price
          sold_price
          quantity
          available_for_sale
          auto_attach
          custom_field
          start_date
          end_date
          task_date
          task_end_date
          media
          repeat
          custom_start
          custom_repeat
          custom_repeat_value
          custom_days
          custom_end
          public_distance
          public_start
          public_end
          latitude
          longitude
          altitude
          purchasing_date
          createdAt
          updatedAt
          tag_attach_id
          tag_level
          status
          floor
          room
          is_sold
          sold_date
          scene_tags
          is_abvertising_paid
          is_admin_approved
          spotify
          parent_id
          top_parent_id
          is_public
          is_tc_accepted
          is_master_attached
          full_day
          attached_with_master
          tagShared_tagsId
          tagChild_tagsId
          tagAttached_tag_idId
          __typename
        }
        shared_tags {
          nextToken
          __typename
        }
        parent_tag_id {
          id
          user_id
          type
          title
          content
          addresses
          visibility
          comments
          tagged_user_id
          memory_date
          occasion
          category
          sub_category
          price
          asset_price
          sold_price
          quantity
          available_for_sale
          auto_attach
          custom_field
          start_date
          end_date
          task_date
          task_end_date
          media
          repeat
          custom_start
          custom_repeat
          custom_repeat_value
          custom_days
          custom_end
          public_distance
          public_start
          public_end
          latitude
          longitude
          altitude
          purchasing_date
          createdAt
          updatedAt
          tag_attach_id
          tag_level
          status
          floor
          room
          is_sold
          sold_date
          scene_tags
          is_abvertising_paid
          is_admin_approved
          spotify
          parent_id
          top_parent_id
          is_public
          is_tc_accepted
          is_master_attached
          full_day
          attached_with_master
          tagShared_tagsId
          tagChild_tagsId
          tagAttached_tag_idId
          __typename
        }
        child_tags {
          nextToken
          __typename
        }
        tag_level
        comment_list {
          nextToken
          __typename
        }
        like_dislike_list {
          nextToken
          __typename
        }
        tag_messages {
          nextToken
          __typename
        }
        status
        floor
        room
        is_sold
        sold_date
        scene_tags
        is_abvertising_paid
        is_admin_approved
        analytics_user_data {
          nextToken
          __typename
        }
        spotify
        parent_id
        top_parent_id
        is_public
        is_tc_accepted
        is_master_attached
        attached_tag_id {
          nextToken
          __typename
        }
        full_day
        attached_with_master
        tagShared_tagsId
        tagChild_tagsId
        tagAttached_tag_idId
        __typename
      }
      user_id
      ipAddress
      browser
      deviceType
      is_view_only
      createdAt
      updatedAt
      tagAnalytics_user_dataId
      __typename
    }
  }
`;
export const deleteTagAnalyticsUserData = /* GraphQL */ `
  mutation DeleteTagAnalyticsUserData(
    $input: DeleteTagAnalyticsUserDataInput!
    $condition: ModelTagAnalyticsUserDataConditionInput
  ) {
    deleteTagAnalyticsUserData(input: $input, condition: $condition) {
      id
      tag_id {
        id
        user_id
        type
        title
        content
        addresses
        visibility
        comments
        tagged_user_id
        memory_date
        occasion
        category
        sub_category
        price
        asset_price
        sold_price
        quantity
        available_for_sale
        auto_attach
        custom_field
        start_date
        end_date
        task_date
        task_end_date
        media
        repeat
        custom_start
        custom_repeat
        custom_repeat_value
        custom_days
        custom_end
        public_distance
        public_start
        public_end
        latitude
        longitude
        altitude
        purchasing_date
        createdAt
        updatedAt
        tag_attach_id
        shared_tag_id {
          id
          user_id
          type
          title
          content
          addresses
          visibility
          comments
          tagged_user_id
          memory_date
          occasion
          category
          sub_category
          price
          asset_price
          sold_price
          quantity
          available_for_sale
          auto_attach
          custom_field
          start_date
          end_date
          task_date
          task_end_date
          media
          repeat
          custom_start
          custom_repeat
          custom_repeat_value
          custom_days
          custom_end
          public_distance
          public_start
          public_end
          latitude
          longitude
          altitude
          purchasing_date
          createdAt
          updatedAt
          tag_attach_id
          tag_level
          status
          floor
          room
          is_sold
          sold_date
          scene_tags
          is_abvertising_paid
          is_admin_approved
          spotify
          parent_id
          top_parent_id
          is_public
          is_tc_accepted
          is_master_attached
          full_day
          attached_with_master
          tagShared_tagsId
          tagChild_tagsId
          tagAttached_tag_idId
          __typename
        }
        shared_tags {
          nextToken
          __typename
        }
        parent_tag_id {
          id
          user_id
          type
          title
          content
          addresses
          visibility
          comments
          tagged_user_id
          memory_date
          occasion
          category
          sub_category
          price
          asset_price
          sold_price
          quantity
          available_for_sale
          auto_attach
          custom_field
          start_date
          end_date
          task_date
          task_end_date
          media
          repeat
          custom_start
          custom_repeat
          custom_repeat_value
          custom_days
          custom_end
          public_distance
          public_start
          public_end
          latitude
          longitude
          altitude
          purchasing_date
          createdAt
          updatedAt
          tag_attach_id
          tag_level
          status
          floor
          room
          is_sold
          sold_date
          scene_tags
          is_abvertising_paid
          is_admin_approved
          spotify
          parent_id
          top_parent_id
          is_public
          is_tc_accepted
          is_master_attached
          full_day
          attached_with_master
          tagShared_tagsId
          tagChild_tagsId
          tagAttached_tag_idId
          __typename
        }
        child_tags {
          nextToken
          __typename
        }
        tag_level
        comment_list {
          nextToken
          __typename
        }
        like_dislike_list {
          nextToken
          __typename
        }
        tag_messages {
          nextToken
          __typename
        }
        status
        floor
        room
        is_sold
        sold_date
        scene_tags
        is_abvertising_paid
        is_admin_approved
        analytics_user_data {
          nextToken
          __typename
        }
        spotify
        parent_id
        top_parent_id
        is_public
        is_tc_accepted
        is_master_attached
        attached_tag_id {
          nextToken
          __typename
        }
        full_day
        attached_with_master
        tagShared_tagsId
        tagChild_tagsId
        tagAttached_tag_idId
        __typename
      }
      user_id
      ipAddress
      browser
      deviceType
      is_view_only
      createdAt
      updatedAt
      tagAnalytics_user_dataId
      __typename
    }
  }
`;
export const createComment = /* GraphQL */ `
  mutation CreateComment(
    $input: CreateCommentInput!
    $condition: ModelCommentConditionInput
  ) {
    createComment(input: $input, condition: $condition) {
      id
      tag_id {
        id
        user_id
        type
        title
        content
        addresses
        visibility
        comments
        tagged_user_id
        memory_date
        occasion
        category
        sub_category
        price
        asset_price
        sold_price
        quantity
        available_for_sale
        auto_attach
        custom_field
        start_date
        end_date
        task_date
        task_end_date
        media
        repeat
        custom_start
        custom_repeat
        custom_repeat_value
        custom_days
        custom_end
        public_distance
        public_start
        public_end
        latitude
        longitude
        altitude
        purchasing_date
        createdAt
        updatedAt
        tag_attach_id
        shared_tag_id {
          id
          user_id
          type
          title
          content
          addresses
          visibility
          comments
          tagged_user_id
          memory_date
          occasion
          category
          sub_category
          price
          asset_price
          sold_price
          quantity
          available_for_sale
          auto_attach
          custom_field
          start_date
          end_date
          task_date
          task_end_date
          media
          repeat
          custom_start
          custom_repeat
          custom_repeat_value
          custom_days
          custom_end
          public_distance
          public_start
          public_end
          latitude
          longitude
          altitude
          purchasing_date
          createdAt
          updatedAt
          tag_attach_id
          tag_level
          status
          floor
          room
          is_sold
          sold_date
          scene_tags
          is_abvertising_paid
          is_admin_approved
          spotify
          parent_id
          top_parent_id
          is_public
          is_tc_accepted
          is_master_attached
          full_day
          attached_with_master
          tagShared_tagsId
          tagChild_tagsId
          tagAttached_tag_idId
          __typename
        }
        shared_tags {
          nextToken
          __typename
        }
        parent_tag_id {
          id
          user_id
          type
          title
          content
          addresses
          visibility
          comments
          tagged_user_id
          memory_date
          occasion
          category
          sub_category
          price
          asset_price
          sold_price
          quantity
          available_for_sale
          auto_attach
          custom_field
          start_date
          end_date
          task_date
          task_end_date
          media
          repeat
          custom_start
          custom_repeat
          custom_repeat_value
          custom_days
          custom_end
          public_distance
          public_start
          public_end
          latitude
          longitude
          altitude
          purchasing_date
          createdAt
          updatedAt
          tag_attach_id
          tag_level
          status
          floor
          room
          is_sold
          sold_date
          scene_tags
          is_abvertising_paid
          is_admin_approved
          spotify
          parent_id
          top_parent_id
          is_public
          is_tc_accepted
          is_master_attached
          full_day
          attached_with_master
          tagShared_tagsId
          tagChild_tagsId
          tagAttached_tag_idId
          __typename
        }
        child_tags {
          nextToken
          __typename
        }
        tag_level
        comment_list {
          nextToken
          __typename
        }
        like_dislike_list {
          nextToken
          __typename
        }
        tag_messages {
          nextToken
          __typename
        }
        status
        floor
        room
        is_sold
        sold_date
        scene_tags
        is_abvertising_paid
        is_admin_approved
        analytics_user_data {
          nextToken
          __typename
        }
        spotify
        parent_id
        top_parent_id
        is_public
        is_tc_accepted
        is_master_attached
        attached_tag_id {
          nextToken
          __typename
        }
        full_day
        attached_with_master
        tagShared_tagsId
        tagChild_tagsId
        tagAttached_tag_idId
        __typename
      }
      user_id
      content
      comment_type
      like_dislike_list {
        items {
          unique_id
          type
          user_id
          diamond
          duck
          createdAt
          updatedAt
          tagLike_dislike_listId
          commentLike_dislike_listId
          __typename
        }
        nextToken
        __typename
      }
      status
      createdAt
      updatedAt
      tagComment_listId
      __typename
    }
  }
`;
export const updateComment = /* GraphQL */ `
  mutation UpdateComment(
    $input: UpdateCommentInput!
    $condition: ModelCommentConditionInput
  ) {
    updateComment(input: $input, condition: $condition) {
      id
      tag_id {
        id
        user_id
        type
        title
        content
        addresses
        visibility
        comments
        tagged_user_id
        memory_date
        occasion
        category
        sub_category
        price
        asset_price
        sold_price
        quantity
        available_for_sale
        auto_attach
        custom_field
        start_date
        end_date
        task_date
        task_end_date
        media
        repeat
        custom_start
        custom_repeat
        custom_repeat_value
        custom_days
        custom_end
        public_distance
        public_start
        public_end
        latitude
        longitude
        altitude
        purchasing_date
        createdAt
        updatedAt
        tag_attach_id
        shared_tag_id {
          id
          user_id
          type
          title
          content
          addresses
          visibility
          comments
          tagged_user_id
          memory_date
          occasion
          category
          sub_category
          price
          asset_price
          sold_price
          quantity
          available_for_sale
          auto_attach
          custom_field
          start_date
          end_date
          task_date
          task_end_date
          media
          repeat
          custom_start
          custom_repeat
          custom_repeat_value
          custom_days
          custom_end
          public_distance
          public_start
          public_end
          latitude
          longitude
          altitude
          purchasing_date
          createdAt
          updatedAt
          tag_attach_id
          tag_level
          status
          floor
          room
          is_sold
          sold_date
          scene_tags
          is_abvertising_paid
          is_admin_approved
          spotify
          parent_id
          top_parent_id
          is_public
          is_tc_accepted
          is_master_attached
          full_day
          attached_with_master
          tagShared_tagsId
          tagChild_tagsId
          tagAttached_tag_idId
          __typename
        }
        shared_tags {
          nextToken
          __typename
        }
        parent_tag_id {
          id
          user_id
          type
          title
          content
          addresses
          visibility
          comments
          tagged_user_id
          memory_date
          occasion
          category
          sub_category
          price
          asset_price
          sold_price
          quantity
          available_for_sale
          auto_attach
          custom_field
          start_date
          end_date
          task_date
          task_end_date
          media
          repeat
          custom_start
          custom_repeat
          custom_repeat_value
          custom_days
          custom_end
          public_distance
          public_start
          public_end
          latitude
          longitude
          altitude
          purchasing_date
          createdAt
          updatedAt
          tag_attach_id
          tag_level
          status
          floor
          room
          is_sold
          sold_date
          scene_tags
          is_abvertising_paid
          is_admin_approved
          spotify
          parent_id
          top_parent_id
          is_public
          is_tc_accepted
          is_master_attached
          full_day
          attached_with_master
          tagShared_tagsId
          tagChild_tagsId
          tagAttached_tag_idId
          __typename
        }
        child_tags {
          nextToken
          __typename
        }
        tag_level
        comment_list {
          nextToken
          __typename
        }
        like_dislike_list {
          nextToken
          __typename
        }
        tag_messages {
          nextToken
          __typename
        }
        status
        floor
        room
        is_sold
        sold_date
        scene_tags
        is_abvertising_paid
        is_admin_approved
        analytics_user_data {
          nextToken
          __typename
        }
        spotify
        parent_id
        top_parent_id
        is_public
        is_tc_accepted
        is_master_attached
        attached_tag_id {
          nextToken
          __typename
        }
        full_day
        attached_with_master
        tagShared_tagsId
        tagChild_tagsId
        tagAttached_tag_idId
        __typename
      }
      user_id
      content
      comment_type
      like_dislike_list {
        items {
          unique_id
          type
          user_id
          diamond
          duck
          createdAt
          updatedAt
          tagLike_dislike_listId
          commentLike_dislike_listId
          __typename
        }
        nextToken
        __typename
      }
      status
      createdAt
      updatedAt
      tagComment_listId
      __typename
    }
  }
`;
export const deleteComment = /* GraphQL */ `
  mutation DeleteComment(
    $input: DeleteCommentInput!
    $condition: ModelCommentConditionInput
  ) {
    deleteComment(input: $input, condition: $condition) {
      id
      tag_id {
        id
        user_id
        type
        title
        content
        addresses
        visibility
        comments
        tagged_user_id
        memory_date
        occasion
        category
        sub_category
        price
        asset_price
        sold_price
        quantity
        available_for_sale
        auto_attach
        custom_field
        start_date
        end_date
        task_date
        task_end_date
        media
        repeat
        custom_start
        custom_repeat
        custom_repeat_value
        custom_days
        custom_end
        public_distance
        public_start
        public_end
        latitude
        longitude
        altitude
        purchasing_date
        createdAt
        updatedAt
        tag_attach_id
        shared_tag_id {
          id
          user_id
          type
          title
          content
          addresses
          visibility
          comments
          tagged_user_id
          memory_date
          occasion
          category
          sub_category
          price
          asset_price
          sold_price
          quantity
          available_for_sale
          auto_attach
          custom_field
          start_date
          end_date
          task_date
          task_end_date
          media
          repeat
          custom_start
          custom_repeat
          custom_repeat_value
          custom_days
          custom_end
          public_distance
          public_start
          public_end
          latitude
          longitude
          altitude
          purchasing_date
          createdAt
          updatedAt
          tag_attach_id
          tag_level
          status
          floor
          room
          is_sold
          sold_date
          scene_tags
          is_abvertising_paid
          is_admin_approved
          spotify
          parent_id
          top_parent_id
          is_public
          is_tc_accepted
          is_master_attached
          full_day
          attached_with_master
          tagShared_tagsId
          tagChild_tagsId
          tagAttached_tag_idId
          __typename
        }
        shared_tags {
          nextToken
          __typename
        }
        parent_tag_id {
          id
          user_id
          type
          title
          content
          addresses
          visibility
          comments
          tagged_user_id
          memory_date
          occasion
          category
          sub_category
          price
          asset_price
          sold_price
          quantity
          available_for_sale
          auto_attach
          custom_field
          start_date
          end_date
          task_date
          task_end_date
          media
          repeat
          custom_start
          custom_repeat
          custom_repeat_value
          custom_days
          custom_end
          public_distance
          public_start
          public_end
          latitude
          longitude
          altitude
          purchasing_date
          createdAt
          updatedAt
          tag_attach_id
          tag_level
          status
          floor
          room
          is_sold
          sold_date
          scene_tags
          is_abvertising_paid
          is_admin_approved
          spotify
          parent_id
          top_parent_id
          is_public
          is_tc_accepted
          is_master_attached
          full_day
          attached_with_master
          tagShared_tagsId
          tagChild_tagsId
          tagAttached_tag_idId
          __typename
        }
        child_tags {
          nextToken
          __typename
        }
        tag_level
        comment_list {
          nextToken
          __typename
        }
        like_dislike_list {
          nextToken
          __typename
        }
        tag_messages {
          nextToken
          __typename
        }
        status
        floor
        room
        is_sold
        sold_date
        scene_tags
        is_abvertising_paid
        is_admin_approved
        analytics_user_data {
          nextToken
          __typename
        }
        spotify
        parent_id
        top_parent_id
        is_public
        is_tc_accepted
        is_master_attached
        attached_tag_id {
          nextToken
          __typename
        }
        full_day
        attached_with_master
        tagShared_tagsId
        tagChild_tagsId
        tagAttached_tag_idId
        __typename
      }
      user_id
      content
      comment_type
      like_dislike_list {
        items {
          unique_id
          type
          user_id
          diamond
          duck
          createdAt
          updatedAt
          tagLike_dislike_listId
          commentLike_dislike_listId
          __typename
        }
        nextToken
        __typename
      }
      status
      createdAt
      updatedAt
      tagComment_listId
      __typename
    }
  }
`;
export const createLikeDislike = /* GraphQL */ `
  mutation CreateLikeDislike(
    $input: CreateLikeDislikeInput!
    $condition: ModelLikeDislikeConditionInput
  ) {
    createLikeDislike(input: $input, condition: $condition) {
      unique_id
      tag_id {
        id
        user_id
        type
        title
        content
        addresses
        visibility
        comments
        tagged_user_id
        memory_date
        occasion
        category
        sub_category
        price
        asset_price
        sold_price
        quantity
        available_for_sale
        auto_attach
        custom_field
        start_date
        end_date
        task_date
        task_end_date
        media
        repeat
        custom_start
        custom_repeat
        custom_repeat_value
        custom_days
        custom_end
        public_distance
        public_start
        public_end
        latitude
        longitude
        altitude
        purchasing_date
        createdAt
        updatedAt
        tag_attach_id
        shared_tag_id {
          id
          user_id
          type
          title
          content
          addresses
          visibility
          comments
          tagged_user_id
          memory_date
          occasion
          category
          sub_category
          price
          asset_price
          sold_price
          quantity
          available_for_sale
          auto_attach
          custom_field
          start_date
          end_date
          task_date
          task_end_date
          media
          repeat
          custom_start
          custom_repeat
          custom_repeat_value
          custom_days
          custom_end
          public_distance
          public_start
          public_end
          latitude
          longitude
          altitude
          purchasing_date
          createdAt
          updatedAt
          tag_attach_id
          tag_level
          status
          floor
          room
          is_sold
          sold_date
          scene_tags
          is_abvertising_paid
          is_admin_approved
          spotify
          parent_id
          top_parent_id
          is_public
          is_tc_accepted
          is_master_attached
          full_day
          attached_with_master
          tagShared_tagsId
          tagChild_tagsId
          tagAttached_tag_idId
          __typename
        }
        shared_tags {
          nextToken
          __typename
        }
        parent_tag_id {
          id
          user_id
          type
          title
          content
          addresses
          visibility
          comments
          tagged_user_id
          memory_date
          occasion
          category
          sub_category
          price
          asset_price
          sold_price
          quantity
          available_for_sale
          auto_attach
          custom_field
          start_date
          end_date
          task_date
          task_end_date
          media
          repeat
          custom_start
          custom_repeat
          custom_repeat_value
          custom_days
          custom_end
          public_distance
          public_start
          public_end
          latitude
          longitude
          altitude
          purchasing_date
          createdAt
          updatedAt
          tag_attach_id
          tag_level
          status
          floor
          room
          is_sold
          sold_date
          scene_tags
          is_abvertising_paid
          is_admin_approved
          spotify
          parent_id
          top_parent_id
          is_public
          is_tc_accepted
          is_master_attached
          full_day
          attached_with_master
          tagShared_tagsId
          tagChild_tagsId
          tagAttached_tag_idId
          __typename
        }
        child_tags {
          nextToken
          __typename
        }
        tag_level
        comment_list {
          nextToken
          __typename
        }
        like_dislike_list {
          nextToken
          __typename
        }
        tag_messages {
          nextToken
          __typename
        }
        status
        floor
        room
        is_sold
        sold_date
        scene_tags
        is_abvertising_paid
        is_admin_approved
        analytics_user_data {
          nextToken
          __typename
        }
        spotify
        parent_id
        top_parent_id
        is_public
        is_tc_accepted
        is_master_attached
        attached_tag_id {
          nextToken
          __typename
        }
        full_day
        attached_with_master
        tagShared_tagsId
        tagChild_tagsId
        tagAttached_tag_idId
        __typename
      }
      like_dislike_id {
        id
        tag_id {
          id
          user_id
          type
          title
          content
          addresses
          visibility
          comments
          tagged_user_id
          memory_date
          occasion
          category
          sub_category
          price
          asset_price
          sold_price
          quantity
          available_for_sale
          auto_attach
          custom_field
          start_date
          end_date
          task_date
          task_end_date
          media
          repeat
          custom_start
          custom_repeat
          custom_repeat_value
          custom_days
          custom_end
          public_distance
          public_start
          public_end
          latitude
          longitude
          altitude
          purchasing_date
          createdAt
          updatedAt
          tag_attach_id
          tag_level
          status
          floor
          room
          is_sold
          sold_date
          scene_tags
          is_abvertising_paid
          is_admin_approved
          spotify
          parent_id
          top_parent_id
          is_public
          is_tc_accepted
          is_master_attached
          full_day
          attached_with_master
          tagShared_tagsId
          tagChild_tagsId
          tagAttached_tag_idId
          __typename
        }
        user_id
        content
        comment_type
        like_dislike_list {
          nextToken
          __typename
        }
        status
        createdAt
        updatedAt
        tagComment_listId
        __typename
      }
      type
      user_id
      diamond
      duck
      createdAt
      updatedAt
      tagLike_dislike_listId
      commentLike_dislike_listId
      __typename
    }
  }
`;
export const updateLikeDislike = /* GraphQL */ `
  mutation UpdateLikeDislike(
    $input: UpdateLikeDislikeInput!
    $condition: ModelLikeDislikeConditionInput
  ) {
    updateLikeDislike(input: $input, condition: $condition) {
      unique_id
      tag_id {
        id
        user_id
        type
        title
        content
        addresses
        visibility
        comments
        tagged_user_id
        memory_date
        occasion
        category
        sub_category
        price
        asset_price
        sold_price
        quantity
        available_for_sale
        auto_attach
        custom_field
        start_date
        end_date
        task_date
        task_end_date
        media
        repeat
        custom_start
        custom_repeat
        custom_repeat_value
        custom_days
        custom_end
        public_distance
        public_start
        public_end
        latitude
        longitude
        altitude
        purchasing_date
        createdAt
        updatedAt
        tag_attach_id
        shared_tag_id {
          id
          user_id
          type
          title
          content
          addresses
          visibility
          comments
          tagged_user_id
          memory_date
          occasion
          category
          sub_category
          price
          asset_price
          sold_price
          quantity
          available_for_sale
          auto_attach
          custom_field
          start_date
          end_date
          task_date
          task_end_date
          media
          repeat
          custom_start
          custom_repeat
          custom_repeat_value
          custom_days
          custom_end
          public_distance
          public_start
          public_end
          latitude
          longitude
          altitude
          purchasing_date
          createdAt
          updatedAt
          tag_attach_id
          tag_level
          status
          floor
          room
          is_sold
          sold_date
          scene_tags
          is_abvertising_paid
          is_admin_approved
          spotify
          parent_id
          top_parent_id
          is_public
          is_tc_accepted
          is_master_attached
          full_day
          attached_with_master
          tagShared_tagsId
          tagChild_tagsId
          tagAttached_tag_idId
          __typename
        }
        shared_tags {
          nextToken
          __typename
        }
        parent_tag_id {
          id
          user_id
          type
          title
          content
          addresses
          visibility
          comments
          tagged_user_id
          memory_date
          occasion
          category
          sub_category
          price
          asset_price
          sold_price
          quantity
          available_for_sale
          auto_attach
          custom_field
          start_date
          end_date
          task_date
          task_end_date
          media
          repeat
          custom_start
          custom_repeat
          custom_repeat_value
          custom_days
          custom_end
          public_distance
          public_start
          public_end
          latitude
          longitude
          altitude
          purchasing_date
          createdAt
          updatedAt
          tag_attach_id
          tag_level
          status
          floor
          room
          is_sold
          sold_date
          scene_tags
          is_abvertising_paid
          is_admin_approved
          spotify
          parent_id
          top_parent_id
          is_public
          is_tc_accepted
          is_master_attached
          full_day
          attached_with_master
          tagShared_tagsId
          tagChild_tagsId
          tagAttached_tag_idId
          __typename
        }
        child_tags {
          nextToken
          __typename
        }
        tag_level
        comment_list {
          nextToken
          __typename
        }
        like_dislike_list {
          nextToken
          __typename
        }
        tag_messages {
          nextToken
          __typename
        }
        status
        floor
        room
        is_sold
        sold_date
        scene_tags
        is_abvertising_paid
        is_admin_approved
        analytics_user_data {
          nextToken
          __typename
        }
        spotify
        parent_id
        top_parent_id
        is_public
        is_tc_accepted
        is_master_attached
        attached_tag_id {
          nextToken
          __typename
        }
        full_day
        attached_with_master
        tagShared_tagsId
        tagChild_tagsId
        tagAttached_tag_idId
        __typename
      }
      like_dislike_id {
        id
        tag_id {
          id
          user_id
          type
          title
          content
          addresses
          visibility
          comments
          tagged_user_id
          memory_date
          occasion
          category
          sub_category
          price
          asset_price
          sold_price
          quantity
          available_for_sale
          auto_attach
          custom_field
          start_date
          end_date
          task_date
          task_end_date
          media
          repeat
          custom_start
          custom_repeat
          custom_repeat_value
          custom_days
          custom_end
          public_distance
          public_start
          public_end
          latitude
          longitude
          altitude
          purchasing_date
          createdAt
          updatedAt
          tag_attach_id
          tag_level
          status
          floor
          room
          is_sold
          sold_date
          scene_tags
          is_abvertising_paid
          is_admin_approved
          spotify
          parent_id
          top_parent_id
          is_public
          is_tc_accepted
          is_master_attached
          full_day
          attached_with_master
          tagShared_tagsId
          tagChild_tagsId
          tagAttached_tag_idId
          __typename
        }
        user_id
        content
        comment_type
        like_dislike_list {
          nextToken
          __typename
        }
        status
        createdAt
        updatedAt
        tagComment_listId
        __typename
      }
      type
      user_id
      diamond
      duck
      createdAt
      updatedAt
      tagLike_dislike_listId
      commentLike_dislike_listId
      __typename
    }
  }
`;
export const deleteLikeDislike = /* GraphQL */ `
  mutation DeleteLikeDislike(
    $input: DeleteLikeDislikeInput!
    $condition: ModelLikeDislikeConditionInput
  ) {
    deleteLikeDislike(input: $input, condition: $condition) {
      unique_id
      tag_id {
        id
        user_id
        type
        title
        content
        addresses
        visibility
        comments
        tagged_user_id
        memory_date
        occasion
        category
        sub_category
        price
        asset_price
        sold_price
        quantity
        available_for_sale
        auto_attach
        custom_field
        start_date
        end_date
        task_date
        task_end_date
        media
        repeat
        custom_start
        custom_repeat
        custom_repeat_value
        custom_days
        custom_end
        public_distance
        public_start
        public_end
        latitude
        longitude
        altitude
        purchasing_date
        createdAt
        updatedAt
        tag_attach_id
        shared_tag_id {
          id
          user_id
          type
          title
          content
          addresses
          visibility
          comments
          tagged_user_id
          memory_date
          occasion
          category
          sub_category
          price
          asset_price
          sold_price
          quantity
          available_for_sale
          auto_attach
          custom_field
          start_date
          end_date
          task_date
          task_end_date
          media
          repeat
          custom_start
          custom_repeat
          custom_repeat_value
          custom_days
          custom_end
          public_distance
          public_start
          public_end
          latitude
          longitude
          altitude
          purchasing_date
          createdAt
          updatedAt
          tag_attach_id
          tag_level
          status
          floor
          room
          is_sold
          sold_date
          scene_tags
          is_abvertising_paid
          is_admin_approved
          spotify
          parent_id
          top_parent_id
          is_public
          is_tc_accepted
          is_master_attached
          full_day
          attached_with_master
          tagShared_tagsId
          tagChild_tagsId
          tagAttached_tag_idId
          __typename
        }
        shared_tags {
          nextToken
          __typename
        }
        parent_tag_id {
          id
          user_id
          type
          title
          content
          addresses
          visibility
          comments
          tagged_user_id
          memory_date
          occasion
          category
          sub_category
          price
          asset_price
          sold_price
          quantity
          available_for_sale
          auto_attach
          custom_field
          start_date
          end_date
          task_date
          task_end_date
          media
          repeat
          custom_start
          custom_repeat
          custom_repeat_value
          custom_days
          custom_end
          public_distance
          public_start
          public_end
          latitude
          longitude
          altitude
          purchasing_date
          createdAt
          updatedAt
          tag_attach_id
          tag_level
          status
          floor
          room
          is_sold
          sold_date
          scene_tags
          is_abvertising_paid
          is_admin_approved
          spotify
          parent_id
          top_parent_id
          is_public
          is_tc_accepted
          is_master_attached
          full_day
          attached_with_master
          tagShared_tagsId
          tagChild_tagsId
          tagAttached_tag_idId
          __typename
        }
        child_tags {
          nextToken
          __typename
        }
        tag_level
        comment_list {
          nextToken
          __typename
        }
        like_dislike_list {
          nextToken
          __typename
        }
        tag_messages {
          nextToken
          __typename
        }
        status
        floor
        room
        is_sold
        sold_date
        scene_tags
        is_abvertising_paid
        is_admin_approved
        analytics_user_data {
          nextToken
          __typename
        }
        spotify
        parent_id
        top_parent_id
        is_public
        is_tc_accepted
        is_master_attached
        attached_tag_id {
          nextToken
          __typename
        }
        full_day
        attached_with_master
        tagShared_tagsId
        tagChild_tagsId
        tagAttached_tag_idId
        __typename
      }
      like_dislike_id {
        id
        tag_id {
          id
          user_id
          type
          title
          content
          addresses
          visibility
          comments
          tagged_user_id
          memory_date
          occasion
          category
          sub_category
          price
          asset_price
          sold_price
          quantity
          available_for_sale
          auto_attach
          custom_field
          start_date
          end_date
          task_date
          task_end_date
          media
          repeat
          custom_start
          custom_repeat
          custom_repeat_value
          custom_days
          custom_end
          public_distance
          public_start
          public_end
          latitude
          longitude
          altitude
          purchasing_date
          createdAt
          updatedAt
          tag_attach_id
          tag_level
          status
          floor
          room
          is_sold
          sold_date
          scene_tags
          is_abvertising_paid
          is_admin_approved
          spotify
          parent_id
          top_parent_id
          is_public
          is_tc_accepted
          is_master_attached
          full_day
          attached_with_master
          tagShared_tagsId
          tagChild_tagsId
          tagAttached_tag_idId
          __typename
        }
        user_id
        content
        comment_type
        like_dislike_list {
          nextToken
          __typename
        }
        status
        createdAt
        updatedAt
        tagComment_listId
        __typename
      }
      type
      user_id
      diamond
      duck
      createdAt
      updatedAt
      tagLike_dislike_listId
      commentLike_dislike_listId
      __typename
    }
  }
`;
export const createConversation = /* GraphQL */ `
  mutation CreateConversation(
    $input: CreateConversationInput!
    $condition: ModelConversationConditionInput
  ) {
    createConversation(input: $input, condition: $condition) {
      id
      sender
      recipient
      messages {
        items {
          id
          sender
          content
          recipient
          media
          is_readed
          message_timestamp
          createdAt
          message_from
          tag_id
          status
          updatedAt
          conversationMessagesId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      message_from
      tag_id
      status
      __typename
    }
  }
`;
export const updateConversation = /* GraphQL */ `
  mutation UpdateConversation(
    $input: UpdateConversationInput!
    $condition: ModelConversationConditionInput
  ) {
    updateConversation(input: $input, condition: $condition) {
      id
      sender
      recipient
      messages {
        items {
          id
          sender
          content
          recipient
          media
          is_readed
          message_timestamp
          createdAt
          message_from
          tag_id
          status
          updatedAt
          conversationMessagesId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      message_from
      tag_id
      status
      __typename
    }
  }
`;
export const deleteConversation = /* GraphQL */ `
  mutation DeleteConversation(
    $input: DeleteConversationInput!
    $condition: ModelConversationConditionInput
  ) {
    deleteConversation(input: $input, condition: $condition) {
      id
      sender
      recipient
      messages {
        items {
          id
          sender
          content
          recipient
          media
          is_readed
          message_timestamp
          createdAt
          message_from
          tag_id
          status
          updatedAt
          conversationMessagesId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      message_from
      tag_id
      status
      __typename
    }
  }
`;
export const createMessages = /* GraphQL */ `
  mutation CreateMessages(
    $input: CreateMessagesInput!
    $condition: ModelMessagesConditionInput
  ) {
    createMessages(input: $input, condition: $condition) {
      id
      conversation_id {
        id
        sender
        recipient
        messages {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        message_from
        tag_id
        status
        __typename
      }
      sender
      content
      recipient
      media
      is_readed
      message_timestamp
      createdAt
      message_from
      tag_id
      status
      updatedAt
      conversationMessagesId
      __typename
    }
  }
`;
export const updateMessages = /* GraphQL */ `
  mutation UpdateMessages(
    $input: UpdateMessagesInput!
    $condition: ModelMessagesConditionInput
  ) {
    updateMessages(input: $input, condition: $condition) {
      id
      conversation_id {
        id
        sender
        recipient
        messages {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        message_from
        tag_id
        status
        __typename
      }
      sender
      content
      recipient
      media
      is_readed
      message_timestamp
      createdAt
      message_from
      tag_id
      status
      updatedAt
      conversationMessagesId
      __typename
    }
  }
`;
export const deleteMessages = /* GraphQL */ `
  mutation DeleteMessages(
    $input: DeleteMessagesInput!
    $condition: ModelMessagesConditionInput
  ) {
    deleteMessages(input: $input, condition: $condition) {
      id
      conversation_id {
        id
        sender
        recipient
        messages {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        message_from
        tag_id
        status
        __typename
      }
      sender
      content
      recipient
      media
      is_readed
      message_timestamp
      createdAt
      message_from
      tag_id
      status
      updatedAt
      conversationMessagesId
      __typename
    }
  }
`;
export const createTagMessage = /* GraphQL */ `
  mutation CreateTagMessage(
    $input: CreateTagMessageInput!
    $condition: ModelTagMessageConditionInput
  ) {
    createTagMessage(input: $input, condition: $condition) {
      id
      sender
      recipient
      content
      parent_id {
        id
        sender
        recipient
        content
        parent_id {
          id
          sender
          recipient
          content
          createdAt
          updatedAt
          tagTag_messagesId
          tagMessageRepliesId
          __typename
        }
        replies {
          nextToken
          __typename
        }
        tag_id {
          id
          user_id
          type
          title
          content
          addresses
          visibility
          comments
          tagged_user_id
          memory_date
          occasion
          category
          sub_category
          price
          asset_price
          sold_price
          quantity
          available_for_sale
          auto_attach
          custom_field
          start_date
          end_date
          task_date
          task_end_date
          media
          repeat
          custom_start
          custom_repeat
          custom_repeat_value
          custom_days
          custom_end
          public_distance
          public_start
          public_end
          latitude
          longitude
          altitude
          purchasing_date
          createdAt
          updatedAt
          tag_attach_id
          tag_level
          status
          floor
          room
          is_sold
          sold_date
          scene_tags
          is_abvertising_paid
          is_admin_approved
          spotify
          parent_id
          top_parent_id
          is_public
          is_tc_accepted
          is_master_attached
          full_day
          attached_with_master
          tagShared_tagsId
          tagChild_tagsId
          tagAttached_tag_idId
          __typename
        }
        createdAt
        updatedAt
        tagTag_messagesId
        tagMessageRepliesId
        __typename
      }
      replies {
        items {
          id
          sender
          recipient
          content
          createdAt
          updatedAt
          tagTag_messagesId
          tagMessageRepliesId
          __typename
        }
        nextToken
        __typename
      }
      tag_id {
        id
        user_id
        type
        title
        content
        addresses
        visibility
        comments
        tagged_user_id
        memory_date
        occasion
        category
        sub_category
        price
        asset_price
        sold_price
        quantity
        available_for_sale
        auto_attach
        custom_field
        start_date
        end_date
        task_date
        task_end_date
        media
        repeat
        custom_start
        custom_repeat
        custom_repeat_value
        custom_days
        custom_end
        public_distance
        public_start
        public_end
        latitude
        longitude
        altitude
        purchasing_date
        createdAt
        updatedAt
        tag_attach_id
        shared_tag_id {
          id
          user_id
          type
          title
          content
          addresses
          visibility
          comments
          tagged_user_id
          memory_date
          occasion
          category
          sub_category
          price
          asset_price
          sold_price
          quantity
          available_for_sale
          auto_attach
          custom_field
          start_date
          end_date
          task_date
          task_end_date
          media
          repeat
          custom_start
          custom_repeat
          custom_repeat_value
          custom_days
          custom_end
          public_distance
          public_start
          public_end
          latitude
          longitude
          altitude
          purchasing_date
          createdAt
          updatedAt
          tag_attach_id
          tag_level
          status
          floor
          room
          is_sold
          sold_date
          scene_tags
          is_abvertising_paid
          is_admin_approved
          spotify
          parent_id
          top_parent_id
          is_public
          is_tc_accepted
          is_master_attached
          full_day
          attached_with_master
          tagShared_tagsId
          tagChild_tagsId
          tagAttached_tag_idId
          __typename
        }
        shared_tags {
          nextToken
          __typename
        }
        parent_tag_id {
          id
          user_id
          type
          title
          content
          addresses
          visibility
          comments
          tagged_user_id
          memory_date
          occasion
          category
          sub_category
          price
          asset_price
          sold_price
          quantity
          available_for_sale
          auto_attach
          custom_field
          start_date
          end_date
          task_date
          task_end_date
          media
          repeat
          custom_start
          custom_repeat
          custom_repeat_value
          custom_days
          custom_end
          public_distance
          public_start
          public_end
          latitude
          longitude
          altitude
          purchasing_date
          createdAt
          updatedAt
          tag_attach_id
          tag_level
          status
          floor
          room
          is_sold
          sold_date
          scene_tags
          is_abvertising_paid
          is_admin_approved
          spotify
          parent_id
          top_parent_id
          is_public
          is_tc_accepted
          is_master_attached
          full_day
          attached_with_master
          tagShared_tagsId
          tagChild_tagsId
          tagAttached_tag_idId
          __typename
        }
        child_tags {
          nextToken
          __typename
        }
        tag_level
        comment_list {
          nextToken
          __typename
        }
        like_dislike_list {
          nextToken
          __typename
        }
        tag_messages {
          nextToken
          __typename
        }
        status
        floor
        room
        is_sold
        sold_date
        scene_tags
        is_abvertising_paid
        is_admin_approved
        analytics_user_data {
          nextToken
          __typename
        }
        spotify
        parent_id
        top_parent_id
        is_public
        is_tc_accepted
        is_master_attached
        attached_tag_id {
          nextToken
          __typename
        }
        full_day
        attached_with_master
        tagShared_tagsId
        tagChild_tagsId
        tagAttached_tag_idId
        __typename
      }
      createdAt
      updatedAt
      tagTag_messagesId
      tagMessageRepliesId
      __typename
    }
  }
`;
export const updateTagMessage = /* GraphQL */ `
  mutation UpdateTagMessage(
    $input: UpdateTagMessageInput!
    $condition: ModelTagMessageConditionInput
  ) {
    updateTagMessage(input: $input, condition: $condition) {
      id
      sender
      recipient
      content
      parent_id {
        id
        sender
        recipient
        content
        parent_id {
          id
          sender
          recipient
          content
          createdAt
          updatedAt
          tagTag_messagesId
          tagMessageRepliesId
          __typename
        }
        replies {
          nextToken
          __typename
        }
        tag_id {
          id
          user_id
          type
          title
          content
          addresses
          visibility
          comments
          tagged_user_id
          memory_date
          occasion
          category
          sub_category
          price
          asset_price
          sold_price
          quantity
          available_for_sale
          auto_attach
          custom_field
          start_date
          end_date
          task_date
          task_end_date
          media
          repeat
          custom_start
          custom_repeat
          custom_repeat_value
          custom_days
          custom_end
          public_distance
          public_start
          public_end
          latitude
          longitude
          altitude
          purchasing_date
          createdAt
          updatedAt
          tag_attach_id
          tag_level
          status
          floor
          room
          is_sold
          sold_date
          scene_tags
          is_abvertising_paid
          is_admin_approved
          spotify
          parent_id
          top_parent_id
          is_public
          is_tc_accepted
          is_master_attached
          full_day
          attached_with_master
          tagShared_tagsId
          tagChild_tagsId
          tagAttached_tag_idId
          __typename
        }
        createdAt
        updatedAt
        tagTag_messagesId
        tagMessageRepliesId
        __typename
      }
      replies {
        items {
          id
          sender
          recipient
          content
          createdAt
          updatedAt
          tagTag_messagesId
          tagMessageRepliesId
          __typename
        }
        nextToken
        __typename
      }
      tag_id {
        id
        user_id
        type
        title
        content
        addresses
        visibility
        comments
        tagged_user_id
        memory_date
        occasion
        category
        sub_category
        price
        asset_price
        sold_price
        quantity
        available_for_sale
        auto_attach
        custom_field
        start_date
        end_date
        task_date
        task_end_date
        media
        repeat
        custom_start
        custom_repeat
        custom_repeat_value
        custom_days
        custom_end
        public_distance
        public_start
        public_end
        latitude
        longitude
        altitude
        purchasing_date
        createdAt
        updatedAt
        tag_attach_id
        shared_tag_id {
          id
          user_id
          type
          title
          content
          addresses
          visibility
          comments
          tagged_user_id
          memory_date
          occasion
          category
          sub_category
          price
          asset_price
          sold_price
          quantity
          available_for_sale
          auto_attach
          custom_field
          start_date
          end_date
          task_date
          task_end_date
          media
          repeat
          custom_start
          custom_repeat
          custom_repeat_value
          custom_days
          custom_end
          public_distance
          public_start
          public_end
          latitude
          longitude
          altitude
          purchasing_date
          createdAt
          updatedAt
          tag_attach_id
          tag_level
          status
          floor
          room
          is_sold
          sold_date
          scene_tags
          is_abvertising_paid
          is_admin_approved
          spotify
          parent_id
          top_parent_id
          is_public
          is_tc_accepted
          is_master_attached
          full_day
          attached_with_master
          tagShared_tagsId
          tagChild_tagsId
          tagAttached_tag_idId
          __typename
        }
        shared_tags {
          nextToken
          __typename
        }
        parent_tag_id {
          id
          user_id
          type
          title
          content
          addresses
          visibility
          comments
          tagged_user_id
          memory_date
          occasion
          category
          sub_category
          price
          asset_price
          sold_price
          quantity
          available_for_sale
          auto_attach
          custom_field
          start_date
          end_date
          task_date
          task_end_date
          media
          repeat
          custom_start
          custom_repeat
          custom_repeat_value
          custom_days
          custom_end
          public_distance
          public_start
          public_end
          latitude
          longitude
          altitude
          purchasing_date
          createdAt
          updatedAt
          tag_attach_id
          tag_level
          status
          floor
          room
          is_sold
          sold_date
          scene_tags
          is_abvertising_paid
          is_admin_approved
          spotify
          parent_id
          top_parent_id
          is_public
          is_tc_accepted
          is_master_attached
          full_day
          attached_with_master
          tagShared_tagsId
          tagChild_tagsId
          tagAttached_tag_idId
          __typename
        }
        child_tags {
          nextToken
          __typename
        }
        tag_level
        comment_list {
          nextToken
          __typename
        }
        like_dislike_list {
          nextToken
          __typename
        }
        tag_messages {
          nextToken
          __typename
        }
        status
        floor
        room
        is_sold
        sold_date
        scene_tags
        is_abvertising_paid
        is_admin_approved
        analytics_user_data {
          nextToken
          __typename
        }
        spotify
        parent_id
        top_parent_id
        is_public
        is_tc_accepted
        is_master_attached
        attached_tag_id {
          nextToken
          __typename
        }
        full_day
        attached_with_master
        tagShared_tagsId
        tagChild_tagsId
        tagAttached_tag_idId
        __typename
      }
      createdAt
      updatedAt
      tagTag_messagesId
      tagMessageRepliesId
      __typename
    }
  }
`;
export const deleteTagMessage = /* GraphQL */ `
  mutation DeleteTagMessage(
    $input: DeleteTagMessageInput!
    $condition: ModelTagMessageConditionInput
  ) {
    deleteTagMessage(input: $input, condition: $condition) {
      id
      sender
      recipient
      content
      parent_id {
        id
        sender
        recipient
        content
        parent_id {
          id
          sender
          recipient
          content
          createdAt
          updatedAt
          tagTag_messagesId
          tagMessageRepliesId
          __typename
        }
        replies {
          nextToken
          __typename
        }
        tag_id {
          id
          user_id
          type
          title
          content
          addresses
          visibility
          comments
          tagged_user_id
          memory_date
          occasion
          category
          sub_category
          price
          asset_price
          sold_price
          quantity
          available_for_sale
          auto_attach
          custom_field
          start_date
          end_date
          task_date
          task_end_date
          media
          repeat
          custom_start
          custom_repeat
          custom_repeat_value
          custom_days
          custom_end
          public_distance
          public_start
          public_end
          latitude
          longitude
          altitude
          purchasing_date
          createdAt
          updatedAt
          tag_attach_id
          tag_level
          status
          floor
          room
          is_sold
          sold_date
          scene_tags
          is_abvertising_paid
          is_admin_approved
          spotify
          parent_id
          top_parent_id
          is_public
          is_tc_accepted
          is_master_attached
          full_day
          attached_with_master
          tagShared_tagsId
          tagChild_tagsId
          tagAttached_tag_idId
          __typename
        }
        createdAt
        updatedAt
        tagTag_messagesId
        tagMessageRepliesId
        __typename
      }
      replies {
        items {
          id
          sender
          recipient
          content
          createdAt
          updatedAt
          tagTag_messagesId
          tagMessageRepliesId
          __typename
        }
        nextToken
        __typename
      }
      tag_id {
        id
        user_id
        type
        title
        content
        addresses
        visibility
        comments
        tagged_user_id
        memory_date
        occasion
        category
        sub_category
        price
        asset_price
        sold_price
        quantity
        available_for_sale
        auto_attach
        custom_field
        start_date
        end_date
        task_date
        task_end_date
        media
        repeat
        custom_start
        custom_repeat
        custom_repeat_value
        custom_days
        custom_end
        public_distance
        public_start
        public_end
        latitude
        longitude
        altitude
        purchasing_date
        createdAt
        updatedAt
        tag_attach_id
        shared_tag_id {
          id
          user_id
          type
          title
          content
          addresses
          visibility
          comments
          tagged_user_id
          memory_date
          occasion
          category
          sub_category
          price
          asset_price
          sold_price
          quantity
          available_for_sale
          auto_attach
          custom_field
          start_date
          end_date
          task_date
          task_end_date
          media
          repeat
          custom_start
          custom_repeat
          custom_repeat_value
          custom_days
          custom_end
          public_distance
          public_start
          public_end
          latitude
          longitude
          altitude
          purchasing_date
          createdAt
          updatedAt
          tag_attach_id
          tag_level
          status
          floor
          room
          is_sold
          sold_date
          scene_tags
          is_abvertising_paid
          is_admin_approved
          spotify
          parent_id
          top_parent_id
          is_public
          is_tc_accepted
          is_master_attached
          full_day
          attached_with_master
          tagShared_tagsId
          tagChild_tagsId
          tagAttached_tag_idId
          __typename
        }
        shared_tags {
          nextToken
          __typename
        }
        parent_tag_id {
          id
          user_id
          type
          title
          content
          addresses
          visibility
          comments
          tagged_user_id
          memory_date
          occasion
          category
          sub_category
          price
          asset_price
          sold_price
          quantity
          available_for_sale
          auto_attach
          custom_field
          start_date
          end_date
          task_date
          task_end_date
          media
          repeat
          custom_start
          custom_repeat
          custom_repeat_value
          custom_days
          custom_end
          public_distance
          public_start
          public_end
          latitude
          longitude
          altitude
          purchasing_date
          createdAt
          updatedAt
          tag_attach_id
          tag_level
          status
          floor
          room
          is_sold
          sold_date
          scene_tags
          is_abvertising_paid
          is_admin_approved
          spotify
          parent_id
          top_parent_id
          is_public
          is_tc_accepted
          is_master_attached
          full_day
          attached_with_master
          tagShared_tagsId
          tagChild_tagsId
          tagAttached_tag_idId
          __typename
        }
        child_tags {
          nextToken
          __typename
        }
        tag_level
        comment_list {
          nextToken
          __typename
        }
        like_dislike_list {
          nextToken
          __typename
        }
        tag_messages {
          nextToken
          __typename
        }
        status
        floor
        room
        is_sold
        sold_date
        scene_tags
        is_abvertising_paid
        is_admin_approved
        analytics_user_data {
          nextToken
          __typename
        }
        spotify
        parent_id
        top_parent_id
        is_public
        is_tc_accepted
        is_master_attached
        attached_tag_id {
          nextToken
          __typename
        }
        full_day
        attached_with_master
        tagShared_tagsId
        tagChild_tagsId
        tagAttached_tag_idId
        __typename
      }
      createdAt
      updatedAt
      tagTag_messagesId
      tagMessageRepliesId
      __typename
    }
  }
`;
export const createMaps = /* GraphQL */ `
  mutation CreateMaps(
    $input: CreateMapsInput!
    $condition: ModelMapsConditionInput
  ) {
    createMaps(input: $input, condition: $condition) {
      id
      item_id
      item_type
      longitude
      latitude
      status
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateMaps = /* GraphQL */ `
  mutation UpdateMaps(
    $input: UpdateMapsInput!
    $condition: ModelMapsConditionInput
  ) {
    updateMaps(input: $input, condition: $condition) {
      id
      item_id
      item_type
      longitude
      latitude
      status
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteMaps = /* GraphQL */ `
  mutation DeleteMaps(
    $input: DeleteMapsInput!
    $condition: ModelMapsConditionInput
  ) {
    deleteMaps(input: $input, condition: $condition) {
      id
      item_id
      item_type
      longitude
      latitude
      status
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createLoginQR = /* GraphQL */ `
  mutation CreateLoginQR(
    $input: CreateLoginQRInput!
    $condition: ModelLoginQRConditionInput
  ) {
    createLoginQR(input: $input, condition: $condition) {
      id
      user_id
      jwt_auth
      ip_address
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateLoginQR = /* GraphQL */ `
  mutation UpdateLoginQR(
    $input: UpdateLoginQRInput!
    $condition: ModelLoginQRConditionInput
  ) {
    updateLoginQR(input: $input, condition: $condition) {
      id
      user_id
      jwt_auth
      ip_address
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteLoginQR = /* GraphQL */ `
  mutation DeleteLoginQR(
    $input: DeleteLoginQRInput!
    $condition: ModelLoginQRConditionInput
  ) {
    deleteLoginQR(input: $input, condition: $condition) {
      id
      user_id
      jwt_auth
      ip_address
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createAppReviews = /* GraphQL */ `
  mutation CreateAppReviews(
    $input: CreateAppReviewsInput!
    $condition: ModelAppReviewsConditionInput
  ) {
    createAppReviews(input: $input, condition: $condition) {
      id
      user_id
      app_id
      comment
      status
      like_dislike_list {
        items {
          unique_id
          app_id
          user_id
          diamond
          duck
          createdAt
          updatedAt
          appReviewsLike_dislike_listId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateAppReviews = /* GraphQL */ `
  mutation UpdateAppReviews(
    $input: UpdateAppReviewsInput!
    $condition: ModelAppReviewsConditionInput
  ) {
    updateAppReviews(input: $input, condition: $condition) {
      id
      user_id
      app_id
      comment
      status
      like_dislike_list {
        items {
          unique_id
          app_id
          user_id
          diamond
          duck
          createdAt
          updatedAt
          appReviewsLike_dislike_listId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteAppReviews = /* GraphQL */ `
  mutation DeleteAppReviews(
    $input: DeleteAppReviewsInput!
    $condition: ModelAppReviewsConditionInput
  ) {
    deleteAppReviews(input: $input, condition: $condition) {
      id
      user_id
      app_id
      comment
      status
      like_dislike_list {
        items {
          unique_id
          app_id
          user_id
          diamond
          duck
          createdAt
          updatedAt
          appReviewsLike_dislike_listId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createLikeDislikeAppReview = /* GraphQL */ `
  mutation CreateLikeDislikeAppReview(
    $input: CreateLikeDislikeAppReviewInput!
    $condition: ModelLikeDislikeAppReviewConditionInput
  ) {
    createLikeDislikeAppReview(input: $input, condition: $condition) {
      unique_id
      app_id
      user_id
      like_dislike_id {
        id
        user_id
        app_id
        comment
        status
        like_dislike_list {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      diamond
      duck
      createdAt
      updatedAt
      appReviewsLike_dislike_listId
      __typename
    }
  }
`;
export const updateLikeDislikeAppReview = /* GraphQL */ `
  mutation UpdateLikeDislikeAppReview(
    $input: UpdateLikeDislikeAppReviewInput!
    $condition: ModelLikeDislikeAppReviewConditionInput
  ) {
    updateLikeDislikeAppReview(input: $input, condition: $condition) {
      unique_id
      app_id
      user_id
      like_dislike_id {
        id
        user_id
        app_id
        comment
        status
        like_dislike_list {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      diamond
      duck
      createdAt
      updatedAt
      appReviewsLike_dislike_listId
      __typename
    }
  }
`;
export const deleteLikeDislikeAppReview = /* GraphQL */ `
  mutation DeleteLikeDislikeAppReview(
    $input: DeleteLikeDislikeAppReviewInput!
    $condition: ModelLikeDislikeAppReviewConditionInput
  ) {
    deleteLikeDislikeAppReview(input: $input, condition: $condition) {
      unique_id
      app_id
      user_id
      like_dislike_id {
        id
        user_id
        app_id
        comment
        status
        like_dislike_list {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      diamond
      duck
      createdAt
      updatedAt
      appReviewsLike_dislike_listId
      __typename
    }
  }
`;
export const createUserActionLog = /* GraphQL */ `
  mutation CreateUserActionLog(
    $input: CreateUserActionLogInput!
    $condition: ModelUserActionLogConditionInput
  ) {
    createUserActionLog(input: $input, condition: $condition) {
      id
      log_type
      user_id
      remote_ip
      device_type
      user_agent
      device_os
      data
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateUserActionLog = /* GraphQL */ `
  mutation UpdateUserActionLog(
    $input: UpdateUserActionLogInput!
    $condition: ModelUserActionLogConditionInput
  ) {
    updateUserActionLog(input: $input, condition: $condition) {
      id
      log_type
      user_id
      remote_ip
      device_type
      user_agent
      device_os
      data
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteUserActionLog = /* GraphQL */ `
  mutation DeleteUserActionLog(
    $input: DeleteUserActionLogInput!
    $condition: ModelUserActionLogConditionInput
  ) {
    deleteUserActionLog(input: $input, condition: $condition) {
      id
      log_type
      user_id
      remote_ip
      device_type
      user_agent
      device_os
      data
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createUserLoginActionlog = /* GraphQL */ `
  mutation CreateUserLoginActionlog(
    $input: CreateUserLoginActionlogInput!
    $condition: ModelUserLoginActionlogConditionInput
  ) {
    createUserLoginActionlog(input: $input, condition: $condition) {
      id
      user_id
      device_os
      device_id
      device_type
      device_name
      user_agent
      remote_ip
      login_status
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateUserLoginActionlog = /* GraphQL */ `
  mutation UpdateUserLoginActionlog(
    $input: UpdateUserLoginActionlogInput!
    $condition: ModelUserLoginActionlogConditionInput
  ) {
    updateUserLoginActionlog(input: $input, condition: $condition) {
      id
      user_id
      device_os
      device_id
      device_type
      device_name
      user_agent
      remote_ip
      login_status
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteUserLoginActionlog = /* GraphQL */ `
  mutation DeleteUserLoginActionlog(
    $input: DeleteUserLoginActionlogInput!
    $condition: ModelUserLoginActionlogConditionInput
  ) {
    deleteUserLoginActionlog(input: $input, condition: $condition) {
      id
      user_id
      device_os
      device_id
      device_type
      device_name
      user_agent
      remote_ip
      login_status
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createUserNotifications = /* GraphQL */ `
  mutation CreateUserNotifications(
    $input: CreateUserNotificationsInput!
    $condition: ModelUserNotificationsConditionInput
  ) {
    createUserNotifications(input: $input, condition: $condition) {
      id
      user_id
      user_name
      alert_msg
      redirect_url
      status
      read_status
      added_by_id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateUserNotifications = /* GraphQL */ `
  mutation UpdateUserNotifications(
    $input: UpdateUserNotificationsInput!
    $condition: ModelUserNotificationsConditionInput
  ) {
    updateUserNotifications(input: $input, condition: $condition) {
      id
      user_id
      user_name
      alert_msg
      redirect_url
      status
      read_status
      added_by_id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteUserNotifications = /* GraphQL */ `
  mutation DeleteUserNotifications(
    $input: DeleteUserNotificationsInput!
    $condition: ModelUserNotificationsConditionInput
  ) {
    deleteUserNotifications(input: $input, condition: $condition) {
      id
      user_id
      user_name
      alert_msg
      redirect_url
      status
      read_status
      added_by_id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createUserSavedPlaces = /* GraphQL */ `
  mutation CreateUserSavedPlaces(
    $input: CreateUserSavedPlacesInput!
    $condition: ModelUserSavedPlacesConditionInput
  ) {
    createUserSavedPlaces(input: $input, condition: $condition) {
      id
      user_id
      address_type
      address
      status
      createdAt
      updatedAt
      latitude
      longitude
      __typename
    }
  }
`;
export const updateUserSavedPlaces = /* GraphQL */ `
  mutation UpdateUserSavedPlaces(
    $input: UpdateUserSavedPlacesInput!
    $condition: ModelUserSavedPlacesConditionInput
  ) {
    updateUserSavedPlaces(input: $input, condition: $condition) {
      id
      user_id
      address_type
      address
      status
      createdAt
      updatedAt
      latitude
      longitude
      __typename
    }
  }
`;
export const deleteUserSavedPlaces = /* GraphQL */ `
  mutation DeleteUserSavedPlaces(
    $input: DeleteUserSavedPlacesInput!
    $condition: ModelUserSavedPlacesConditionInput
  ) {
    deleteUserSavedPlaces(input: $input, condition: $condition) {
      id
      user_id
      address_type
      address
      status
      createdAt
      updatedAt
      latitude
      longitude
      __typename
    }
  }
`;
export const createUserStorageManagement = /* GraphQL */ `
  mutation CreateUserStorageManagement(
    $input: CreateUserStorageManagementInput!
    $condition: ModelUserStorageManagementConditionInput
  ) {
    createUserStorageManagement(input: $input, condition: $condition) {
      id
      user_id
      key_name
      file_type
      file_size
      tag_type
      status
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateUserStorageManagement = /* GraphQL */ `
  mutation UpdateUserStorageManagement(
    $input: UpdateUserStorageManagementInput!
    $condition: ModelUserStorageManagementConditionInput
  ) {
    updateUserStorageManagement(input: $input, condition: $condition) {
      id
      user_id
      key_name
      file_type
      file_size
      tag_type
      status
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteUserStorageManagement = /* GraphQL */ `
  mutation DeleteUserStorageManagement(
    $input: DeleteUserStorageManagementInput!
    $condition: ModelUserStorageManagementConditionInput
  ) {
    deleteUserStorageManagement(input: $input, condition: $condition) {
      id
      user_id
      key_name
      file_type
      file_size
      tag_type
      status
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createUserOnlineStatus = /* GraphQL */ `
  mutation CreateUserOnlineStatus(
    $input: CreateUserOnlineStatusInput!
    $condition: ModelUserOnlineStatusConditionInput
  ) {
    createUserOnlineStatus(input: $input, condition: $condition) {
      id
      user_id
      last_active_date
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateUserOnlineStatus = /* GraphQL */ `
  mutation UpdateUserOnlineStatus(
    $input: UpdateUserOnlineStatusInput!
    $condition: ModelUserOnlineStatusConditionInput
  ) {
    updateUserOnlineStatus(input: $input, condition: $condition) {
      id
      user_id
      last_active_date
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteUserOnlineStatus = /* GraphQL */ `
  mutation DeleteUserOnlineStatus(
    $input: DeleteUserOnlineStatusInput!
    $condition: ModelUserOnlineStatusConditionInput
  ) {
    deleteUserOnlineStatus(input: $input, condition: $condition) {
      id
      user_id
      last_active_date
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createUserInAppEmails = /* GraphQL */ `
  mutation CreateUserInAppEmails(
    $input: CreateUserInAppEmailsInput!
    $condition: ModelUserInAppEmailsConditionInput
  ) {
    createUserInAppEmails(input: $input, condition: $condition) {
      id
      from_email
      to_email
      from_user_id
      to_user_id
      is_from_admin
      subject_line
      message_body
      read_status
      status
      category
      sub_category
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateUserInAppEmails = /* GraphQL */ `
  mutation UpdateUserInAppEmails(
    $input: UpdateUserInAppEmailsInput!
    $condition: ModelUserInAppEmailsConditionInput
  ) {
    updateUserInAppEmails(input: $input, condition: $condition) {
      id
      from_email
      to_email
      from_user_id
      to_user_id
      is_from_admin
      subject_line
      message_body
      read_status
      status
      category
      sub_category
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteUserInAppEmails = /* GraphQL */ `
  mutation DeleteUserInAppEmails(
    $input: DeleteUserInAppEmailsInput!
    $condition: ModelUserInAppEmailsConditionInput
  ) {
    deleteUserInAppEmails(input: $input, condition: $condition) {
      id
      from_email
      to_email
      from_user_id
      to_user_id
      is_from_admin
      subject_line
      message_body
      read_status
      status
      category
      sub_category
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createMediaPlayLogs = /* GraphQL */ `
  mutation CreateMediaPlayLogs(
    $input: CreateMediaPlayLogsInput!
    $condition: ModelMediaPlayLogsConditionInput
  ) {
    createMediaPlayLogs(input: $input, condition: $condition) {
      id
      tag_id
      tag_type
      media_type
      user_id
      extra
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateMediaPlayLogs = /* GraphQL */ `
  mutation UpdateMediaPlayLogs(
    $input: UpdateMediaPlayLogsInput!
    $condition: ModelMediaPlayLogsConditionInput
  ) {
    updateMediaPlayLogs(input: $input, condition: $condition) {
      id
      tag_id
      tag_type
      media_type
      user_id
      extra
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteMediaPlayLogs = /* GraphQL */ `
  mutation DeleteMediaPlayLogs(
    $input: DeleteMediaPlayLogsInput!
    $condition: ModelMediaPlayLogsConditionInput
  ) {
    deleteMediaPlayLogs(input: $input, condition: $condition) {
      id
      tag_id
      tag_type
      media_type
      user_id
      extra
      createdAt
      updatedAt
      __typename
    }
  }
`;
