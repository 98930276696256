import { TAGLISTDETAIL, RESET_TAG, LISTUI, CLEAR_ERRORS, UPDATE_LOADING, UPDATE_ERROR, TAG_UPDATE, CLEAR_UPDATE } from '../types'

const initialState = {
    TagListDetail: [],
    isloading: true,
    loader: false,
    editloader: false,
    TagDetail: {}
}

export const Dashboardreducer = (state = initialState, action) => {
    switch (action.type) {
        case TAGLISTDETAIL:
            return {
                ...state,
                TagListDetail: action.payload.Taglist,
                isloading: false,
                loader: false
            }
        case LISTUI:
            return {
                ...state,
                loader: true
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                loader: false
            }
        case UPDATE_LOADING:
            return {
                ...state,
                editloader: true
            }
        case UPDATE_ERROR:
            return {
                ...state,
                editloader: false
            }
        case TAG_UPDATE:
            return {
                ...state,
                editloader: false,
                TagDetail: action.payload.TagDetail
            }
        case CLEAR_UPDATE:
            return {
                ...state,
                editloader: false,
                TagDetail: {}
            }
        case RESET_TAG:
            return {
                ...state,
                TagListDetail: [],
            }
        default:
            return state;
    }
}